import React from 'react';
import PropTypes from 'prop-types';

function MedicationsIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M24.5625 22.5938L34.4062 32.4844C34.5 32.5469 34.5469 32.6406 34.5469 32.7656C34.5469 32.8906 34.5 32.9844 34.4062 33.0469C32.9375 34.1406 31.2812 34.6094 29.4375 34.4531C27.5938 34.3281 26.0156 33.625 24.7031 32.3438C23.3906 31.0312 22.6719 29.4531 22.5469 27.6094C22.4219 25.7656 22.8906 24.1094 23.9531 22.6406C24.0469 22.5469 24.1562 22.5 24.2812 22.5C24.375 22.5 24.4688 22.5312 24.5625 22.5938ZM30.6094 19.5469C32.4531 19.6719 34.0156 20.3906 35.2969 21.7031C36.6094 23.0156 37.3281 24.5938 37.4531 26.4375C37.6094 28.2812 37.1406 29.9375 36.0469 31.4062C35.9844 31.5 35.8906 31.5469 35.7656 31.5469C35.6719 31.5469 35.5781 31.5 35.4844 31.4062L25.5938 21.5625C25.5312 21.4688 25.5 21.375 25.5 21.2812C25.5 21.1562 25.5469 21.0469 25.6406 20.9531C27.1094 19.8906 28.7656 19.4219 30.6094 19.5469ZM12.0469 15.0469C13.0781 14.0156 14.3125 13.5 15.75 13.5C17.1875 13.5 18.4219 14.0156 19.4531 15.0469C20.4844 16.0781 21 17.3125 21 18.75V24H10.5V18.75C10.5 17.3125 11.0156 16.0781 12.0469 15.0469Z" />
      <path fill={color} d="M21 24V29.25C21 30.6875 20.4844 31.9219 19.4531 32.9531C18.4219 33.9844 17.1875 34.5 15.75 34.5C14.3125 34.5 13.0781 33.9844 12.0469 32.9531C11.0156 31.9219 10.5 30.6875 10.5 29.25V24H21Z" />
    </svg>
  );
}

MedicationsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default MedicationsIcon;
