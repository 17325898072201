import HttpClient from './HttpClient';
import parseResponse from './parseResponse';
import LoginProvider from '../../Models/LoginProvider';

export default class LoginProviderRepository {
  static async getAll() {
    return HttpClient.get('/loginProviders').then(
      (response) => parseResponse(response, (data) => data.map(LoginProvider.fromApi)),
      ({ response }) => parseResponse(response),
    );
  }
}
