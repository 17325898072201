import { configureStore } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { reducer as sessionReducer } from './Session/slice';
import { reducer as spaceReducer } from './Space/slice';
import { reducer as achievementReducer } from './Achievement/slice';
import { reducer as blackHoleReducer } from './BlackHole/slice';
import { reducer as dialogReducer } from './Dialog/slice';
import { reducer as loginProviderReducer } from './LoginProvider/slice';
import { reducer as emojiReducer } from './Emoji/slice';
import { LAST_USED_EMOJI_KEY } from '../../Common/const';

const lastUsedEmojiLocalStorageSync = (store) => (next) => (action) => {
  const result = next(action);
  const lastUsedEmoji = _get(store.getState(), 'emoji.lastUsedEmoji');
  localStorage.setItem(LAST_USED_EMOJI_KEY, JSON.stringify(lastUsedEmoji));
  return result;
};

export default configureStore({
  reducer: {
    session: sessionReducer,
    space: spaceReducer,
    achievement: achievementReducer,
    blackHole: blackHoleReducer,
    dialog: dialogReducer,
    loginProvider: loginProviderReducer,
    emoji: emojiReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
    });

    middlewares.push(lastUsedEmojiLocalStorageSync);

    return middlewares;
  },
});
