const TYPE = 'type';
const IS_LINKED = 'isLinked';

export default class LoginProvider {
  constructor(parameters) {
    const {
      type, isLinked,
    } = parameters;
    this.type = type;
    this.isLinked = isLinked;
  }

  static fromApi(object = {}) {
    return new LoginProvider({
      type: object[TYPE],
      isLinked: object[IS_LINKED],
    });
  }

  toApi() {
    return {
      [TYPE]: this.type,
      [IS_LINKED]: this.isLinked,
    };
  }
}
