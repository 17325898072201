/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import _random from 'lodash/random';
import styles from './SpaceBoxPlaceholder.module.scss';
import GoalBoxPlaceholder from '../GoalBoxPlaceholder/GoalBoxPlaceholder';
import { ReactComponent as CashSvg } from './Cash.svg';
import { ReactComponent as DiscoSvg } from './Disco.svg';
import { ReactComponent as HomeSvg } from './Home.svg';
import { ReactComponent as ImageSvg } from './Image.svg';
import { ReactComponent as UserSvg } from './User.svg';

function SpaceBoxPlaceholder() {
  const goalCount = useMemo(() => _random(1, 3), []);
  const Icon = useMemo(() => {
    switch (_random(4)) {
      case 0:
        return CashSvg;
      case 1:
        return DiscoSvg;
      case 2:
        return HomeSvg;
      case 3:
        return ImageSvg;
      default:
        return UserSvg;
    }
  }, []);

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <Icon className={styles.spaceIcon} />
        <div className={styles.spaceName} />
      </div>
      <div className={styles.goals}>
        {[...Array(goalCount)].map((item, index) => (
          <GoalBoxPlaceholder key={index} />
        ))}
      </div>
    </div>
  );
}

export default SpaceBoxPlaceholder;
