import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SPACE_ICONS } from '../../const';

import NotebookIcon from './NotebookIcon';
import UserIcon from './UserIcon';
import CarIcon from './CarIcon';
import HomeIcon from './HomeIcon';
import PersonIcon from './PersonIcon';
import CalendarIcon from './CalendarIcon';
import ToolsIcon from './ToolsIcon';
import DollarIcon from './DollarIcon';
import ImageIcon from './ImageIcon';
import MedicationsIcon from './MedicationsIcon';
import TruckIcon from './TruckIcon';
import TeacherIcon from './TeacherIcon';
import InfoIcon from './InfoIcon';
import KeyIcon from './KeyIcon';
import AirplaneIcon from './AirplaneIcon';
import BallIcon from './BallIcon';

import styles from './SpaceIcon.module.scss';

function SpaceIcon({
  color, iconName, selectedIcon, className, ...props
}) {
  const Component = useMemo(() => {
    switch (iconName) {
      case SPACE_ICONS.USER:
        return UserIcon;
      case SPACE_ICONS.NOTEBOOK:
        return NotebookIcon;
      case SPACE_ICONS.CAR:
        return CarIcon;
      case SPACE_ICONS.HOME:
        return HomeIcon;
      case SPACE_ICONS.PERSON:
        return PersonIcon;
      case SPACE_ICONS.CALENDAR:
        return CalendarIcon;
      case SPACE_ICONS.AIRPLANE:
        return AirplaneIcon;
      case SPACE_ICONS.BALL:
        return BallIcon;
      case SPACE_ICONS.TOOLS:
        return ToolsIcon;
      case SPACE_ICONS.DOLLAR:
        return DollarIcon;
      case SPACE_ICONS.IMAGE:
        return ImageIcon;
      case SPACE_ICONS.MEDICATIONS:
        return MedicationsIcon;
      case SPACE_ICONS.TRUCK:
        return TruckIcon;
      case SPACE_ICONS.TEACHER:
        return TeacherIcon;
      case SPACE_ICONS.INFO:
        return InfoIcon;
      default:
        return KeyIcon;
    }
  }, [iconName]);

  const imgClass = useMemo(
    () => classNames(styles.icon, className),
    [className],
  );

  return (
    <Component
      className={imgClass}
      style={iconName === selectedIcon ? { outline: `2px solid ${color}` } : {}}
      color={color}
      {...props}
    />
  );
}

SpaceIcon.propTypes = {
  color: PropTypes.string.isRequired,
  iconName: PropTypes.oneOf(Object.values(SPACE_ICONS)).isRequired,
  selectedIcon: PropTypes.oneOf(Object.values(SPACE_ICONS)),
  className: PropTypes.string,
};

SpaceIcon.defaultProps = {
  selectedIcon: undefined,
  className: '',
};

export default SpaceIcon;
