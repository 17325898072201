import store from '../Store/store';
import TokenRepository from '../Repositories/LocalStorage/TokenRepository';
import { removeToken, setToken } from '../Store/Session/slice';
import { resetSearchEmoji } from '../Store/Emoji/slice';
import { resetSpace } from '../Store/Space/slice';
import { resetLoginProvider } from '../Store/LoginProvider/slice';
import { resetBlackHole } from '../Store/BlackHole/slice';
import { resetAchievement } from '../Store/Achievement/slice';

export default class AuthService {
  static logIn(token) {
    TokenRepository.save(token);
    store.dispatch(setToken(token));

    return { status: true };
  }

  static logOut() {
    TokenRepository.remove();
    store.dispatch(removeToken());
    store.dispatch(resetAchievement());
    store.dispatch(resetBlackHole());
    store.dispatch(resetSearchEmoji());
    store.dispatch(resetLoginProvider());
    store.dispatch(resetSpace());

    return { status: true };
  }
}
