import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import styles from './TopAchieveBox.module.scss';
import AchieveBox from '../AchieveBox/AchieveBox';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import GoalFull from '../../../DataCore/Models/GoalFull';
import GoalBoxPlaceholder from '../GoalBoxPlaceholder/GoalBoxPlaceholder';

function TopAchieveBox({
  number, goal, placeholder, onReorder,
}) {
  const [, drop] = useDrop({
    accept: DRAG_TYPES.GOAL_FROM_ACHIEVE,
    drop: (item) => ({
      name: GOAL_DROP.TOP_ACHIEVE,
      goal: new GoalFull({
        ...item.goal,
        favoriteIndex: number,
      }),
    }),
    hover(item) {
      const dragStartSpaceId = item.startSpaceId;
      onReorder({
        sourceSpaceId: dragStartSpaceId,
        goal: new GoalFull({
          ...item.goal,
          favoriteIndex: number,
        }),
      });
    },
  });

  const content = () => {
    switch (true) {
      case placeholder:
        return <GoalBoxPlaceholder achievement className={styles.achieve} />;
      case !!goal:
        return <AchieveBox className={styles.achieve} goal={goal} />;
      default:
        return <div className={styles.empty} />;
    }
  };

  return (
    <div ref={drop} className={styles.box}>
      <h2 className={styles.medal}>{number}</h2>
      {content()}
    </div>
  );
}

TopAchieveBox.propTypes = {
  number: PropTypes.number.isRequired,
  goal: PropTypes.object,
  placeholder: PropTypes.bool,
  onReorder: PropTypes.func.isRequired,
};

TopAchieveBox.defaultProps = {
  goal: undefined,
  placeholder: false,
};

export default TopAchieveBox;
