import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action) => {
      const {
        name, spaceId, goal, space,
      } = action.payload;

      return ({
        ...state,
        name,
        spaceId,
        goal,
        space,
      });
    },
    closeDialog: (state) => ({
      ...state,
      name: undefined,
      spaceId: undefined,
      goal: undefined,
      space: undefined,
    }),
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const getDialogName = (state) => state.dialog.name;

export const getDialogData = (state) => ({
  spaceId: state.dialog.spaceId,
  goal: state.dialog.goal,
  space: state.dialog.space,
});

export const { reducer } = dialogSlice;
