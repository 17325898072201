import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SpaceIcon from '../SpaceIcon/SpaceIcon';
import styles from './SpaceAchieveBox.module.scss';

function SpaceAchieveBox({
  innerRef, color, children, spaceName, spaceIcon, transparency, className,
}) {
  return (
    <div
      ref={innerRef}
      className={classNames(
        styles.box,
        {
          [styles.transparency]: transparency,
        },
        className,
      )}
      style={{ border: `2px solid ${color}` }}
    >
      <div className={styles.header} style={{ color }}>
        <div className={styles.title}>
          <SpaceIcon className={styles.spaceIcon} iconName={spaceIcon} color={color} />
          <h3 className={styles.spaceName}>{spaceName}</h3>
        </div>
      </div>
      {children}
    </div>
  );
}

SpaceAchieveBox.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  spaceName: PropTypes.string.isRequired,
  spaceIcon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  transparency: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};

SpaceAchieveBox.defaultProps = {
  innerRef: undefined,
  transparency: false,
  className: '',
};

export default SpaceAchieveBox;
