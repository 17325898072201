import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AchievementService from '../../DataCore/Service/AchievementService';
import BlackHole from '../../Common/Components/BlackHole/BlackHole';
import styles from './Main.module.scss';
import SpacesList from '../../Common/Components/SpacesList/SpacesList';
import AchievesList from '../../Common/Components/AchievesList/AchievesList';
import BlackHoleButton from '../../Common/Components/BlackHoleButton/BlackHoleButton';
import PreloadImages from '../../Common/Components/PreloadImages/PreloadImages';

function MainPage() {
  const [showBlackHole, setShowBlackHole] = useState(false);
  const { goalId: routeGoalId } = useParams();

  useEffect(() => {
    if (+routeGoalId) {
      AchievementService.loadAndOpenGoalDialog(+routeGoalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeGoalId]);

  return (
    <div className={styles.content}>
      <SpacesList />
      <AchievesList />
      <BlackHoleButton
        onClick={() => setShowBlackHole(!showBlackHole)}
      />
      <BlackHole
        showBlackHole={showBlackHole}
        onClose={() => setShowBlackHole(false)}
      />
      <PreloadImages />
    </div>
  );
}

export default MainPage;
