import moment from 'moment';
import { DATE_FORMAT } from '../../Common/const';

const ID = 'id';
const NAME = 'name';
const ICON = 'icon';
const DESCRIPTION = 'description';
const STATE = 'state';
const INDEX = 'index';
const DEADLINE = 'deadline';
const COMPLETED_DATE = 'compeleteDate';
const SPACE_ID = 'spaceId';
const FAVORITE_INDEX = 'favoriteIndex';

const ERROR_NAME = 'Name';
const ERROR_ICON = 'Icon';
const ERROR_DESCRIPTION = 'Description';
const ERROR_STATE = 'State';
const ERROR_INDEX = 'Index';
const ERROR_DEADLINE = 'Deadline';
const ERROR_COMPLETED_DATE = 'CompeleteDate';
const ERROR_SPACE_ID = 'SpaceId';
const ERROR_FAVORITE_INDEX = 'FavoriteIndex';

export default class Goal {
  constructor(parameters) {
    const {
      id,
      name,
      icon,
      description,
      state,
      index,
      deadline,
      completedDate,
      spaceId,
      favoriteIndex,
    } = parameters;
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.description = description;
    this.state = state;
    this.index = index;
    this.deadline = deadline;
    this.completedDate = completedDate;
    this.spaceId = spaceId;
    this.favoriteIndex = favoriteIndex;
  }

  static fromApi(object = {}) {
    const deadline = object[DEADLINE] && moment(object[DEADLINE]);
    const completedDate = object[COMPLETED_DATE] && moment(object[COMPLETED_DATE]);
    return new Goal({
      id: object[ID],
      name: object[NAME],
      icon: object[ICON],
      description: object[DESCRIPTION],
      state: object[STATE],
      index: object[INDEX],
      deadline: deadline?.isValid() ? deadline.format(DATE_FORMAT) : '',
      completedDate: completedDate?.isValid() ? completedDate.format(DATE_FORMAT) : '',
      spaceId: object[SPACE_ID],
      favoriteIndex: object[FAVORITE_INDEX],
    });
  }

  toApi() {
    return {
      [ID]: this.id,
      [NAME]: this.name,
      [ICON]: this.icon,
      [DESCRIPTION]: this.description,
      [STATE]: this.state,
      [INDEX]: this.index,
      [DEADLINE]: this.deadline,
      [COMPLETED_DATE]: this.completedDate,
      [SPACE_ID]: this.spaceId,
      [FAVORITE_INDEX]: this.favoriteIndex,
    };
  }

  static parseErrors(object = {}) {
    return {
      name: object[ERROR_NAME],
      icon: object[ERROR_ICON],
      description: object[ERROR_DESCRIPTION],
      state: object[ERROR_STATE],
      index: object[ERROR_INDEX],
      deadline: object[ERROR_DEADLINE],
      completedDate: object[ERROR_COMPLETED_DATE],
      spaceId: object[ERROR_SPACE_ID],
      favoriteIndex: object[ERROR_FAVORITE_INDEX],
    };
  }
}
