import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BaseDialog from '../BaseDialog/BaseDialog';
import { DATE_FORMAT } from '../../const';
import { composeValidators, maxLengthValidate, requiredValidate } from '../../validators';
import GoalService from '../../../DataCore/Service/GoalService';
import ActionButtons from '../ActionButtons/ActionButtons';
import styles from './GoalAchievementDialog.module.scss';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { parseFormErrors } from '../../func';

function GoalAchievementDialog({
  spaceId, goal, showDialog, onClose,
}) {
  const { t } = useTranslation('translation');

  const handleGoalAchievement = async (values) => {
    if (!spaceId) {
      throw new Error('Required field spaceId');
    }

    const result = await GoalService.moveToAchievements(spaceId, values);
    if (result.status) {
      onClose();
      return undefined;
    }
    return parseFormErrors(result);
  };

  return (
    <FinalForm
      onSubmit={handleGoalAchievement}
      initialValues={{
        ...goal,
        completedDate: moment().format(DATE_FORMAT),
      }}
      render={({
        handleSubmit, pristine, submitError,
      }) => (
        <BaseDialog
          className={styles.dialog}
          showDialog={showDialog}
          onClose={onClose}
          liteClose={pristine}
        >
          <form onSubmit={handleSubmit} className={styles.goalForm}>
            <h2 className={styles.header}>{t('goalAchievementDialog.title')}</h2>
            <div>
              <Field name="name" validate={composeValidators(requiredValidate, maxLengthValidate(50))}>
                {({ input, meta }) => (
                  <label
                    htmlFor="goalName"
                    className={classNames(
                      styles.goalLabel,
                      { [styles.error]: meta.touched && meta.error },
                    )}
                  >
                    {t('goalAchievementDialog.name')}
                    <input
                      id="goalName"
                      className={styles.goalInput}
                      placeholder={t('goalAchievementDialog.namePlaceholder')}
                      maxLength={50}
                      {...input}
                    />
                    <div className={styles.errorMessage}>{meta.error}</div>
                  </label>
                )}
              </Field>
            </div>
            <div>
              <Field name="completedDate" validate={requiredValidate}>
                {({ input, meta }) => (
                  <label
                    htmlFor="goalCompletedDate"
                    className={classNames(
                      styles.goalLabel,
                      { [styles.error]: meta.touched && meta.error },
                    )}
                  >
                    {t('goalAchievementDialog.completedDate')}
                    <input
                      id="goalCompletedDate"
                      className={styles.goalInput}
                      placeholder={t('goalAchievementDialog.completedDatePlaceholder')}
                      type="date"
                      {...input}
                    />
                    <div className={styles.errorMessage}>{meta.error}</div>
                  </label>
                )}
              </Field>
            </div>
            <ErrorMessage content={submitError ? t(submitError) : undefined} />
            <ActionButtons
              confirmButtonText={t('goalAchievementDialog.confirm')}
              cancelButtonText={t('goalAchievementDialog.cancel')}
              onCancelButtonClick={onClose}
            />
          </form>
        </BaseDialog>
      )}
    />
  );
}

GoalAchievementDialog.propTypes = {
  spaceId: PropTypes.number,
  goal: PropTypes.object,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

GoalAchievementDialog.defaultProps = {
  spaceId: undefined,
  goal: undefined,
};

export default GoalAchievementDialog;
