import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const loginProviderSlice = createSlice({
  name: 'loginProvider',
  initialState,
  reducers: {
    startLoadingLoginProviders: (state) => ({
      ...state,
      loadingLoginProviders: true,
    }),
    endLoadingLoginProviders: (state) => ({
      ...state,
      loadingLoginProviders: false,
    }),
    setAvailableLoginProviders: (state, action) => {
      const availableLoginProviders = action.payload;
      return {
        ...state,
        availableLoginProviders,
      };
    },
    resetLoginProvider: () => initialState,
  },
});

export const {
  startLoadingLoginProviders,
  endLoadingLoginProviders,
  setAvailableLoginProviders,
  resetLoginProvider,
} = loginProviderSlice.actions;

export const getAvailableLoginProviders = (state) => state.loginProvider.availableLoginProviders;

export const getLoadingLoginProviders = (state) => state.loginProvider.loadingLoginProviders;

export const { reducer } = loginProviderSlice;
