import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { endLoadingSpaces, setReorderGoal, startLoadingSpaces } from '../Store/Space/slice';
import GoalRepository from '../Repositories/Api/GoalRepository';
import SpaceService from './SpaceService';
import store from '../Store/store';
import { ymAction } from '../../Common/func';
import { GOAL_STATES, YM_ACTION_TYPES } from '../../Common/const';
import Goal from '../Models/Goal';
import {
  addAchievements,
  setAchievementsTotal, setAchievementsTotalIntoSpaces,
} from '../Store/Achievement/slice';
import GoalFull from '../Models/GoalFull';
import AchievementRepository from '../Repositories/Api/AchievementRepository';

export default class GoalService {
  static async create(spaceId, goal) {
    store.dispatch(startLoadingSpaces());

    const resultCreate = await GoalRepository.create(spaceId, goal);
    if (!resultCreate.status) {
      store.dispatch(endLoadingSpaces());
      return resultCreate;
    }

    ymAction(YM_ACTION_TYPES.CREATE_GOAL);
    await SpaceService.getAll();

    store.dispatch(endLoadingSpaces());
    return resultCreate;
  }

  static async update(spaceId, goal) {
    store.dispatch(startLoadingSpaces());

    const state = store.getState();
    const { spaces } = state.space;
    const goals = _flatten(_map(spaces, 'goals'));
    const { achievements } = state.achievement;
    const abandonedGoals = state.blackHole.goals;
    const oldGoal = _find([
      ...goals,
      ...achievements,
      ...abandonedGoals,
    ], ['id', goal.id]);

    const goalIsEqual = _isEqual(new Goal(goal), new Goal(oldGoal));
    if (goalIsEqual) {
      return { status: true, data: new Goal(goal) };
    }

    const result = await GoalRepository.update(spaceId, goal);
    if (!result.status) {
      store.dispatch(endLoadingSpaces());
      return result;
    }

    if (oldGoal) {
      if (oldGoal.spaceId === goal.spaceId && oldGoal.name !== goal.name) {
        ymAction(YM_ACTION_TYPES.RENAME_GOAL);
      } else if (oldGoal.spaceId !== goal.spaceId && oldGoal.state === GOAL_STATES.ACHIEVED) {
        ymAction(YM_ACTION_TYPES.MOVING_ACHIEVEMENT_TO_ANOTHER_SPACE);
      } else if (oldGoal.spaceId !== goal.spaceId && oldGoal.state === GOAL_STATES.IN_PROGRESS) {
        ymAction(YM_ACTION_TYPES.MOVING_GOAL_TO_ANOTHER_SPACE);
      } else if (oldGoal.state === GOAL_STATES.IN_PROGRESS && goal.state === GOAL_STATES.ACHIEVED) {
        ymAction(YM_ACTION_TYPES.REACHED_GOAL);
        // eslint-disable-next-line max-len
      } else if (oldGoal.state === GOAL_STATES.IN_PROGRESS && goal.state === GOAL_STATES.IN_ARCHIVE) {
        ymAction(YM_ACTION_TYPES.ABANDONED_GOAL);
        // eslint-disable-next-line max-len
      } else if (oldGoal.state === GOAL_STATES.IN_ARCHIVE && goal.state === GOAL_STATES.IN_PROGRESS) {
        ymAction(YM_ACTION_TYPES.RESURRECTED_GOAL);
      } else if (!oldGoal.favoriteIndex && goal.favoriteIndex) {
        ymAction(YM_ACTION_TYPES.MOVING_ACHIEVEMENT_TO_TOP);
      }
    }

    if (goal.state === GOAL_STATES.IN_PROGRESS || oldGoal?.state === GOAL_STATES.IN_PROGRESS) {
      await SpaceService.getAll();
    }
    store.dispatch(endLoadingSpaces());

    ymAction(YM_ACTION_TYPES.EDIT_GOAL);
    return result;
  }

  static async moveToAchievements(spaceId, goal) {
    store.dispatch(startLoadingSpaces());

    const newGoal = new Goal({
      ...goal,
      index: 0,
      state: GOAL_STATES.ACHIEVED,
    });
    const result = await GoalRepository.update(spaceId, newGoal);
    if (!result.status) {
      store.dispatch(endLoadingSpaces());
      return result;
    }

    const state = store.getState();
    const { achievementsTotal } = state.achievement;
    const { spaces } = state.space;

    const goalSpace = _find(spaces, ['id', _get(result, 'data.spaceId')]);
    const achievement = goalSpace ? new GoalFull({
      ...result.data,
      space: goalSpace,
    }) : await AchievementRepository.getById(_get(result, 'data.id'));

    store.dispatch(addAchievements([achievement]));
    store.dispatch(setAchievementsTotal(achievementsTotal + 1));

    ymAction(YM_ACTION_TYPES.REACHED_GOAL);
    await SpaceService.getAll();
    store.dispatch(endLoadingSpaces());
    return result;
  }

  static async moveToTopAchievements(spaceId, goal) {
    const result = await GoalRepository.update(spaceId, goal);
    if (!result.status) {
      return result;
    }

    const state = store.getState();
    const { achievements } = state.achievement;
    if (goal.favoriteIndex) {
      const oldFavoriteAchievements = _filter(
        achievements,
        (item) => item.favoriteIndex === goal.favoriteIndex,
      )
        .map((achievement) => new GoalFull({ ...achievement, favoriteIndex: undefined }));
      store.dispatch(addAchievements([new GoalFull(goal), ...oldFavoriteAchievements]));
    }

    ymAction(YM_ACTION_TYPES.MOVING_ACHIEVEMENT_TO_TOP);
    return result;
  }

  static async moveOfAchievementToSpace(spaceId, goal) {
    const result = await GoalRepository.update(spaceId, goal);
    if (!result.status) {
      store.dispatch(endLoadingSpaces());
      return result;
    }

    const state = store.getState();
    const { achievementsTotalIntoSpaces } = state.achievement;
    const { spaces } = state.space;

    const goalSpace = _find(spaces, ['id', _get(result, 'data.spaceId')]);
    const achievement = goalSpace ? new GoalFull({
      ...result.data,
      space: goalSpace,
    }) : await AchievementRepository.getById(_get(result, 'data.id'));

    store.dispatch(addAchievements([achievement]));
    store.dispatch(setAchievementsTotalIntoSpaces({
      spaceId,
      achievementsTotal: _get(achievementsTotalIntoSpaces, spaceId, 1) - 1,
    }));
    store.dispatch(setAchievementsTotalIntoSpaces({
      spaceId: goal.spaceId,
      achievementsTotal: _get(achievementsTotalIntoSpaces, goal.spaceId, 0) + 1,
    }));

    if (spaceId !== goal.spaceId) {
      ymAction(YM_ACTION_TYPES.MOVING_ACHIEVEMENT_TO_ANOTHER_SPACE);
    }
    return result;
  }

  static setReorderGoal(goal) {
    store.dispatch(setReorderGoal(goal));
  }
}
