/* eslint-disable object-curly-newline */
export const AUTHORIZATION_HEADER = 'authorization';

export const ERROR_TYPES = Object.freeze({
  BAD_REQUEST: 'BadRequest',
  UNAUTHORIZED: 'Unauthorized',
  FORBIDDEN: 'Forbidden',
  NOT_FOUND: 'NotFound',
  SERVER_ERROR: 'ServerError',
  NETWORK_ERROR: 'NetworkError',
  OTHER: 'Other',
});

export const SPACE_COLORS = Object.freeze({
  BLUE: '#6B7FE3',
  GREEN: '#64B26C',
  ORANGE: '#EDAC2E',
  RED: '#D35D47',
  VIOLET: '#914CB1',
  AZURE: '#3AB3C4',
  BLACK: '#7C7E8B',
  GREY: '#DADCDF',
});

export const SPACE_ICONS = Object.freeze({
  USER: 'user',
  NOTEBOOK: 'notebook',
  CAR: 'car',
  HOME: 'home',
  PERSON: 'person',
  CALENDAR: 'calendar',
  AIRPLANE: 'airplane',
  BALL: 'ball',
  TOOLS: 'tools',
  DOLLAR: 'dollar',
  IMAGE: 'image',
  MEDICATIONS: 'medications',
  TRUCK: 'truck',
  TEACHER: 'teacher',
  INFO: 'info',
  KEY: 'key',
});

export const TOAST_STATUS = Object.freeze({
  PRIMARY: 'PRIMARY',
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  DANGER: 'DANGER',
});

export const GOAL_STATES = Object.freeze({
  IN_PROGRESS: 'InProgress',
  ACHIEVED: 'Achieved',
  IN_ARCHIVE: 'InArchive',
});

export const GOAL_DROP = Object.freeze({
  SPACE: 'Space',
  ACHIEVE: 'Achieve',
  TOP_ACHIEVE: 'TopAchieve',
  BLACK_HOLE: 'BlackHole',
});

export const DRAG_TYPES = Object.freeze({
  SPACE: 'Space',
  GOAL_FROM_SPACE: 'GoalFromSpace',
  GOAL_FROM_BLACK_HOLE: 'GoalFromBlackHole',
  GOAL_FROM_ACHIEVE: 'GoalFromAchieve',
});

export const DATE_FORMAT = 'YYYY-MM-DD';

export const VIEW_DATE_FORMAT = 'D.MM.YYYY';

export const DIALOG_NAMES = Object.freeze({
  CREATE_SPACE: 'CREATE_SPACE',
  EDIT_SPACE: 'EDIT_SPACE',
  CREATE_GOAL: 'CREATE_GOAL',
  EDIT_GOAL: 'EDIT_GOAL',
  GOAL_ACHIEVEMENT: 'GOAL_ACHIEVEMENT',
  GOAL_BLACK_HOLE: 'GOAL_BLACK_HOLE',
  HELP: 'HELP',
  ACHIEVE_SHARE: 'ACHIEVE_SHARE',
  FEEDBACK: 'FEEDBACK',
});

export const LOGIN_PROVIDER_TYPES = Object.freeze({
  GOOGLE: 'Google',
  TELEGRAM: 'Telegram',
});

export const YM_ACTION_TYPES = Object.freeze({
  SIGN_IN_GOOGLE: 'SIGN_IN_GOOGLE',
  SIGN_IN_TELEGRAM: 'SIGN_IN_TELEGRAM',
  ADD_PROVIDER_GOOGLE: 'ADD_PROVIDER_GOOGLE',
  ADD_PROVIDER_TELEGRAM: 'ADD_PROVIDER_TELEGRAM',
  CREATE_SPACE: 'CREATE_SPACE',
  RENAME_SPACE: 'RENAME_SPACE',
  EDIT_SPACE: 'EDIT_SPACE',
  CREATE_GOAL: 'CREATE_GOAL',
  RENAME_GOAL: 'RENAME_GOAL',
  EDIT_GOAL: 'EDIT_GOAL',
  REACHED_GOAL: 'REACHED_GOAL',
  ABANDONED_GOAL: 'ABANDONED_GOAL',
  RESURRECTED_GOAL: 'RESURRECTED_GOAL',
  OPEN_HELP: 'OPEN_HELP',
  MOVING_GOAL_TO_ANOTHER_SPACE: 'MOVING_GOAL_TO_ANOTHER_SPACE',
  MOVING_ACHIEVEMENT_TO_ANOTHER_SPACE: 'MOVING_ACHIEVEMENT_TO_ANOTHER_SPACE',
  MOVING_ACHIEVEMENT_TO_TOP: 'MOVING_ACHIEVEMENT_TO_TOP',
  SHARE_ACHIEVEMENT_TELEGRAM: 'SHARE_ACHIEVEMENT_TELEGRAM',
  SHARE_ACHIEVEMENT_VK: 'SHARE_ACHIEVEMENT_VK',
});

export const LAST_USED_EMOJI_KEY = 'lastUsedEmoji';

export const EMOJI_CATEGORIES = Object.freeze({
  ACTIVITY: 'activity',
  FOOD_AND_DRINK: 'foodAndDrink',
  NATURE: 'nature',
  OBJECTS: 'objects',
  SYMBOLS_AND_FLAGS: 'symbolsAndFlags',
  TRAVEL_AND_PLACES: 'travelAndPlaces',
  PEOPLE: 'people',
});

export const EMOJI = Object.freeze({
  BEAMING_FACE_WITH_SMILING_EYES: { search: 'beaming face with smiling eyes|сияющее лицо с улыбающимися глазами', name: '129', category: EMOJI_CATEGORIES.PEOPLE },
  ANGRY_FACE: { search: 'angry face|сердитый лицо', name: '25', category: EMOJI_CATEGORIES.PEOPLE },
  ANGRY_FACE_WITH_HORNS: { search: 'angry face with horns|сердитое лицо с рогами', name: '24', category: EMOJI_CATEGORIES.PEOPLE },
  ANGUISHED_FACE: { search: 'anguished face|страдающее лицо', name: '27', category: EMOJI_CATEGORIES.PEOPLE },
  ANXIOUS_FACE_WITH_SWEAT: { search: 'anxious face with sweat|тревожное лицо с потом', name: '29', category: EMOJI_CATEGORIES.PEOPLE },
  ASTONISHED_FACE: { search: 'astonished face|удивленное лицо', name: '40', category: EMOJI_CATEGORIES.PEOPLE },
  CRYING_FACE: { search: 'crying face|плакать лицо', name: '399', category: EMOJI_CATEGORIES.PEOPLE },
  BLUSHING_FACE_WITH_HAND_OVER_MOUTH: { search: 'blushing face with hand over mouth|покрасневшее лицо с рукой, прикрывающей рот', name: '187', category: EMOJI_CATEGORIES.PEOPLE },
  COLD_FACE: { search: 'cold face|холодное лицо', name: '341', category: EMOJI_CATEGORIES.PEOPLE },
  EXHALING_FACE: { search: 'exhaling face|выдохнуть лицо', name: '533', category: EMOJI_CATEGORIES.PEOPLE },
  EXPLODING_HEAD: { search: 'exploding head|взрывая голова', name: '534', category: EMOJI_CATEGORIES.PEOPLE },
  EXPRESSIONLESS_FACE: { search: 'expressionless face|бессрочное лицо', name: '536', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_BLOWING_A_KISS: { search: 'face blowing a kiss|лицо дует поцелуй', name: '539', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_IN_CLOUDS: { search: 'face in clouds|лицо в облаках', name: '540', category: EMOJI_CATEGORIES.PEOPLE },
  CONFOUNDED_FACE: { search: 'confounded face|озадаченное лицо', name: '347', category: EMOJI_CATEGORIES.PEOPLE },
  CONFUSED_FACE: { search: 'confused face|растерянное лицо', name: '348', category: EMOJI_CATEGORIES.PEOPLE },
  COWBOY_FACE: { search: 'cowboy face|ковбой лицо', name: '385', category: EMOJI_CATEGORIES.PEOPLE },
  DISAPPOINTED_FACE: { search: 'disappointed face|разочарованное лицо', name: '455', category: EMOJI_CATEGORIES.PEOPLE },
  DISGUISED_FACE: { search: 'disguised face|замаскированное лицо', name: '456', category: EMOJI_CATEGORIES.PEOPLE },
  DISTRAUGHT_FACE: { search: 'distraught face|развратное лицо', name: '457', category: EMOJI_CATEGORIES.PEOPLE },
  DIZZY_FACE: { search: 'dizzy face|головокружительное лицо', name: '461', category: EMOJI_CATEGORIES.PEOPLE },
  DOWNCAST_FACE_WITH_SWEAT: { search: 'downcast face with sweat|downcast лицо с пот', name: '475', category: EMOJI_CATEGORIES.PEOPLE },
  DROOLING_FACE: { search: 'drooling face|утопление лица', name: '484', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_SCREAMING_IN_FEAR: { search: 'face screaming in fear|лицо кричит в страхе', name: '541', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_A_ZIPPER_MOUTH: { search: 'face with a zipper mouth|лицо с молнией рот', name: '542', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_HEAD_BANDAGE: { search: 'face with head-bandage|лицо с повязкой', name: '543', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_MEDICAL_MASK: { search: 'face with medical mask|лицо с медицинской маской', name: '544', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_MONOCLE: { search: 'face with monocle|лицо с монокла', name: '545', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITHOUT_MOUTH: { search: 'face without mouth|лицо без рта', name: '557', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_RAISED_EYEBROW: { search: 'face with raised eyebrow|лицо с повышенным брови', name: '546', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_ROLLING_EYES: { search: 'face with rolling eyes|лицо с подвижными глазами', name: '547', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_SPIRAL_EYES: { search: 'face with spiral eyes|лицо с спиральными глазами', name: '548', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_STARRY_EYES: { search: 'face with starry eyes|лицо со звездными глазами', name: '549', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_STEAM_FROM_NOSE: { search: 'face with steam from nose|лицо с паром из носа', name: '550', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_STUCK_OUT_TONGUE_AND_SQUINTING_EYES: { search: 'face with stuck-out tongue and squinting eyes|лицо с застрянутым языком и пайет глазами', name: '551', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_STUCK_OUT_TONGUE: { search: 'face with stuck-out tongue|лицо с застрявшим языком', name: '552', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_SYMBOLS_OVER_MOUTH: { search: 'face with symbols over mouth|лицо с символами во рту', name: '553', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_TEARS_OF_JOY: { search: 'face with tears of joy|лицо с слезами радости', name: '554', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_THERMOMETER: { search: 'face with thermometer|лицо с термометром', name: '555', category: EMOJI_CATEGORIES.PEOPLE },
  FACE_WITH_WIDE_OPEN_EYES: { search: 'face with wide open eyes|лицо с широкими открытыми глазами', name: '556', category: EMOJI_CATEGORIES.PEOPLE },
  FEARFUL_FACE: { search: 'fearful face|страшное лицо', name: '607', category: EMOJI_CATEGORIES.PEOPLE },
  FROWNING_FACE: { search: 'frowning face|жареное лицо', name: '932', category: EMOJI_CATEGORIES.PEOPLE },
  FROWNING_FACE_WITH_OPEN_MOUTH: { search: 'frowning face with open mouth|жареное лицо с открытым ртом', name: '931', category: EMOJI_CATEGORIES.PEOPLE },
  GRIMACING_FACE: { search: 'grimacing face|grimacing лицо', name: '981', category: EMOJI_CATEGORIES.PEOPLE },
  GOOFY_FACE: { search: 'goofy face|goofy лицо', name: '971', category: EMOJI_CATEGORIES.PEOPLE },
  GRINNING_FACE: { search: 'grinning face|шлифованное лицо', name: '987', category: EMOJI_CATEGORIES.PEOPLE },
  GRINNING_FACE_WITH_BIG_EYES: { search: 'grinning face with big eyes|шлифованное лицо с большими глазами', name: '983', category: EMOJI_CATEGORIES.PEOPLE },
  GRINNING_FACE_WITH_SMILING_EYES: { search: 'grinning face with smiling eyes|шлифованное лицо с улыбчивыми глазами', name: '984', category: EMOJI_CATEGORIES.PEOPLE },
  GRINNING_FACE_WITH_SQUINTING_EYES: { search: 'grinning face with squinting eyes|шлифованное лицо с пайетными глазами', name: '985', category: EMOJI_CATEGORIES.PEOPLE },
  GRINNING_FACE_WITH_SWEAT: { search: 'grinning face with sweat|шлифованное лицо с пот', name: '986', category: EMOJI_CATEGORIES.PEOPLE },
  HOT_FACE: { search: 'hot face|горячее лицо', name: '1077', category: EMOJI_CATEGORIES.PEOPLE },
  HUGGING_FACE: { search: 'hugging face|hugging лицо', name: '1085', category: EMOJI_CATEGORIES.PEOPLE },
  KISSING_FACE: { search: 'kissing face|поцелуй лицо', name: '1187', category: EMOJI_CATEGORIES.PEOPLE },
  KISSING_FACE_WITH_CLOSED_EYES: { search: 'kissing face with closed eyes|поцелуй лицо с закрытыми глазами', name: '1185', category: EMOJI_CATEGORIES.PEOPLE },
  KISSING_FACE_WITH_SMILING_EYES: { search: 'kissing face with smiling eyes|поцелуй лицо с улыбчивыми глазами', name: '1186', category: EMOJI_CATEGORIES.PEOPLE },
  LOUDLY_CRYING_FACE: { search: 'loudly crying face|громко плакать лицо', name: '1244', category: EMOJI_CATEGORIES.PEOPLE },
  LYING_FACE: { search: 'lying face|лживое лицо', name: '1256', category: EMOJI_CATEGORIES.PEOPLE },
  MONEY_MOUTH_FACE: { search: 'money-mouth face|money mouth лицо', name: '1736', category: EMOJI_CATEGORIES.PEOPLE },
  NAUSEATED_FACE: { search: 'nauseated face|населенный лицо', name: '1792', category: EMOJI_CATEGORIES.PEOPLE },
  NEUTRAL_FACE: { search: 'neutral face|нейтральное лицо', name: '1795', category: EMOJI_CATEGORIES.PEOPLE },
  PARTY_FACE: { search: 'party face|сторона лицо', name: '1919', category: EMOJI_CATEGORIES.PEOPLE },
  PERSEVERING_FACE: { search: 'persevering face|настойчивое лицо', name: '1953', category: EMOJI_CATEGORIES.PEOPLE },
  PLEADING_FACE: { search: 'pleading face|приятное лицо', name: '2185', category: EMOJI_CATEGORIES.PEOPLE },
  RED_ANGRY_FACE: { search: 'red angry face|красное сердитое лицо', name: '2287', category: EMOJI_CATEGORIES.PEOPLE },
  RELIEVED_FACE: { search: 'relieved face|освобожденное лицо', name: '2302', category: EMOJI_CATEGORIES.PEOPLE },
  ROLLING_ON_THE_FLOOR_LAUGHING_FACE: { search: 'rolling on the floor laughing face|катание на полу смеясь лицом', name: '2340', category: EMOJI_CATEGORIES.PEOPLE },
  SAD_BUT_RELIEVED_FACE: { search: 'sad but relieved face|печальное но снятое лицо', name: '2353', category: EMOJI_CATEGORIES.PEOPLE },
  SAD_PENSIVE_FACE: { search: 'sad pensive face|печальное лицо', name: '2354', category: EMOJI_CATEGORIES.PEOPLE },
  SHUSHING_FACE: { search: 'shushing face|шлифовка лица', name: '2419', category: EMOJI_CATEGORIES.PEOPLE },
  SLEEPING_FACE: { search: 'sleeping face|спать лицо', name: '2465', category: EMOJI_CATEGORIES.PEOPLE },
  SLEEPY_FACE: { search: 'sleepy face|сонливое лицо', name: '2467', category: EMOJI_CATEGORIES.PEOPLE },
  SLIGHTLY_FROWNING_FACE: { search: 'slightly frowning face|слегка жареное лицо', name: '2468', category: EMOJI_CATEGORIES.PEOPLE },
  SLIGHTLY_SMILING_FACE: { search: 'slightly smiling face|немного улыбается лицо', name: '2469', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_LICKING_LIPS: { search: 'smiling face licking lips|улыбающиеся лица лизать губы', name: '2476', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE: { search: 'smiling face|улыбка лица', name: '2485', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_GLASSES: { search: 'smiling face with glasses|улыбка лица с очками', name: '2477', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_HALO: { search: 'smiling face with halo|улыбаясь лицом с хало', name: '2478', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_HEART_EYES: { search: 'smiling face with heart-eyes|улыбающееся лицо с сердечными глазами', name: '2479', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_HEARTS: { search: 'smiling face with hearts|улыбающееся лицо с сердцами', name: '2480', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_HORNS: { search: 'smiling face with horns|улыбаясь лицом с рогами', name: '2481', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_SMILING_EYES: { search: 'smiling face with smiling eyes|улыбаясь лицом с улыбчивыми глазами', name: '2482', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_SUNGLASSES: { search: 'smiling face with sunglasses|улыбающееся лицо с солнцезащитными очками', name: '2483', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_FACE_WITH_TEAR: { search: 'smiling face with tear|улыбается лицо слезой', name: '2484', category: EMOJI_CATEGORIES.PEOPLE },
  SMIRKING_FACE: { search: 'smirking face|восхитительное лицо', name: '2487', category: EMOJI_CATEGORIES.PEOPLE },
  SNEEZING_FACE: { search: 'sneezing face|снежное лицо', name: '2490', category: EMOJI_CATEGORIES.PEOPLE },
  SURPRISED_FACE: { search: 'surprised face|удивленное лицо', name: '2591', category: EMOJI_CATEGORIES.PEOPLE },
  SURPRISED_FACE_WITH_OPEN_MOUTH: { search: 'surprised face with open mouth|удивленное лицо с открытым ртом', name: '2590', category: EMOJI_CATEGORIES.PEOPLE },
  THINKING_FACE: { search: 'thinking face|мышление лицо', name: '2638', category: EMOJI_CATEGORIES.PEOPLE },
  UNAMUSED_FACE: { search: 'unamused face|неиспользуемое лицо', name: '2786', category: EMOJI_CATEGORIES.PEOPLE },
  UPSIDE_DOWN_FACE: { search: 'upside down face|вверх вниз лицом', name: '2795', category: EMOJI_CATEGORIES.PEOPLE },
  VOMITING_FACE: { search: 'vomiting face|вомитинг лицо', name: '2811', category: EMOJI_CATEGORIES.PEOPLE },
  WEARY_FACE: { search: 'weary face|weary лицо', name: '2834', category: EMOJI_CATEGORIES.PEOPLE },
  WINKING_FACE: { search: 'winking face|победить лицо', name: '2863', category: EMOJI_CATEGORIES.PEOPLE },
  WINKING_FACE_WITH_STUCK_OUT_TONGUE: { search: 'winking face with stuck-out tongue|победить лицо с застрявшим языком', name: '2862', category: EMOJI_CATEGORIES.PEOPLE },
  YAWNING_FACE: { search: 'yawning face|рассветное лицо', name: '3301', category: EMOJI_CATEGORIES.PEOPLE },
  WOOZY_FACE: { search: 'woozy face|woozy лицо', name: '3288', category: EMOJI_CATEGORIES.PEOPLE },
  WORRIED_FACE: { search: 'worried face|беспокоится лицо', name: '3291', category: EMOJI_CATEGORIES.PEOPLE },
  CLOWN_FACE: { search: 'clown face|клоунское лицо', name: '333', category: EMOJI_CATEGORIES.PEOPLE },
  PILE_OF_POOP: { search: 'pile of poop|свай poop', name: '2155', category: EMOJI_CATEGORIES.PEOPLE },
  ROBOT_FACE: { search: 'robot face|робот лицо', name: '2327', category: EMOJI_CATEGORIES.PEOPLE },
  ALIEN_MONSTER: { search: 'alien monster|инопланетный монстр', name: '16', category: EMOJI_CATEGORIES.PEOPLE },
  GOBLIN: { search: 'goblin|гоблин', name: '961', category: EMOJI_CATEGORIES.PEOPLE },
  OGRE: { search: 'ogre|огре', name: '1837', category: EMOJI_CATEGORIES.PEOPLE },
  JACK_O_LANTERN: { search: 'jack-o-lantern|джек о лантерн', name: '1128', category: EMOJI_CATEGORIES.PEOPLE },
  SKULL_AND_CROSSBONES: { search: 'skull and crossbones|череп и кроссбони', name: '2461', category: EMOJI_CATEGORIES.PEOPLE },
  SKULL: { search: 'skull|череп', name: '2462', category: EMOJI_CATEGORIES.PEOPLE },
  GHOST: { search: 'ghost|призрак', name: '943', category: EMOJI_CATEGORIES.PEOPLE },
  ALIEN: { search: 'alien|инопланетяне', name: '17', category: EMOJI_CATEGORIES.PEOPLE },
  SMIRKING_CAT_FACE: { search: 'smirking cat face|smirking кошка лицо', name: '2486', category: EMOJI_CATEGORIES.PEOPLE },
  CAT_FACE: { search: 'cat face|кошачья морда', name: '264', category: EMOJI_CATEGORIES.PEOPLE },
  POUTING_CAT_FACE: { search: 'pouting cat face|pouting кошка лицо', name: '2220', category: EMOJI_CATEGORIES.PEOPLE },
  HAPPY_CAT_FACE: { search: 'happy cat face|счастливое лицо кошки', name: '1037', category: EMOJI_CATEGORIES.PEOPLE },
  SMILING_CAT_FACE_WITH_HEART_EYES: { search: 'smiling cat face with heart-eyes|улыбаясь кошка лицом к сердцу', name: '2475', category: EMOJI_CATEGORIES.PEOPLE },
  KISSING_CAT_FACE: { search: 'kissing cat face|поцелуй кошки лицо', name: '1184', category: EMOJI_CATEGORIES.PEOPLE },
  CRYING_CAT_FACE: { search: 'crying cat face|плачущая кошачья морда', name: '398', category: EMOJI_CATEGORIES.PEOPLE },
  GRINNING_CAT_FACE: { search: 'grinning cat face|гриннинг кошка лицо', name: '982', category: EMOJI_CATEGORIES.PEOPLE },
  CAT_FACE_SCREAMING_IN_FEAR: { search: 'cat face screaming in fear|морда кота кричит от страха', name: '262', category: EMOJI_CATEGORIES.PEOPLE },
  CAT_FACE_WITH_TEARS_OF_JOY: { search: 'cat face with tears of joy|кошачье лицо со слезами радости', name: '263', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_DOWN_0: { search: 'backhand index finger pointing down 0|указательный палец левой руки направлен вниз 0', name: '74', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_DOWN_1: { search: 'backhand index finger pointing down 1|указательный палец левой руки направлен вниз 1', name: '75', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_DOWN_2: { search: 'backhand index finger pointing down 2|указательный палец левой руки направлен вниз 2', name: '76', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_DOWN_3: { search: 'backhand index finger pointing down 3|указательный палец левой руки направлен вниз 3', name: '77', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_DOWN_4: { search: 'backhand index finger pointing down 4|указательный палец левой руки направлен вниз 4', name: '78', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_DOWN_5: { search: 'backhand index finger pointing down 5|указательный палец левой руки направлен вниз 5', name: '79', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_LEFT_0: { search: 'backhand index finger pointing left 0|указательный палец левой руки указывает влево 0', name: '80', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_LEFT_1: { search: 'backhand index finger pointing left 1|указательный палец левой руки указывает влево 1', name: '81', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_LEFT_2: { search: 'backhand index finger pointing left 2|указательный палец левой руки указывает влево 2', name: '82', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_LEFT_3: { search: 'backhand index finger pointing left 3|указательный палец левой руки указывает влево 3', name: '83', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_LEFT_4: { search: 'backhand index finger pointing left 4|указательный палец левой руки указывает влево 4', name: '84', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_LEFT_5: { search: 'backhand index finger pointing left 5|указательный палец левой руки указывает влево 5', name: '85', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_RIGHT_0: { search: 'backhand index finger pointing right 0|указательный палец левой руки указывает вправо 0', name: '86', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_RIGHT_1: { search: 'backhand index finger pointing right 1|указательный палец левой руки указывает вправо 1', name: '87', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_RIGHT_2: { search: 'backhand index finger pointing right 2|указательный палец левой руки указывает вправо 2', name: '88', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_RIGHT_3: { search: 'backhand index finger pointing right 3|указательный палец левой руки указывает вправо 3', name: '89', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_RIGHT_4: { search: 'backhand index finger pointing right 4|указательный палец левой руки указывает вправо 4', name: '90', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_RIGHT_5: { search: 'backhand index finger pointing right 5|указательный палец левой руки указывает вправо 5', name: '91', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_UP_0: { search: 'backhand index finger pointing up 0|указательный палец левой руки направлен вверх 0', name: '92', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_UP_1: { search: 'backhand index finger pointing up 1|указательный палец левой руки направлен вверх 1', name: '93', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_UP_2: { search: 'backhand index finger pointing up 2|указательный палец левой руки направлен вверх 2', name: '94', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_UP_3: { search: 'backhand index finger pointing up 3|указательный палец левой руки направлен вверх 3', name: '95', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_UP_4: { search: 'backhand index finger pointing up 4|указательный палец левой руки направлен вверх 4', name: '96', category: EMOJI_CATEGORIES.PEOPLE },
  BACKHAND_INDEX_FINGER_POINTING_UP_5: { search: 'backhand index finger pointing up 5|указательный палец левой руки направлен вверх 5', name: '97', category: EMOJI_CATEGORIES.PEOPLE },
  BACK_OF_HAND_0: { search: 'back of hand 0|тыльная сторона ладони 0', name: '68', category: EMOJI_CATEGORIES.PEOPLE },
  BACK_OF_HAND_1: { search: 'back of hand 1|тыльная сторона ладони 1', name: '69', category: EMOJI_CATEGORIES.PEOPLE },
  BACK_OF_HAND_2: { search: 'back of hand 2|тыльная сторона ладони 2', name: '70', category: EMOJI_CATEGORIES.PEOPLE },
  BACK_OF_HAND_3: { search: 'back of hand 3|тыльная сторона ладони 3', name: '71', category: EMOJI_CATEGORIES.PEOPLE },
  BACK_OF_HAND_4: { search: 'back of hand 4|тыльная сторона ладони 4', name: '72', category: EMOJI_CATEGORIES.PEOPLE },
  BACK_OF_HAND_5: { search: 'back of hand 5|тыльная сторона ладони 5', name: '73', category: EMOJI_CATEGORIES.PEOPLE },
  CALL_ME_HAND_SIGN_0: { search: 'call me hand sign 0|позвони 0', name: '239', category: EMOJI_CATEGORIES.PEOPLE },
  CALL_ME_HAND_SIGN_1: { search: 'call me hand sign 1|позвони 1', name: '240', category: EMOJI_CATEGORIES.PEOPLE },
  CALL_ME_HAND_SIGN_2: { search: 'call me hand sign 2|позвони 2', name: '241', category: EMOJI_CATEGORIES.PEOPLE },
  CALL_ME_HAND_SIGN_3: { search: 'call me hand sign 3|позвони 3', name: '242', category: EMOJI_CATEGORIES.PEOPLE },
  CALL_ME_HAND_SIGN_4: { search: 'call me hand sign 4|позвони 4', name: '243', category: EMOJI_CATEGORIES.PEOPLE },
  CALL_ME_HAND_SIGN_5: { search: 'call me hand sign 5|позвони 5', name: '244', category: EMOJI_CATEGORIES.PEOPLE },
  CLAPPING_HANDS_0: { search: 'clapping hands 0|хлопать в ладоши 0 0', name: '305', category: EMOJI_CATEGORIES.PEOPLE },
  CLAPPING_HANDS_1: { search: 'clapping hands 1|хлопать в ладоши 1', name: '306', category: EMOJI_CATEGORIES.PEOPLE },
  CLAPPING_HANDS_2: { search: 'clapping hands 2|хлопать в ладоши 2', name: '307', category: EMOJI_CATEGORIES.PEOPLE },
  CLAPPING_HANDS_3: { search: 'clapping hands 3|хлопать в ладоши 3', name: '308', category: EMOJI_CATEGORIES.PEOPLE },
  CLAPPING_HANDS_4: { search: 'clapping hands 4|хлопать в ладоши 4', name: '309', category: EMOJI_CATEGORIES.PEOPLE },
  CLAPPING_HANDS_5: { search: 'clapping hands 5|хлопать в ладоши 5', name: '310', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_FIST_0: { search: 'closed fist 0|сжатый кулак 0', name: '317', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_FIST_1: { search: 'closed fist 1|сжатый кулак 1', name: '318', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_FIST_2: { search: 'closed fist 2|сжатый кулак 2', name: '319', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_FIST_3: { search: 'closed fist 3|сжатый кулак 3', name: '320', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_FIST_4: { search: 'closed fist 4|сжатый кулак 4', name: '321', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_FIST_5: { search: 'closed fist 5|сжатый кулак 5', name: '322', category: EMOJI_CATEGORIES.PEOPLE },
  FINGERS_CROSSED_0: { search: 'fingers crossed 0|пальцы пересекли 0', name: '616', category: EMOJI_CATEGORIES.PEOPLE },
  FINGERS_CROSSED_1: { search: 'fingers crossed 1|пальцы пересекли 1', name: '617', category: EMOJI_CATEGORIES.PEOPLE },
  FINGERS_CROSSED_2: { search: 'fingers crossed 2|пальцы пересекли 2', name: '618', category: EMOJI_CATEGORIES.PEOPLE },
  FINGERS_CROSSED_3: { search: 'fingers crossed 3|пальцы пересекли 3', name: '619', category: EMOJI_CATEGORIES.PEOPLE },
  FINGERS_CROSSED_4: { search: 'fingers crossed 4|пальцы пересекли 4', name: '620', category: EMOJI_CATEGORIES.PEOPLE },
  FINGERS_CROSSED_5: { search: 'fingers crossed 5|пальцы пересекли 5', name: '621', category: EMOJI_CATEGORIES.PEOPLE },
  INDEX_FINGER_POINTING_UP_0: { search: 'index finger pointing up 0|индекс пальца указывающий на 0', name: '1096', category: EMOJI_CATEGORIES.PEOPLE },
  INDEX_FINGER_POINTING_UP_1: { search: 'index finger pointing up 1|индекс пальца указывающий до 1', name: '1097', category: EMOJI_CATEGORIES.PEOPLE },
  INDEX_FINGER_POINTING_UP_2: { search: 'index finger pointing up 2|индекс пальца указывающий на 2', name: '1098', category: EMOJI_CATEGORIES.PEOPLE },
  INDEX_FINGER_POINTING_UP_3: { search: 'index finger pointing up 3|индекс пальца указывающий на 3', name: '1099', category: EMOJI_CATEGORIES.PEOPLE },
  INDEX_FINGER_POINTING_UP_4: { search: 'index finger pointing up 4|индекс пальца указывающий на 4', name: '1100', category: EMOJI_CATEGORIES.PEOPLE },
  INDEX_FINGER_POINTING_UP_5: { search: 'index finger pointing up 5|индекс пальца указывающий на 5', name: '1101', category: EMOJI_CATEGORIES.PEOPLE },
  LOVE_YOU_GESTURE_0: { search: 'love-you gesture 0|любовь вы жест 0', name: '1247', category: EMOJI_CATEGORIES.PEOPLE },
  LOVE_YOU_GESTURE_1: { search: 'love-you gesture 1|love вы жест 1', name: '1248', category: EMOJI_CATEGORIES.PEOPLE },
  LOVE_YOU_GESTURE_2: { search: 'love-you gesture 2|love вы жест 2', name: '1249', category: EMOJI_CATEGORIES.PEOPLE },
  LOVE_YOU_GESTURE_3: { search: 'love-you gesture 3|love you жест 3', name: '1250', category: EMOJI_CATEGORIES.PEOPLE },
  LOVE_YOU_GESTURE_4: { search: 'love-you gesture 4|love you жест 4', name: '1251', category: EMOJI_CATEGORIES.PEOPLE },
  LOVE_YOU_GESTURE_5: { search: 'love-you gesture 5|love you жест 5', name: '1252', category: EMOJI_CATEGORIES.PEOPLE },
  OPEN_HANDS_0: { search: 'open hands 0|открытые руки 0', name: '1878', category: EMOJI_CATEGORIES.PEOPLE },
  OPEN_HANDS_1: { search: 'open hands 1|открытые руки 1', name: '1879', category: EMOJI_CATEGORIES.PEOPLE },
  OPEN_HANDS_2: { search: 'open hands 2|открытые руки 2', name: '1880', category: EMOJI_CATEGORIES.PEOPLE },
  OPEN_HANDS_3: { search: 'open hands 3|открытые руки 3', name: '1881', category: EMOJI_CATEGORIES.PEOPLE },
  OPEN_HANDS_4: { search: 'open hands 4|открытые руки 4', name: '1882', category: EMOJI_CATEGORIES.PEOPLE },
  OPEN_HANDS_5: { search: 'open hands 5|открытые руки 5', name: '1883', category: EMOJI_CATEGORIES.PEOPLE },
  PALMS_TOGETHER_FACING_UP_0: { search: 'palms together facing up 0|пальмы вместе стоящие вверх 0', name: '1905', category: EMOJI_CATEGORIES.PEOPLE },
  PALMS_TOGETHER_FACING_UP_1: { search: 'palms together facing up 1|пальмы вместе с 1', name: '1906', category: EMOJI_CATEGORIES.PEOPLE },
  PALMS_TOGETHER_FACING_UP_2: { search: 'palms together facing up 2|пальмы вместе с 2', name: '1907', category: EMOJI_CATEGORIES.PEOPLE },
  PALMS_TOGETHER_FACING_UP_3: { search: 'palms together facing up 3|пальмы вместе с 3', name: '1908', category: EMOJI_CATEGORIES.PEOPLE },
  PALMS_TOGETHER_FACING_UP_4: { search: 'palms together facing up 4|пальмы вместе с 4', name: '1909', category: EMOJI_CATEGORIES.PEOPLE },
  PALMS_TOGETHER_FACING_UP_5: { search: 'palms together facing up 5|пальмы вместе стоящие на 5', name: '1910', category: EMOJI_CATEGORIES.PEOPLE },
  PEACE_HAND_SIGN_0: { search: 'peace hand sign 0|знак спокойствия 0', name: '1925', category: EMOJI_CATEGORIES.PEOPLE },
  PEACE_HAND_SIGN_1: { search: 'peace hand sign 1|знак спокойствия 1', name: '1926', category: EMOJI_CATEGORIES.PEOPLE },
  PEACE_HAND_SIGN_2: { search: 'peace hand sign 2|знак спокойствия 2', name: '1927', category: EMOJI_CATEGORIES.PEOPLE },
  PEACE_HAND_SIGN_3: { search: 'peace hand sign 3|знак спокойствия 3', name: '1928', category: EMOJI_CATEGORIES.PEOPLE },
  PEACE_HAND_SIGN_4: { search: 'peace hand sign 4|знак руки мира 4', name: '1929', category: EMOJI_CATEGORIES.PEOPLE },
  PEACE_HAND_SIGN_5: { search: 'peace hand sign 5|знак руки мира 5', name: '1930', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHED_FINGERS_0: { search: 'pinched fingers 0|пиндельные пальцы 0', name: '2164', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHED_FINGERS_1: { search: 'pinched fingers 1|пиндельные пальцы 1', name: '2165', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHED_FINGERS_2: { search: 'pinched fingers 2|пиндельные пальцы 2', name: '2166', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHED_FINGERS_3: { search: 'pinched fingers 3|пиндельные пальцы 3', name: '2167', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHED_FINGERS_4: { search: 'pinched fingers 4|пиндельные пальцы 4', name: '2168', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHED_FINGERS_5: { search: 'pinched fingers 5|пиндельные пальцы 5', name: '2169', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHING_HAND_0: { search: 'pinching hand 0|пинчинг рука 0', name: '2170', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHING_HAND_1: { search: 'pinching hand 1|пинчинг рука 1', name: '2171', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHING_HAND_2: { search: 'pinching hand 2|пинчинг рука 2', name: '2172', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHING_HAND_3: { search: 'pinching hand 3|пинчинг рука 3', name: '2173', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHING_HAND_4: { search: 'pinching hand 4|пинчинг рука 4', name: '2174', category: EMOJI_CATEGORIES.PEOPLE },
  PINCHING_HAND_5: { search: 'pinching hand 5|пинчинг рука 5', name: '2175', category: EMOJI_CATEGORIES.PEOPLE },
  HANDSHAKE: { search: 'handshake|руки', name: '1036', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_PRESSED_TOGETHER_0: { search: 'hands pressed together 0|руки нажатые вместе 0', name: '1024', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_PRESSED_TOGETHER_1: { search: 'hands pressed together 1|руки прессовали вместе 1', name: '1025', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_PRESSED_TOGETHER_2: { search: 'hands pressed together 2|руки прессовали вместе 2', name: '1026', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_PRESSED_TOGETHER_3: { search: 'hands pressed together 3|руки прессовали вместе 3', name: '1027', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_PRESSED_TOGETHER_4: { search: 'hands pressed together 4|руки прессовали вместе 4', name: '1028', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_PRESSED_TOGETHER_5: { search: 'hands pressed together 5|руки прессовали вместе 5', name: '1029', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_RAISED_IN_CELEBRATION_0: { search: 'hands raised in celebration 0|руки поднятые в праздновании 0', name: '1030', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_RAISED_IN_CELEBRATION_1: { search: 'hands raised in celebration 1|руки поднятые в праздновании 1', name: '1031', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_RAISED_IN_CELEBRATION_2: { search: 'hands raised in celebration 2|руки поднятые в праздновании 2', name: '1032', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_RAISED_IN_CELEBRATION_3: { search: 'hands raised in celebration 3|руки поднятые в праздновании 3', name: '1033', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_RAISED_IN_CELEBRATION_4: { search: 'hands raised in celebration 4|руки поднятые в праздновании 4', name: '1034', category: EMOJI_CATEGORIES.PEOPLE },
  HANDS_RAISED_IN_CELEBRATION_5: { search: 'hands raised in celebration 5|руки поднятые в праздновании 5', name: '1035', category: EMOJI_CATEGORIES.PEOPLE },
  HAND_WITH_FINGERS_SPLIT_BETWEEN_MIDDLE_AND_RING_FINGERS_0: { search: 'hand with fingers split between middle and ring fingers 0|рука с пальцами разделена между середине и пальцами кольца 0', name: '1017', category: EMOJI_CATEGORIES.PEOPLE },
  HAND_WITH_FINGERS_SPLIT_BETWEEN_MIDDLE_AND_RING_FINGERS_1: { search: 'hand with fingers split between middle and ring fingers 1|рука с пальцами разделена между середине и пальцами кольца 1', name: '1018', category: EMOJI_CATEGORIES.PEOPLE },
  HAND_WITH_FINGERS_SPLIT_BETWEEN_MIDDLE_AND_RING_FINGERS_2: { search: 'hand with fingers split between middle and ring fingers 2|рука с пальцами разделена между середине и пальцами кольца 2', name: '1019', category: EMOJI_CATEGORIES.PEOPLE },
  HAND_WITH_FINGERS_SPLIT_BETWEEN_MIDDLE_AND_RING_FINGERS_3: { search: 'hand with fingers split between middle and ring fingers 3|рука с пальцами разделена между середине и пальцами кольца 3', name: '1020', category: EMOJI_CATEGORIES.PEOPLE },
  HAND_WITH_FINGERS_SPLIT_BETWEEN_MIDDLE_AND_RING_FINGERS_4: { search: 'hand with fingers split between middle and ring fingers 4|рука с пальцами разделена между середине и пальцами кольца 4', name: '1021', category: EMOJI_CATEGORIES.PEOPLE },
  HAND_WITH_FINGERS_SPLIT_BETWEEN_MIDDLE_AND_RING_FINGERS_5: { search: 'hand with fingers split between middle and ring fingers 5|рука с пальцами разделена между середине и пальцами кольца 5', name: '1022', category: EMOJI_CATEGORIES.PEOPLE },
  LEFT_FACING_FIST_0: { search: 'left-facing fist 0|левый футер 0', name: '1210', category: EMOJI_CATEGORIES.PEOPLE },
  LEFT_FACING_FIST_1: { search: 'left-facing fist 1|левый футер 1', name: '1211', category: EMOJI_CATEGORIES.PEOPLE },
  LEFT_FACING_FIST_2: { search: 'left-facing fist 2|левый футер 2', name: '1212', category: EMOJI_CATEGORIES.PEOPLE },
  LEFT_FACING_FIST_3: { search: 'left-facing fist 3|левый футер 3', name: '1213', category: EMOJI_CATEGORIES.PEOPLE },
  LEFT_FACING_FIST_4: { search: 'left-facing fist 4|левый футер 4', name: '1214', category: EMOJI_CATEGORIES.PEOPLE },
  LEFT_FACING_FIST_5: { search: 'left-facing fist 5|левый футер 5', name: '1215', category: EMOJI_CATEGORIES.PEOPLE },
  OK_HAND_SIGN_0: { search: 'OK hand sign 0|ok знак руки 0', name: '1839', category: EMOJI_CATEGORIES.PEOPLE },
  OK_HAND_SIGN_1: { search: 'OK hand sign 1|ok знак руки 1', name: '1840', category: EMOJI_CATEGORIES.PEOPLE },
  OK_HAND_SIGN_2: { search: 'OK hand sign 2|ок рука знак 2', name: '1841', category: EMOJI_CATEGORIES.PEOPLE },
  OK_HAND_SIGN_3: { search: 'OK hand sign 3|ok знак руки 3', name: '1842', category: EMOJI_CATEGORIES.PEOPLE },
  OK_HAND_SIGN_4: { search: 'OK hand sign 4|ok знак руки 4', name: '1843', category: EMOJI_CATEGORIES.PEOPLE },
  OK_HAND_SIGN_5: { search: 'OK hand sign 5|ok знак руки 5', name: '1844', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_FIST_0: { search: 'raised fist 0|поднятый fist 0', name: '2263', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_FIST_1: { search: 'raised fist 1|поднятый fist 1', name: '2264', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_FIST_2: { search: 'raised fist 2|поднятый fist 2', name: '2265', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_FIST_3: { search: 'raised fist 3|поднятый fist 3', name: '2266', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_FIST_4: { search: 'raised fist 4|поднятый fist 4', name: '2267', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_FIST_5: { search: 'raised fist 5|поднятый fist 5', name: '2268', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_0: { search: 'raised hand 0|поднятая рука 0', name: '2269', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_1: { search: 'raised hand 1|поднятая рука 1', name: '2270', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_2: { search: 'raised hand 2|поднятая рука 2', name: '2271', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_3: { search: 'raised hand 3|поднятая рука 3', name: '2272', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_4: { search: 'raised hand 4|поднятая рука 4', name: '2273', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_5: { search: 'raised hand 5|поднятая рука 5', name: '2274', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_WITH_FINGERS_SPLAYED_0: { search: 'raised hand with fingers splayed 0|поднятая рука с пальцами проиграна 0', name: '2275', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_WITH_FINGERS_SPLAYED_1: { search: 'raised hand with fingers splayed 1|поднятая рука с пальцами проиграна 1', name: '2276', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_WITH_FINGERS_SPLAYED_2: { search: 'raised hand with fingers splayed 2|поднятая рука с пальцами проиграна 2', name: '2277', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_WITH_FINGERS_SPLAYED_3: { search: 'raised hand with fingers splayed 3|поднятая рука с пальцами проиграна 3', name: '2278', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_WITH_FINGERS_SPLAYED_4: { search: 'raised hand with fingers splayed 4|поднятая рука с пальцами проиграна 4', name: '2279', category: EMOJI_CATEGORIES.PEOPLE },
  RAISED_HAND_WITH_FINGERS_SPLAYED_5: { search: 'raised hand with fingers splayed 5|поднятая рука с пальцами проиграна 5', name: '2280', category: EMOJI_CATEGORIES.PEOPLE },
  RIGHT_FACING_FIST_0: { search: 'right-facing fist 0|правый упаковка fist 0', name: '2317', category: EMOJI_CATEGORIES.PEOPLE },
  RIGHT_FACING_FIST_1: { search: 'right-facing fist 1|правый fist 1', name: '2318', category: EMOJI_CATEGORIES.PEOPLE },
  RIGHT_FACING_FIST_2: { search: 'right-facing fist 2|правый fist 2', name: '2319', category: EMOJI_CATEGORIES.PEOPLE },
  RIGHT_FACING_FIST_3: { search: 'right-facing fist 3|правый fist 3', name: '2320', category: EMOJI_CATEGORIES.PEOPLE },
  RIGHT_FACING_FIST_4: { search: 'right-facing fist 4|правый fist 4', name: '2321', category: EMOJI_CATEGORIES.PEOPLE },
  RIGHT_FACING_FIST_5: { search: 'right-facing fist 5|правый fist 5', name: '2322', category: EMOJI_CATEGORIES.PEOPLE },
  SIGN_OF_THE_HORNS_0: { search: 'sign of the horns 0|знак рога 0', name: '2420', category: EMOJI_CATEGORIES.PEOPLE },
  SIGN_OF_THE_HORNS_1: { search: 'sign of the horns 1|знак рога 1', name: '2421', category: EMOJI_CATEGORIES.PEOPLE },
  SIGN_OF_THE_HORNS_2: { search: 'sign of the horns 2|знак рога 2', name: '2422', category: EMOJI_CATEGORIES.PEOPLE },
  SIGN_OF_THE_HORNS_3: { search: 'sign of the horns 3|знак рога 3', name: '2423', category: EMOJI_CATEGORIES.PEOPLE },
  SIGN_OF_THE_HORNS_4: { search: 'sign of the horns 4|знак рога 4', name: '2424', category: EMOJI_CATEGORIES.PEOPLE },
  SIGN_OF_THE_HORNS_5: { search: 'sign of the horns 5|знак рога 5', name: '2425', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_DOWN_0: { search: 'thumbs down 0|пачки вниз 0', name: '2643', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_DOWN_1: { search: 'thumbs down 1|пачки вниз 1', name: '2644', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_DOWN_2: { search: 'thumbs down 2|пачки вниз 2', name: '2645', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_DOWN_3: { search: 'thumbs down 3|пачки вниз 3', name: '2646', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_DOWN_4: { search: 'thumbs down 4|пачки вниз 4', name: '2647', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_DOWN_5: { search: 'thumbs down 5|пачки вниз 5', name: '2648', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_UP_0: { search: 'thumbs up 0|пачки до 0', name: '2649', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_UP_1: { search: 'thumbs up 1|пальцы до 1', name: '2650', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_UP_2: { search: 'thumbs up 2|пальцы до 2', name: '2651', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_UP_3: { search: 'thumbs up 3|пальцы до 3', name: '2652', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_UP_4: { search: 'thumbs up 4|пальцы до 4', name: '2653', category: EMOJI_CATEGORIES.PEOPLE },
  THUMBS_UP_5: { search: 'thumbs up 5|пальцы до 5', name: '2654', category: EMOJI_CATEGORIES.PEOPLE },
  WAVING_HAND_0: { search: 'waving hand 0|waving рука 0', name: '2825', category: EMOJI_CATEGORIES.PEOPLE },
  WAVING_HAND_1: { search: 'waving hand 1|waving рука 1', name: '2826', category: EMOJI_CATEGORIES.PEOPLE },
  WAVING_HAND_2: { search: 'waving hand 2|вися рука 2', name: '2827', category: EMOJI_CATEGORIES.PEOPLE },
  WAVING_HAND_3: { search: 'waving hand 3|вися рука 3', name: '2828', category: EMOJI_CATEGORIES.PEOPLE },
  WAVING_HAND_4: { search: 'waving hand 4|waving рука 4', name: '2829', category: EMOJI_CATEGORIES.PEOPLE },
  WAVING_HAND_5: { search: 'waving hand 5|тиральная рука 5', name: '2830', category: EMOJI_CATEGORIES.PEOPLE },
  FLEXED_BICEP_0: { search: 'flexed bicep 0|флексированный бицеп 0', name: '897', category: EMOJI_CATEGORIES.PEOPLE },
  FLEXED_BICEP_1: { search: 'flexed bicep 1|флексированный бицепт 1', name: '898', category: EMOJI_CATEGORIES.PEOPLE },
  FLEXED_BICEP_2: { search: 'flexed bicep 2|флексированный бицеп 2', name: '899', category: EMOJI_CATEGORIES.PEOPLE },
  FLEXED_BICEP_3: { search: 'flexed bicep 3|флексированный бицепт 3', name: '900', category: EMOJI_CATEGORIES.PEOPLE },
  FLEXED_BICEP_4: { search: 'flexed bicep 4|флексированный бицепт 4', name: '901', category: EMOJI_CATEGORIES.PEOPLE },
  FLEXED_BICEP_5: { search: 'flexed bicep 5|флексированный бицеп 5', name: '902', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANICAL_ARM: { search: 'mechanical arm|механическая рука', name: '1685', category: EMOJI_CATEGORIES.PEOPLE },
  LEG_0: { search: 'leg 0|ноги 0', name: '1219', category: EMOJI_CATEGORIES.PEOPLE },
  LEG_1: { search: 'leg 1|ноги 1', name: '1220', category: EMOJI_CATEGORIES.PEOPLE },
  LEG_2: { search: 'leg 2|ноги 2', name: '1221', category: EMOJI_CATEGORIES.PEOPLE },
  LEG_3: { search: 'leg 3|ноги 3', name: '1222', category: EMOJI_CATEGORIES.PEOPLE },
  LEG_4: { search: 'leg 4|ноги 4', name: '1223', category: EMOJI_CATEGORIES.PEOPLE },
  LEG_5: { search: 'leg 5|ноги 5', name: '1224', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANICAL_LEG: { search: 'mechanical leg|механическая нога', name: '1686', category: EMOJI_CATEGORIES.PEOPLE },
  MIDDLE_FINGER_0: { search: 'middle finger 0|средний палец 0', name: '1719', category: EMOJI_CATEGORIES.PEOPLE },
  MIDDLE_FINGER_1: { search: 'middle finger 1|средний палец 1', name: '1720', category: EMOJI_CATEGORIES.PEOPLE },
  MIDDLE_FINGER_2: { search: 'middle finger 2|средний палец 2', name: '1721', category: EMOJI_CATEGORIES.PEOPLE },
  MIDDLE_FINGER_3: { search: 'middle finger 3|средний палец 3', name: '1722', category: EMOJI_CATEGORIES.PEOPLE },
  MIDDLE_FINGER_4: { search: 'middle finger 4|средний палец 4', name: '1723', category: EMOJI_CATEGORIES.PEOPLE },
  MIDDLE_FINGER_5: { search: 'middle finger 5|средний палец 5', name: '1724', category: EMOJI_CATEGORIES.PEOPLE },
  WRITING_HAND_0: { search: 'writing hand 0|написание руки 0', name: '3293', category: EMOJI_CATEGORIES.PEOPLE },
  WRITING_HAND_1: { search: 'writing hand 1|написание руки 1', name: '3294', category: EMOJI_CATEGORIES.PEOPLE },
  WRITING_HAND_2: { search: 'writing hand 2|написание руки 2', name: '3295', category: EMOJI_CATEGORIES.PEOPLE },
  WRITING_HAND_3: { search: 'writing hand 3|написание руки 3', name: '3296', category: EMOJI_CATEGORIES.PEOPLE },
  WRITING_HAND_4: { search: 'writing hand 4|написание руки 4', name: '3297', category: EMOJI_CATEGORIES.PEOPLE },
  WRITING_HAND_5: { search: 'writing hand 5|написание руки 5', name: '3298', category: EMOJI_CATEGORIES.PEOPLE },
  FOOT_0: { search: 'foot 0|пешком 0', name: '910', category: EMOJI_CATEGORIES.PEOPLE },
  FOOT_1: { search: 'foot 1|пешком 1', name: '911', category: EMOJI_CATEGORIES.PEOPLE },
  FOOT_2: { search: 'foot 2|пешком 2', name: '912', category: EMOJI_CATEGORIES.PEOPLE },
  FOOT_3: { search: 'foot 3|пешком 3', name: '913', category: EMOJI_CATEGORIES.PEOPLE },
  FOOT_4: { search: 'foot 4|пешком 4', name: '914', category: EMOJI_CATEGORIES.PEOPLE },
  FOOT_5: { search: 'foot 5|пешком 5', name: '915', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_0: { search: 'ear 0|ухо 0', name: '492', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_1: { search: 'ear 1|ухо 1', name: '493', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_2: { search: 'ear 2|ухо 2', name: '494', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_3: { search: 'ear 3|ухо 3', name: '495', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_4: { search: 'ear 4|ухо 4', name: '496', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_5: { search: 'ear 5|ухо 5', name: '497', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_WITH_HEARING_AID_0: { search: 'ear with hearing aid 0|ухо с слуховой аппарат 0', name: '500', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_WITH_HEARING_AID_1: { search: 'ear with hearing aid 1|ухо с слуховой аппарат 1', name: '501', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_WITH_HEARING_AID_2: { search: 'ear with hearing aid 2|ухо с слуховой аппарат 2', name: '502', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_WITH_HEARING_AID_3: { search: 'ear with hearing aid 3|ухо с слуховой аппарат 3', name: '503', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_WITH_HEARING_AID_4: { search: 'ear with hearing aid 4|ухо с слуховой аппарат 4', name: '504', category: EMOJI_CATEGORIES.PEOPLE },
  EAR_WITH_HEARING_AID_5: { search: 'ear with hearing aid 5|ухо с помощью слуха 5', name: '505', category: EMOJI_CATEGORIES.PEOPLE },
  NOSE_0: { search: 'nose 0|нос 0', name: '1818', category: EMOJI_CATEGORIES.PEOPLE },
  NOSE_1: { search: 'nose 1|нос 1', name: '1819', category: EMOJI_CATEGORIES.PEOPLE },
  NOSE_2: { search: 'nose 2|нос 2', name: '1820', category: EMOJI_CATEGORIES.PEOPLE },
  NOSE_3: { search: 'nose 3|нос 3', name: '1821', category: EMOJI_CATEGORIES.PEOPLE },
  NOSE_4: { search: 'nose 4|нос 4', name: '1822', category: EMOJI_CATEGORIES.PEOPLE },
  NOSE_5: { search: 'nose 5|нос 5', name: '1823', category: EMOJI_CATEGORIES.PEOPLE },
  FOOTPRINTS: { search: 'footprints|следы', name: '916', category: EMOJI_CATEGORIES.PEOPLE },
  LIPSTICK: { search: 'lipstick|помада', name: '1236', category: EMOJI_CATEGORIES.PEOPLE },
  KISS_MARK: { search: 'kiss mark|поцелуй знак', name: '1183', category: EMOJI_CATEGORIES.PEOPLE },
  MOUTH: { search: 'mouth|рот', name: '1762', category: EMOJI_CATEGORIES.PEOPLE },
  TONGUE: { search: 'tongue|язык', name: '2664', category: EMOJI_CATEGORIES.PEOPLE },
  TOOTH: { search: 'tooth|зуб', name: '2666', category: EMOJI_CATEGORIES.PEOPLE },
  EYE: { search: 'eye|глаза', name: '537', category: EMOJI_CATEGORIES.PEOPLE },
  EYES: { search: 'eyes|глаза', name: '538', category: EMOJI_CATEGORIES.PEOPLE },
  BRAIN: { search: 'brain|мозг', name: '208', category: EMOJI_CATEGORIES.PEOPLE },
  LUNGS: { search: 'lungs|легкие', name: '1255', category: EMOJI_CATEGORIES.PEOPLE },
  ANATOMICAL_HEART: { search: 'anatomical heart|анатомическое сердце', name: '21', category: EMOJI_CATEGORIES.PEOPLE },
  SPEAKING_SILHOUETTE_HEAD: { search: 'speaking silhouette head|говорить голова силуэта', name: '2523', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_OF_PERSON: { search: 'silhouette of person|силуэт человека', name: '2428', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_OF_TWO_PEOPLE: { search: 'silhouette of two people|силуэт двух человек', name: '2429', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HUGGING: { search: 'people hugging|люди hugging', name: '1950', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_0: { search: 'baby 0|малыш 0', name: '52', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_1: { search: 'baby 1|малыш 1', name: '53', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_2: { search: 'baby 2|малыш 2', name: '54', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_3: { search: 'baby 3|малыш 3', name: '55', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_4: { search: 'baby 4|малыш 4', name: '56', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_5: { search: 'baby 5|малыш 5', name: '57', category: EMOJI_CATEGORIES.PEOPLE },
  GIRL_0: { search: 'girl 0|девушка 0', name: '945', category: EMOJI_CATEGORIES.PEOPLE },
  GIRL_1: { search: 'girl 1|девушка 1', name: '946', category: EMOJI_CATEGORIES.PEOPLE },
  GIRL_2: { search: 'girl 2|девушка 2', name: '947', category: EMOJI_CATEGORIES.PEOPLE },
  GIRL_3: { search: 'girl 3|девушка 3', name: '948', category: EMOJI_CATEGORIES.PEOPLE },
  GIRL_4: { search: 'girl 4|девушка 4', name: '949', category: EMOJI_CATEGORIES.PEOPLE },
  GIRL_5: { search: 'girl 5|девушка 5', name: '950', category: EMOJI_CATEGORIES.PEOPLE },
  CHILD_0: { search: 'child 0|ребенок 0', name: '283', category: EMOJI_CATEGORIES.PEOPLE },
  CHILD_1: { search: 'child 1|ребенок 1', name: '284', category: EMOJI_CATEGORIES.PEOPLE },
  CHILD_2: { search: 'child 2|ребенок 2', name: '285', category: EMOJI_CATEGORIES.PEOPLE },
  CHILD_3: { search: 'child 3|ребенок 3', name: '286', category: EMOJI_CATEGORIES.PEOPLE },
  CHILD_4: { search: 'child 4|ребенок 4', name: '287', category: EMOJI_CATEGORIES.PEOPLE },
  CHILD_5: { search: 'child 5|ребенок 5', name: '288', category: EMOJI_CATEGORIES.PEOPLE },
  BOY_0: { search: 'boy 0|мальчик 0', name: '202', category: EMOJI_CATEGORIES.PEOPLE },
  BOY_1: { search: 'boy 1|мальчик 1', name: '203', category: EMOJI_CATEGORIES.PEOPLE },
  BOY_2: { search: 'boy 2|мальчик 2', name: '204', category: EMOJI_CATEGORIES.PEOPLE },
  BOY_3: { search: 'boy 3|мальчик 3', name: '205', category: EMOJI_CATEGORIES.PEOPLE },
  BOY_4: { search: 'boy 4|мальчик 4', name: '206', category: EMOJI_CATEGORIES.PEOPLE },
  BOY_5: { search: 'boy 5|мальчик 5', name: '207', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_0: { search: 'woman 0|женщина 0', name: '2871', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_1: { search: 'woman 1|женщина 1', name: '2872', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_2: { search: 'woman 2|женщина 2', name: '2873', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_3: { search: 'woman 3|женщина 3', name: '2874', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_4: { search: 'woman 4|женщина 4', name: '2875', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_5: { search: 'woman 5|женщина 5', name: '2876', category: EMOJI_CATEGORIES.PEOPLE },
  ADULT_0: { search: 'adult 0|взрослый 0', name: '4', category: EMOJI_CATEGORIES.PEOPLE },
  ADULT_1: { search: 'adult 1|взрослый 1', name: '5', category: EMOJI_CATEGORIES.PEOPLE },
  ADULT_2: { search: 'adult 2|взрослый 2', name: '6', category: EMOJI_CATEGORIES.PEOPLE },
  ADULT_3: { search: 'adult 3|взрослый 3', name: '7', category: EMOJI_CATEGORIES.PEOPLE },
  ADULT_4: { search: 'adult 4|взрослый 4', name: '8', category: EMOJI_CATEGORIES.PEOPLE },
  ADULT_5: { search: 'adult 5|взрослый 5', name: '9', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_0: { search: 'man 0|человек 0', name: '1261', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_1: { search: 'man 1|человек 1', name: '1262', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_2: { search: 'man 2|человек 2', name: '1263', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_3: { search: 'man 3|человек 3', name: '1264', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_4: { search: 'man 4|человек 4', name: '1265', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_5: { search: 'man 5|человек 5', name: '1266', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_MAN_0: { search: 'bearded man 0|бородатый мужчина 0', name: '131', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_MAN_1: { search: 'bearded man 1|бородатый мужчина 1', name: '132', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_MAN_2: { search: 'bearded man 2|бородатый мужчина 2', name: '133', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_MAN_3: { search: 'bearded man 3|бородатый мужчина 3', name: '134', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_MAN_4: { search: 'bearded man 4|бородатый мужчина 4', name: '135', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_MAN_5: { search: 'bearded man 5|бородатый мужчина 5', name: '136', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_PERSON_0: { search: 'bearded person 0|бородатый человек 0', name: '137', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_PERSON_1: { search: 'bearded person 1|бородатый человек 1', name: '138', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_PERSON_2: { search: 'bearded person 2|бородатый человек 2', name: '139', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_PERSON_3: { search: 'bearded person 3|бородатый человек 3', name: '140', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_PERSON_4: { search: 'bearded person 4|бородатый человек 4', name: '141', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_PERSON_5: { search: 'bearded person 5|бородатый человек 5', name: '142', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_WOMAN_0: { search: 'bearded woman 0|бородатая женщина 0', name: '143', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_WOMAN_1: { search: 'bearded woman 1|бородатая женщина 1', name: '144', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_WOMAN_2: { search: 'bearded woman 2|бородатая женщина 2', name: '145', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_WOMAN_3: { search: 'bearded woman 3|бородатая женщина 3', name: '146', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_WOMAN_4: { search: 'bearded woman 4|бородатая женщина 4', name: '147', category: EMOJI_CATEGORIES.PEOPLE },
  BEARDED_WOMAN_5: { search: 'bearded woman 5|бородатая женщина 5', name: '148', category: EMOJI_CATEGORIES.PEOPLE },
  OLDER_ADULT_0: { search: 'older adult 0|старый взрослый 0', name: '1859', category: EMOJI_CATEGORIES.PEOPLE },
  OLDER_ADULT_1: { search: 'older adult 1|старший взрослый 1', name: '1860', category: EMOJI_CATEGORIES.PEOPLE },
  OLDER_ADULT_2: { search: 'older adult 2|старший взрослый 2', name: '1861', category: EMOJI_CATEGORIES.PEOPLE },
  OLDER_ADULT_3: { search: 'older adult 3|старший взрослый 3', name: '1862', category: EMOJI_CATEGORIES.PEOPLE },
  OLDER_ADULT_4: { search: 'older adult 4|старший взрослый 4', name: '1863', category: EMOJI_CATEGORIES.PEOPLE },
  OLDER_ADULT_5: { search: 'older adult 5|старший взрослый 5', name: '1864', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_MAN_0: { search: 'old man 0|старик 0', name: '1847', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_MAN_1: { search: 'old man 1|старик 1', name: '1848', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_MAN_2: { search: 'old man 2|старик 2', name: '1849', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_MAN_3: { search: 'old man 3|старый мужчина 3', name: '1850', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_MAN_4: { search: 'old man 4|старик 4', name: '1851', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_MAN_5: { search: 'old man 5|старик 5', name: '1852', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_WOMAN_0: { search: 'old woman 0|старая женщина 0', name: '1853', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_WOMAN_1: { search: 'old woman 1|старая женщина 1', name: '1854', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_WOMAN_2: { search: 'old woman 2|старая женщина 2', name: '1855', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_WOMAN_3: { search: 'old woman 3|старая женщина 3', name: '1856', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_WOMAN_4: { search: 'old woman 4|старая женщина 4', name: '1857', category: EMOJI_CATEGORIES.PEOPLE },
  OLD_WOMAN_5: { search: 'old woman 5|старая женщина 5', name: '1858', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_BLOND_HAIR_0: { search: 'person with blond hair 0|человек с блондными волосами 0', name: '2099', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_BLOND_HAIR_1: { search: 'person with blond hair 1|человек с блондными волосами 1', name: '2100', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_BLOND_HAIR_2: { search: 'person with blond hair 2|человек с блондными волосами 2', name: '2101', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_BLOND_HAIR_3: { search: 'person with blond hair 3|человек с блондными волосами 3', name: '2102', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_BLOND_HAIR_4: { search: 'person with blond hair 4|человек с блондными волосами 4', name: '2103', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_BLOND_HAIR_5: { search: 'person with blond hair 5|человек с блондными волосами 5', name: '2104', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_CURLY_HAIR_0: { search: 'person with curly hair 0|человек с любопытными волосами 0', name: '2105', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_CURLY_HAIR_1: { search: 'person with curly hair 1|человек с любопытными волосами 1', name: '2106', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_CURLY_HAIR_2: { search: 'person with curly hair 2|человек с любопытными волосами 2', name: '2107', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_CURLY_HAIR_3: { search: 'person with curly hair 3|человек с любопытными волосами 3', name: '2108', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_CURLY_HAIR_4: { search: 'person with curly hair 4|человек с любопытными волосами 4', name: '2109', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_CURLY_HAIR_5: { search: 'person with curly hair 5|человек с curly волосы 5', name: '2110', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_NO_HAIR_0: { search: 'person with no hair 0|человек без волос 0', name: '2111', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_NO_HAIR_1: { search: 'person with no hair 1|человек без волос 1', name: '2112', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_NO_HAIR_2: { search: 'person with no hair 2|человек без волос 2', name: '2113', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_NO_HAIR_3: { search: 'person with no hair 3|человек без волос 3', name: '2114', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_NO_HAIR_4: { search: 'person with no hair 4|человек без волос 4', name: '2115', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_NO_HAIR_5: { search: 'person with no hair 5|человек без волос 5', name: '2116', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_RED_HAIR_0: { search: 'person with red hair 0|человек с красными волосами 0', name: '2117', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_RED_HAIR_1: { search: 'person with red hair 1|человек с красными волосами 1', name: '2118', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_RED_HAIR_2: { search: 'person with red hair 2|человек с красными волосами 2', name: '2119', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_RED_HAIR_3: { search: 'person with red hair 3|человек с красными волосами 3', name: '2120', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_RED_HAIR_4: { search: 'person with red hair 4|человек с красными волосами 4', name: '2121', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_RED_HAIR_5: { search: 'person with red hair 5|человек с красными волосами 5', name: '2122', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_TURBAN_0: { search: 'person with turban 0|человек с турбаном 0', name: '2123', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_TURBAN_1: { search: 'person with turban 1|человек с турбаном 1', name: '2124', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_TURBAN_2: { search: 'person with turban 2|человек с турбаном 2', name: '2125', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_TURBAN_3: { search: 'person with turban 3|человек с турбаном 3', name: '2126', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_TURBAN_4: { search: 'person with turban 4|человек с турбаном 4', name: '2127', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_TURBAN_5: { search: 'person with turban 5|человек с турбаном 5', name: '2128', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_HAIR_0: { search: 'person with white hair 0|человек с белыми волосами 0', name: '2141', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_HAIR_1: { search: 'person with white hair 1|человек с белыми волосами 1', name: '2142', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_HAIR_2: { search: 'person with white hair 2|человек с белыми волосами 2', name: '2143', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_HAIR_3: { search: 'person with white hair 3|человек с белыми волосами 3', name: '2144', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_HAIR_4: { search: 'person with white hair 4|человек с белыми волосами 4', name: '2145', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_HAIR_5: { search: 'person with white hair 5|человек с белыми волосами 5', name: '2146', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_BLOND_HAIR_0: { search: 'man with blond hair 0|человек с блондинками 0', name: '1617', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_BLOND_HAIR_1: { search: 'man with blond hair 1|человек с блондными волосами 1', name: '1618', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_BLOND_HAIR_2: { search: 'man with blond hair 2|человек с блондными волосами 2', name: '1619', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_BLOND_HAIR_3: { search: 'man with blond hair 3|человек с блондными волосами 3', name: '1620', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_BLOND_HAIR_4: { search: 'man with blond hair 4|человек с блондными волосами 4', name: '1621', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_BLOND_HAIR_5: { search: 'man with blond hair 5|человек с блондными волосами 5', name: '1622', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CHINESE_CAP_0: { search: 'man with Chinese cap 0|человек с китайской крышкой 0', name: '1623', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CHINESE_CAP_1: { search: 'man with Chinese cap 1|человек с китайской крышкой 1', name: '1624', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CHINESE_CAP_2: { search: 'man with Chinese cap 2|человек с китайской крышкой 2', name: '1625', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CHINESE_CAP_3: { search: 'man with Chinese cap 3|человек с китайской крышкой 3', name: '1626', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CHINESE_CAP_4: { search: 'man with Chinese cap 4|человек с китайской крышкой 4', name: '1627', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CHINESE_CAP_5: { search: 'man with Chinese cap 5|человек с китайской крышкой 5', name: '1628', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CURLY_HAIR_0: { search: 'man with curly hair 0|человек с curly волосы 0', name: '1629', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CURLY_HAIR_1: { search: 'man with curly hair 1|человек с curly волосы 1', name: '1630', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CURLY_HAIR_2: { search: 'man with curly hair 2|человек с любопытными волосами 2', name: '1631', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CURLY_HAIR_3: { search: 'man with curly hair 3|человек с curly волосы 3', name: '1632', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CURLY_HAIR_4: { search: 'man with curly hair 4|человек с curly волосы 4', name: '1633', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_CURLY_HAIR_5: { search: 'man with curly hair 5|человек с curly волосы 5', name: '1634', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_NO_HAIR_0: { search: 'man with no hair 0|человек без волос 0', name: '1635', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_NO_HAIR_1: { search: 'man with no hair 1|человек без волос 1', name: '1636', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_NO_HAIR_2: { search: 'man with no hair 2|человек без волос 2', name: '1637', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_NO_HAIR_3: { search: 'man with no hair 3|человек без волос 3', name: '1638', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_NO_HAIR_4: { search: 'man with no hair 4|человек без волос 4', name: '1639', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_NO_HAIR_5: { search: 'man with no hair 5|человек без волос 5', name: '1640', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_RED_HAIR_0: { search: 'man with red hair 0|человек с красными волосами 0', name: '1641', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_RED_HAIR_1: { search: 'man with red hair 1|человек с красными волосами 1', name: '1642', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_RED_HAIR_2: { search: 'man with red hair 2|человек с красными волосами 2', name: '1643', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_RED_HAIR_3: { search: 'man with red hair 3|человек с красными волосами 3', name: '1644', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_RED_HAIR_4: { search: 'man with red hair 4|человек с красными волосами 4', name: '1645', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_RED_HAIR_5: { search: 'man with red hair 5|человек с красными волосами 5', name: '1646', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_HAIR_0: { search: 'man with white hair 0|человек с белыми волосами 0', name: '1665', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_HAIR_1: { search: 'man with white hair 1|человек с белыми волосами 1', name: '1666', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_HAIR_2: { search: 'man with white hair 2|человек с белыми волосами 2', name: '1667', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_HAIR_3: { search: 'man with white hair 3|человек с белыми волосами 3', name: '1668', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_HAIR_4: { search: 'man with white hair 4|человек с белыми волосами 4', name: '1669', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_HAIR_5: { search: 'man with white hair 5|человек с белыми волосами 5', name: '1670', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_TURBAN_0: { search: 'man with turban 0|человек с турбаном 0', name: '1647', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_TURBAN_1: { search: 'man with turban 1|человек с турбаном 1', name: '1648', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_TURBAN_2: { search: 'man with turban 2|человек с турбаном 2', name: '1649', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_TURBAN_3: { search: 'man with turban 3|человек с турбаном 3', name: '1650', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_TURBAN_4: { search: 'man with turban 4|человек с турбаном 4', name: '1651', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_TURBAN_5: { search: 'man with turban 5|человек с турбаном 5', name: '1652', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_BLONDE_HAIR_0: { search: 'woman with blonde hair 0|женщина с блондинкой волос 0', name: '3225', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_BLONDE_HAIR_1: { search: 'woman with blonde hair 1|женщина с блондинками волосы 1', name: '3226', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_BLONDE_HAIR_2: { search: 'woman with blonde hair 2|женщина с блондинками волосы 2', name: '3227', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_BLONDE_HAIR_3: { search: 'woman with blonde hair 3|женщина с блондинкой волос 3', name: '3228', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_BLONDE_HAIR_4: { search: 'woman with blonde hair 4|женщина с блондинками волосы 4', name: '3229', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_BLONDE_HAIR_5: { search: 'woman with blonde hair 5|женщина с блондинкой волос 5', name: '3230', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_CURLY_HAIR_0: { search: 'woman with curly hair 0|женщина с curly hair 0', name: '3231', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_CURLY_HAIR_1: { search: 'woman with curly hair 1|женщина с curly hair 1', name: '3232', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_CURLY_HAIR_2: { search: 'woman with curly hair 2|женщина с curly hair 2', name: '3233', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_CURLY_HAIR_3: { search: 'woman with curly hair 3|женщина с curly hair 3', name: '3234', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_CURLY_HAIR_4: { search: 'woman with curly hair 4|женщина с curly hair 4', name: '3235', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_CURLY_HAIR_5: { search: 'woman with curly hair 5|женщина с curly волосы 5', name: '3236', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_HEADSCARF_0: { search: 'woman with headscarf 0|женщина с платком 0', name: '3237', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_HEADSCARF_1: { search: 'woman with headscarf 1|женщина с платком 1', name: '3238', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_HEADSCARF_2: { search: 'woman with headscarf 2|женщина с платком 2', name: '3239', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_HEADSCARF_3: { search: 'woman with headscarf 3|женщина с платком 3', name: '3240', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_HEADSCARF_4: { search: 'woman with headscarf 4|женщина с платком 4', name: '3241', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_HEADSCARF_5: { search: 'woman with headscarf 5|женщина с платком 5', name: '3242', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_NO_HAIR_0: { search: 'woman with no hair 0|женщина без волос 0', name: '3243', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_NO_HAIR_1: { search: 'woman with no hair 1|женщина без волос 1', name: '3244', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_NO_HAIR_2: { search: 'woman with no hair 2|женщина без волос 2', name: '3245', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_NO_HAIR_3: { search: 'woman with no hair 3|женщина без волос 3', name: '3246', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_NO_HAIR_4: { search: 'woman with no hair 4|женщина без волос 4', name: '3247', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_NO_HAIR_5: { search: 'woman with no hair 5|женщина без волос 5', name: '3248', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_RED_HAIR_0: { search: 'woman with red hair 0|женщина с красными волосами 0', name: '3249', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_RED_HAIR_1: { search: 'woman with red hair 1|женщина с красными волосами 1', name: '3250', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_RED_HAIR_2: { search: 'woman with red hair 2|женщина с красными волосами 2', name: '3251', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_RED_HAIR_3: { search: 'woman with red hair 3|женщина с красными волосами 3', name: '3252', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_RED_HAIR_4: { search: 'woman with red hair 4|женщина с красными волосами 4', name: '3253', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_RED_HAIR_5: { search: 'woman with red hair 5|женщина с красными волосами 5', name: '3254', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_TURBAN_0: { search: 'woman with turban 0|женщина с турбаном 0', name: '3255', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_TURBAN_1: { search: 'woman with turban 1|женщина с турбаном 1', name: '3256', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_TURBAN_2: { search: 'woman with turban 2|женщина с турбаном 2', name: '3257', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_TURBAN_3: { search: 'woman with turban 3|женщина с турбаном 3', name: '3258', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_TURBAN_4: { search: 'woman with turban 4|женщина с турбаном 4', name: '3259', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_TURBAN_5: { search: 'woman with turban 5|женщина с турбаном 5', name: '3260', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_HAIR_0: { search: 'woman with white hair 0|женщина с белыми волосами 0', name: '3273', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_HAIR_1: { search: 'woman with white hair 1|женщина с белыми волосами 1', name: '3274', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_HAIR_2: { search: 'woman with white hair 2|женщина с белыми волосами 2', name: '3275', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_HAIR_3: { search: 'woman with white hair 3|женщина с белыми волосами 3', name: '3276', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_HAIR_4: { search: 'woman with white hair 4|женщина с белыми волосами 4', name: '3277', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_HAIR_5: { search: 'woman with white hair 5|женщина с белыми волосами 5', name: '3278', category: EMOJI_CATEGORIES.PEOPLE },
  ARTIST_0: { search: 'artist 0|художник 0', name: '33', category: EMOJI_CATEGORIES.PEOPLE },
  ARTIST_1: { search: 'artist 1|художник 1', name: '34', category: EMOJI_CATEGORIES.PEOPLE },
  ARTIST_2: { search: 'artist 2|художник 2', name: '35', category: EMOJI_CATEGORIES.PEOPLE },
  ARTIST_3: { search: 'artist 3|художник 3', name: '36', category: EMOJI_CATEGORIES.PEOPLE },
  ARTIST_4: { search: 'artist 4|художник 4', name: '37', category: EMOJI_CATEGORIES.PEOPLE },
  ARTIST_5: { search: 'artist 5|художник 5', name: '38', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ARTIST_0: { search: 'man artist 0|человек художник 0', name: '1268', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ARTIST_1: { search: 'man artist 1|человек художник 1', name: '1269', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ARTIST_2: { search: 'man artist 2|человек художник 2', name: '1270', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ARTIST_3: { search: 'man artist 3|человек художник 3', name: '1271', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ARTIST_4: { search: 'man artist 4|человек художник 4', name: '1272', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ARTIST_5: { search: 'man artist 5|человек художник 5', name: '1273', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ARTIST_0: { search: 'woman artist 0|женщина художник 0', name: '2882', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ARTIST_1: { search: 'woman artist 1|женщина художник 1', name: '2883', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ARTIST_2: { search: 'woman artist 2|женщина художник 2', name: '2884', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ARTIST_3: { search: 'woman artist 3|женщина художник 3', name: '2885', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ARTIST_4: { search: 'woman artist 4|женщина художник 4', name: '2886', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ARTIST_5: { search: 'woman artist 5|женщина художник 5', name: '2887', category: EMOJI_CATEGORIES.PEOPLE },
  ASTRONAUT_0: { search: 'astronaut 0|астронавт 0', name: '41', category: EMOJI_CATEGORIES.PEOPLE },
  ASTRONAUT_1: { search: 'astronaut 1|астронавт 1', name: '42', category: EMOJI_CATEGORIES.PEOPLE },
  ASTRONAUT_2: { search: 'astronaut 2|астронавт 2', name: '43', category: EMOJI_CATEGORIES.PEOPLE },
  ASTRONAUT_3: { search: 'astronaut 3|астронавт 3', name: '44', category: EMOJI_CATEGORIES.PEOPLE },
  ASTRONAUT_4: { search: 'astronaut 4|астронавт 4', name: '45', category: EMOJI_CATEGORIES.PEOPLE },
  ASTRONAUT_5: { search: 'astronaut 5|астронавт 5', name: '46', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ASTRONAUT_0: { search: 'man astronaut 0|человек астронавт 0', name: '1274', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ASTRONAUT_1: { search: 'man astronaut 1|человек астронавт 1', name: '1275', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ASTRONAUT_2: { search: 'man astronaut 2|человек астронавт 2', name: '1276', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ASTRONAUT_3: { search: 'man astronaut 3|человек астронавт 3', name: '1277', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ASTRONAUT_4: { search: 'man astronaut 4|человек астронавт 4', name: '1278', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ASTRONAUT_5: { search: 'man astronaut 5|человек астронавт 5', name: '1279', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ASTRONAUT_0: { search: 'woman astronaut 0|женщина астронавт 0', name: '2888', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ASTRONAUT_1: { search: 'woman astronaut 1|женщина астронавт 1', name: '2889', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ASTRONAUT_2: { search: 'woman astronaut 2|женщина астронавт 2', name: '2890', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ASTRONAUT_3: { search: 'woman astronaut 3|женщина астронавт 3', name: '2891', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ASTRONAUT_4: { search: 'woman astronaut 4|женщина астронавт 4', name: '2892', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ASTRONAUT_5: { search: 'woman astronaut 5|женщина астронавт 5', name: '2893', category: EMOJI_CATEGORIES.PEOPLE },
  CONSTRUCTION_WORKER_0: { search: 'construction worker 0|строитель 0', name: '350', category: EMOJI_CATEGORIES.PEOPLE },
  CONSTRUCTION_WORKER_1: { search: 'construction worker 1|строитель 1', name: '351', category: EMOJI_CATEGORIES.PEOPLE },
  CONSTRUCTION_WORKER_2: { search: 'construction worker 2|строитель 2', name: '352', category: EMOJI_CATEGORIES.PEOPLE },
  CONSTRUCTION_WORKER_3: { search: 'construction worker 3|строитель 3', name: '353', category: EMOJI_CATEGORIES.PEOPLE },
  CONSTRUCTION_WORKER_4: { search: 'construction worker 4|строитель 4', name: '354', category: EMOJI_CATEGORIES.PEOPLE },
  CONSTRUCTION_WORKER_5: { search: 'construction worker 5|строитель 5', name: '355', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_CONSTRUCTION_WORKER_0: { search: 'man construction worker 0|человек строительный работник 0', name: '1292', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_CONSTRUCTION_WORKER_1: { search: 'man construction worker 1|человек строительный работник 1', name: '1293', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_CONSTRUCTION_WORKER_2: { search: 'man construction worker 2|человек строительный работник 2', name: '1294', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_CONSTRUCTION_WORKER_3: { search: 'man construction worker 3|человек строительный работник 3', name: '1295', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_CONSTRUCTION_WORKER_4: { search: 'man construction worker 4|человек строительный работник 4', name: '1296', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_CONSTRUCTION_WORKER_5: { search: 'man construction worker 5|человек строительный работник 5', name: '1297', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_CONSTRUCTION_WORKER_0: { search: 'woman construction worker 0|женщина строительный работник 0', name: '2906', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_CONSTRUCTION_WORKER_1: { search: 'woman construction worker 1|женщина строительный работник 1', name: '2907', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_CONSTRUCTION_WORKER_2: { search: 'woman construction worker 2|женщина строительный работник 2', name: '2908', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_CONSTRUCTION_WORKER_3: { search: 'woman construction worker 3|женщина строительный работник 3', name: '2909', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_CONSTRUCTION_WORKER_4: { search: 'woman construction worker 4|женщина строительный работник 4', name: '2910', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_CONSTRUCTION_WORKER_5: { search: 'woman construction worker 5|женщина строительный работник 5', name: '2911', category: EMOJI_CATEGORIES.PEOPLE },
  COOK_0: { search: 'cook 0|повар 0', name: '358', category: EMOJI_CATEGORIES.PEOPLE },
  COOK_1: { search: 'cook 1|повар 1', name: '359', category: EMOJI_CATEGORIES.PEOPLE },
  COOK_2: { search: 'cook 2|повар 2', name: '360', category: EMOJI_CATEGORIES.PEOPLE },
  COOK_3: { search: 'cook 3|повар 3', name: '361', category: EMOJI_CATEGORIES.PEOPLE },
  COOK_4: { search: 'cook 4|повар 4', name: '362', category: EMOJI_CATEGORIES.PEOPLE },
  COOK_5: { search: 'cook 5|повар 5', name: '363', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_COOK_0: { search: 'man cook 0|человек повар 0', name: '1298', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_COOK_1: { search: 'man cook 1|человек повар 1', name: '1299', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_COOK_2: { search: 'man cook 2|человек повар 2', name: '1300', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_COOK_3: { search: 'man cook 3|человек повар 3', name: '1301', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_COOK_4: { search: 'man cook 4|человек повар 4', name: '1302', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_COOK_5: { search: 'man cook 5|человек повар 5', name: '1303', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_COOK_0: { search: 'woman cook 0|женщина повар 0', name: '2912', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_COOK_1: { search: 'woman cook 1|женщина повар 1', name: '2913', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_COOK_2: { search: 'woman cook 2|женщина повар 2', name: '2914', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_COOK_3: { search: 'woman cook 3|женщина повар 3', name: '2915', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_COOK_4: { search: 'woman cook 4|женщина повар 4', name: '2916', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_COOK_5: { search: 'woman cook 5|женщина повар 5', name: '2917', category: EMOJI_CATEGORIES.PEOPLE },
  DETECTIVE_0: { search: 'detective 0|детектив 0', name: '446', category: EMOJI_CATEGORIES.PEOPLE },
  DETECTIVE_1: { search: 'detective 1|детектив 1', name: '447', category: EMOJI_CATEGORIES.PEOPLE },
  DETECTIVE_2: { search: 'detective 2|детектив 2', name: '448', category: EMOJI_CATEGORIES.PEOPLE },
  DETECTIVE_3: { search: 'detective 3|детектив 3', name: '449', category: EMOJI_CATEGORIES.PEOPLE },
  DETECTIVE_4: { search: 'detective 4|детектив 4', name: '450', category: EMOJI_CATEGORIES.PEOPLE },
  DETECTIVE_5: { search: 'detective 5|детектив 5', name: '451', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DETECTIVE_0: { search: 'man detective 0|человек детектив 0', name: '1316', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DETECTIVE_1: { search: 'man detective 1|человек детектив 1', name: '1317', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DETECTIVE_2: { search: 'man detective 2|человек детектив 2', name: '1318', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DETECTIVE_3: { search: 'man detective 3|человек детектив 3', name: '1319', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DETECTIVE_4: { search: 'man detective 4|человек детектив 4', name: '1320', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DETECTIVE_5: { search: 'man detective 5|человек детектив 5', name: '1321', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DETECTIVE_0: { search: 'woman detective 0|женщина детектив 0', name: '2930', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DETECTIVE_1: { search: 'woman detective 1|женщина детектив 1', name: '2931', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DETECTIVE_2: { search: 'woman detective 2|женщина детектив 2', name: '2932', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DETECTIVE_3: { search: 'woman detective 3|женщина детектив 3', name: '2933', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DETECTIVE_4: { search: 'woman detective 4|женщина детектив 4', name: '2934', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DETECTIVE_5: { search: 'woman detective 5|женщина детектив 5', name: '2935', category: EMOJI_CATEGORIES.PEOPLE },
  FACTORY_WORKER_0: { search: 'factory worker 0|завод работник 0', name: '558', category: EMOJI_CATEGORIES.PEOPLE },
  FACTORY_WORKER_1: { search: 'factory worker 1|завод работник 1', name: '559', category: EMOJI_CATEGORIES.PEOPLE },
  FACTORY_WORKER_2: { search: 'factory worker 2|завод работник 2', name: '560', category: EMOJI_CATEGORIES.PEOPLE },
  FACTORY_WORKER_3: { search: 'factory worker 3|завод работник 3', name: '561', category: EMOJI_CATEGORIES.PEOPLE },
  FACTORY_WORKER_4: { search: 'factory worker 4|завод работник 4', name: '562', category: EMOJI_CATEGORIES.PEOPLE },
  FACTORY_WORKER_5: { search: 'factory worker 5|завод работник 5', name: '563', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACTORY_WORKER_0: { search: 'man factory worker 0|человек завод работник 0', name: '1340', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACTORY_WORKER_1: { search: 'man factory worker 1|человек завод работник 1', name: '1341', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACTORY_WORKER_2: { search: 'man factory worker 2|человек завод работник 2', name: '1342', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACTORY_WORKER_3: { search: 'man factory worker 3|человек завод работник 3', name: '1343', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACTORY_WORKER_4: { search: 'man factory worker 4|человек завод работник 4', name: '1344', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACTORY_WORKER_5: { search: 'man factory worker 5|человек завод работник 5', name: '1345', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACTORY_WORKER_0: { search: 'woman factory worker 0|женщина завод работник 0', name: '2954', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACTORY_WORKER_1: { search: 'woman factory worker 1|женщина завод работник 1', name: '2955', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACTORY_WORKER_2: { search: 'woman factory worker 2|женщина завод работник 2', name: '2956', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACTORY_WORKER_3: { search: 'woman factory worker 3|женщина завод работник 3', name: '2957', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACTORY_WORKER_4: { search: 'woman factory worker 4|женщина завод работник 4', name: '2958', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACTORY_WORKER_5: { search: 'woman factory worker 5|женщина завод работник 5', name: '2959', category: EMOJI_CATEGORIES.PEOPLE },
  FARMER_0: { search: 'farmer 0|фермер 0', name: '599', category: EMOJI_CATEGORIES.PEOPLE },
  FARMER_1: { search: 'farmer 1|фермер 1', name: '600', category: EMOJI_CATEGORIES.PEOPLE },
  FARMER_2: { search: 'farmer 2|фермер 2', name: '601', category: EMOJI_CATEGORIES.PEOPLE },
  FARMER_3: { search: 'farmer 3|фермер 3', name: '602', category: EMOJI_CATEGORIES.PEOPLE },
  FARMER_4: { search: 'farmer 4|фермер 4', name: '603', category: EMOJI_CATEGORIES.PEOPLE },
  FARMER_5: { search: 'farmer 5|фермер 5', name: '604', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FARMER_0: { search: 'man farmer 0|человек фермер 0', name: '1352', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FARMER_1: { search: 'man farmer 1|человек фермер 1', name: '1353', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FARMER_2: { search: 'man farmer 2|человек фермер 2', name: '1354', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FARMER_3: { search: 'man farmer 3|человек фермер 3', name: '1355', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FARMER_4: { search: 'man farmer 4|человек фермер 4', name: '1356', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FARMER_5: { search: 'man farmer 5|человек фермер 5', name: '1357', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FARMER_0: { search: 'woman farmer 0|женщина фермер 0', name: '2966', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FARMER_1: { search: 'woman farmer 1|женщина фермер 1', name: '2967', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FARMER_2: { search: 'woman farmer 2|женщина фермер 2', name: '2968', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FARMER_3: { search: 'woman farmer 3|женщина фермер 3', name: '2969', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FARMER_4: { search: 'woman farmer 4|женщина фермер 4', name: '2970', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FARMER_5: { search: 'woman farmer 5|женщина фермер 5', name: '2971', category: EMOJI_CATEGORIES.PEOPLE },
  FIREFIGHTER_0: { search: 'firefighter 0|пожарный 0', name: '626', category: EMOJI_CATEGORIES.PEOPLE },
  FIREFIGHTER_1: { search: 'firefighter 1|пожарный 1', name: '627', category: EMOJI_CATEGORIES.PEOPLE },
  FIREFIGHTER_2: { search: 'firefighter 2|пожарный 1 2 3 4 5', name: '628', category: EMOJI_CATEGORIES.PEOPLE },
  FIREFIGHTER_3: { search: 'firefighter 3|пожарный 3', name: '629', category: EMOJI_CATEGORIES.PEOPLE },
  FIREFIGHTER_4: { search: 'firefighter 4|пожарный 4', name: '630', category: EMOJI_CATEGORIES.PEOPLE },
  FIREFIGHTER_5: { search: 'firefighter 5|пожарный 5', name: '631', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FIREFIGHTER_0: { search: 'man firefighter 0|человек пожарный 0', name: '1364', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FIREFIGHTER_1: { search: 'man firefighter 1|человек пожарный 1', name: '1365', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FIREFIGHTER_2: { search: 'man firefighter 2|человек пожарный 2', name: '1366', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FIREFIGHTER_3: { search: 'man firefighter 3|человек пожарный 3', name: '1367', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FIREFIGHTER_4: { search: 'man firefighter 4|человек пожарный 4', name: '1368', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FIREFIGHTER_5: { search: 'man firefighter 5|человек пожарный 5', name: '1369', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FIREFIGHTER_0: { search: 'woman firefighter 0|женщина пожарная 0', name: '2978', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FIREFIGHTER_1: { search: 'woman firefighter 1|женщина пожарная 1', name: '2979', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FIREFIGHTER_2: { search: 'woman firefighter 2|женщина пожарная 2', name: '2980', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FIREFIGHTER_3: { search: 'woman firefighter 3|женщина пожарная 3', name: '2981', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FIREFIGHTER_4: { search: 'woman firefighter 4|женщина пожарная 4', name: '2982', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FIREFIGHTER_5: { search: 'woman firefighter 5|женщина пожарная 5', name: '2983', category: EMOJI_CATEGORIES.PEOPLE },
  GUARD_0: { search: 'guard 0|охранник 0', name: '990', category: EMOJI_CATEGORIES.PEOPLE },
  GUARD_1: { search: 'guard 1|охранник 1', name: '991', category: EMOJI_CATEGORIES.PEOPLE },
  GUARD_2: { search: 'guard 2|охрана 2', name: '992', category: EMOJI_CATEGORIES.PEOPLE },
  GUARD_3: { search: 'guard 3|охрана 3', name: '993', category: EMOJI_CATEGORIES.PEOPLE },
  GUARD_4: { search: 'guard 4|охрана 4', name: '994', category: EMOJI_CATEGORIES.PEOPLE },
  GUARD_5: { search: 'guard 5|охрана 5', name: '995', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSMAN_0: { search: 'guardsman 0|охранник 0', name: '996', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSMAN_1: { search: 'guardsman 1|охранник 1', name: '997', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSMAN_2: { search: 'guardsman 2|охранник 2', name: '998', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSMAN_3: { search: 'guardsman 3|охранник 3', name: '999', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSMAN_4: { search: 'guardsman 4|охранник 4', name: '1000', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSMAN_5: { search: 'guardsman 5|охранник 5', name: '1001', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSWOMAN_0: { search: 'guardswoman 0|охранник 0', name: '1002', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSWOMAN_1: { search: 'guardswoman 1|охранник 1', name: '1003', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSWOMAN_2: { search: 'guardswoman 2|охранник 2', name: '1004', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSWOMAN_3: { search: 'guardswoman 3|охранник 3', name: '1005', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSWOMAN_4: { search: 'guardswoman 4|охранник 4', name: '1006', category: EMOJI_CATEGORIES.PEOPLE },
  GUARDSWOMAN_5: { search: 'guardswoman 5|охранник 5', name: '1007', category: EMOJI_CATEGORIES.PEOPLE },
  HEALTH_WORKER_0: { search: 'health worker 0|работник 0', name: '1041', category: EMOJI_CATEGORIES.PEOPLE },
  HEALTH_WORKER_1: { search: 'health worker 1|работник здоровья 1', name: '1042', category: EMOJI_CATEGORIES.PEOPLE },
  HEALTH_WORKER_2: { search: 'health worker 2|работник здоровья 2', name: '1043', category: EMOJI_CATEGORIES.PEOPLE },
  HEALTH_WORKER_3: { search: 'health worker 3|работник здоровья 3', name: '1044', category: EMOJI_CATEGORIES.PEOPLE },
  HEALTH_WORKER_4: { search: 'health worker 4|работник здоровья 4', name: '1045', category: EMOJI_CATEGORIES.PEOPLE },
  HEALTH_WORKER_5: { search: 'health worker 5|работник здоровья 5', name: '1046', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_HEALTH_WORKER_0: { search: 'man health worker 0|человек здоровый работник 0', name: '1407', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_HEALTH_WORKER_1: { search: 'man health worker 1|человек работник здоровья 1', name: '1408', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_HEALTH_WORKER_2: { search: 'man health worker 2|человек работник здоровья 2', name: '1409', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_HEALTH_WORKER_3: { search: 'man health worker 3|человек здоровый работник 3', name: '1410', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_HEALTH_WORKER_4: { search: 'man health worker 4|человек здоровый работник 4', name: '1411', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_HEALTH_WORKER_5: { search: 'man health worker 5|человек здоровый работник 5', name: '1412', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_HEALTH_WORKER_0: { search: 'woman health worker 0|женщина работник здоровья 0', name: '3021', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_HEALTH_WORKER_1: { search: 'woman health worker 1|женщина работник здоровья 1', name: '3022', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_HEALTH_WORKER_2: { search: 'woman health worker 2|женщина работник здоровья 2', name: '3023', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_HEALTH_WORKER_3: { search: 'woman health worker 3|женщина здоровый работник 3', name: '3024', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_HEALTH_WORKER_4: { search: 'woman health worker 4|женщина работник здоровья 4', name: '3025', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_HEALTH_WORKER_5: { search: 'woman health worker 5|женщина работник здоровья 5', name: '3026', category: EMOJI_CATEGORIES.PEOPLE },
  JUDGE_0: { search: 'judge 0|судья 0', name: '1159', category: EMOJI_CATEGORIES.PEOPLE },
  JUDGE_1: { search: 'judge 1|судья 1', name: '1160', category: EMOJI_CATEGORIES.PEOPLE },
  JUDGE_2: { search: 'judge 2|судья 2', name: '1161', category: EMOJI_CATEGORIES.PEOPLE },
  JUDGE_3: { search: 'judge 3|судья 3', name: '1162', category: EMOJI_CATEGORIES.PEOPLE },
  JUDGE_4: { search: 'judge 4|судья 4', name: '1163', category: EMOJI_CATEGORIES.PEOPLE },
  JUDGE_5: { search: 'judge 5|судья 5', name: '1164', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_JUDGE_0: { search: 'man judge 0|человек судья 0', name: '1449', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_JUDGE_1: { search: 'man judge 1|человек судья 1', name: '1450', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_JUDGE_2: { search: 'man judge 2|человек судья 2', name: '1451', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_JUDGE_3: { search: 'man judge 3|человек судья 3', name: '1452', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_JUDGE_4: { search: 'man judge 4|человек судья 4', name: '1453', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_JUDGE_5: { search: 'man judge 5|человек судья 5', name: '1454', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_JUDGE_0: { search: 'woman judge 0|женщина судья 0', name: '3057', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_JUDGE_1: { search: 'woman judge 1|женщина судья 1', name: '3058', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_JUDGE_2: { search: 'woman judge 2|женщина судья 2', name: '3059', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_JUDGE_3: { search: 'woman judge 3|женщина судья 3', name: '3060', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_JUDGE_4: { search: 'woman judge 4|женщина судья 4', name: '3061', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_JUDGE_5: { search: 'woman judge 5|женщина судья 5', name: '3062', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_VEIL_0: { search: 'man with veil 0|человек с фатой 0', name: '1653', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_VEIL_1: { search: 'man with veil 1|человек с фатой 1', name: '1654', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_VEIL_2: { search: 'man with veil 2|человек с фатой 2', name: '1655', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_VEIL_3: { search: 'man with veil 3|человек с фатой 3', name: '1656', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_VEIL_4: { search: 'man with veil 4|человек с фатой 4', name: '1657', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_VEIL_5: { search: 'man with veil 5|человек с фатой 5', name: '1658', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_VEIL_0: { search: 'person with veil 0|человек с фатой 0', name: '2129', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_VEIL_1: { search: 'person with veil 1|человек с фатой 1', name: '2130', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_VEIL_2: { search: 'person with veil 2|человек с фатой 2', name: '2131', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_VEIL_3: { search: 'person with veil 3|человек с фатой 3', name: '2132', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_VEIL_4: { search: 'person with veil 4|человек с фатой 4', name: '2133', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_VEIL_5: { search: 'person with veil 5|человек с фатой 5', name: '2134', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_VEIL_0: { search: 'woman with veil 0|женщина с фатой 0', name: '3261', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_VEIL_1: { search: 'woman with veil 1|женщина с фатой 1', name: '3262', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_VEIL_2: { search: 'woman with veil 2|женщина с фатой 2', name: '3263', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_VEIL_3: { search: 'woman with veil 3|женщина с фатой 3', name: '3264', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_VEIL_4: { search: 'woman with veil 4|женщина с фатой 4', name: '3265', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_VEIL_5: { search: 'woman with veil 5|женщина с фатой 5', name: '3266', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_TUXEDO_0: { search: 'man in tuxedo 0|человек в tuxedo 0', name: '1443', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_TUXEDO_1: { search: 'man in tuxedo 1|человек в tuxedo 1', name: '1444', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_TUXEDO_2: { search: 'man in tuxedo 2|человек в tuxedo 2', name: '1445', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_TUXEDO_3: { search: 'man in tuxedo 3|человек в tuxedo 3', name: '1446', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_TUXEDO_4: { search: 'man in tuxedo 4|человек в tuxedo 4', name: '1447', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_TUXEDO_5: { search: 'man in tuxedo 5|человек в tuxedo 5', name: '1448', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_TUXEDO_0: { search: 'person in tuxedo 0|человек в tuxedo 0', name: '2032', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_TUXEDO_1: { search: 'person in tuxedo 1|человек в tuxedo 1', name: '2033', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_TUXEDO_2: { search: 'person in tuxedo 2|человек в tuxedo 2', name: '2034', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_TUXEDO_3: { search: 'person in tuxedo 3|человек в tuxedo 3', name: '2035', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_TUXEDO_4: { search: 'person in tuxedo 4|человек в tuxedo 4', name: '2036', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_TUXEDO_5: { search: 'person in tuxedo 5|человек в tuxedo 5', name: '2037', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_TUXEDO_0: { search: 'woman in tuxedo 0|женщина в tuxedo 0', name: '3051', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_TUXEDO_1: { search: 'woman in tuxedo 1|женщина в tuxedo 1', name: '3052', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_TUXEDO_2: { search: 'woman in tuxedo 2|женщина в tuxedo 2', name: '3053', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_TUXEDO_3: { search: 'woman in tuxedo 3|женщина в tuxedo 3', name: '3054', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_TUXEDO_4: { search: 'woman in tuxedo 4|женщина в tuxedo 4', name: '3055', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_TUXEDO_5: { search: 'woman in tuxedo 5|женщина в tuxedo 5', name: '3056', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEMAN_0: { search: 'policeman 0|полицейский 0', name: '2197', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEMAN_1: { search: 'policeman 1|полицейский 1', name: '2198', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEMAN_2: { search: 'policeman 2|полицейский 2', name: '2199', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEMAN_3: { search: 'policeman 3|полицейский 3', name: '2200', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEMAN_4: { search: 'policeman 4|полицейский 4', name: '2201', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEMAN_5: { search: 'policeman 5|полицейский 5', name: '2202', category: EMOJI_CATEGORIES.PEOPLE },
  POLICE_OFFICER_0: { search: 'police officer 0|офицер полиции 0', name: '2191', category: EMOJI_CATEGORIES.PEOPLE },
  POLICE_OFFICER_1: { search: 'police officer 1|офицер полиции 1', name: '2192', category: EMOJI_CATEGORIES.PEOPLE },
  POLICE_OFFICER_2: { search: 'police officer 2|офицер полиции 2', name: '2193', category: EMOJI_CATEGORIES.PEOPLE },
  POLICE_OFFICER_3: { search: 'police officer 3|офицер полиции 3', name: '2194', category: EMOJI_CATEGORIES.PEOPLE },
  POLICE_OFFICER_4: { search: 'police officer 4|офицер полиции 4', name: '2195', category: EMOJI_CATEGORIES.PEOPLE },
  POLICE_OFFICER_5: { search: 'police officer 5|офицер полиции 5', name: '2196', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEWOMAN_0: { search: 'policewoman 0|полицейский 0', name: '2203', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEWOMAN_1: { search: 'policewoman 1|полицияжен 1', name: '2204', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEWOMAN_2: { search: 'policewoman 2|полицейский 2', name: '2205', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEWOMAN_3: { search: 'policewoman 3|полицейский 3', name: '2206', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEWOMAN_4: { search: 'policewoman 4|полицейский 4', name: '2207', category: EMOJI_CATEGORIES.PEOPLE },
  POLICEWOMAN_5: { search: 'policewoman 5|полиция 5', name: '2208', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANIC_0: { search: 'mechanic 0|механик 0', name: '1679', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANIC_1: { search: 'mechanic 1|механик 1', name: '1680', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANIC_2: { search: 'mechanic 2|механик 2', name: '1681', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANIC_3: { search: 'mechanic 3|механик 3', name: '1682', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANIC_4: { search: 'mechanic 4|механик 4', name: '1683', category: EMOJI_CATEGORIES.PEOPLE },
  MECHANIC_5: { search: 'mechanic 5|механик 5', name: '1684', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_MECHANIC_0: { search: 'man mechanic 0|человек механик 0', name: '1467', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_MECHANIC_1: { search: 'man mechanic 1|человек механик 1', name: '1468', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_MECHANIC_2: { search: 'man mechanic 2|человек механик 2', name: '1469', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_MECHANIC_3: { search: 'man mechanic 3|человек механик 3', name: '1470', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_MECHANIC_4: { search: 'man mechanic 4|человек механик 4', name: '1471', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_MECHANIC_5: { search: 'man mechanic 5|человек механик 5', name: '1472', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_MECHANIC_0: { search: 'woman mechanic 0|женщина механика 0', name: '3075', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_MECHANIC_1: { search: 'woman mechanic 1|женщина механика 1', name: '3076', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_MECHANIC_2: { search: 'woman mechanic 2|женщина механика 2', name: '3077', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_MECHANIC_3: { search: 'woman mechanic 3|женщина механика 3', name: '3078', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_MECHANIC_4: { search: 'woman mechanic 4|женщина механика 4', name: '3079', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_MECHANIC_5: { search: 'woman mechanic 5|женщина механика 5', name: '3080', category: EMOJI_CATEGORIES.PEOPLE },
  OFFICE_WORKER_0: { search: 'office worker 0|офисный работник 0', name: '1831', category: EMOJI_CATEGORIES.PEOPLE },
  OFFICE_WORKER_1: { search: 'office worker 1|офисный работник 1', name: '1832', category: EMOJI_CATEGORIES.PEOPLE },
  OFFICE_WORKER_2: { search: 'office worker 2|офисный работник 2', name: '1833', category: EMOJI_CATEGORIES.PEOPLE },
  OFFICE_WORKER_3: { search: 'office worker 3|офисный работник 3', name: '1834', category: EMOJI_CATEGORIES.PEOPLE },
  OFFICE_WORKER_4: { search: 'office worker 4|офисный работник 4', name: '1835', category: EMOJI_CATEGORIES.PEOPLE },
  OFFICE_WORKER_5: { search: 'office worker 5|офисный работник 5', name: '1836', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_OFFICE_WORKER_0: { search: 'man office worker 0|человек офис работник 0', name: '1479', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_OFFICE_WORKER_1: { search: 'man office worker 1|человек офис рабочий 1', name: '1480', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_OFFICE_WORKER_2: { search: 'man office worker 2|человек офис рабочий 2', name: '1481', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_OFFICE_WORKER_3: { search: 'man office worker 3|человек офис рабочий 3', name: '1482', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_OFFICE_WORKER_4: { search: 'man office worker 4|человек офис рабочий 4', name: '1483', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_OFFICE_WORKER_5: { search: 'man office worker 5|человек офис рабочий 5', name: '1484', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_OFFICE_WORKER_0: { search: 'woman office worker 0|женщина офис работник 0', name: '3087', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_OFFICE_WORKER_1: { search: 'woman office worker 1|женщина офис работник 1', name: '3088', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_OFFICE_WORKER_2: { search: 'woman office worker 2|женщина офис рабочий 2', name: '3089', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_OFFICE_WORKER_3: { search: 'woman office worker 3|женщина офис рабочий 3', name: '3090', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_OFFICE_WORKER_4: { search: 'woman office worker 4|женщина офис работник 4', name: '3091', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_OFFICE_WORKER_5: { search: 'woman office worker 5|женщина офис работник 5', name: '3092', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PILOT_0: { search: 'man pilot 0|человек пилот 0', name: '1491', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PILOT_1: { search: 'man pilot 1|человек пилот 1', name: '1492', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PILOT_2: { search: 'man pilot 2|человек пилот 2', name: '1493', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PILOT_3: { search: 'man pilot 3|человек пилот 3', name: '1494', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PILOT_4: { search: 'man pilot 4|человек пилот 4', name: '1495', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PILOT_5: { search: 'man pilot 5|человек пилот 5', name: '1496', category: EMOJI_CATEGORIES.PEOPLE },
  PILOT_0: { search: 'pilot 0|пилот 0', name: '2157', category: EMOJI_CATEGORIES.PEOPLE },
  PILOT_1: { search: 'pilot 1|пилот 1', name: '2158', category: EMOJI_CATEGORIES.PEOPLE },
  PILOT_2: { search: 'pilot 2|пилот 2', name: '2159', category: EMOJI_CATEGORIES.PEOPLE },
  PILOT_3: { search: 'pilot 3|пилот 3', name: '2160', category: EMOJI_CATEGORIES.PEOPLE },
  PILOT_4: { search: 'pilot 4|пилот 4', name: '2161', category: EMOJI_CATEGORIES.PEOPLE },
  PILOT_5: { search: 'pilot 5|пилот 5', name: '2162', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PILOT_0: { search: 'woman pilot 0|женщина пилот 0', name: '3099', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PILOT_1: { search: 'woman pilot 1|женщина пилот 1', name: '3100', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PILOT_2: { search: 'woman pilot 2|женщина пилот 2', name: '3101', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PILOT_3: { search: 'woman pilot 3|женщина пилот 3', name: '3102', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PILOT_4: { search: 'woman pilot 4|женщина пилот 4', name: '3103', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PILOT_5: { search: 'woman pilot 5|женщина пилот 5', name: '3104', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SCIENTIST_0: { search: 'man scientist 0|человек ученый 0', name: '1539', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SCIENTIST_1: { search: 'man scientist 1|человек ученый 1', name: '1540', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SCIENTIST_2: { search: 'man scientist 2|человек ученый 2', name: '1541', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SCIENTIST_3: { search: 'man scientist 3|человек ученый 3', name: '1542', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SCIENTIST_4: { search: 'man scientist 4|человек ученый 4', name: '1543', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SCIENTIST_5: { search: 'man scientist 5|человек ученый 5', name: '1544', category: EMOJI_CATEGORIES.PEOPLE },
  SCIENTIST_0: { search: 'scientist 0|ученый 0', name: '2376', category: EMOJI_CATEGORIES.PEOPLE },
  SCIENTIST_1: { search: 'scientist 1|ученый 1', name: '2377', category: EMOJI_CATEGORIES.PEOPLE },
  SCIENTIST_2: { search: 'scientist 2|ученый 2', name: '2378', category: EMOJI_CATEGORIES.PEOPLE },
  SCIENTIST_3: { search: 'scientist 3|ученый 3', name: '2379', category: EMOJI_CATEGORIES.PEOPLE },
  SCIENTIST_4: { search: 'scientist 4|ученый 4', name: '2380', category: EMOJI_CATEGORIES.PEOPLE },
  SCIENTIST_5: { search: 'scientist 5|ученый 5', name: '2381', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SCIENTIST_0: { search: 'woman scientist 0|женщина ученый 0', name: '3147', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SCIENTIST_1: { search: 'woman scientist 1|женщина ученый 1', name: '3148', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SCIENTIST_2: { search: 'woman scientist 2|женщина ученый 2', name: '3149', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SCIENTIST_3: { search: 'woman scientist 3|женщина ученый 3', name: '3150', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SCIENTIST_4: { search: 'woman scientist 4|женщина ученый 4', name: '3151', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SCIENTIST_5: { search: 'woman scientist 5|женщина ученый 5', name: '3152', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TEACHER_0: { search: 'man teacher 0|учитель 0', name: '1593', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TEACHER_1: { search: 'man teacher 1|человек учитель 1', name: '1594', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TEACHER_2: { search: 'man teacher 2|человек учитель 2', name: '1595', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TEACHER_3: { search: 'man teacher 3|человек учитель 3', name: '1596', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TEACHER_4: { search: 'man teacher 4|человек учитель 4', name: '1597', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TEACHER_5: { search: 'man teacher 5|человек учитель 5', name: '1598', category: EMOJI_CATEGORIES.PEOPLE },
  TEACHER_0: { search: 'teacher 0|учитель 0', name: '2611', category: EMOJI_CATEGORIES.PEOPLE },
  TEACHER_1: { search: 'teacher 1|учитель 1', name: '2612', category: EMOJI_CATEGORIES.PEOPLE },
  TEACHER_2: { search: 'teacher 2|учитель 2', name: '2613', category: EMOJI_CATEGORIES.PEOPLE },
  TEACHER_3: { search: 'teacher 3|учитель 3', name: '2614', category: EMOJI_CATEGORIES.PEOPLE },
  TEACHER_4: { search: 'teacher 4|учитель 4', name: '2615', category: EMOJI_CATEGORIES.PEOPLE },
  TEACHER_5: { search: 'teacher 5|учитель 5', name: '2616', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TEACHER_0: { search: 'woman teacher 0|учитель женщины 0', name: '3201', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TEACHER_1: { search: 'woman teacher 1|учитель женщины 1', name: '3202', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TEACHER_2: { search: 'woman teacher 2|учитель женщины 2', name: '3203', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TEACHER_3: { search: 'woman teacher 3|учитель женщины 3', name: '3204', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TEACHER_4: { search: 'woman teacher 4|учитель женщины 4', name: '3205', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TEACHER_5: { search: 'woman teacher 5|учитель женщины 5', name: '3206', category: EMOJI_CATEGORIES.PEOPLE },
  TECHNOLOGIST_0: { search: 'technologist 0|технолог 0', name: '2620', category: EMOJI_CATEGORIES.PEOPLE },
  TECHNOLOGIST_1: { search: 'technologist 1|технолог 1', name: '2621', category: EMOJI_CATEGORIES.PEOPLE },
  TECHNOLOGIST_2: { search: 'technologist 2|технолог 2', name: '2622', category: EMOJI_CATEGORIES.PEOPLE },
  TECHNOLOGIST_3: { search: 'technologist 3|технолог 3', name: '2623', category: EMOJI_CATEGORIES.PEOPLE },
  TECHNOLOGIST_4: { search: 'technologist 4|технолог 4', name: '2624', category: EMOJI_CATEGORIES.PEOPLE },
  TECHNOLOGIST_5: { search: 'technologist 5|технолог 5', name: '2625', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TECHNOLOGIST_0: { search: 'man technologist 0|человек технолог 0', name: '1599', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TECHNOLOGIST_1: { search: 'man technologist 1|человек технолог 1', name: '1600', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TECHNOLOGIST_2: { search: 'man technologist 2|человек технолог 2', name: '1601', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TECHNOLOGIST_3: { search: 'man technologist 3|человек технолог 3', name: '1602', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TECHNOLOGIST_4: { search: 'man technologist 4|человек технолог 4', name: '1603', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_TECHNOLOGIST_5: { search: 'man technologist 5|человек технолог 5', name: '1604', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TECHNOLOGIST_0: { search: 'woman technologist 0|женщина технолог 0', name: '3207', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TECHNOLOGIST_1: { search: 'woman technologist 1|женщина технолог 1', name: '3208', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TECHNOLOGIST_2: { search: 'woman technologist 2|женщина технолог 2', name: '3209', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TECHNOLOGIST_3: { search: 'woman technologist 3|женщина технолог 3', name: '3210', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TECHNOLOGIST_4: { search: 'woman technologist 4|женщина технолог 4', name: '3211', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_TECHNOLOGIST_5: { search: 'woman technologist 5|женщина технолог 5', name: '3212', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SINGER_0: { search: 'man singer 0|человек певица 0', name: '1551', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SINGER_1: { search: 'man singer 1|человек певица 1', name: '1552', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SINGER_2: { search: 'man singer 2|человек певица 2', name: '1553', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SINGER_3: { search: 'man singer 3|человек певица 3', name: '1554', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SINGER_4: { search: 'man singer 4|человек певица 4', name: '1555', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SINGER_5: { search: 'man singer 5|человек певица 5', name: '1556', category: EMOJI_CATEGORIES.PEOPLE },
  SINGER_0: { search: 'singer 0|певица 0', name: '2447', category: EMOJI_CATEGORIES.PEOPLE },
  SINGER_1: { search: 'singer 1|певица 1', name: '2448', category: EMOJI_CATEGORIES.PEOPLE },
  SINGER_2: { search: 'singer 2|певица 2', name: '2449', category: EMOJI_CATEGORIES.PEOPLE },
  SINGER_3: { search: 'singer 3|певица 3', name: '2450', category: EMOJI_CATEGORIES.PEOPLE },
  SINGER_4: { search: 'singer 4|певица 4', name: '2451', category: EMOJI_CATEGORIES.PEOPLE },
  SINGER_5: { search: 'singer 5|певица 5', name: '2452', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SINGER_0: { search: 'woman singer 0|женщина певица 0', name: '3159', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SINGER_1: { search: 'woman singer 1|женщина певица 1', name: '3160', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SINGER_2: { search: 'woman singer 2|женщина певица 2', name: '3161', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SINGER_3: { search: 'woman singer 3|женщина певица 3', name: '3162', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SINGER_4: { search: 'woman singer 4|женщина певица 4', name: '3163', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SINGER_5: { search: 'woman singer 5|женщина певица 5', name: '3164', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STUDENT_0: { search: 'man student 0|человек студент 0', name: '1563', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STUDENT_1: { search: 'man student 1|человек студент 1', name: '1564', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STUDENT_2: { search: 'man student 2|человек студент 2', name: '1565', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STUDENT_3: { search: 'man student 3|человек студент 3', name: '1566', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STUDENT_4: { search: 'man student 4|человек студент 4', name: '1567', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STUDENT_5: { search: 'man student 5|человек студент 5', name: '1568', category: EMOJI_CATEGORIES.PEOPLE },
  STUDENT_0: { search: 'student 0|студент 0', name: '2553', category: EMOJI_CATEGORIES.PEOPLE },
  STUDENT_1: { search: 'student 1|студент 1', name: '2554', category: EMOJI_CATEGORIES.PEOPLE },
  STUDENT_2: { search: 'student 2|студент 2', name: '2555', category: EMOJI_CATEGORIES.PEOPLE },
  STUDENT_3: { search: 'student 3|студент 3', name: '2556', category: EMOJI_CATEGORIES.PEOPLE },
  STUDENT_4: { search: 'student 4|студент 4', name: '2557', category: EMOJI_CATEGORIES.PEOPLE },
  STUDENT_5: { search: 'student 5|студент 5', name: '2558', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STUDENT_0: { search: 'woman student 0|женщина студент 0', name: '3171', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STUDENT_1: { search: 'woman student 1|женщина студент 1', name: '3172', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STUDENT_2: { search: 'woman student 2|женщина студент 2', name: '3173', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STUDENT_3: { search: 'woman student 3|женщина студент 3', name: '3174', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STUDENT_4: { search: 'woman student 4|женщина студент 4', name: '3175', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STUDENT_5: { search: 'woman student 5|женщина студент 5', name: '3176', category: EMOJI_CATEGORIES.PEOPLE },
  NINJA_0: { search: 'ninja 0|ниндзя 0', name: '1803', category: EMOJI_CATEGORIES.PEOPLE },
  NINJA_1: { search: 'ninja 1|ниндзя 1', name: '1804', category: EMOJI_CATEGORIES.PEOPLE },
  NINJA_2: { search: 'ninja 2|ниндзя 2', name: '1805', category: EMOJI_CATEGORIES.PEOPLE },
  NINJA_3: { search: 'ninja 3|ниндзя 3', name: '1806', category: EMOJI_CATEGORIES.PEOPLE },
  NINJA_4: { search: 'ninja 4|ниндзя 4', name: '1807', category: EMOJI_CATEGORIES.PEOPLE },
  NINJA_5: { search: 'ninja 5|ниндзя 5', name: '1808', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERHERO_0: { search: 'man superhero 0|человек супергерой 0', name: '1569', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERHERO_1: { search: 'man superhero 1|человек супергерой 1', name: '1570', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERHERO_2: { search: 'man superhero 2|человек супергерой 2', name: '1571', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERHERO_3: { search: 'man superhero 3|человек супергерой 3', name: '1572', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERHERO_4: { search: 'man superhero 4|человек супергерой 4', name: '1573', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERHERO_5: { search: 'man superhero 5|человек супергерой 5', name: '1574', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERHERO_0: { search: 'superhero 0|супергеро 0', name: '2572', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERHERO_1: { search: 'superhero 1|супергеро 1', name: '2573', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERHERO_2: { search: 'superhero 2|супергеро 2', name: '2574', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERHERO_3: { search: 'superhero 3|супергеро 3', name: '2575', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERHERO_4: { search: 'superhero 4|супергеро 4', name: '2576', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERHERO_5: { search: 'superhero 5|супергеро 5', name: '2577', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERHERO_0: { search: 'woman superhero 0|женщина супергерой 0', name: '3177', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERHERO_1: { search: 'woman superhero 1|женщина супергерой 1', name: '3178', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERHERO_2: { search: 'woman superhero 2|женщина супергерой 2', name: '3179', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERHERO_3: { search: 'woman superhero 3|женщина супергерой 3', name: '3180', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERHERO_4: { search: 'woman superhero 4|женщина супергерой 4', name: '3181', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERHERO_5: { search: 'woman superhero 5|женщина супергерой 5', name: '3182', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERVILLAIN_0: { search: 'man supervillain 0|человек supervillain 0', name: '1575', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERVILLAIN_1: { search: 'man supervillain 1|человек supervillain 1', name: '1576', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERVILLAIN_2: { search: 'man supervillain 2|человек supervillain 2', name: '1577', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERVILLAIN_3: { search: 'man supervillain 3|человек supervillain 3', name: '1578', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERVILLAIN_4: { search: 'man supervillain 4|человек supervillain 4', name: '1579', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SUPERVILLAIN_5: { search: 'man supervillain 5|человек supervillain 5', name: '1580', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERVILLAIN_0: { search: 'supervillain 0|супервиллаин 0', name: '2578', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERVILLAIN_1: { search: 'supervillain 1|супервиллаин 1', name: '2579', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERVILLAIN_2: { search: 'supervillain 2|супервиллаин 2', name: '2580', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERVILLAIN_3: { search: 'supervillain 3|супервиллаин 3', name: '2581', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERVILLAIN_4: { search: 'supervillain 4|супервиллаин 4', name: '2582', category: EMOJI_CATEGORIES.PEOPLE },
  SUPERVILLAIN_5: { search: 'supervillain 5|супервиллаин 5', name: '2583', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERVILLAIN_0: { search: 'woman supervillain 0|женщина supervillain 0', name: '3183', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERVILLAIN_1: { search: 'woman supervillain 1|женщина supervillain 1', name: '3184', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERVILLAIN_2: { search: 'woman supervillain 2|женщина supervillain 2', name: '3185', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERVILLAIN_3: { search: 'woman supervillain 3|женщина supervillain 3', name: '3186', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERVILLAIN_4: { search: 'woman supervillain 4|женщина supervillain 4', name: '3187', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SUPERVILLAIN_5: { search: 'woman supervillain 5|женщина supervillain 5', name: '3188', category: EMOJI_CATEGORIES.PEOPLE },
  MRS__CLAUS_0: { search: 'Mrs. Claus 0|госпожа клаус 0', name: '1764', category: EMOJI_CATEGORIES.PEOPLE },
  MRS__CLAUS_1: { search: 'Mrs. Claus 1|госпожа клаус 1', name: '1765', category: EMOJI_CATEGORIES.PEOPLE },
  MRS__CLAUS_2: { search: 'Mrs. Claus 2|госпожа клаус 2', name: '1766', category: EMOJI_CATEGORIES.PEOPLE },
  MRS__CLAUS_3: { search: 'Mrs. Claus 3|госпожа клаус 3', name: '1767', category: EMOJI_CATEGORIES.PEOPLE },
  MRS__CLAUS_4: { search: 'Mrs. Claus 4|госпожа клаус 4', name: '1768', category: EMOJI_CATEGORIES.PEOPLE },
  MRS__CLAUS_5: { search: 'Mrs. Claus 5|госпожа клаус 5', name: '1769', category: EMOJI_CATEGORIES.PEOPLE },
  MX__CLAUS_0: { search: 'Mx. Claus 0|мкс клаус 0', name: '1778', category: EMOJI_CATEGORIES.PEOPLE },
  MX__CLAUS_1: { search: 'Mx. Claus 1|мкс клаус 1', name: '1779', category: EMOJI_CATEGORIES.PEOPLE },
  MX__CLAUS_2: { search: 'Mx. Claus 2|мкс клаус 2', name: '1780', category: EMOJI_CATEGORIES.PEOPLE },
  MX__CLAUS_3: { search: 'Mx. Claus 3|мкс клаус 3', name: '1781', category: EMOJI_CATEGORIES.PEOPLE },
  MX__CLAUS_4: { search: 'Mx. Claus 4|мкс клаус 4', name: '1782', category: EMOJI_CATEGORIES.PEOPLE },
  MX__CLAUS_5: { search: 'Mx. Claus 5|мкс клаус 5', name: '1783', category: EMOJI_CATEGORIES.PEOPLE },
  SANTA_CLAUS_0: { search: 'Santa Claus 0|дед мороз 0', name: '2363', category: EMOJI_CATEGORIES.PEOPLE },
  SANTA_CLAUS_1: { search: 'Santa Claus 1|санта клаус 1', name: '2364', category: EMOJI_CATEGORIES.PEOPLE },
  SANTA_CLAUS_2: { search: 'Santa Claus 2|санта клаус 2', name: '2365', category: EMOJI_CATEGORIES.PEOPLE },
  SANTA_CLAUS_3: { search: 'Santa Claus 3|санта клаус 3', name: '2366', category: EMOJI_CATEGORIES.PEOPLE },
  SANTA_CLAUS_4: { search: 'Santa Claus 4|санта клаус 4', name: '2367', category: EMOJI_CATEGORIES.PEOPLE },
  SANTA_CLAUS_5: { search: 'Santa Claus 5|санта клаус 5', name: '2368', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCE_0: { search: 'prince 0|принц 0', name: '2230', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCE_1: { search: 'prince 1|принц 1', name: '2231', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCE_2: { search: 'prince 2|принц 2', name: '2232', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCE_3: { search: 'prince 3|принц 3', name: '2233', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCE_4: { search: 'prince 4|принцесса 4', name: '2234', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCE_5: { search: 'prince 5|принц 5', name: '2235', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCESS_0: { search: 'princess 0|принцесса 0', name: '2236', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCESS_1: { search: 'princess 1|принцесса 1', name: '2237', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCESS_2: { search: 'princess 2|принцесса 2', name: '2238', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCESS_3: { search: 'princess 3|принцесса 3', name: '2239', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCESS_4: { search: 'princess 4|принцесса 4', name: '2240', category: EMOJI_CATEGORIES.PEOPLE },
  PRINCESS_5: { search: 'princess 5|принцесса 5', name: '2241', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERER_0: { search: 'sorcerer 0|колдун 0', name: '2502', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERER_1: { search: 'sorcerer 1|колдун 1', name: '2503', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERER_2: { search: 'sorcerer 2|колдун 2', name: '2504', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERER_3: { search: 'sorcerer 3|колдун 3', name: '2505', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERER_4: { search: 'sorcerer 4|колдун 4', name: '2506', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERER_5: { search: 'sorcerer 5|колдун 5', name: '2507', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERESS_0: { search: 'sorceress 0|согресс 0', name: '2508', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERESS_1: { search: 'sorceress 1|согресс 1', name: '2509', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERESS_2: { search: 'sorceress 2|согресс 2', name: '2510', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERESS_3: { search: 'sorceress 3|согресс 3', name: '2511', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERESS_4: { search: 'sorceress 4|согресс 4', name: '2512', category: EMOJI_CATEGORIES.PEOPLE },
  SORCERESS_5: { search: 'sorceress 5|согресс 5', name: '2513', category: EMOJI_CATEGORIES.PEOPLE },
  WIZARD_0: { search: 'wizard 0|мастер 0', name: '2864', category: EMOJI_CATEGORIES.PEOPLE },
  WIZARD_1: { search: 'wizard 1|мастер 1', name: '2865', category: EMOJI_CATEGORIES.PEOPLE },
  WIZARD_2: { search: 'wizard 2|мастер 2', name: '2866', category: EMOJI_CATEGORIES.PEOPLE },
  WIZARD_3: { search: 'wizard 3|мастер 3', name: '2867', category: EMOJI_CATEGORIES.PEOPLE },
  WIZARD_4: { search: 'wizard 4|мастер 4', name: '2868', category: EMOJI_CATEGORIES.PEOPLE },
  WIZARD_5: { search: 'wizard 5|мастер 5', name: '2869', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_VAMPIRE_0: { search: 'man vampire 0|человек вампир 0', name: '1605', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_VAMPIRE_1: { search: 'man vampire 1|человек вампир 1', name: '1606', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_VAMPIRE_2: { search: 'man vampire 2|человек вампир 2', name: '1607', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_VAMPIRE_3: { search: 'man vampire 3|человек вампир 3', name: '1608', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_VAMPIRE_4: { search: 'man vampire 4|человек вампир 4', name: '1609', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_VAMPIRE_5: { search: 'man vampire 5|человек вампир 5', name: '1610', category: EMOJI_CATEGORIES.PEOPLE },
  VAMPIRE_0: { search: 'vampire 0|вампир 0', name: '2796', category: EMOJI_CATEGORIES.PEOPLE },
  VAMPIRE_1: { search: 'vampire 1|вампир 1', name: '2797', category: EMOJI_CATEGORIES.PEOPLE },
  VAMPIRE_2: { search: 'vampire 2|вампир 2', name: '2798', category: EMOJI_CATEGORIES.PEOPLE },
  VAMPIRE_3: { search: 'vampire 3|вампир 3', name: '2799', category: EMOJI_CATEGORIES.PEOPLE },
  VAMPIRE_4: { search: 'vampire 4|вампир 4', name: '2800', category: EMOJI_CATEGORIES.PEOPLE },
  VAMPIRE_5: { search: 'vampire 5|вампир 5', name: '2801', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_VAMPIRE_0: { search: 'woman vampire 0|женщина вампир 0', name: '3213', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_VAMPIRE_1: { search: 'woman vampire 1|женщина вампир 1', name: '3214', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_VAMPIRE_2: { search: 'woman vampire 2|женщина вампир 2', name: '3215', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_VAMPIRE_3: { search: 'woman vampire 3|женщина вампир 3', name: '3216', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_VAMPIRE_4: { search: 'woman vampire 4|женщина вампир 4', name: '3217', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_VAMPIRE_5: { search: 'woman vampire 5|женщина вампир 5', name: '3218', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ZOMBIE: { search: 'man zombie|человек зомби', name: '1671', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ZOMBIE: { search: 'woman zombie|женщина зомби', name: '3279', category: EMOJI_CATEGORIES.PEOPLE },
  ZOMBIE: { search: 'zombie|зомби', name: '3309', category: EMOJI_CATEGORIES.PEOPLE },
  ELF_0: { search: 'elf 0|эльф 0', name: '519', category: EMOJI_CATEGORIES.PEOPLE },
  ELF_1: { search: 'elf 1|эльф 1', name: '520', category: EMOJI_CATEGORIES.PEOPLE },
  ELF_2: { search: 'elf 2|эльф 2', name: '521', category: EMOJI_CATEGORIES.PEOPLE },
  ELF_3: { search: 'elf 3|эльф 3', name: '522', category: EMOJI_CATEGORIES.PEOPLE },
  ELF_4: { search: 'elf 4|эльф 4', name: '523', category: EMOJI_CATEGORIES.PEOPLE },
  ELF_5: { search: 'elf 5|эльф 5', name: '524', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ELF_0: { search: 'man elf 0|человек эльф 0', name: '1328', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ELF_1: { search: 'man elf 1|человек elf 1', name: '1329', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ELF_2: { search: 'man elf 2|человек elf 2', name: '1330', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ELF_3: { search: 'man elf 3|мужчина elf 3', name: '1331', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ELF_4: { search: 'man elf 4|мужчина elf 4', name: '1332', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_ELF_5: { search: 'man elf 5|человек elf 5', name: '1333', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ELF_0: { search: 'woman elf 0|женщина эльф 0', name: '2942', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ELF_1: { search: 'woman elf 1|женщина эльф 1', name: '2943', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ELF_2: { search: 'woman elf 2|женщина эльф 2', name: '2944', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ELF_3: { search: 'woman elf 3|женщина эльф 3', name: '2945', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ELF_4: { search: 'woman elf 4|женщина эльф 4', name: '2946', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_ELF_5: { search: 'woman elf 5|женщина эльф 5', name: '2947', category: EMOJI_CATEGORIES.PEOPLE },
  GENIE: { search: 'genie|гени', name: '942', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GENIE: { search: 'man genie|человек гени', name: '1376', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GENIE: { search: 'woman genie|женщина гени', name: '2990', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAID_0: { search: 'mermaid 0|русалка 0', name: '1698', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAID_1: { search: 'mermaid 1|русалка 1', name: '1699', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAID_2: { search: 'mermaid 2|русалка 2', name: '1700', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAID_3: { search: 'mermaid 3|русалка 3', name: '1701', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAID_4: { search: 'mermaid 4|русалка 4', name: '1702', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAID_5: { search: 'mermaid 5|русалка 5', name: '1703', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAN_0: { search: 'merman 0|merman 0', name: '1704', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAN_1: { search: 'merman 1|merman 1', name: '1705', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAN_2: { search: 'merman 2|merman 2', name: '1706', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAN_3: { search: 'merman 3|merman 3', name: '1707', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAN_4: { search: 'merman 4|merman 4', name: '1708', category: EMOJI_CATEGORIES.PEOPLE },
  MERMAN_5: { search: 'merman 5|merman 5', name: '1709', category: EMOJI_CATEGORIES.PEOPLE },
  MERPERSON_0: { search: 'merperson 0|merperson 0', name: '1710', category: EMOJI_CATEGORIES.PEOPLE },
  MERPERSON_1: { search: 'merperson 1|merperson 1', name: '1711', category: EMOJI_CATEGORIES.PEOPLE },
  MERPERSON_2: { search: 'merperson 2|merperson 2', name: '1712', category: EMOJI_CATEGORIES.PEOPLE },
  MERPERSON_3: { search: 'merperson 3|merperson 3', name: '1713', category: EMOJI_CATEGORIES.PEOPLE },
  MERPERSON_4: { search: 'merperson 4|merperson 4', name: '1714', category: EMOJI_CATEGORIES.PEOPLE },
  MERPERSON_5: { search: 'merperson 5|merperson 5', name: '1715', category: EMOJI_CATEGORIES.PEOPLE },
  FAIRY_0: { search: 'fairy 0|фея 0', name: '565', category: EMOJI_CATEGORIES.PEOPLE },
  FAIRY_1: { search: 'fairy 1|фея 1', name: '566', category: EMOJI_CATEGORIES.PEOPLE },
  FAIRY_2: { search: 'fairy 2|фея 2', name: '567', category: EMOJI_CATEGORIES.PEOPLE },
  FAIRY_3: { search: 'fairy 3|фея 3', name: '568', category: EMOJI_CATEGORIES.PEOPLE },
  FAIRY_4: { search: 'fairy 4|фея 4', name: '569', category: EMOJI_CATEGORIES.PEOPLE },
  FAIRY_5: { search: 'fairy 5|фея 5', name: '570', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FAIRY_0: { search: 'man fairy 0|человек фея 0', name: '1346', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FAIRY_1: { search: 'man fairy 1|человек фея 1', name: '1347', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FAIRY_2: { search: 'man fairy 2|человек фея 2', name: '1348', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FAIRY_3: { search: 'man fairy 3|человек фея 3', name: '1349', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FAIRY_4: { search: 'man fairy 4|человек фея 4', name: '1350', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FAIRY_5: { search: 'man fairy 5|человек фея 5', name: '1351', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FAIRY_0: { search: 'woman fairy 0|женская фея 0', name: '2960', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FAIRY_1: { search: 'woman fairy 1|женская фея 1', name: '2961', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FAIRY_2: { search: 'woman fairy 2|женская фея 2', name: '2962', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FAIRY_3: { search: 'woman fairy 3|женская фея 3', name: '2963', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FAIRY_4: { search: 'woman fairy 4|женская фея 4', name: '2964', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FAIRY_5: { search: 'woman fairy 5|женская фея 5', name: '2965', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_ANGEL_0: { search: 'baby angel 0|ребенок ангел 0', name: '58', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_ANGEL_1: { search: 'baby angel 1|ребенок ангел 1', name: '59', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_ANGEL_2: { search: 'baby angel 2|ребенок ангел 2', name: '60', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_ANGEL_3: { search: 'baby angel 3|ребенок ангел 3', name: '61', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_ANGEL_4: { search: 'baby angel 4|ребенок ангел 4', name: '62', category: EMOJI_CATEGORIES.PEOPLE },
  BABY_ANGEL_5: { search: 'baby angel 5|ребенок ангел 5', name: '63', category: EMOJI_CATEGORIES.PEOPLE },
  PREGNANT_WOMAN_0: { search: 'pregnant woman 0|беременная женщина 0', name: '2222', category: EMOJI_CATEGORIES.PEOPLE },
  PREGNANT_WOMAN_1: { search: 'pregnant woman 1|беременная женщина 1', name: '2223', category: EMOJI_CATEGORIES.PEOPLE },
  PREGNANT_WOMAN_2: { search: 'pregnant woman 2|беременная женщина 2', name: '2224', category: EMOJI_CATEGORIES.PEOPLE },
  PREGNANT_WOMAN_3: { search: 'pregnant woman 3|беременная женщина 3', name: '2225', category: EMOJI_CATEGORIES.PEOPLE },
  PREGNANT_WOMAN_4: { search: 'pregnant woman 4|беременная женщина 4', name: '2226', category: EMOJI_CATEGORIES.PEOPLE },
  PREGNANT_WOMAN_5: { search: 'pregnant woman 5|беременная женщина 5', name: '2227', category: EMOJI_CATEGORIES.PEOPLE },
  BREASTFEEDING_0: { search: 'breastfeeding 0|грудное вскармливание 0', name: '210', category: EMOJI_CATEGORIES.PEOPLE },
  BREASTFEEDING_1: { search: 'breastfeeding 1|грудное вскармливание 1', name: '211', category: EMOJI_CATEGORIES.PEOPLE },
  BREASTFEEDING_2: { search: 'breastfeeding 2|грудное вскармливание 2', name: '212', category: EMOJI_CATEGORIES.PEOPLE },
  BREASTFEEDING_3: { search: 'breastfeeding 3|грудное вскармливание 3', name: '213', category: EMOJI_CATEGORIES.PEOPLE },
  BREASTFEEDING_4: { search: 'breastfeeding 4|грудное вскармливание 4', name: '214', category: EMOJI_CATEGORIES.PEOPLE },
  BREASTFEEDING_5: { search: 'breastfeeding 5|грудное вскармливание 5', name: '215', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FEEDING_BABY_0: { search: 'man feeding baby 0|человек кормления ребенок 0', name: '1358', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FEEDING_BABY_1: { search: 'man feeding baby 1|человек кормление ребенок 1', name: '1359', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FEEDING_BABY_2: { search: 'man feeding baby 2|человек кормление ребенка 2', name: '1360', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FEEDING_BABY_3: { search: 'man feeding baby 3|человек кормление ребенка 3', name: '1361', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FEEDING_BABY_4: { search: 'man feeding baby 4|человек кормления ребенка 4', name: '1362', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FEEDING_BABY_5: { search: 'man feeding baby 5|человек кормления ребенка 5', name: '1363', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FEEDING_BABY_0: { search: 'person feeding baby 0|человек кормящий ребенок 0', name: '1972', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FEEDING_BABY_1: { search: 'person feeding baby 1|человек кормление ребенок 1', name: '1973', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FEEDING_BABY_2: { search: 'person feeding baby 2|человек кормление ребенка 2', name: '1974', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FEEDING_BABY_3: { search: 'person feeding baby 3|человек кормление ребенка 3', name: '1975', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FEEDING_BABY_4: { search: 'person feeding baby 4|человек кормления ребенка 4', name: '1976', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FEEDING_BABY_5: { search: 'person feeding baby 5|человек кормления ребенка 5', name: '1977', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FEEDING_BABY_0: { search: 'woman feeding baby 0|женщина кормление ребенок 0', name: '2972', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FEEDING_BABY_1: { search: 'woman feeding baby 1|женщина кормление ребенок 1', name: '2973', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FEEDING_BABY_2: { search: 'woman feeding baby 2|женщина кормление ребенок 2', name: '2974', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FEEDING_BABY_3: { search: 'woman feeding baby 3|женщина кормление ребенок 3', name: '2975', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FEEDING_BABY_4: { search: 'woman feeding baby 4|женщина кормящая малыш 4', name: '2976', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FEEDING_BABY_5: { search: 'woman feeding baby 5|женщина кормление ребенок 5', name: '2977', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_BOWING_0: { search: 'man bowing 0|человек bowing 0', name: '1286', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_BOWING_1: { search: 'man bowing 1|человек bowing 1', name: '1287', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_BOWING_2: { search: 'man bowing 2|человек bowing 2', name: '1288', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_BOWING_3: { search: 'man bowing 3|человек bowing 3', name: '1289', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_BOWING_4: { search: 'man bowing 4|человек bowing 4', name: '1290', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_BOWING_5: { search: 'man bowing 5|человек bowing 5', name: '1291', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_BOWING_0: { search: 'person bowing 0|человек bowing 0', name: '1954', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_BOWING_1: { search: 'person bowing 1|человек bowing 1', name: '1955', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_BOWING_2: { search: 'person bowing 2|человек bowing 2', name: '1956', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_BOWING_3: { search: 'person bowing 3|человек bowing 3', name: '1957', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_BOWING_4: { search: 'person bowing 4|человек bowing 4', name: '1958', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_BOWING_5: { search: 'person bowing 5|человек bowing 5', name: '1959', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_BOWING_0: { search: 'woman bowing 0|женщина bowing 0', name: '2900', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_BOWING_1: { search: 'woman bowing 1|женщина bowing 1', name: '2901', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_BOWING_2: { search: 'woman bowing 2|женщина bowing 2', name: '2902', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_BOWING_3: { search: 'woman bowing 3|женщина bowing 3', name: '2903', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_BOWING_4: { search: 'woman bowing 4|женщина бульвар 4', name: '2904', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_BOWING_5: { search: 'woman bowing 5|женщина bowing 5', name: '2905', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_ATTENDANT_0: { search: 'information desk attendant 0|информационный стол участников 0', name: '1103', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_ATTENDANT_1: { search: 'information desk attendant 1|информационный стол участников 1', name: '1104', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_ATTENDANT_2: { search: 'information desk attendant 2|информационный стол участников 2', name: '1105', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_ATTENDANT_3: { search: 'information desk attendant 3|информационный стол участников 3', name: '1106', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_ATTENDANT_4: { search: 'information desk attendant 4|информационный стол участников 4', name: '1107', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_ATTENDANT_5: { search: 'information desk attendant 5|информационный стол участников 5', name: '1108', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_MAN_0: { search: 'information desk man 0|справка человек 0', name: '1109', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_MAN_1: { search: 'information desk man 1|информация стол человек 1', name: '1110', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_MAN_2: { search: 'information desk man 2|справка человек 2', name: '1111', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_MAN_3: { search: 'information desk man 3|информация стол человек 3', name: '1112', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_MAN_4: { search: 'information desk man 4|информация стол человек 4', name: '1113', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_MAN_5: { search: 'information desk man 5|справка человек 5', name: '1114', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_WOMAN_0: { search: 'information desk woman 0|информация стол женщина 0', name: '1115', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_WOMAN_1: { search: 'information desk woman 1|информация стол женщина 1', name: '1116', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_WOMAN_2: { search: 'information desk woman 2|информационный стол женщина 2', name: '1117', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_WOMAN_3: { search: 'information desk woman 3|информация стол женщина 3', name: '1118', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_WOMAN_4: { search: 'information desk woman 4|информация стол женщина 4', name: '1119', category: EMOJI_CATEGORIES.PEOPLE },
  INFORMATION_DESK_WOMAN_5: { search: 'information desk woman 5|информационный стол женщина 5', name: '1120', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_MAN_0: { search: 'Deaf man 0|глухой человек 0', name: '421', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_MAN_1: { search: 'Deaf man 1|глухой человек 1', name: '422', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_MAN_2: { search: 'Deaf man 2|глухой человек 2', name: '423', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_MAN_3: { search: 'Deaf man 3|глухой человек 3', name: '424', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_MAN_4: { search: 'Deaf man 4|глухой человек 4', name: '425', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_MAN_5: { search: 'Deaf man 5|глухой человек 5', name: '426', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_PERSON_0: { search: 'Deaf person 0|глухое лицо 0', name: '427', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_PERSON_1: { search: 'Deaf person 1|глухое лицо 1', name: '428', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_PERSON_2: { search: 'Deaf person 2|глухое лицо 2', name: '429', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_PERSON_3: { search: 'Deaf person 3|глухое лицо 3', name: '430', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_PERSON_4: { search: 'Deaf person 4|глухое лицо 4', name: '431', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_PERSON_5: { search: 'Deaf person 5|глухое лицо 5', name: '432', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_WOMAN_0: { search: 'Deaf woman 0|глухая женщина 0', name: '433', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_WOMAN_1: { search: 'Deaf woman 1|глухая женщина 1', name: '434', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_WOMAN_2: { search: 'Deaf woman 2|глухая женщина 2', name: '435', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_WOMAN_3: { search: 'Deaf woman 3|глухая женщина 3', name: '436', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_WOMAN_4: { search: 'Deaf woman 4|глухая женщина 4', name: '437', category: EMOJI_CATEGORIES.PEOPLE },
  DEAF_WOMAN_5: { search: 'Deaf woman 5|глухая женщина 5', name: '438', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FACEPALMING_0: { search: 'person facepalming 0|человек facepalming 0', name: '1966', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FACEPALMING_1: { search: 'person facepalming 1|человек facepalming 1', name: '1967', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FACEPALMING_2: { search: 'person facepalming 2|человек facepalming 2', name: '1968', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FACEPALMING_3: { search: 'person facepalming 3|человек facepalming 3', name: '1969', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FACEPALMING_4: { search: 'person facepalming 4|человек facepalming 4', name: '1970', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FACEPALMING_5: { search: 'person facepalming 5|человек facepalming 5', name: '1971', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACEPALMING_0: { search: 'man facepalming 0|человек facepalming 0', name: '1334', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACEPALMING_1: { search: 'man facepalming 1|человек facepalming 1', name: '1335', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACEPALMING_2: { search: 'man facepalming 2|человек facepalming 2', name: '1336', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACEPALMING_3: { search: 'man facepalming 3|человек facepalming 3', name: '1337', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACEPALMING_4: { search: 'man facepalming 4|человек facepalming 4', name: '1338', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FACEPALMING_5: { search: 'man facepalming 5|человек facepalming 5', name: '1339', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACEPALMING_0: { search: 'woman facepalming 0|женщина facepalming 0', name: '2948', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACEPALMING_1: { search: 'woman facepalming 1|женщина facepalming 1', name: '2949', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACEPALMING_2: { search: 'woman facepalming 2|женщина facepalming 2', name: '2950', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACEPALMING_3: { search: 'woman facepalming 3|женщина facepalming 3', name: '2951', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACEPALMING_4: { search: 'woman facepalming 4|женщина facepalming 4', name: '2952', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FACEPALMING_5: { search: 'woman facepalming 5|женщина facepalming 5', name: '2953', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_NO_0: { search: 'man gesturing no 0|человек жест no 0', name: '1377', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_NO_1: { search: 'man gesturing no 1|мужчина жест no 1', name: '1378', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_NO_2: { search: 'man gesturing no 2|человек жест no 2', name: '1379', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_NO_3: { search: 'man gesturing no 3|человек жест no 3', name: '1380', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_NO_4: { search: 'man gesturing no 4|человек жест no 4', name: '1381', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_NO_5: { search: 'man gesturing no 5|человек жест no 5', name: '1382', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_NO_0: { search: 'person gesturing no 0|человек жест no 0', name: '1984', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_NO_1: { search: 'person gesturing no 1|человек жест no 1', name: '1985', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_NO_2: { search: 'person gesturing no 2|человек жест no 2', name: '1986', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_NO_3: { search: 'person gesturing no 3|человек жест no 3', name: '1987', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_NO_4: { search: 'person gesturing no 4|человек жест no 4', name: '1988', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_NO_5: { search: 'person gesturing no 5|человек жест no 5', name: '1989', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_NO_0: { search: 'woman gesturing no 0|женщина жест no 0', name: '2991', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_NO_1: { search: 'woman gesturing no 1|женщина жест no 1', name: '2992', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_NO_2: { search: 'woman gesturing no 2|женщина жест no 2', name: '2993', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_NO_3: { search: 'woman gesturing no 3|женщина жест no 3', name: '2994', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_NO_4: { search: 'woman gesturing no 4|женщина жест no 4', name: '2995', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_NO_5: { search: 'woman gesturing no 5|женщина жест не 5', name: '2996', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_OK_0: { search: 'man gesturing OK 0|человек жест ok 0', name: '1383', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_OK_1: { search: 'man gesturing OK 1|человек жест ok 1', name: '1384', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_OK_2: { search: 'man gesturing OK 2|человек жест ok 2', name: '1385', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_OK_3: { search: 'man gesturing OK 3|человек жест ok 3', name: '1386', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_OK_4: { search: 'man gesturing OK 4|человек жест ok 4', name: '1387', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GESTURING_OK_5: { search: 'man gesturing OK 5|человек жест ok 5', name: '1388', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_OK_0: { search: 'person gesturing OK 0|человек жест ok 0', name: '1990', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_OK_1: { search: 'person gesturing OK 1|человек жест ok 1', name: '1991', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_OK_2: { search: 'person gesturing OK 2|человек жест ok 2', name: '1992', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_OK_3: { search: 'person gesturing OK 3|человек жест ok 3', name: '1993', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_OK_4: { search: 'person gesturing OK 4|человек жест ok 4', name: '1994', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GESTURING_OK_5: { search: 'person gesturing OK 5|человек жест ok 5', name: '1995', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_OK_0: { search: 'woman gesturing OK 0|женщина жест ok 0', name: '2997', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_OK_1: { search: 'woman gesturing OK 1|женщина жест ок 1', name: '2998', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_OK_2: { search: 'woman gesturing OK 2|женщина жест ok 2', name: '2999', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_OK_3: { search: 'woman gesturing OK 3|женщина жест ok 3', name: '3000', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_OK_4: { search: 'woman gesturing OK 4|женщина жест ok 4', name: '3001', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GESTURING_OK_5: { search: 'woman gesturing OK 5|женщина жест ok 5', name: '3002', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FROWNING_0: { search: 'man frowning 0|человек frowning 0', name: '1370', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FROWNING_1: { search: 'man frowning 1|человек frowning 1', name: '1371', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FROWNING_2: { search: 'man frowning 2|человек frowning 2', name: '1372', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FROWNING_3: { search: 'man frowning 3|человек frowning 3', name: '1373', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FROWNING_4: { search: 'man frowning 4|человек frowning 4', name: '1374', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_FROWNING_5: { search: 'man frowning 5|человек frowning 5', name: '1375', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FROWNING_0: { search: 'person frowning 0|человек frowning 0', name: '1978', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FROWNING_1: { search: 'person frowning 1|человек храбрый 1', name: '1979', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FROWNING_2: { search: 'person frowning 2|человек фраулинг 2', name: '1980', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FROWNING_3: { search: 'person frowning 3|человек фраулинг 3', name: '1981', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FROWNING_4: { search: 'person frowning 4|человек храбрый 4', name: '1982', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_FROWNING_5: { search: 'person frowning 5|человек фраулинг 5', name: '1983', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FROWNING_0: { search: 'woman frowning 0|женщина жареная 0', name: '2984', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FROWNING_1: { search: 'woman frowning 1|женщина фраулинг 1', name: '2985', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FROWNING_2: { search: 'woman frowning 2|женщина обжает 2', name: '2986', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FROWNING_3: { search: 'woman frowning 3|женщина утопающая 3', name: '2987', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FROWNING_4: { search: 'woman frowning 4|женщина обжает 4', name: '2988', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_FROWNING_5: { search: 'woman frowning 5|женщина утопающая 5', name: '2989', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_POUTING_0: { search: 'man pouting 0|человек pouting 0', name: '1509', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_POUTING_1: { search: 'man pouting 1|человек pouting 1', name: '1510', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_POUTING_2: { search: 'man pouting 2|человек pouting 2', name: '1511', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_POUTING_3: { search: 'man pouting 3|человек pouting 3', name: '1512', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_POUTING_4: { search: 'man pouting 4|человек pouting 4', name: '1513', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_POUTING_5: { search: 'man pouting 5|человек путинг 5', name: '1514', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_POUTING_0: { search: 'person pouting 0|человек pouting 0', name: '2062', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_POUTING_1: { search: 'person pouting 1|человек pouting 1', name: '2063', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_POUTING_2: { search: 'person pouting 2|человек pouting 2', name: '2064', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_POUTING_3: { search: 'person pouting 3|человек pouting 3', name: '2065', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_POUTING_4: { search: 'person pouting 4|человек pouting 4', name: '2066', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_POUTING_5: { search: 'person pouting 5|человек употребляющий 5', name: '2067', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_POUTING_0: { search: 'woman pouting 0|женщина pouting 0', name: '3117', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_POUTING_1: { search: 'woman pouting 1|женщина pouting 1', name: '3118', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_POUTING_2: { search: 'woman pouting 2|женщина паут 2', name: '3119', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_POUTING_3: { search: 'woman pouting 3|женщина путинг 3', name: '3120', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_POUTING_4: { search: 'woman pouting 4|женщина путинг 4', name: '3121', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_POUTING_5: { search: 'woman pouting 5|женщина путинг 5', name: '3122', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RAISING_ONE_HAND_0: { search: 'man raising one hand 0|человек поднимет одну руку 0', name: '1515', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RAISING_ONE_HAND_1: { search: 'man raising one hand 1|человек поднимет одну руку 1', name: '1516', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RAISING_ONE_HAND_2: { search: 'man raising one hand 2|человек поднимет одну руку 2', name: '1517', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RAISING_ONE_HAND_3: { search: 'man raising one hand 3|человек поднимет одну руку 3', name: '1518', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RAISING_ONE_HAND_4: { search: 'man raising one hand 4|человек поднимает одну руку 4', name: '1519', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RAISING_ONE_HAND_5: { search: 'man raising one hand 5|человек поднимает одну руку 5', name: '1520', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_RAISING_ONE_HAND_0: { search: 'person raising one hand 0|человек поднимающий одну руку 0', name: '2068', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_RAISING_ONE_HAND_1: { search: 'person raising one hand 1|человек поднимающий одну руку 1', name: '2069', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_RAISING_ONE_HAND_2: { search: 'person raising one hand 2|человек поднимающий одну руку 2', name: '2070', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_RAISING_ONE_HAND_3: { search: 'person raising one hand 3|человек поднимающий одну руку 3', name: '2071', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_RAISING_ONE_HAND_4: { search: 'person raising one hand 4|человек поднимающий одну руку 4', name: '2072', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_RAISING_ONE_HAND_5: { search: 'person raising one hand 5|человек поднимающий одну руку 5', name: '2073', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RAISING_ONE_HAND_0: { search: 'woman raising one hand 0|женщина поднимет одну руку 0', name: '3123', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RAISING_ONE_HAND_1: { search: 'woman raising one hand 1|женщина поднимет одну руку 1', name: '3124', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RAISING_ONE_HAND_2: { search: 'woman raising one hand 2|женщина поднимет одну руку 2', name: '3125', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RAISING_ONE_HAND_3: { search: 'woman raising one hand 3|женщина поднимет одну руку 3', name: '3126', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RAISING_ONE_HAND_4: { search: 'woman raising one hand 4|женщина поднимет одну руку 4', name: '3127', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RAISING_ONE_HAND_5: { search: 'woman raising one hand 5|женщина поднимет одну руку 5', name: '3128', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SHRUGGING_0: { search: 'man shrugging 0|человек борется 0', name: '1545', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SHRUGGING_1: { search: 'man shrugging 1|человек борется 1', name: '1546', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SHRUGGING_2: { search: 'man shrugging 2|человек борется 2', name: '1547', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SHRUGGING_3: { search: 'man shrugging 3|человек борется 3', name: '1548', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SHRUGGING_4: { search: 'man shrugging 4|человек борется 4', name: '1549', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_SHRUGGING_5: { search: 'man shrugging 5|человек борется 5', name: '1550', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_SHRUGGING_0: { search: 'person shrugging 0|человек shrugging 0', name: '2080', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_SHRUGGING_1: { search: 'person shrugging 1|человек кричать 1', name: '2081', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_SHRUGGING_2: { search: 'person shrugging 2|человек кричать 2', name: '2082', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_SHRUGGING_3: { search: 'person shrugging 3|человек кричать 3', name: '2083', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_SHRUGGING_4: { search: 'person shrugging 4|человек кричать 4', name: '2084', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_SHRUGGING_5: { search: 'person shrugging 5|человек кричать 5', name: '2085', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SHRUGGING_0: { search: 'woman shrugging 0|женщина борется 0', name: '3153', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SHRUGGING_1: { search: 'woman shrugging 1|женщина борется 1', name: '3154', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SHRUGGING_2: { search: 'woman shrugging 2|женщина борется 2', name: '3155', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SHRUGGING_3: { search: 'woman shrugging 3|женщина борется 3', name: '3156', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SHRUGGING_4: { search: 'woman shrugging 4|женщина борется 4', name: '3157', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_SHRUGGING_5: { search: 'woman shrugging 5|женщина борется 5', name: '3158', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HAIRCUT_0: { search: 'man getting a haircut 0|человек получает стрижку 0', name: '1389', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HAIRCUT_1: { search: 'man getting a haircut 1|человек получает стрижку 1', name: '1390', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HAIRCUT_2: { search: 'man getting a haircut 2|человек получает стрижку 2', name: '1391', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HAIRCUT_3: { search: 'man getting a haircut 3|человек получает стрижку 3', name: '1392', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HAIRCUT_4: { search: 'man getting a haircut 4|человек получает стрижку 4', name: '1393', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HAIRCUT_5: { search: 'man getting a haircut 5|человек получает стрижку 5', name: '1394', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HAIRCUT_0: { search: 'person getting a haircut 0|человек получает стрижку 0', name: '1996', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HAIRCUT_1: { search: 'person getting a haircut 1|человек получает стрижку 1', name: '1997', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HAIRCUT_2: { search: 'person getting a haircut 2|человек получает стрижку 2', name: '1998', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HAIRCUT_3: { search: 'person getting a haircut 3|человек получает стрижку 3', name: '1999', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HAIRCUT_4: { search: 'person getting a haircut 4|человек получает стрижку 4', name: '2000', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HAIRCUT_5: { search: 'person getting a haircut 5|человек получает стрижку 5', name: '2001', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HAIRCUT_0: { search: 'woman getting a haircut 0|женщина получает стрижку 0', name: '3003', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HAIRCUT_1: { search: 'woman getting a haircut 1|женщина получает стрижку 1', name: '3004', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HAIRCUT_2: { search: 'woman getting a haircut 2|женщина получает стрижку 2', name: '3005', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HAIRCUT_3: { search: 'woman getting a haircut 3|женщина получает стрижку 3', name: '3006', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HAIRCUT_4: { search: 'woman getting a haircut 4|женщина получает стрижку 4', name: '3007', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HAIRCUT_5: { search: 'woman getting a haircut 5|женщина получает стрижку 5', name: '3008', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HEAD_MASSAGE_0: { search: 'man getting a head massage 0|человек получает массаж головы 0', name: '1395', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HEAD_MASSAGE_1: { search: 'man getting a head massage 1|человек получает массаж головы 1', name: '1396', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HEAD_MASSAGE_2: { search: 'man getting a head massage 2|человек получает массаж головы 2', name: '1397', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HEAD_MASSAGE_3: { search: 'man getting a head massage 3|человек получает массаж головы 3', name: '1398', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HEAD_MASSAGE_4: { search: 'man getting a head massage 4|человек получает массаж головы 4', name: '1399', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_GETTING_A_HEAD_MASSAGE_5: { search: 'man getting a head massage 5|человек получает массаж головы 5', name: '1400', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HEAD_MASSAGE_0: { search: 'person getting a head massage 0|человек получает массаж головы 0', name: '2002', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HEAD_MASSAGE_1: { search: 'person getting a head massage 1|человек получает массаж головы 1', name: '2003', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HEAD_MASSAGE_2: { search: 'person getting a head massage 2|человек получает массаж головы 2', name: '2004', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HEAD_MASSAGE_3: { search: 'person getting a head massage 3|человек получает массаж головы 3', name: '2005', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HEAD_MASSAGE_4: { search: 'person getting a head massage 4|человек получает массаж головы 4', name: '2006', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_GETTING_A_HEAD_MASSAGE_5: { search: 'person getting a head massage 5|человек получает массаж головы 5', name: '2007', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HEAD_MASSAGE_0: { search: 'woman getting a head massage 0|женщина получает массаж головы 0', name: '3009', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HEAD_MASSAGE_1: { search: 'woman getting a head massage 1|женщина получает массаж головы 1', name: '3010', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HEAD_MASSAGE_2: { search: 'woman getting a head massage 2|женщина получает массаж головы 2', name: '3011', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HEAD_MASSAGE_3: { search: 'woman getting a head massage 3|женщина получает массаж головы 3', name: '3012', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HEAD_MASSAGE_4: { search: 'woman getting a head massage 4|женщина получает массаж головы 4', name: '3013', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_GETTING_A_HEAD_MASSAGE_5: { search: 'woman getting a head massage 5|женщина получает массаж головы 5', name: '3014', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_A_SAUNA_0: { search: 'man in a sauna 0|человек в сауне 0', name: '1413', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_A_SAUNA_1: { search: 'man in a sauna 1|человек в сауне 1', name: '1414', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_A_SAUNA_2: { search: 'man in a sauna 2|человек в сауне 2', name: '1415', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_A_SAUNA_3: { search: 'man in a sauna 3|человек в сауне 3', name: '1416', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_A_SAUNA_4: { search: 'man in a sauna 4|человек в сауне 4', name: '1417', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_A_SAUNA_5: { search: 'man in a sauna 5|человек в сауне 5', name: '1418', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_A_SAUNA_0: { search: 'person in a sauna 0|человек в сауне 0', name: '2008', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_A_SAUNA_1: { search: 'person in a sauna 1|человек в сауне 1', name: '2009', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_A_SAUNA_2: { search: 'person in a sauna 2|человек в сауне 2', name: '2010', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_A_SAUNA_3: { search: 'person in a sauna 3|человек в сауне 3', name: '2011', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_A_SAUNA_4: { search: 'person in a sauna 4|человек в сауне 4', name: '2012', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_A_SAUNA_5: { search: 'person in a sauna 5|человек в сауне 5', name: '2013', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_A_SAUNA_0: { search: 'woman in a sauna 0|женщина в сауне 0', name: '3027', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_A_SAUNA_1: { search: 'woman in a sauna 1|женщина в сауне 1', name: '3028', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_A_SAUNA_2: { search: 'woman in a sauna 2|женщина в сауне 2', name: '3029', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_A_SAUNA_3: { search: 'woman in a sauna 3|женщина в сауне 3', name: '3030', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_A_SAUNA_4: { search: 'woman in a sauna 4|женщина в сауне 4', name: '3031', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_A_SAUNA_5: { search: 'woman in a sauna 5|женщина в сауне 5', name: '3032', category: EMOJI_CATEGORIES.PEOPLE },
  NAIL_POLISH_0: { search: 'nail polish 0|лак ногтя 0', name: '1784', category: EMOJI_CATEGORIES.PEOPLE },
  NAIL_POLISH_1: { search: 'nail polish 1|лак ногтя 1', name: '1785', category: EMOJI_CATEGORIES.PEOPLE },
  NAIL_POLISH_2: { search: 'nail polish 2|лак ногтя 2', name: '1786', category: EMOJI_CATEGORIES.PEOPLE },
  NAIL_POLISH_3: { search: 'nail polish 3|лак ногтя 3', name: '1787', category: EMOJI_CATEGORIES.PEOPLE },
  NAIL_POLISH_4: { search: 'nail polish 4|лак для ногтей 4', name: '1788', category: EMOJI_CATEGORIES.PEOPLE },
  NAIL_POLISH_5: { search: 'nail polish 5|лак ногтя 5', name: '1789', category: EMOJI_CATEGORIES.PEOPLE },
  SELFIE_0: { search: 'selfie 0|селфи 0', name: '2393', category: EMOJI_CATEGORIES.PEOPLE },
  SELFIE_1: { search: 'selfie 1|селфи 1', name: '2394', category: EMOJI_CATEGORIES.PEOPLE },
  SELFIE_2: { search: 'selfie 2|селфи 2', name: '2395', category: EMOJI_CATEGORIES.PEOPLE },
  SELFIE_3: { search: 'selfie 3|селфи 3', name: '2396', category: EMOJI_CATEGORIES.PEOPLE },
  SELFIE_4: { search: 'selfie 4|селфи 4', name: '2397', category: EMOJI_CATEGORIES.PEOPLE },
  SELFIE_5: { search: 'selfie 5|селфи 5', name: '2398', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DANCING_0: { search: 'woman dancing 0|женщина танцует 0 0', name: '2924', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DANCING_1: { search: 'woman dancing 1|женщина танцует 1', name: '2925', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DANCING_2: { search: 'woman dancing 2|женщина танцует 1 2 3 4 5', name: '2926', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DANCING_3: { search: 'woman dancing 3|женщина танцует 2 3 4 5', name: '2927', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DANCING_4: { search: 'woman dancing 4|женщина танцует 3 4 5 6 7 8 9 10', name: '2928', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_DANCING_5: { search: 'woman dancing 5|женщина танцует 5 5 5', name: '2929', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DANCING_0: { search: 'man dancing 0|мужчина танцует 0', name: '1310', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DANCING_1: { search: 'man dancing 1|человек танцует 1', name: '1311', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DANCING_2: { search: 'man dancing 2|человек танцует 2', name: '1312', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DANCING_3: { search: 'man dancing 3|мужчина танцует 3', name: '1313', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DANCING_4: { search: 'man dancing 4|мужчина танцует 4', name: '1314', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_DANCING_5: { search: 'man dancing 5|мужчина танцует 5', name: '1315', category: EMOJI_CATEGORIES.PEOPLE },
  DANCING_MEN_WEARING_BUNNY_EARS: { search: 'dancing men wearing bunny ears|танцы мужчины нося кроя уши', name: '417', category: EMOJI_CATEGORIES.PEOPLE },
  DANCING_PEOPLE_WEARING_BUNNY_EARS: { search: 'dancing people wearing bunny ears|танцы люди нося кроя уши', name: '418', category: EMOJI_CATEGORIES.PEOPLE },
  DANCING_WOMEN_WEARING_BUNNY_EARS: { search: 'dancing women wearing bunny ears|танцы женщины нося кроя уши', name: '419', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_BUSINESS_SUIT_LEVITATING_0: { search: 'man in business suit levitating 0|человек в деловом костюме levitating 0', name: '1419', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_BUSINESS_SUIT_LEVITATING_1: { search: 'man in business suit levitating 1|человек в деловом костюме levitating 1', name: '1420', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_BUSINESS_SUIT_LEVITATING_2: { search: 'man in business suit levitating 2|человек в деловом костюме levitating 2', name: '1421', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_BUSINESS_SUIT_LEVITATING_3: { search: 'man in business suit levitating 3|человек в деловом костюме levitating 3', name: '1422', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_BUSINESS_SUIT_LEVITATING_4: { search: 'man in business suit levitating 4|человек в деловом костюме levitating 4', name: '1423', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_BUSINESS_SUIT_LEVITATING_5: { search: 'man in business suit levitating 5|человек в деловом костюме levitating 5', name: '1424', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MANUAL_WHEELCHAIR_0: { search: 'man in manual wheelchair 0|человек в ручном инвалидном коляске 0', name: '1431', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MANUAL_WHEELCHAIR_1: { search: 'man in manual wheelchair 1|человек в ручном инвалидном коляске 1', name: '1432', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MANUAL_WHEELCHAIR_2: { search: 'man in manual wheelchair 2|человек в ручном инвалидном коляске 2', name: '1433', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MANUAL_WHEELCHAIR_3: { search: 'man in manual wheelchair 3|человек в ручном инвалидном коляске 3', name: '1434', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MANUAL_WHEELCHAIR_4: { search: 'man in manual wheelchair 4|человек в ручном инвалидном коляске 4', name: '1435', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MANUAL_WHEELCHAIR_5: { search: 'man in manual wheelchair 5|человек в ручном инвалидном коляске 5', name: '1436', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MANUAL_WHEELCHAIR_0: { search: 'person in manual wheelchair 0|человек в ручном инвалидном коляске 0', name: '2020', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MANUAL_WHEELCHAIR_1: { search: 'person in manual wheelchair 1|человек в ручном инвалидном коляске 1', name: '2021', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MANUAL_WHEELCHAIR_2: { search: 'person in manual wheelchair 2|человек в ручном инвалидном коляске 2', name: '2022', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MANUAL_WHEELCHAIR_3: { search: 'person in manual wheelchair 3|человек в ручном инвалидном коляске 3', name: '2023', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MANUAL_WHEELCHAIR_4: { search: 'person in manual wheelchair 4|человек в ручном инвалидном коляске 4', name: '2024', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MANUAL_WHEELCHAIR_5: { search: 'person in manual wheelchair 5|человек в ручном инвалидном коляске 5', name: '2025', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MANUAL_WHEELCHAIR_0: { search: 'woman in manual wheelchair 0|женщина в ручном инвалидном коляске 0', name: '3039', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MANUAL_WHEELCHAIR_1: { search: 'woman in manual wheelchair 1|женщина в ручном инвалидном коляске 1', name: '3040', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MANUAL_WHEELCHAIR_2: { search: 'woman in manual wheelchair 2|женщина в ручном инвалидном коляске 2', name: '3041', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MANUAL_WHEELCHAIR_3: { search: 'woman in manual wheelchair 3|женщина в ручном инвалидном коляске 3', name: '3042', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MANUAL_WHEELCHAIR_4: { search: 'woman in manual wheelchair 4|женщина в ручном инвалидном кресле 4', name: '3043', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MANUAL_WHEELCHAIR_5: { search: 'woman in manual wheelchair 5|женщина в ручном инвалидном коляске 5', name: '3044', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MOTORIZED_WHEELCHAIR_0: { search: 'person in motorized wheelchair 0|человек в автоколяске 0', name: '2026', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MOTORIZED_WHEELCHAIR_1: { search: 'person in motorized wheelchair 1|человек в моторизованном инвалидном коляске 1', name: '2027', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MOTORIZED_WHEELCHAIR_2: { search: 'person in motorized wheelchair 2|человек в автокресло 2', name: '2028', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MOTORIZED_WHEELCHAIR_3: { search: 'person in motorized wheelchair 3|человек в моторизованном инвалидном коляске 3', name: '2029', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MOTORIZED_WHEELCHAIR_4: { search: 'person in motorized wheelchair 4|человек в автоколяске 4', name: '2030', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_IN_MOTORIZED_WHEELCHAIR_5: { search: 'person in motorized wheelchair 5|человек в автокресло 5', name: '2031', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MOTORIZED_WHEELCHAIR_0: { search: 'man in motorized wheelchair 0|человек в моторизованном инвалидном коляске 0', name: '1437', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MOTORIZED_WHEELCHAIR_1: { search: 'man in motorized wheelchair 1|человек в моторизованном инвалидном коляске 1', name: '1438', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MOTORIZED_WHEELCHAIR_2: { search: 'man in motorized wheelchair 2|человек в моторизованном инвалидном коляске 2', name: '1439', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MOTORIZED_WHEELCHAIR_3: { search: 'man in motorized wheelchair 3|человек в моторизованном инвалидном коляске 3', name: '1440', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MOTORIZED_WHEELCHAIR_4: { search: 'man in motorized wheelchair 4|человек в моторизованном инвалидном коляске 4', name: '1441', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_IN_MOTORIZED_WHEELCHAIR_5: { search: 'man in motorized wheelchair 5|человек в моторизованном инвалидном коляске 5', name: '1442', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MOTORIZED_WHEELCHAIR_0: { search: 'woman in motorized wheelchair 0|женщина в моторизованном инвалидном коляске 0', name: '3045', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MOTORIZED_WHEELCHAIR_1: { search: 'woman in motorized wheelchair 1|женщина в моторизованном инвалидном коляске 1', name: '3046', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MOTORIZED_WHEELCHAIR_2: { search: 'woman in motorized wheelchair 2|женщина в моторизованном инвалидном коляске 2', name: '3047', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MOTORIZED_WHEELCHAIR_3: { search: 'woman in motorized wheelchair 3|женщина в моторизованном инвалидном коляске 3', name: '3048', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MOTORIZED_WHEELCHAIR_4: { search: 'woman in motorized wheelchair 4|женщина в моторизованном инвалидном коляске 4', name: '3049', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_IN_MOTORIZED_WHEELCHAIR_5: { search: 'woman in motorized wheelchair 5|женщина в моторизованном инвалидном коляске 5', name: '3050', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_KNEELING_0: { search: 'man kneeling 0|человек колени 0', name: '1461', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_KNEELING_1: { search: 'man kneeling 1|человек колени 1', name: '1462', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_KNEELING_2: { search: 'man kneeling 2|человек колени 2', name: '1463', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_KNEELING_3: { search: 'man kneeling 3|человек колени 3', name: '1464', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_KNEELING_4: { search: 'man kneeling 4|человек колени 4', name: '1465', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_KNEELING_5: { search: 'man kneeling 5|человек колена 5', name: '1466', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_KNEELING_0: { search: 'person kneeling 0|человек колени 0', name: '2044', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_KNEELING_1: { search: 'person kneeling 1|человек колени 1', name: '2045', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_KNEELING_2: { search: 'person kneeling 2|человек колени 2', name: '2046', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_KNEELING_3: { search: 'person kneeling 3|человек колена 3', name: '2047', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_KNEELING_4: { search: 'person kneeling 4|человек колени 4', name: '2048', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_KNEELING_5: { search: 'person kneeling 5|человек колена 5', name: '2049', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_KNEELING_0: { search: 'woman kneeling 0|женщина на колени 0', name: '3069', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_KNEELING_1: { search: 'woman kneeling 1|женщина на колени 1', name: '3070', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_KNEELING_2: { search: 'woman kneeling 2|женщина колена 2', name: '3071', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_KNEELING_3: { search: 'woman kneeling 3|женщина колена 3', name: '3072', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_KNEELING_4: { search: 'woman kneeling 4|женщина колена 4', name: '3073', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_KNEELING_5: { search: 'woman kneeling 5|женщина на колени 5', name: '3074', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PEDESTRIAN_0: { search: 'man pedestrian 0|человек пешеход 0', name: '1485', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PEDESTRIAN_1: { search: 'man pedestrian 1|человек пешеход 1', name: '1486', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PEDESTRIAN_2: { search: 'man pedestrian 2|человек пешеходный 2', name: '1487', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PEDESTRIAN_3: { search: 'man pedestrian 3|человек пешеходный 3', name: '1488', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PEDESTRIAN_4: { search: 'man pedestrian 4|человек пешеходный 4', name: '1489', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_PEDESTRIAN_5: { search: 'man pedestrian 5|человек пешеход 5', name: '1490', category: EMOJI_CATEGORIES.PEOPLE },
  PEDESTRIAN_0: { search: 'pedestrian 0|пешеходная 0', name: '1936', category: EMOJI_CATEGORIES.PEOPLE },
  PEDESTRIAN_1: { search: 'pedestrian 1|пешеходная 1', name: '1937', category: EMOJI_CATEGORIES.PEOPLE },
  PEDESTRIAN_2: { search: 'pedestrian 2|пешеходная 2', name: '1938', category: EMOJI_CATEGORIES.PEOPLE },
  PEDESTRIAN_3: { search: 'pedestrian 3|пешеходная 3', name: '1939', category: EMOJI_CATEGORIES.PEOPLE },
  PEDESTRIAN_4: { search: 'pedestrian 4|пешеходная 4', name: '1940', category: EMOJI_CATEGORIES.PEOPLE },
  PEDESTRIAN_5: { search: 'pedestrian 5|пешеходная 5', name: '1941', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PEDESTRIAN_0: { search: 'woman pedestrian 0|женщина пешеходная 0', name: '3093', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PEDESTRIAN_1: { search: 'woman pedestrian 1|женщина пешеходная 1', name: '3094', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PEDESTRIAN_2: { search: 'woman pedestrian 2|женщина пешеходная 2', name: '3095', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PEDESTRIAN_3: { search: 'woman pedestrian 3|женщина пешеходная 3', name: '3096', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PEDESTRIAN_4: { search: 'woman pedestrian 4|женщина пешеходная 4', name: '3097', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_PEDESTRIAN_5: { search: 'woman pedestrian 5|женщина пешеходная 5', name: '3098', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_CANE_0: { search: 'man with white cane 0|человек с белым тросом 0', name: '1659', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_CANE_1: { search: 'man with white cane 1|человек с белым тростником 1', name: '1660', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_CANE_2: { search: 'man with white cane 2|человек с белым тростником 2', name: '1661', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_CANE_3: { search: 'man with white cane 3|человек с белой тростью 3', name: '1662', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_CANE_4: { search: 'man with white cane 4|человек с белой тростью 4', name: '1663', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_WITH_WHITE_CANE_5: { search: 'man with white cane 5|человек с белой тростью 5', name: '1664', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_CANE_0: { search: 'person with white cane 0|человек с белым тросом 0', name: '2135', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_CANE_1: { search: 'person with white cane 1|человек с белым тросом 1', name: '2136', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_CANE_2: { search: 'person with white cane 2|человек с белым тросом 2', name: '2137', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_CANE_3: { search: 'person with white cane 3|человек с белой тросткой 3', name: '2138', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_CANE_4: { search: 'person with white cane 4|человек с белым тросом', name: '2139', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_WITH_WHITE_CANE_5: { search: 'person with white cane 5|человек с белым тросом 5', name: '2140', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_CANE_0: { search: 'woman with white cane 0|женщина с белым тростником 0', name: '3267', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_CANE_1: { search: 'woman with white cane 1|женщина с белым тростником 1', name: '3268', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_CANE_2: { search: 'woman with white cane 2|женщина с белым тростником 2', name: '3269', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_CANE_3: { search: 'woman with white cane 3|женщина с белым тростником 3', name: '3270', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_CANE_4: { search: 'woman with white cane 4|женщина с белой троской 4', name: '3271', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_WITH_WHITE_CANE_5: { search: 'woman with white cane 5|женщина с белым тросом 5', name: '3272', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RUNNER_0: { search: 'man runner 0|человек бегун 0', name: '1533', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RUNNER_1: { search: 'man runner 1|человек бегун 1', name: '1534', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RUNNER_2: { search: 'man runner 2|человек бегун 2', name: '1535', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RUNNER_3: { search: 'man runner 3|человек бегун 3', name: '1536', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RUNNER_4: { search: 'man runner 4|человек бегун 4', name: '1537', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_RUNNER_5: { search: 'man runner 5|человек бегун 5', name: '1538', category: EMOJI_CATEGORIES.PEOPLE },
  RUNNER_0: { search: 'runner 0|бегун 0', name: '2346', category: EMOJI_CATEGORIES.PEOPLE },
  RUNNER_1: { search: 'runner 1|бегун 1', name: '2347', category: EMOJI_CATEGORIES.PEOPLE },
  RUNNER_2: { search: 'runner 2|бегун 2', name: '2348', category: EMOJI_CATEGORIES.PEOPLE },
  RUNNER_3: { search: 'runner 3|бегун 3', name: '2349', category: EMOJI_CATEGORIES.PEOPLE },
  RUNNER_4: { search: 'runner 4|бегун 4', name: '2350', category: EMOJI_CATEGORIES.PEOPLE },
  RUNNER_5: { search: 'runner 5|бегун 5', name: '2351', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RUNNER_0: { search: 'woman runner 0|женщина рунер 0', name: '3141', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RUNNER_1: { search: 'woman runner 1|женщина рунер 1', name: '3142', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RUNNER_2: { search: 'woman runner 2|женщина рунер 2', name: '3143', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RUNNER_3: { search: 'woman runner 3|женщина рунер 3', name: '3144', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RUNNER_4: { search: 'woman runner 4|женщина рунер 4', name: '3145', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_RUNNER_5: { search: 'woman runner 5|женщина рунер 5', name: '3146', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STANDING_0: { search: 'man standing 0|человек стоя 0', name: '1557', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STANDING_1: { search: 'man standing 1|человек стоя 1', name: '1558', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STANDING_2: { search: 'man standing 2|человек стоя 2', name: '1559', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STANDING_3: { search: 'man standing 3|человек стоя 3', name: '1560', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STANDING_4: { search: 'man standing 4|человек стоя 4', name: '1561', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_STANDING_5: { search: 'man standing 5|человек стоящий 5', name: '1562', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_STANDING_0: { search: 'person standing 0|человек стоя 0', name: '2087', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_STANDING_1: { search: 'person standing 1|человек стоящий 1', name: '2088', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_STANDING_2: { search: 'person standing 2|человек стоящий 2', name: '2089', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_STANDING_3: { search: 'person standing 3|человек стоящий 3', name: '2090', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_STANDING_4: { search: 'person standing 4|человек стоящий 4', name: '2091', category: EMOJI_CATEGORIES.PEOPLE },
  PERSON_STANDING_5: { search: 'person standing 5|человек стоящий 5', name: '2092', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STANDING_0: { search: 'woman standing 0|женщина стоя 0', name: '3165', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STANDING_1: { search: 'woman standing 1|женщина стоя 1', name: '3166', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STANDING_2: { search: 'woman standing 2|женщина стоя 2', name: '3167', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STANDING_3: { search: 'woman standing 3|женщина стоя 3', name: '3168', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STANDING_4: { search: 'woman standing 4|женщина стоя 4', name: '3169', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_STANDING_5: { search: 'woman standing 5|женщина стоя 5', name: '3170', category: EMOJI_CATEGORIES.PEOPLE },
  TWO_WOMEN_HOLDING_HANDS: { search: 'two women holding hands|две женщины удерживающие руки', name: '2698', category: EMOJI_CATEGORIES.PEOPLE },
  WOMEN_HOLDING_HANDS_1: { search: 'women holding hands 1|женщины удерживающие руки 1', name: '3280', category: EMOJI_CATEGORIES.PEOPLE },
  WOMEN_HOLDING_HANDS_2: { search: 'women holding hands 2|женщины удерживающие руки 2', name: '3281', category: EMOJI_CATEGORIES.PEOPLE },
  WOMEN_HOLDING_HANDS_3: { search: 'women holding hands 3|женщины удерживающие руки 3', name: '3282', category: EMOJI_CATEGORIES.PEOPLE },
  WOMEN_HOLDING_HANDS_4: { search: 'women holding hands 4|женщины удерживающие руки 4', name: '3283', category: EMOJI_CATEGORIES.PEOPLE },
  WOMEN_HOLDING_HANDS_5: { search: 'women holding hands 5|женщины удерживающие руки 5', name: '3284', category: EMOJI_CATEGORIES.PEOPLE },
  MAN_AND_WOMAN_HOLDING_HANDS: { search: 'man and woman holding hands|мужчина и женщина держат руки', name: '1267', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_AND_MAN_HOLDING_HANDS_1: { search: 'woman and man holding hands 1|женщина и человек держат руки 1', name: '2877', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_AND_MAN_HOLDING_HANDS_2: { search: 'woman and man holding hands 2|женщина и человек удерживающие руки 2', name: '2878', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_AND_MAN_HOLDING_HANDS_3: { search: 'woman and man holding hands 3|женщина и человек удерживающие руки 3', name: '2879', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_AND_MAN_HOLDING_HANDS_4: { search: 'woman and man holding hands 4|женщина и человек держат руки 4', name: '2880', category: EMOJI_CATEGORIES.PEOPLE },
  WOMAN_AND_MAN_HOLDING_HANDS_5: { search: 'woman and man holding hands 5|женщина и человек удерживающие руки 5', name: '2881', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HOLDING_HANDS: { search: 'people holding hands|люди удерживающие руки', name: '1949', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HOLDING_HANDS_1: { search: 'people holding hands 1|люди удерживающие руки 1', name: '1944', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HOLDING_HANDS_2: { search: 'people holding hands 2|люди удерживающие руки 2', name: '1945', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HOLDING_HANDS_3: { search: 'people holding hands 3|люди удерживающие руки 3', name: '1946', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HOLDING_HANDS_4: { search: 'people holding hands 4|люди удерживающие руки 4', name: '1947', category: EMOJI_CATEGORIES.PEOPLE },
  PEOPLE_HOLDING_HANDS_5: { search: 'people holding hands 5|люди удерживающие руки 5', name: '1948', category: EMOJI_CATEGORIES.PEOPLE },
  TWO_MEN_HOLDING_HANDS: { search: 'two men holding hands|два мужчины удерживающие руки', name: '2696', category: EMOJI_CATEGORIES.PEOPLE },
  MEN_HOLDING_HANDS_1: { search: 'men holding hands 1|мужчины удерживающие руки 1', name: '1689', category: EMOJI_CATEGORIES.PEOPLE },
  MEN_HOLDING_HANDS_2: { search: 'men holding hands 2|мужчины удерживающие руки 2', name: '1690', category: EMOJI_CATEGORIES.PEOPLE },
  MEN_HOLDING_HANDS_3: { search: 'men holding hands 3|мужчины удерживающие руки 3', name: '1691', category: EMOJI_CATEGORIES.PEOPLE },
  MEN_HOLDING_HANDS_4: { search: 'men holding hands 4|мужчины удерживающие руки 4', name: '1692', category: EMOJI_CATEGORIES.PEOPLE },
  MEN_HOLDING_HANDS_5: { search: 'men holding hands 5|мужчины удерживающие руки 5', name: '1693', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_KISSING_0: { search: 'couple kissing 0|пара целуется 0', name: '371', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_KISSING_1: { search: 'couple kissing 1|пара целуется 1', name: '372', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_KISSING_2: { search: 'couple kissing 2|пара целуется 2', name: '373', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_KISSING_3: { search: 'couple kissing 3|пара целуется 3', name: '374', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_KISSING_4: { search: 'couple kissing 4|пара целуется 4', name: '375', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_KISSING_5: { search: 'couple kissing 5|пара целуется 5', name: '376', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_WITH_HEART_0: { search: 'couple with heart 0|пара с сердцем 0', name: '377', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_WITH_HEART_1: { search: 'couple with heart 1|пара с сердцем 1', name: '378', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_WITH_HEART_2: { search: 'couple with heart 2|пара с сердцем 2', name: '379', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_WITH_HEART_3: { search: 'couple with heart 3|пара с сердцем 3', name: '380', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_WITH_HEART_4: { search: 'couple with heart 4|пара с сердцем 4', name: '381', category: EMOJI_CATEGORIES.PEOPLE },
  COUPLE_WITH_HEART_5: { search: 'couple with heart 5|пара с сердцем 5', name: '382', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MBB: { search: 'family mbb|семья mbb', name: '574', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MB: { search: 'family mb|семья mb', name: '573', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MGB: { search: 'family mgb|семейный мгб', name: '576', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MGG: { search: 'family mgg|семья mgg', name: '577', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MG: { search: 'family mg|семейный мг', name: '575', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MMBB: { search: 'family mmbb|семья mmbb', name: '579', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MMB: { search: 'family mmb|семья mmb', name: '578', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MMGB: { search: 'family mmgb|семья mmgb', name: '581', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MMGG: { search: 'family mmgg|семья mmgg', name: '582', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MMG: { search: 'family mmg|семья mmg', name: '580', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MWBB: { search: 'family mwbb|семья mwbb', name: '584', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MWB: { search: 'family mwb|семья mwb', name: '583', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MWGB: { search: 'family mwgb|семья mwgb', name: '586', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MWGG: { search: 'family mwgg|семья mwgg', name: '587', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_MWG: { search: 'family mwg|семья mwg', name: '585', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY: { search: 'family|семья', name: '598', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WBB: { search: 'family wbb|семья wbb', name: '589', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WB: { search: 'family wb|семья wb', name: '588', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WGB: { search: 'family wgb|семья wgb', name: '591', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WGG: { search: 'family wgg|семья wgg', name: '592', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WG: { search: 'family wg|семья wg', name: '590', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WWBB: { search: 'family wwbb|семья wwbb', name: '594', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WWB: { search: 'family wwb|семья wwb', name: '593', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WWGB: { search: 'family wwgb|семья wwgb', name: '596', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WWGG: { search: 'family wwgg|семья wwgg', name: '597', category: EMOJI_CATEGORIES.PEOPLE },
  FAMILY_WWG: { search: 'family wwg|семья wwg', name: '595', category: EMOJI_CATEGORIES.PEOPLE },
  KNOT: { search: 'knot|knot', name: '1191', category: EMOJI_CATEGORIES.PEOPLE },
  SEWING_NEEDLE: { search: 'sewing needle|швейная игла', name: '2402', category: EMOJI_CATEGORIES.PEOPLE },
  THREAD: { search: 'thread|нити', name: '2640', category: EMOJI_CATEGORIES.PEOPLE },
  YARN: { search: 'yarn|пряжа', name: '3300', category: EMOJI_CATEGORIES.PEOPLE },
  GLASSES: { search: 'glasses|очки', name: '952', category: EMOJI_CATEGORIES.PEOPLE },
  GOGGLES: { search: 'goggles|очки', name: '962', category: EMOJI_CATEGORIES.PEOPLE },
  SUNGLASSES: { search: 'sunglasses|солнцезащитные очки', name: '2568', category: EMOJI_CATEGORIES.PEOPLE },
  COAT: { search: 'coat|пальто', name: '335', category: EMOJI_CATEGORIES.PEOPLE },
  LAB_COAT: { search: 'lab coat|лабораторное пальто', name: '1193', category: EMOJI_CATEGORIES.PEOPLE },
  SAFETY_VEST: { search: 'safety vest|безопасность vest', name: '2356', category: EMOJI_CATEGORIES.PEOPLE },
  BIKINI: { search: 'bikini|бикини', name: '160', category: EMOJI_CATEGORIES.PEOPLE },
  ONE_PIECE_SWIMSUIT: { search: 'one-piece swimsuit|one piece купальник', name: '1873', category: EMOJI_CATEGORIES.PEOPLE },
  BRIEFS: { search: 'briefs|плавки', name: '220', category: EMOJI_CATEGORIES.PEOPLE },
  GLOVES: { search: 'gloves|перчатки', name: '957', category: EMOJI_CATEGORIES.PEOPLE },
  JEANS: { search: 'jeans|джинсы', name: '1150', category: EMOJI_CATEGORIES.PEOPLE },
  SHORTS: { search: 'shorts|шорты', name: '2415', category: EMOJI_CATEGORIES.PEOPLE },
  BILLED_HAT: { search: 'billed hat|кепка', name: '161', category: EMOJI_CATEGORIES.PEOPLE },
  GRADUATION_CAP: { search: 'graduation cap|выпускная крышка', name: '973', category: EMOJI_CATEGORIES.PEOPLE },
  HAT_WITH_BOW: { search: 'hat with bow|шляпа с луком', name: '1038', category: EMOJI_CATEGORIES.PEOPLE },
  TOP_HAT: { search: 'top hat|верхняя шляпа', name: '2669', category: EMOJI_CATEGORIES.PEOPLE },
  HELMET_WITH_WHITE_CROSS: { search: 'helmet with white cross|шлем с белым крестом', name: '1057', category: EMOJI_CATEGORIES.PEOPLE },
  MILITARY_HELMET: { search: 'military helmet|военный шлем', name: '1725', category: EMOJI_CATEGORIES.PEOPLE },
  CROWN: { search: 'crown|корона', name: '397', category: EMOJI_CATEGORIES.PEOPLE },
  RING: { search: 'ring|кольцо', name: '2325', category: EMOJI_CATEGORIES.PEOPLE },
  POLO_SHIRT: { search: 'polo shirt|поло рубашка', name: '2209', category: EMOJI_CATEGORIES.PEOPLE },
  SHIRT_AND_TIE: { search: 'shirt and tie|рубашка и галстук', name: '2410', category: EMOJI_CATEGORIES.PEOPLE },
  BLOUSE: { search: 'blouse|блузка', name: '180', category: EMOJI_CATEGORIES.PEOPLE },
  DRESS: { search: 'dress|платье', name: '483', category: EMOJI_CATEGORIES.PEOPLE },
  KIMONO: { search: 'kimono|кимоно', name: '1182', category: EMOJI_CATEGORIES.PEOPLE },
  SARI: { search: 'sari|сари', name: '2369', category: EMOJI_CATEGORIES.PEOPLE },
  SCARF: { search: 'scarf|шарф', name: '2374', category: EMOJI_CATEGORIES.PEOPLE },
  SOCKS: { search: 'socks|носки', name: '2498', category: EMOJI_CATEGORIES.PEOPLE },
  SANDAL: { search: 'sandal|песка', name: '2361', category: EMOJI_CATEGORIES.PEOPLE },
  DRESS_SHOE: { search: 'dress shoe|платье обувь', name: '482', category: EMOJI_CATEGORIES.PEOPLE },
  FLAT_SHOE: { search: 'flat shoe|плоская обувь', name: '895', category: EMOJI_CATEGORIES.PEOPLE },
  HEELED_BOOTS: { search: 'heeled boots|каблуки', name: '1054', category: EMOJI_CATEGORIES.PEOPLE },
  HIGH_HEELED_SHOE: { search: 'high-heeled shoe|высоконагруженная обувь', name: '1062', category: EMOJI_CATEGORIES.PEOPLE },
  HIKING_BOOT: { search: 'hiking boot|походная ботинка', name: '1065', category: EMOJI_CATEGORIES.PEOPLE },
  TENNIS_SHOE: { search: 'tennis shoe|теннисная обувь', name: '2633', category: EMOJI_CATEGORIES.PEOPLE },
  HEELED_SANDAL: { search: 'heeled sandal|целеный сандал', name: '1055', category: EMOJI_CATEGORIES.PEOPLE },
  BACKPACK: { search: 'backpack|рюкзак', name: '98', category: EMOJI_CATEGORIES.PEOPLE },
  BRIEFCASE: { search: 'briefcase|портфель', name: '219', category: EMOJI_CATEGORIES.PEOPLE },
  HANDBAG: { search: 'handbag|сумка', name: '1023', category: EMOJI_CATEGORIES.PEOPLE },
  LUGGAGE: { search: 'luggage|багаж', name: '1254', category: EMOJI_CATEGORIES.PEOPLE },
  POUCH: { search: 'pouch|пакет', name: '2217', category: EMOJI_CATEGORIES.PEOPLE },
  PURSE: { search: 'purse|кошелек', name: '2248', category: EMOJI_CATEGORIES.PEOPLE },
  CLOSED_UMBRELLA: { search: 'closed umbrella|закрытый зонтик', name: '327', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_ML: { search: 'silhouette.ML|силуэт мл', name: '2430', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_MR: { search: 'silhouette.MR|силуэт мр', name: '2431', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F468_U1F48B_L: { search: 'silhouette.u1F468.u1F48B.L|silhouette u 1 f 468 u 1 f 48 b l', name: '2432', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F468_U1F48B_R: { search: 'silhouette.u1F468.u1F48B.R|silhouette u 1 f 468 u 1 f 48 b r', name: '2433', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F468_U2764_L: { search: 'silhouette.u1F468.u2764.L|silhouette u 1 f 468 u 2764 l', name: '2434', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F468_U2764_R: { search: 'silhouette.u1F468.u2764.R|silhouette u 1 f 468 u 2764 r', name: '2435', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F469_U1F48B_L: { search: 'silhouette.u1F469.u1F48B.L|silhouette u 1 f 469 u 1 f 48 b l', name: '2436', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F469_U1F48B_R: { search: 'silhouette.u1F469.u1F48B.R|silhouette u 1 f 469 u 1 f 48 b r', name: '2437', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F469_U2764_L: { search: 'silhouette.u1F469.u2764.L|silhouette u 1 f 469 u 2764 l', name: '2438', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F469_U2764_R: { search: 'silhouette.u1F469.u2764.R|silhouette u 1 f 469 u 2764 r', name: '2439', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F9D1_U1F48B_L: { search: 'silhouette.u1F9D1.u1F48B.L|silhouette u 1 f 9 d 1 u 1 f 48 b l', name: '2440', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F9D1_U1F48B_R: { search: 'silhouette.u1F9D1.u1F48B.R|silhouette u 1 f 9 d 1 u 1 f 48 b r', name: '2441', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F9D1_U2764_L: { search: 'silhouette.u1F9D1.u2764.L|silhouette u 1 f 9 d 1 u 2764 l', name: '2442', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_U1F9D1_U2764_R: { search: 'silhouette.u1F9D1.u2764.R|silhouette u 1 f 9 d 1 u 2764 r', name: '2443', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_WL: { search: 'silhouette.WL|силуэт вл', name: '2444', category: EMOJI_CATEGORIES.PEOPLE },
  SILHOUETTE_WR: { search: 'silhouette.WR|силуэт вр', name: '2445', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_0_U1F48B_L_0: { search: 'u1F468.0.u1F48B.L 0|у 1 ф 468 0 у 1 ф 48 б л 0', name: '2706', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_0_U1F48B_R_0: { search: 'u1F468.0.u1F48B.R 0|у 1 ф 468 0 у 1 ф 48 б р 0', name: '2707', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_0_U2764_L_0: { search: 'u1F468.0.u2764.L 0|у 1 ф 468 0 у 2764 л 0 0', name: '2708', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_0_U2764_R_0: { search: 'u1F468.0.u2764.R 0|у 1 ф 468 0 у 2764 р 0', name: '2709', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_1_U1F48B_L_1: { search: 'u1F468.1.u1F48B.L 1|у 1 ф 468 1 у 1 ф 48 б л 1', name: '2710', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_1_U1F48B_R_1: { search: 'u1F468.1.u1F48B.R 1|у 1 ф 468 1 у 1 ф 48 б р 1', name: '2711', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_1_U2764_L_1: { search: 'u1F468.1.u2764.L 1|у 1 ф 468 1 у 2764 л 1', name: '2712', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_1_U2764_R_1: { search: 'u1F468.1.u2764.R 1|у 1 ф 468 1 у 2764 р 1', name: '2713', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_2_U1F48B_L_2: { search: 'u1F468.2.u1F48B.L 2|у 1 ф 468 2 у 1 ф 48 б л 2', name: '2714', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_2_U1F48B_R_2: { search: 'u1F468.2.u1F48B.R 2|u 1 f 468 2 u 1 f 48 b r 2', name: '2715', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_2_U2764_L_2: { search: 'u1F468.2.u2764.L 2|у 1 ф 468 2 у 2764 л 1 2 3 4 5', name: '2716', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_2_U2764_R_2: { search: 'u1F468.2.u2764.R 2|у 1 ф 468 2 у 2764 р 2', name: '2717', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_3_U1F48B_L_3: { search: 'u1F468.3.u1F48B.L 3|у 1 ф 468 3 у 1 ф 48 б л 3', name: '2718', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_3_U1F48B_R_3: { search: 'u1F468.3.u1F48B.R 3|у 1 ф 468 3 у 1 ф 48 б р 3', name: '2719', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_3_U2764_L_3: { search: 'u1F468.3.u2764.L 3|у 1 ф 468 3 у 2764 л 2 3 4 5', name: '2720', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_3_U2764_R_3: { search: 'u1F468.3.u2764.R 3|у 1 ф 468 3 у 2764 р 2 3 4 5', name: '2721', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_4_U1F48B_L_4: { search: 'u1F468.4.u1F48B.L 4|у 1 ф 468 4 у 1 ф 48 б л 4', name: '2722', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_4_U1F48B_R_4: { search: 'u1F468.4.u1F48B.R 4|у 1 ф 468 4 у 1 ф 48 б р 4', name: '2723', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_4_U2764_L_4: { search: 'u1F468.4.u2764.L 4|у 1 ф 468 4 у 2764 л 3 4 5 6 7 8 9 10', name: '2724', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_4_U2764_R_4: { search: 'u1F468.4.u2764.R 4|у 1 ф 468 4 у 2764 р 4', name: '2725', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_5_U1F48B_L_5: { search: 'u1F468.5.u1F48B.L 5|у 1 ф 468 5 у 1 ф 48 б л 5', name: '2726', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_5_U1F48B_R_5: { search: 'u1F468.5.u1F48B.R 5|у 1 ф 468 5 у 1 ф 48 б р 5', name: '2727', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_5_U2764_L_5: { search: 'u1F468.5.u2764.L 5|у 1 ф 468 5 у 2764 л 5 5 5', name: '2728', category: EMOJI_CATEGORIES.PEOPLE },
  U1F468_5_U2764_R_5: { search: 'u1F468.5.u2764.R 5|у 1 ф 468 5 у 2764 р 5', name: '2729', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_0_U1F48B_L_0: { search: 'u1F469.0.u1F48B.L 0|у 1 ф 469 0 у 1 ф 48 б л 0', name: '2730', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_0_U1F48B_R_0: { search: 'u1F469.0.u1F48B.R 0|у 1 ф 469 0 у 1 ф 48 б р 0', name: '2731', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_0_U2764_L_0: { search: 'u1F469.0.u2764.L 0|у 1 ф 469 0 у 2764 л 0 0', name: '2732', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_0_U2764_R_0: { search: 'u1F469.0.u2764.R 0|у 1 ф 469 0 у 2764 р 0', name: '2733', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_1_U1F48B_L_1: { search: 'u1F469.1.u1F48B.L 1|у 1 ф 469 1 у 1 ф 48 б л 1', name: '2734', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_1_U1F48B_R_1: { search: 'u1F469.1.u1F48B.R 1|u 1 f 469 1 u 1 f 48 b r 1', name: '2735', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_1_U2764_L_1: { search: 'u1F469.1.u2764.L 1|у 1 ф 469 1 у 2764 л 1', name: '2736', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_1_U2764_R_1: { search: 'u1F469.1.u2764.R 1|у 1 ф 469 1 у 2764 р 1', name: '2737', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_2_U1F48B_L_2: { search: 'u1F469.2.u1F48B.L 2|у 1 ф 469 2 у 1 ф 48 б л 2', name: '2738', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_2_U1F48B_R_2: { search: 'u1F469.2.u1F48B.R 2|у 1 ф 469 2 у 1 ф 48 б р 2', name: '2739', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_2_U2764_L_2: { search: 'u1F469.2.u2764.L 2|у 1 ф 469 2 у 2764 л 1 2 3 4 5', name: '2740', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_2_U2764_R_2: { search: 'u1F469.2.u2764.R 2|у 1 ф 469 2 у 2764 р 2', name: '2741', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_3_U1F48B_L_3: { search: 'u1F469.3.u1F48B.L 3|у 1 ф 469 3 у 1 ф 48 б л 3', name: '2742', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_3_U1F48B_R_3: { search: 'u1F469.3.u1F48B.R 3|у 1 ф 469 3 у 1 ф 48 б р 3', name: '2743', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_3_U2764_L_3: { search: 'u1F469.3.u2764.L 3|у 1 ф 469 3 у 2764 л 2 3 4 5', name: '2744', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_3_U2764_R_3: { search: 'u1F469.3.u2764.R 3|у 1 ф 469 3 у 2764 р 2 3 4 5', name: '2745', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_4_U1F48B_L_4: { search: 'u1F469.4.u1F48B.L 4|у 1 ф 469 4 у 1 ф 48 б л 4', name: '2746', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_4_U1F48B_R_4: { search: 'u1F469.4.u1F48B.R 4|у 1 ф 469 4 у 1 ф 48 б р 4', name: '2747', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_4_U2764_L_4: { search: 'u1F469.4.u2764.L 4|у 1 ф 469 4 у 2764 л 3 4 5 6 7 8 9 10', name: '2748', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_4_U2764_R_4: { search: 'u1F469.4.u2764.R 4|у 1 ф 469 4 у 2764 р 4', name: '2749', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_5_U1F48B_L_5: { search: 'u1F469.5.u1F48B.L 5|у 1 ф 469 5 у 1 ф 48 б л 5', name: '2750', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_5_U1F48B_R_5: { search: 'u1F469.5.u1F48B.R 5|у 1 ф 469 5 у 1 ф 48 б р 5', name: '2751', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_5_U2764_L_5: { search: 'u1F469.5.u2764.L 5|у 1 ф 469 5 у 2764 л 5 5 5', name: '2752', category: EMOJI_CATEGORIES.PEOPLE },
  U1F469_5_U2764_R_5: { search: 'u1F469.5.u2764.R 5|у 1 ф 469 5 у 2764 р 5', name: '2753', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9B0: { search: 'u1F9B0|у 1 ф 9 б 0', name: '2755', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9B1: { search: 'u1F9B1|у 1 ф 9 б 1', name: '2756', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9B2: { search: 'u1F9B2|у 1 ф 9 б 2', name: '2757', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9B3: { search: 'u1F9B3|у 1 ф 9 б 3', name: '2758', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_0_U1F48B_L_0: { search: 'u1F9D1.0.u1F48B.L 0|у 1 ф 9 д 1 0 у 1 ф 48 б л 0', name: '2759', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_0_U1F48B_R_0: { search: 'u1F9D1.0.u1F48B.R 0|у 1 ф 9 д 1 0 у 1 ф 48 б р 0', name: '2760', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_0_U2764_L_0: { search: 'u1F9D1.0.u2764.L 0|у 1 ф 9 д 1 0 у 2764 л 0 0', name: '2761', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_0_U2764_R_0: { search: 'u1F9D1.0.u2764.R 0|у 1 ф 9 д 1 0 у 2764 р 0', name: '2762', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_1_U1F48B_L_1: { search: 'u1F9D1.1.u1F48B.L 1|у 1 ф 9 д 1 1 у 1 ф 48 б л 1', name: '2763', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_1_U1F48B_R_1: { search: 'u1F9D1.1.u1F48B.R 1|u 1 f 9 d 1 1 u 1 f 48 b r 1', name: '2764', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_1_U2764_L_1: { search: 'u1F9D1.1.u2764.L 1|у 1 ф 9 д 1 1 у 2764 л 1', name: '2765', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_1_U2764_R_1: { search: 'u1F9D1.1.u2764.R 1|у 1 ф 9 д 1 1 у 2764 р 1', name: '2766', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_2_U1F48B_L_2: { search: 'u1F9D1.2.u1F48B.L 2|у 1 ф 9 д 1 2 у 1 ф 48 б л 2', name: '2767', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_2_U1F48B_R_2: { search: 'u1F9D1.2.u1F48B.R 2|u 1 f 9 d 1 2 u 1 f 48 b r 2', name: '2768', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_2_U2764_L_2: { search: 'u1F9D1.2.u2764.L 2|у 1 ф 9 д 1 2 у 2764 л 1 2 3 4 5', name: '2769', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_2_U2764_R_2: { search: 'u1F9D1.2.u2764.R 2|у 1 ф 9 д 1 2 у 2764 р 2', name: '2770', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_3_U1F48B_L_3: { search: 'u1F9D1.3.u1F48B.L 3|у 1 ф 9 д 1 3 у 1 ф 48 б л 3', name: '2771', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_3_U1F48B_R_3: { search: 'u1F9D1.3.u1F48B.R 3|у 1 ф 9 д 1 3 у 1 ф 48 б р 3', name: '2772', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_3_U2764_L_3: { search: 'u1F9D1.3.u2764.L 3|у 1 ф 9 д 1 3 у 2764 л 2 3 4 5', name: '2773', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_3_U2764_R_3: { search: 'u1F9D1.3.u2764.R 3|у 1 ф 9 д 1 3 у 2764 р 3', name: '2774', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_4_U1F48B_L_4: { search: 'u1F9D1.4.u1F48B.L 4|у 1 ф 9 д 1 4 у 1 ф 48 б л 4', name: '2775', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_4_U1F48B_R_4: { search: 'u1F9D1.4.u1F48B.R 4|у 1 ф 9 д 1 4 у 1 ф 48 б р 4', name: '2776', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_4_U2764_L_4: { search: 'u1F9D1.4.u2764.L 4|у 1 ф 9 д 1 4 у 2764 л 4', name: '2777', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_4_U2764_R_4: { search: 'u1F9D1.4.u2764.R 4|у 1 ф 9 д 1 4 у 2764 р 4', name: '2778', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_5_U1F48B_L_5: { search: 'u1F9D1.5.u1F48B.L 5|у 1 ф 9 д 1 5 у 1 ф 48 б л 5', name: '2779', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_5_U1F48B_R_5: { search: 'u1F9D1.5.u1F48B.R 5|у 1 ф 9 д 1 5 у 1 ф 48 б р 5', name: '2780', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_5_U2764_L_5: { search: 'u1F9D1.5.u2764.L 5|у 1 ф 9 д 1 5 у 2764 л 5 5 5', name: '2781', category: EMOJI_CATEGORIES.PEOPLE },
  U1F9D1_5_U2764_R_5: { search: 'u1F9D1.5.u2764.R 5|у 1 ф 9 д 1 5 у 2764 р 5', name: '2782', category: EMOJI_CATEGORIES.PEOPLE },
  ANT: { search: 'ant|муравей', name: '28', category: EMOJI_CATEGORIES.NATURE },
  BADGER: { search: 'badger|барсук', name: '100', category: EMOJI_CATEGORIES.NATURE },
  BAT: { search: 'bat|летучая мышь', name: '124', category: EMOJI_CATEGORIES.NATURE },
  BEAR_FACE: { search: 'bear face|медведь', name: '130', category: EMOJI_CATEGORIES.NATURE },
  BEAVER: { search: 'beaver|бобр', name: '150', category: EMOJI_CATEGORIES.NATURE },
  BEE: { search: 'bee|пчела', name: '152', category: EMOJI_CATEGORIES.NATURE },
  BEETLE: { search: 'beetle|божья коровка', name: '154', category: EMOJI_CATEGORIES.NATURE },
  BABY_CHICK: { search: 'baby chick|цыпленок', name: '65', category: EMOJI_CATEGORIES.NATURE },
  HATCHING_CHICK: { search: 'hatching chick|увлекательный цыпленок', name: '1039', category: EMOJI_CATEGORIES.NATURE },
  STANDING_CHICK: { search: 'standing chick|стоя чик', name: '2539', category: EMOJI_CATEGORIES.NATURE },
  PENGUIN: { search: 'penguin|пингвин', name: '1943', category: EMOJI_CATEGORIES.NATURE },
  CHICKEN: { search: 'chicken|курица', name: '282', category: EMOJI_CATEGORIES.NATURE },
  BIRD: { search: 'bird|птица', name: '164', category: EMOJI_CATEGORIES.NATURE },
  BISON: { search: 'bison|бизон', name: '166', category: EMOJI_CATEGORIES.NATURE },
  BLOWFISH: { search: 'blowfish|иглобрюх', name: '181', category: EMOJI_CATEGORIES.NATURE },
  BOAR: { search: 'boar|кабан', name: '188', category: EMOJI_CATEGORIES.NATURE },
  BUG: { search: 'bug|жук', name: '229', category: EMOJI_CATEGORIES.NATURE },
  BUTTERFLY: { search: 'butterfly|бабочка', name: '236', category: EMOJI_CATEGORIES.NATURE },
  CAMEL: { search: 'camel|верблюд', name: '245', category: EMOJI_CATEGORIES.NATURE },
  TWO_HUMP_CAMEL: { search: 'two-hump camel|двухрамный верблюд', name: '2699', category: EMOJI_CATEGORIES.NATURE },
  CAT: { search: 'cat|кот', name: '265', category: EMOJI_CATEGORIES.NATURE },
  BLACK_CAT: { search: 'black cat|черный кот', name: '167', category: EMOJI_CATEGORIES.NATURE },
  CHIPMUNK: { search: 'chipmunk|бурундук', name: '290', category: EMOJI_CATEGORIES.NATURE },
  COCKROACH: { search: 'cockroach|таракан', name: '336', category: EMOJI_CATEGORIES.NATURE },
  COW_FACE: { search: 'cow face|коровье лицо', name: '383', category: EMOJI_CATEGORIES.NATURE },
  COW: { search: 'cow|корова', name: '384', category: EMOJI_CATEGORIES.NATURE },
  CRAB: { search: 'crab|краб', name: '386', category: EMOJI_CATEGORIES.NATURE },
  CRICKET: { search: 'cricket|сверчок', name: '391', category: EMOJI_CATEGORIES.NATURE },
  CROCODILE: { search: 'crocodile|крокодил', name: '392', category: EMOJI_CATEGORIES.NATURE },
  DEER: { search: 'deer|олень', name: '439', category: EMOJI_CATEGORIES.NATURE },
  DINOSAUR: { search: 'dinosaur|динозавр', name: '454', category: EMOJI_CATEGORIES.NATURE },
  DODO_BIRD: { search: 'dodo bird|додо птица', name: '464', category: EMOJI_CATEGORIES.NATURE },
  DOG_FACE: { search: 'dog face|собака лицо', name: '465', category: EMOJI_CATEGORIES.NATURE },
  DOG: { search: 'dog|собака', name: '466', category: EMOJI_CATEGORIES.NATURE },
  DOLPHIN: { search: 'dolphin|дельфин', name: '469', category: EMOJI_CATEGORIES.NATURE },
  DOVE: { search: 'dove|дове', name: '473', category: EMOJI_CATEGORIES.NATURE },
  DRAGON_HEAD: { search: 'dragon head|голова дракона', name: '480', category: EMOJI_CATEGORIES.NATURE },
  DRAGON: { search: 'dragon|дракон', name: '481', category: EMOJI_CATEGORIES.NATURE },
  DUCK: { search: 'duck|утка', name: '488', category: EMOJI_CATEGORIES.NATURE },
  EAGLE: { search: 'eagle|орел', name: '491', category: EMOJI_CATEGORIES.NATURE },
  ELEPHANT: { search: 'elephant|слон', name: '515', category: EMOJI_CATEGORIES.NATURE },
  FISH: { search: 'fish|рыба', name: '637', category: EMOJI_CATEGORIES.NATURE },
  FLAMINGO: { search: 'flamingo|фламинго', name: '892', category: EMOJI_CATEGORIES.NATURE },
  FLY: { search: 'fly|летом', name: '906', category: EMOJI_CATEGORIES.NATURE },
  FOX_FACE: { search: 'fox face|лис лицо', name: '925', category: EMOJI_CATEGORIES.NATURE },
  FROG_FACE: { search: 'frog face|лягушка лицо', name: '930', category: EMOJI_CATEGORIES.NATURE },
  GIRAFFE: { search: 'giraffe|жираф', name: '944', category: EMOJI_CATEGORIES.NATURE },
  GOAT: { search: 'goat|козы', name: '960', category: EMOJI_CATEGORIES.NATURE },
  GORILLA: { search: 'gorilla|горилла', name: '972', category: EMOJI_CATEGORIES.NATURE },
  GUIDE_DOG: { search: 'guide dog|гид собака', name: '1008', category: EMOJI_CATEGORIES.NATURE },
  HAMSTER_FACE: { search: 'hamster face|гамстер лицо', name: '1015', category: EMOJI_CATEGORIES.NATURE },
  HEDGEHOG: { search: 'hedgehog|хеджог', name: '1053', category: EMOJI_CATEGORIES.NATURE },
  HIPPOPOTAMUS: { search: 'hippopotamus|хиппопотамус', name: '1067', category: EMOJI_CATEGORIES.NATURE },
  HORSE_HEAD: { search: 'horse head|лошадь голова', name: '1072', category: EMOJI_CATEGORIES.NATURE },
  HORSE: { search: 'horse|лошади', name: '1073', category: EMOJI_CATEGORIES.NATURE },
  KANGAROO: { search: 'kangaroo|кенгуру', name: '1167', category: EMOJI_CATEGORIES.NATURE },
  KOALA_FACE: { search: 'koala face|коала лицо', name: '1192', category: EMOJI_CATEGORIES.NATURE },
  LADYBUG: { search: 'ladybug|дамбаг', name: '1197', category: EMOJI_CATEGORIES.NATURE },
  LION_FACE: { search: 'lion face|лев лицо', name: '1235', category: EMOJI_CATEGORIES.NATURE },
  LIZARD: { search: 'lizard|лизард', name: '1237', category: EMOJI_CATEGORIES.NATURE },
  LLAMA: { search: 'llama|ллама', name: '1238', category: EMOJI_CATEGORIES.NATURE },
  LOBSTER: { search: 'lobster|лобстер', name: '1239', category: EMOJI_CATEGORIES.NATURE },
  MAMMOTH: { search: 'mammoth|мамонт', name: '1260', category: EMOJI_CATEGORIES.NATURE },
  LEOPARD: { search: 'leopard|леопард', name: '1227', category: EMOJI_CATEGORIES.NATURE },
  MOSQUITO: { search: 'mosquito|москит', name: '1743', category: EMOJI_CATEGORIES.NATURE },
  MOUSE_FACE: { search: 'mouse face|мыши лицо', name: '1759', category: EMOJI_CATEGORIES.NATURE },
  MOUSE: { search: 'mouse|мыши', name: '1761', category: EMOJI_CATEGORIES.NATURE },
  OCTOPUS: { search: 'octopus|октоп', name: '1828', category: EMOJI_CATEGORIES.NATURE },
  ORANGUTAN: { search: 'orangutan|орангутан', name: '1892', category: EMOJI_CATEGORIES.NATURE },
  OTTER: { search: 'otter|otter', name: '1894', category: EMOJI_CATEGORIES.NATURE },
  OWL: { search: 'owl|сова', name: '1896', category: EMOJI_CATEGORIES.NATURE },
  OX: { search: 'ox|окс', name: '1897', category: EMOJI_CATEGORIES.NATURE },
  PANDA_FACE: { search: 'panda face|panda лицо', name: '1913', category: EMOJI_CATEGORIES.NATURE },
  PARROT: { search: 'parrot|паррот', name: '1917', category: EMOJI_CATEGORIES.NATURE },
  PEACOCK: { search: 'peacock|пакок', name: '1933', category: EMOJI_CATEGORIES.NATURE },
  PIG_FACE: { search: 'pig face|свиное лицо', name: '2152', category: EMOJI_CATEGORIES.NATURE },
  PIG_NOSE: { search: 'pig nose|свиньи нос', name: '2153', category: EMOJI_CATEGORIES.NATURE },
  PIG: { search: 'pig|свиней', name: '2154', category: EMOJI_CATEGORIES.NATURE },
  POLAR_BEAR: { search: 'polar bear|полярный медведь', name: '2188', category: EMOJI_CATEGORIES.NATURE },
  POODLE: { search: 'poodle|пюль', name: '2210', category: EMOJI_CATEGORIES.NATURE },
  RABBIT_FACE: { search: 'rabbit face|кроликовое лицо', name: '2252', category: EMOJI_CATEGORIES.NATURE },
  RABBIT: { search: 'rabbit|кролик', name: '2253', category: EMOJI_CATEGORIES.NATURE },
  RACCOON: { search: 'raccoon|раккон', name: '2254', category: EMOJI_CATEGORIES.NATURE },
  RAM: { search: 'ram|драм', name: '2281', category: EMOJI_CATEGORIES.NATURE },
  RAT: { search: 'rat|крыса', name: '2282', category: EMOJI_CATEGORIES.NATURE },
  RHINOCEROS: { search: 'rhinoceros|ринокеро', name: '2309', category: EMOJI_CATEGORIES.NATURE },
  ROOSTER: { search: 'rooster|роостер', name: '2341', category: EMOJI_CATEGORIES.NATURE },
  SCORPION: { search: 'scorpion|скорпион', name: '2385', category: EMOJI_CATEGORIES.NATURE },
  SEAL: { search: 'seal|печать', name: '2388', category: EMOJI_CATEGORIES.NATURE },
  SHARK: { search: 'shark|акулы', name: '2404', category: EMOJI_CATEGORIES.NATURE },
  SHEEP: { search: 'sheep|овец', name: '2406', category: EMOJI_CATEGORIES.NATURE },
  SPOUTING_WHALE: { search: 'spouting whale|шпиона за китами', name: '2535', category: EMOJI_CATEGORIES.NATURE },
  SPIDER: { search: 'spider|паук', name: '2527', category: EMOJI_CATEGORIES.NATURE },
  SHRIMP: { search: 'shrimp|креветки', name: '2417', category: EMOJI_CATEGORIES.NATURE },
  SKUNK: { search: 'skunk|скунк', name: '2463', category: EMOJI_CATEGORIES.NATURE },
  SLOTH: { search: 'sloth|слот', name: '2471', category: EMOJI_CATEGORIES.NATURE },
  SNAIL: { search: 'snail|улитка', name: '2488', category: EMOJI_CATEGORIES.NATURE },
  SNAKE: { search: 'snake|змеи', name: '2489', category: EMOJI_CATEGORIES.NATURE },
  SQUID: { search: 'squid|сквид', name: '2537', category: EMOJI_CATEGORIES.NATURE },
  SWAN: { search: 'swan|лебедь', name: '2594', category: EMOJI_CATEGORIES.NATURE },
  TIGER_FACE: { search: 'tiger face|тигр лицо', name: '2656', category: EMOJI_CATEGORIES.NATURE },
  TIGER: { search: 'tiger|тигр', name: '2657', category: EMOJI_CATEGORIES.NATURE },
  T_REX: { search: 'T-Rex|т рекс', name: '2680', category: EMOJI_CATEGORIES.NATURE },
  TURKEY: { search: 'turkey|индейка', name: '2691', category: EMOJI_CATEGORIES.NATURE },
  TURTLE: { search: 'turtle|черепаха', name: '2692', category: EMOJI_CATEGORIES.NATURE },
  TROPICAL_FISH: { search: 'tropical fish|тропическая рыба', name: '2687', category: EMOJI_CATEGORIES.NATURE },
  SERVICE_DOG: { search: 'service dog|услуги собака', name: '2399', category: EMOJI_CATEGORIES.NATURE },
  HEAR_NO_EVIL_MONKEY: { search: 'hear-no-evil monkey|слышат без зла обезьяны', name: '1047', category: EMOJI_CATEGORIES.NATURE },
  MONKEY_FACE: { search: 'monkey face|обезьяна лицо', name: '1737', category: EMOJI_CATEGORIES.NATURE },
  MONKEY: { search: 'monkey|обезьяна', name: '1738', category: EMOJI_CATEGORIES.NATURE },
  SEE_NO_EVIL_MONKEY: { search: 'see-no-evil monkey|see no evil обезьяна', name: '2392', category: EMOJI_CATEGORIES.NATURE },
  SPEAK_NO_EVIL_MONKEY: { search: 'speak-no-evil monkey|разговорная обезьяна', name: '2524', category: EMOJI_CATEGORIES.NATURE },
  UNICORN: { search: 'unicorn|единорог', name: '2787', category: EMOJI_CATEGORIES.NATURE },
  WATER_BUFFALO: { search: 'water buffalo|буффало воды', name: '2818', category: EMOJI_CATEGORIES.NATURE },
  WHALE: { search: 'whale|виски', name: '2842', category: EMOJI_CATEGORIES.NATURE },
  WOLF: { search: 'wolf|волк', name: '2870', category: EMOJI_CATEGORIES.NATURE },
  WORM: { search: 'worm|ворм', name: '3290', category: EMOJI_CATEGORIES.NATURE },
  ZEBRA: { search: 'zebra|зебра', name: '3308', category: EMOJI_CATEGORIES.NATURE },
  BONE: { search: 'bone|кость', name: '191', category: EMOJI_CATEGORIES.NATURE },
  FEATHER: { search: 'feather|перо', name: '608', category: EMOJI_CATEGORIES.NATURE },
  ROCK: { search: 'rock|рок', name: '2334', category: EMOJI_CATEGORIES.NATURE },
  SEASHELL: { search: 'seashell|море', name: '2389', category: EMOJI_CATEGORIES.NATURE },
  SPIDERWEB: { search: 'spiderweb|паутина', name: '2528', category: EMOJI_CATEGORIES.NATURE },
  SNOWFLAKE: { search: 'snowflake|снежинка', name: '2493', category: EMOJI_CATEGORIES.NATURE },
  SNOWMAN: { search: 'snowman|снеговик', name: '2495', category: EMOJI_CATEGORIES.NATURE },
  SNOWMAN_WITH_SNOWFLAKES: { search: 'snowman with snowflakes|снеговик со снежинками', name: '2494', category: EMOJI_CATEGORIES.NATURE },
  PAW_PRINTS: { search: 'paw prints|paw печати', name: '1924', category: EMOJI_CATEGORIES.NATURE },
  BOUQUET: { search: 'bouquet|букет', name: '197', category: EMOJI_CATEGORIES.NATURE },
  CACTUS: { search: 'cactus|кактус', name: '237', category: EMOJI_CATEGORIES.NATURE },
  FALLEN_LEAVES: { search: 'fallen leaves|падение листьев', name: '572', category: EMOJI_CATEGORIES.NATURE },
  EAR_OF_RICE: { search: 'ear of rice|ухо риса', name: '499', category: EMOJI_CATEGORIES.NATURE },
  CHERRY_BLOSSOM: { search: 'cherry blossom|вишня в цвету', name: '279', category: EMOJI_CATEGORIES.NATURE },
  FLOWER: { search: 'flower|цветок', name: '905', category: EMOJI_CATEGORIES.NATURE },
  FOUR_LEAF_CLOVER: { search: 'four leaf clover|четыре листьев клевер', name: '922', category: EMOJI_CATEGORIES.NATURE },
  EVERGREEN_TREE: { search: 'evergreen tree|вечнозеленое дерево', name: '532', category: EMOJI_CATEGORIES.NATURE },
  CHRISTMAS_TREE: { search: 'Christmas tree|Рождественская елка', name: '293', category: EMOJI_CATEGORIES.NATURE },
  HERB: { search: 'herb|трава', name: '1058', category: EMOJI_CATEGORIES.NATURE },
  HIBISCUS: { search: 'hibiscus|ибискус', name: '1059', category: EMOJI_CATEGORIES.NATURE },
  LEAVES_FLUTTERING_IN_WIND: { search: 'leaves fluttering in wind|листья флеттеринг в ветре', name: '1205', category: EMOJI_CATEGORIES.NATURE },
  MAPLE_LEAF: { search: 'maple leaf|листовка', name: '1675', category: EMOJI_CATEGORIES.NATURE },
  MUSHROOM: { search: 'mushroom|гриб', name: '1771', category: EMOJI_CATEGORIES.NATURE },
  PALM_TREE: { search: 'palm tree|пальмовое дерево', name: '1904', category: EMOJI_CATEGORIES.NATURE },
  PINE_DECORATION: { search: 'pine decoration|сосновое украшение', name: '2176', category: EMOJI_CATEGORIES.NATURE },
  POTTED_PLANT: { search: 'potted plant|гусеничное растение', name: '2216', category: EMOJI_CATEGORIES.NATURE },
  ROSE: { search: 'rose|роза', name: '2342', category: EMOJI_CATEGORIES.NATURE },
  SEEDLING: { search: 'seedling|семена', name: '2391', category: EMOJI_CATEGORIES.NATURE },
  SHAMROCK: { search: 'shamrock|шамрок', name: '2403', category: EMOJI_CATEGORIES.NATURE },
  SUNFLOWER: { search: 'sunflower|подсолнечник', name: '2567', category: EMOJI_CATEGORIES.NATURE },
  TANABATA_TREE: { search: 'tanabata tree|танабата дерево', name: '2607', category: EMOJI_CATEGORIES.NATURE },
  TREE: { search: 'tree|дерево', name: '2679', category: EMOJI_CATEGORIES.NATURE },
  WOOD: { search: 'wood|дерево', name: '3287', category: EMOJI_CATEGORIES.NATURE },
  TULIP: { search: 'tulip|тюльпан', name: '2689', category: EMOJI_CATEGORIES.NATURE },
  WILTED_ROSE: { search: 'wilted rose|вяченая роза', name: '2857', category: EMOJI_CATEGORIES.NATURE },
  DROPLET: { search: 'droplet|дроплет', name: '486', category: EMOJI_CATEGORIES.NATURE },
  WATER_DROPLETS: { search: 'water droplets|водопады', name: '2820', category: EMOJI_CATEGORIES.NATURE },
  WAVE: { search: 'wave|волна', name: '2824', category: EMOJI_CATEGORIES.NATURE },
  RAINBOW: { search: 'rainbow|радуга', name: '2262', category: EMOJI_CATEGORIES.NATURE },
  CLOUD: { search: 'cloud|облако', name: '332', category: EMOJI_CATEGORIES.NATURE },
  CLOUD_WITH_LIGHTNING_AND_RAIN: { search: 'cloud with lightning and rain|облако с молнией и дождем', name: '328', category: EMOJI_CATEGORIES.NATURE },
  CLOUD_WITH_LIGHTNING: { search: 'cloud with lightning|облако с молнией', name: '329', category: EMOJI_CATEGORIES.NATURE },
  CLOUD_WITH_RAIN: { search: 'cloud with rain|облако с дождем', name: '330', category: EMOJI_CATEGORIES.NATURE },
  CLOUD_WITH_SNOW: { search: 'cloud with snow|облако со снегом', name: '331', category: EMOJI_CATEGORIES.NATURE },
  SUN_BEHIND_CLOUD: { search: 'sun behind cloud|солнце за облака', name: '2561', category: EMOJI_CATEGORIES.NATURE },
  SUN_BEHIND_LARGE_CLOUD: { search: 'sun behind large cloud|солнце за большим облаком', name: '2562', category: EMOJI_CATEGORIES.NATURE },
  SUN_BEHIND_RAIN_CLOUD: { search: 'sun behind rain cloud|солнце за дождем облака', name: '2563', category: EMOJI_CATEGORIES.NATURE },
  SUN_BEHIND_SMALL_CLOUD: { search: 'sun behind small cloud|солнце за небольшим облаком', name: '2564', category: EMOJI_CATEGORIES.NATURE },
  SUN: { search: 'sun|солнце', name: '2566', category: EMOJI_CATEGORIES.NATURE },
  SUN_WITH_FACE: { search: 'sun with face|солнце с лицом', name: '2565', category: EMOJI_CATEGORIES.NATURE },
  GUST_OF_WIND: { search: 'gust of wind|ветер', name: '1010', category: EMOJI_CATEGORIES.NATURE },
  TORNADO: { search: 'tornado|торнадо', name: '2670', category: EMOJI_CATEGORIES.NATURE },
  COMET: { search: 'comet|комета', name: '342', category: EMOJI_CATEGORIES.NATURE },
  WIND_BLOWING_FACE: { search: 'wind blowing face|ветер дует лицо', name: '2858', category: EMOJI_CATEGORIES.NATURE },
  EXPLOSION: { search: 'explosion|взрыв', name: '535', category: EMOJI_CATEGORIES.NATURE },
  FIRE: { search: 'fire|огонь', name: '624', category: EMOJI_CATEGORIES.NATURE },
  DIZZY_SYMBOL: { search: 'dizzy symbol|dizzy символ', name: '462', category: EMOJI_CATEGORIES.NATURE },
  SPARKLES: { search: 'sparkles|игры', name: '2518', category: EMOJI_CATEGORIES.NATURE },
  STAR: { search: 'star|звезда', name: '2542', category: EMOJI_CATEGORIES.NATURE },
  GLOWING_STAR: { search: 'glowing star|светящая звезда', name: '958', category: EMOJI_CATEGORIES.NATURE },
  LIGHTNING_BOLT: { search: 'lightning bolt|lightning болт', name: '1232', category: EMOJI_CATEGORIES.NATURE },
  GLOBE_SHOWING_AMERICAS: { search: 'globe showing Americas|глобус показывающий америки', name: '953', category: EMOJI_CATEGORIES.NATURE },
  GLOBE_SHOWING_ASIA_AND_AUSTRALIA: { search: 'globe showing Asia and Australia|глобус показывающий азию и австралию', name: '954', category: EMOJI_CATEGORIES.NATURE },
  GLOBE_SHOWING_EUROPE_AND_AFRICA: { search: 'globe showing Europe and Africa|глобус показывающий европу и африку', name: '955', category: EMOJI_CATEGORIES.NATURE },
  PLANET: { search: 'planet|планета', name: '2183', category: EMOJI_CATEGORIES.NATURE },
  CRESCENT_MOON: { search: 'crescent moon|полумесяц', name: '389', category: EMOJI_CATEGORIES.NATURE },
  FIRST_QUARTER_MOON: { search: 'first quarter moon|первый квартал луна', name: '635', category: EMOJI_CATEGORIES.NATURE },
  FIRST_QUARTER_MOON_WITH_FACE: { search: 'first quarter moon with face|первая четверть луны с лицом', name: '634', category: EMOJI_CATEGORIES.NATURE },
  FULL_MOON: { search: 'full moon|полная луна', name: '935', category: EMOJI_CATEGORIES.NATURE },
  FULL_MOON_WITH_FACE: { search: 'full moon with face|полная луна с лицом', name: '934', category: EMOJI_CATEGORIES.NATURE },
  LAST_QUARTER_MOON: { search: 'last quarter moon|последний квартал луна', name: '1202', category: EMOJI_CATEGORIES.NATURE },
  LAST_QUARTER_MOON_WITH_FACE: { search: 'last quarter moon with face|последний квартал луна с лицом', name: '1201', category: EMOJI_CATEGORIES.NATURE },
  NEW_MOON: { search: 'new moon|новая луна', name: '1797', category: EMOJI_CATEGORIES.NATURE },
  NEW_MOON_WITH_FACE: { search: 'new moon with face|новая луна с лицом', name: '1796', category: EMOJI_CATEGORIES.NATURE },
  UMBRELLA: { search: 'umbrella|зонт', name: '2785', category: EMOJI_CATEGORIES.NATURE },
  UMBRELLA_WITH_RAIN_DROPS: { search: 'umbrella with rain drops|зонт с каплями дождя', name: '2784', category: EMOJI_CATEGORIES.NATURE },
  WANING_CRESCENT_MOON: { search: 'waning crescent moon|лежащая луна', name: '2813', category: EMOJI_CATEGORIES.NATURE },
  WANING_GIBBOUS_MOON: { search: 'waning gibbous moon|бьет гигантскую луну', name: '2814', category: EMOJI_CATEGORIES.NATURE },
  WAXING_CRESCENT_MOON: { search: 'waxing crescent moon|восковая луна', name: '2832', category: EMOJI_CATEGORIES.NATURE },
  WAXING_GIBBOUS_MOON: { search: 'waxing gibbous moon|восковая гигантская луна', name: '2833', category: EMOJI_CATEGORIES.NATURE },
  FOG: { search: 'fog|фог', name: '908', category: EMOJI_CATEGORIES.NATURE },
  AVOCADO: { search: 'avocado|авокадо', name: '50', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BABY_BOTTLE: { search: 'baby bottle|детская бутылочка', name: '64', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BACON: { search: 'bacon|бекон', name: '99', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BAGEL: { search: 'bagel|рогалик', name: '102', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BAGUETTE: { search: 'baguette|батон', name: '104', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BANANA: { search: 'banana|банан', name: '110', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BEER_MUG: { search: 'beer mug|пивная кружка', name: '153', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BELL_PEPPER: { search: 'hot pepper|острый перец', name: '155', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BENTO_BOX: { search: 'bento box|бенто', name: '158', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BIRTHDAY_CAKE: { search: 'birthday cake|Торт на день рождения', name: '165', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BLUEBERRIES: { search: 'blueberries|черника', name: '186', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BOBA_TEA: { search: 'boba tea|боба чай', name: '189', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BOTTLE_WITH_POPPING_CORK: { search: 'bottle with popping cork|бутылка с пробкой', name: '196', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BOWL_WITH_SPOON: { search: 'bowl with spoon|чаша с ложка', name: '199', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BREAD: { search: 'bread|хлеб', name: '209', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BROCCOLI: { search: 'broccoli|брокколи', name: '221', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BURRITO: { search: 'burrito|буррито', name: '232', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  BUTTER: { search: 'butter|масло', name: '235', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CANDY: { search: 'candy|конфеты', name: '251', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CANNED_FOOD: { search: 'canned food|консервы', name: '252', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CARROT: { search: 'carrot|морковь', name: '261', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CHEESE_WEDGE: { search: 'cheese wedge|сыр', name: '276', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CHERRIES: { search: 'cherries|вишня', name: '278', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CHESTNUT: { search: 'chestnut|каштан', name: '281', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CHOCOLATE_BAR: { search: 'chocolate|шоколад', name: '291', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CHOPSTICKS: { search: 'chopsticks|палочки', name: '292', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CLINKING_BEER_MUGS: { search: 'clinking beer mugs|пивные кружки', name: '312', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CLINKING_GLASSES: { search: 'clinking glasses|бокалы', name: '313', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  COCKTAIL_GLASS: { search: 'cocktail glass|коктейльный бокал', name: '337', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  COCONUT: { search: 'coconut|кокос', name: '338', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  COOKED_RICE: { search: 'cooked rice|приготовленный рис', name: '364', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  COOKIE: { search: 'cookie|печенье', name: '365', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  COOKING: { search: 'cooking|готовить', name: '366', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CROISSANT: { search: 'croissant|круассан', name: '393', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CUCUMBER: { search: 'cucumber|огурец', name: '401', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CUPCAKE: { search: 'cupcake|кекс', name: '402', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CURRY_AND_RICE: { search: 'curry and rice|карри и рис', name: '406', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  CUSTARD: { search: 'custard|custard', name: '407', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  DANGO: { search: 'dango|данго', name: '420', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  DOUGHNUT: { search: 'doughnut|тест', name: '472', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  DUMPLING: { search: 'dumpling|самосвал', name: '489', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  EAR_OF_CORN: { search: 'ear of corn|ухо кукурузы', name: '498', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  EGGPLANT: { search: 'eggplant|баклажан', name: '508', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  EGG: { search: 'egg|яйцо', name: '507', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FALAFEL: { search: 'falafel|фальафель', name: '571', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FISH_CAKE_WITH_SWIRL_DESIGN: { search: 'fish cake with swirl design|рыбный торт с дизайном swirl', name: '636', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FLAT_BREAD: { search: 'flat bread|плоский хлеб', name: '894', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FONDUE: { search: 'fondue|фото', name: '909', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FORK_AND_KNIFE: { search: 'fork and knife|форк и нож', name: '918', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FORK_AND_KNIFE_WITH_PLATE: { search: 'fork and knife with plate|форк и нож с тарелкой', name: '917', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FORTUNE_COOKIE: { search: 'fortune cookie|печенья fortune', name: '919', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FRENCH_FRIES: { search: 'french fries|французский картофель фри', name: '928', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  FRIED_SHRIMP: { search: 'fried shrimp|жареные креветки', name: '929', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  GARLIC: { search: 'garlic|чеснок', name: '938', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  GLASS_OF_MILK: { search: 'glass of milk|стакан молока', name: '951', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  GRAPES: { search: 'grapes|виноград', name: '974', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  GREEN_APPLE: { search: 'green apple|зеленое яблоко', name: '975', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  GREEN_SALAD: { search: 'green salad|зеленый салат', name: '978', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  HAMBURGER: { search: 'hamburger|гамбургер', name: '1011', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  HONEY_POT: { search: 'honey pot|медовый горшок', name: '1069', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  HOT_BEVERAGE: { search: 'hot beverage|горячий напиток', name: '1075', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  HOT_DOG: { search: 'hot dog|горячая собака', name: '1076', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  HOT_PEPPER: { search: 'hot pepper|горячий перец', name: '1078', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  ICE_CREAM: { search: 'ice cream|мороженое', name: '1089', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  ICE: { search: 'ice|лед', name: '1092', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  JUICE_BOX: { search: 'juice box|коробка сока', name: '1165', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  KIWI: { search: 'kiwi|киви', name: '1189', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  LEAFY_GREENS: { search: 'leafy greens|листовая зелень', name: '1204', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  LEMON: { search: 'lemon|лимон', name: '1225', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  LOLLIPOP: { search: 'lollipop|лоллипоп', name: '1241', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  MANGO: { search: 'mango|манго', name: '1672', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  MATE: { search: 'maté|мате', name: '1677', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  MEAT_ON_BONE: { search: 'meat on bone|мясо на кости', name: '1678', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  MELON: { search: 'melon|мелон', name: '1687', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  MOONCAKE: { search: 'mooncake|лунакейк', name: '1741', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  ODEN: { search: 'oden|оден', name: '1829', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  OLIVE: { search: 'olive|маслин', name: '1865', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  ONION: { search: 'onion|лук', name: '1875', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  OYSTER: { search: 'oyster|устрица', name: '1898', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PANCAKES: { search: 'pancakes|блинчики', name: '1912', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PAN_OF_FOOD: { search: 'pan of food|панеля еды', name: '1911', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PEACH: { search: 'peach|персик', name: '1932', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PEANUTS: { search: 'peanuts|арахис', name: '1934', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PEAR: { search: 'pear|груша', name: '1935', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PIE: { search: 'pie|пирог', name: '2151', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PINEAPPLE: { search: 'pineapple|ананас', name: '2177', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PITA_SANDWICH: { search: 'pita sandwich|pita сэндвич', name: '2180', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PIZZA: { search: 'pizza|пицца', name: '2181', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  POPCORN: { search: 'popcorn|попкорн', name: '2211', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  POTATO: { search: 'potato|картофель', name: '2215', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  POT_OF_FOOD: { search: 'pot of food|горшок еды', name: '2214', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  POULTRY_LEG: { search: 'poultry leg|птица ноги', name: '2218', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  PRETZEL: { search: 'pretzel|pretzel', name: '2229', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  RED_APPLE: { search: 'red apple|красное яблоко', name: '2288', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  RICE_BALL: { search: 'rice ball|рисовый шар', name: '2311', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  RICE_CRACKER: { search: 'rice cracker|рис трещина', name: '2312', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  ROASTED_SWEET_POTATO: { search: 'roasted sweet potato|жареный сладкий картофель', name: '2326', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SAKE_BOTTLE_AND_CUP: { search: 'sake bottle and cup|ради бутылки и чашки', name: '2359', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SALT: { search: 'salt|соль', name: '2360', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SANDWICH: { search: 'sandwich|сэндвич', name: '2362', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SHAVED_ICE: { search: 'shaved ice|бритый лед', name: '2405', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SHORTCAKE: { search: 'shortcake|коротко', name: '2414', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SOFT_ICE_CREAM: { search: 'soft ice cream|мягкий мороженое', name: '2499', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SPAGHETTI: { search: 'spaghetti|спагетти', name: '2516', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SPOON: { search: 'spoon|ложка', name: '2532', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  STEAK: { search: 'steak|стейк', name: '2545', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  STEAMING_BOWL: { search: 'steaming bowl|паровая чаша', name: '2547', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  STRAWBERRY: { search: 'strawberry|клубника', name: '2552', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  SUSHI: { search: 'sushi|суши', name: '2592', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TACO: { search: 'taco|тако', name: '2604', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TAKEOUT_BOX: { search: 'takeout box|коробка вылета', name: '2605', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TAMALE: { search: 'tamale|тамаль', name: '2606', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TANGERINE: { search: 'tangerine|мандарин', name: '2608', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TEACUP_WITHOUT_HANDLE: { search: 'teacup without handle|чайкап без ручки', name: '2617', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TEAPOT: { search: 'teapot|чайник', name: '2618', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TO_GO_CUP: { search: 'to-go cup|to go чашка', name: '2659', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TOMATO: { search: 'tomato|помидор', name: '2662', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TROPICAL_DRINK: { search: 'tropical drink|тропический напиток', name: '2686', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  TUMBLER_GLASS: { search: 'tumbler glass|стакан', name: '2690', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  WAFFLE: { search: 'waffle|вафли', name: '2812', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  WATERMELON: { search: 'watermelon|арбуз', name: '2823', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  WINE_GLASS: { search: 'wine glass|вино стекло', name: '2861', category: EMOJI_CATEGORIES.FOOD_AND_DRINK },
  AMERICAN_FOOTBALL: { search: 'American football|Американский футбол', name: '19', category: EMOJI_CATEGORIES.ACTIVITY },
  BASEBALL: { search: 'baseball|бейсбол', name: '115', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL: { search: 'basketball|баскетбол', name: '123', category: EMOJI_CATEGORIES.ACTIVITY },
  RUGBY_FOOTBALL: { search: 'rugby football|rugby футбол', name: '2345', category: EMOJI_CATEGORIES.ACTIVITY },
  SOCCER_BALL: { search: 'soccer ball|футбол', name: '2497', category: EMOJI_CATEGORIES.ACTIVITY },
  SOFTBALL: { search: 'softball|софтбол', name: '2500', category: EMOJI_CATEGORIES.ACTIVITY },
  TENNIS_BALL: { search: 'tennis ball|теннисный мяч', name: '2632', category: EMOJI_CATEGORIES.ACTIVITY },
  VOLLEYBALL: { search: 'volleyball|волейбол', name: '2810', category: EMOJI_CATEGORIES.ACTIVITY },
  BILLIARDS: { search: 'billiards|бильярд', name: '162', category: EMOJI_CATEGORIES.ACTIVITY },
  YO_YO: { search: 'yo-yo|йо йо', name: '3307', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLD_MEDAL: { search: 'gold medal|золотая медаль', name: '963', category: EMOJI_CATEGORIES.ACTIVITY },
  SILVER_MEDAL: { search: 'silver medal|серебряная медаль', name: '2446', category: EMOJI_CATEGORIES.ACTIVITY },
  BRONZE_MEDAL: { search: 'bronze medal|бронзовая медаль', name: '223', category: EMOJI_CATEGORIES.ACTIVITY },
  SPORTS_MEDAL: { search: 'sports medal|спортивная медаль', name: '2534', category: EMOJI_CATEGORIES.ACTIVITY },
  TROPHY: { search: 'trophy|трофей', name: '2685', category: EMOJI_CATEGORIES.ACTIVITY },
  MILITARY_MEDAL: { search: 'military medal|военная медаль', name: '1726', category: EMOJI_CATEGORIES.ACTIVITY },
  ROSETTE: { search: 'rosette|розетка', name: '2343', category: EMOJI_CATEGORIES.ACTIVITY },
  REMINDER_RIBBON: { search: 'reminder ribbon|напоминание ленты', name: '2303', category: EMOJI_CATEGORIES.ACTIVITY },
  ACCORDION: { search: 'accordion|аккордеон', name: '1', category: EMOJI_CATEGORIES.ACTIVITY },
  ARTIST_PALETTE: { search: 'artist palette|палитра художника', name: '39', category: EMOJI_CATEGORIES.ACTIVITY },
  BADMINTON_RACQUET_AND_SHUTTLECOCK: { search: 'badminton|бадминтон', name: '101', category: EMOJI_CATEGORIES.ACTIVITY },
  BALLET_SHOES: { search: 'ballet shoes|балетная обувь', name: '105', category: EMOJI_CATEGORIES.ACTIVITY },
  BANJO: { search: 'banjo|банджо', name: '111', category: EMOJI_CATEGORIES.ACTIVITY },
  BOOMERANG: { search: 'boomerang|бумеранг', name: '195', category: EMOJI_CATEGORIES.ACTIVITY },
  BOW_AND_ARROW: { search: 'bow and arrow|лук и стрелка', name: '198', category: EMOJI_CATEGORIES.ACTIVITY },
  BOWLING_BALL_AND_PINS: { search: 'bowling|боулинг', name: '200', category: EMOJI_CATEGORIES.ACTIVITY },
  BOXING_GLOVE: { search: 'boxing glove|перчатка', name: '201', category: EMOJI_CATEGORIES.ACTIVITY },
  BULLSEYE: { search: 'bullseye|Дартс', name: '231', category: EMOJI_CATEGORIES.ACTIVITY },
  CHESS_PAWN: { search: 'chess pawn|шахматная пешка', name: '280', category: EMOJI_CATEGORIES.ACTIVITY },
  CIRCUS_TENT: { search: 'circus tent|цирковой шатер', name: '299', category: EMOJI_CATEGORIES.ACTIVITY },
  CLAPPER_BOARD: { search: 'clapper board|кино', name: '304', category: EMOJI_CATEGORIES.ACTIVITY },
  CRICKET_BAT_AND_BALL: { search: 'cricket bat and ball|крикет', name: '390', category: EMOJI_CATEGORIES.ACTIVITY },
  CURLING_STONE: { search: 'curling stone|курящий камень', name: '403', category: EMOJI_CATEGORIES.ACTIVITY },
  DIVING_MASK: { search: 'diving mask|дайвинг маска', name: '458', category: EMOJI_CATEGORIES.ACTIVITY },
  DRUM: { search: 'drum|барабан', name: '487', category: EMOJI_CATEGORIES.ACTIVITY },
  FIELD_HOCKEY_STICK_AND_BALL: { search: 'field hockey stick and ball|поле хоккейная палочка и мяч', name: '612', category: EMOJI_CATEGORIES.ACTIVITY },
  FISHING_POLE_AND_FISH: { search: 'fishing pole and fish|рыболовный полюс и рыба', name: '638', category: EMOJI_CATEGORIES.ACTIVITY },
  FLYING_DISC: { search: 'flying disc|летающий диск', name: '907', category: EMOJI_CATEGORIES.ACTIVITY },
  GAME_DIE: { search: 'game die|игра die', name: '937', category: EMOJI_CATEGORIES.ACTIVITY },
  GOAL_NET: { search: 'goal net|цель net', name: '959', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLF_HOLE_WITH_FLAG: { search: 'golf hole with flag|отверстие для гольфа с флагом', name: '964', category: EMOJI_CATEGORIES.ACTIVITY },
  GUITAR: { search: 'guitar|гитара', name: '1009', category: EMOJI_CATEGORIES.ACTIVITY },
  HEADPHONES: { search: 'headphones|наушники', name: '1040', category: EMOJI_CATEGORIES.ACTIVITY },
  ICE_HOCKEY_STICK_AND_PUCK: { search: 'ice hockey stick and puck|хоккейная палочка и утка', name: '1090', category: EMOJI_CATEGORIES.ACTIVITY },
  ICE_SKATE: { search: 'ice skate|ледяной каток', name: '1091', category: EMOJI_CATEGORIES.ACTIVITY },
  KITE: { search: 'kite|кайт', name: '1188', category: EMOJI_CATEGORIES.ACTIVITY },
  LACROSSE_STICK_AND_BALL: { search: 'lacrosse stick and ball|lacrosse палочка и мяч', name: '1195', category: EMOJI_CATEGORIES.ACTIVITY },
  LONG_DRUM: { search: 'long drum|длинный барабан', name: '1242', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_BASKETBALL_PLAYER_0: { search: 'man basketball player 0|человек баскетболист 0', name: '1280', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_BASKETBALL_PLAYER_1: { search: 'man basketball player 1|человек баскетболист 1', name: '1281', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_BASKETBALL_PLAYER_2: { search: 'man basketball player 2|человек баскетболист 2', name: '1282', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_BASKETBALL_PLAYER_3: { search: 'man basketball player 3|человек баскетболист 3', name: '1283', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_BASKETBALL_PLAYER_4: { search: 'man basketball player 4|человек баскетболист 4', name: '1284', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_BASKETBALL_PLAYER_5: { search: 'man basketball player 5|человек баскетболист 5', name: '1285', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL_PLAYER_0: { search: 'basketball player 0|баскетболист 0', name: '117', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL_PLAYER_1: { search: 'basketball player 1|баскетболист 1', name: '118', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL_PLAYER_2: { search: 'basketball player 2|баскетболист 2', name: '119', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL_PLAYER_3: { search: 'basketball player 3|баскетболист 2', name: '120', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL_PLAYER_4: { search: 'basketball player 4|баскетболист 3', name: '121', category: EMOJI_CATEGORIES.ACTIVITY },
  BASKETBALL_PLAYER_5: { search: 'basketball player 5|баскетболист 5', name: '122', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_BASKETBALL_PLAYER_0: { search: 'woman basketball player 0|женщина баскетболист 0', name: '2894', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_BASKETBALL_PLAYER_1: { search: 'woman basketball player 1|женщина баскетболист 1', name: '2895', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_BASKETBALL_PLAYER_2: { search: 'woman basketball player 2|женщина баскетболист 2', name: '2896', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_BASKETBALL_PLAYER_3: { search: 'woman basketball player 3|женщина баскетболист 3', name: '2897', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_BASKETBALL_PLAYER_4: { search: 'woman basketball player 4|женщина баскетболист 4', name: '2898', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_BASKETBALL_PLAYER_5: { search: 'woman basketball player 5|женщина баскетболист 5', name: '2899', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_CYCLIST_0: { search: 'man cyclist 0|человек велосипедист 0', name: '1304', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_CYCLIST_1: { search: 'man cyclist 1|человек велосипедист 1', name: '1305', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_CYCLIST_2: { search: 'man cyclist 2|человек велосипедист 2', name: '1306', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_CYCLIST_3: { search: 'man cyclist 3|человек велосипедист 3', name: '1307', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_CYCLIST_4: { search: 'man cyclist 4|человек велосипедист 4', name: '1308', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_CYCLIST_5: { search: 'man cyclist 5|человек велосипедист 5', name: '1309', category: EMOJI_CATEGORIES.ACTIVITY },
  CYCLIST_0: { search: 'cyclist 0|велосипедист 0', name: '409', category: EMOJI_CATEGORIES.ACTIVITY },
  CYCLIST_1: { search: 'cyclist 1|велосипедист 1', name: '410', category: EMOJI_CATEGORIES.ACTIVITY },
  CYCLIST_2: { search: 'cyclist 2|велосипедист 2', name: '411', category: EMOJI_CATEGORIES.ACTIVITY },
  CYCLIST_3: { search: 'cyclist 3|велосипедист 3', name: '412', category: EMOJI_CATEGORIES.ACTIVITY },
  CYCLIST_4: { search: 'cyclist 4|велосипедист 4', name: '413', category: EMOJI_CATEGORIES.ACTIVITY },
  CYCLIST_5: { search: 'cyclist 5|велосипедист 5', name: '414', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_CYCLIST_0: { search: 'woman cyclist 0|женский велосипедист 0', name: '2918', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_CYCLIST_1: { search: 'woman cyclist 1|женский велосипедист 1', name: '2919', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_CYCLIST_2: { search: 'woman cyclist 2|женский велосипедист 2', name: '2920', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_CYCLIST_3: { search: 'woman cyclist 3|женский велосипедист 3', name: '2921', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_CYCLIST_4: { search: 'woman cyclist 4|женский велосипедист 4', name: '2922', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_CYCLIST_5: { search: 'woman cyclist 5|женский велосипедист 5', name: '2923', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_DOING_A_CARTWHEEL_0: { search: 'man doing a cartwheel 0|человек делает колёс 0', name: '1322', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_DOING_A_CARTWHEEL_1: { search: 'man doing a cartwheel 1|человек делает колёс 1', name: '1323', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_DOING_A_CARTWHEEL_2: { search: 'man doing a cartwheel 2|человек делает колёс 2', name: '1324', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_DOING_A_CARTWHEEL_3: { search: 'man doing a cartwheel 3|человек делает колёс 3', name: '1325', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_DOING_A_CARTWHEEL_4: { search: 'man doing a cartwheel 4|человек делает колёс 4', name: '1326', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_DOING_A_CARTWHEEL_5: { search: 'man doing a cartwheel 5|человек делает колёс 5', name: '1327', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_DOING_A_CARTWHEEL_0: { search: 'person doing a cartwheel 0|человек делает колёс 0', name: '1960', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_DOING_A_CARTWHEEL_1: { search: 'person doing a cartwheel 1|человек делает колёс 1', name: '1961', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_DOING_A_CARTWHEEL_2: { search: 'person doing a cartwheel 2|человек делает колёс 2', name: '1962', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_DOING_A_CARTWHEEL_3: { search: 'person doing a cartwheel 3|человек делает колёс 3', name: '1963', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_DOING_A_CARTWHEEL_4: { search: 'person doing a cartwheel 4|человек делает колёс 4', name: '1964', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_DOING_A_CARTWHEEL_5: { search: 'person doing a cartwheel 5|человек делает колёс 5', name: '1965', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_DOING_A_CARTWHEEL_0: { search: 'woman doing a cartwheel 0|женщина делает колёс 0', name: '2936', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_DOING_A_CARTWHEEL_1: { search: 'woman doing a cartwheel 1|женщина делает колёс 1', name: '2937', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_DOING_A_CARTWHEEL_2: { search: 'woman doing a cartwheel 2|женщина делает колёс 2', name: '2938', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_DOING_A_CARTWHEEL_3: { search: 'woman doing a cartwheel 3|женщина делает колёс 3', name: '2939', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_DOING_A_CARTWHEEL_4: { search: 'woman doing a cartwheel 4|женщина делает колёс 4', name: '2940', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_DOING_A_CARTWHEEL_5: { search: 'woman doing a cartwheel 5|женщина делает колёс 5', name: '2941', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_GOLFER_0: { search: 'man golfer 0|человек гольфист 0', name: '1401', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_GOLFER_1: { search: 'man golfer 1|человек гольфист 1', name: '1402', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_GOLFER_2: { search: 'man golfer 2|человек гольфист 2', name: '1403', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_GOLFER_3: { search: 'man golfer 3|человек гольфист 3', name: '1404', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_GOLFER_4: { search: 'man golfer 4|человек гольфист 4', name: '1405', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_GOLFER_5: { search: 'man golfer 5|человек гольфист 5', name: '1406', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLFER_0: { search: 'golfer 0|гольфист 0', name: '965', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLFER_1: { search: 'golfer 1|гольфист 1', name: '966', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLFER_2: { search: 'golfer 2|гольфист 2', name: '967', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLFER_3: { search: 'golfer 3|гольфист 3', name: '968', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLFER_4: { search: 'golfer 4|гольфист 4', name: '969', category: EMOJI_CATEGORIES.ACTIVITY },
  GOLFER_5: { search: 'golfer 5|гольфист 5', name: '970', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_GOLFER_0: { search: 'woman golfer 0|женщина гольфист 0', name: '3015', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_GOLFER_1: { search: 'woman golfer 1|женщина гольфист 1', name: '3016', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_GOLFER_2: { search: 'woman golfer 2|женщина гольфист 2', name: '3017', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_GOLFER_3: { search: 'woman golfer 3|женщина гольфист 3', name: '3018', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_GOLFER_4: { search: 'woman golfer 4|женщина гольфист 4', name: '3019', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_GOLFER_5: { search: 'woman golfer 5|женщина гольфист 5', name: '3020', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_IN_LOTUS_POSITION_0: { search: 'man in lotus position 0|человек в лотосной позиции 0', name: '1425', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_IN_LOTUS_POSITION_1: { search: 'man in lotus position 1|человек в лотосной позиции 1', name: '1426', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_IN_LOTUS_POSITION_2: { search: 'man in lotus position 2|человек в лотос позиции 2', name: '1427', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_IN_LOTUS_POSITION_3: { search: 'man in lotus position 3|человек в лотосной позиции 3', name: '1428', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_IN_LOTUS_POSITION_4: { search: 'man in lotus position 4|человек в лотосной позиции 4', name: '1429', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_IN_LOTUS_POSITION_5: { search: 'man in lotus position 5|человек в лотосной позиции 5', name: '1430', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_IN_LOTUS_POSITION_0: { search: 'person in lotus position 0|человек в лотосной позиции 0', name: '2014', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_IN_LOTUS_POSITION_1: { search: 'person in lotus position 1|человек в лотос позиции 1', name: '2015', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_IN_LOTUS_POSITION_2: { search: 'person in lotus position 2|человек в лотос позиции 2', name: '2016', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_IN_LOTUS_POSITION_3: { search: 'person in lotus position 3|человек в лотос позиции 3', name: '2017', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_IN_LOTUS_POSITION_4: { search: 'person in lotus position 4|человек в лотос позиции 4', name: '2018', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_IN_LOTUS_POSITION_5: { search: 'person in lotus position 5|человек в лотосной позиции 5', name: '2019', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_IN_LOTUS_POSITION_0: { search: 'woman in lotus position 0|женщина в лотос позиции 0', name: '3033', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_IN_LOTUS_POSITION_1: { search: 'woman in lotus position 1|женщина в лотос позиции 1', name: '3034', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_IN_LOTUS_POSITION_2: { search: 'woman in lotus position 2|женщина в лотос позиции 2', name: '3035', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_IN_LOTUS_POSITION_3: { search: 'woman in lotus position 3|женщина в лотос позиции 3', name: '3036', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_IN_LOTUS_POSITION_4: { search: 'woman in lotus position 4|женщина в лотос позиции 4', name: '3037', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_IN_LOTUS_POSITION_5: { search: 'woman in lotus position 5|женщина в лотос позиции 5', name: '3038', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_JUGGLING_0: { search: 'man juggling 0|человек жонглирование 0', name: '1455', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_JUGGLING_1: { search: 'man juggling 1|человек жонглирование 1', name: '1456', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_JUGGLING_2: { search: 'man juggling 2|человек жонглирование 2', name: '1457', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_JUGGLING_3: { search: 'man juggling 3|человек жонглирование 3', name: '1458', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_JUGGLING_4: { search: 'man juggling 4|человек жонглирование 4', name: '1459', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_JUGGLING_5: { search: 'man juggling 5|человек жонглирование 5', name: '1460', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_JUGGLING_0: { search: 'person juggling 0|человек жонглирование 0', name: '2038', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_JUGGLING_1: { search: 'person juggling 1|человек жонглирование 1', name: '2039', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_JUGGLING_2: { search: 'person juggling 2|человек жонглирование 2', name: '2040', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_JUGGLING_3: { search: 'person juggling 3|человек жонглирование 3', name: '2041', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_JUGGLING_4: { search: 'person juggling 4|человек жонглирование 4', name: '2042', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_JUGGLING_5: { search: 'person juggling 5|человек жонглирование 5', name: '2043', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_JUGGLING_0: { search: 'woman juggling 0|женщина жонглирование 0', name: '3063', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_JUGGLING_1: { search: 'woman juggling 1|женщина жонглирование 1', name: '3064', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_JUGGLING_2: { search: 'woman juggling 2|женщина жонглирование 2', name: '3065', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_JUGGLING_3: { search: 'woman juggling 3|женщина жонглирование 3', name: '3066', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_JUGGLING_4: { search: 'woman juggling 4|женщина жонглирование 4', name: '3067', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_JUGGLING_5: { search: 'woman juggling 5|женщина жонглирование 5', name: '3068', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_MOUNTAIN_BIKER_0: { search: 'man mountain biker 0|человек горный байкер 0', name: '1473', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_MOUNTAIN_BIKER_1: { search: 'man mountain biker 1|человек гора байкер 1', name: '1474', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_MOUNTAIN_BIKER_2: { search: 'man mountain biker 2|человек горный байкер 2', name: '1475', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_MOUNTAIN_BIKER_3: { search: 'man mountain biker 3|человек горный велосипед 3', name: '1476', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_MOUNTAIN_BIKER_4: { search: 'man mountain biker 4|человек горный байкер 4', name: '1477', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_MOUNTAIN_BIKER_5: { search: 'man mountain biker 5|человек гора велосипедист 5', name: '1478', category: EMOJI_CATEGORIES.ACTIVITY },
  MOUNTAIN_BIKER_0: { search: 'mountain biker 0|горный байкер 0', name: '1750', category: EMOJI_CATEGORIES.ACTIVITY },
  MOUNTAIN_BIKER_1: { search: 'mountain biker 1|горный байкер 1', name: '1751', category: EMOJI_CATEGORIES.ACTIVITY },
  MOUNTAIN_BIKER_2: { search: 'mountain biker 2|горный велосипед 2', name: '1752', category: EMOJI_CATEGORIES.ACTIVITY },
  MOUNTAIN_BIKER_3: { search: 'mountain biker 3|горный велосипед 3', name: '1753', category: EMOJI_CATEGORIES.ACTIVITY },
  MOUNTAIN_BIKER_4: { search: 'mountain biker 4|горный байкер 4', name: '1754', category: EMOJI_CATEGORIES.ACTIVITY },
  MOUNTAIN_BIKER_5: { search: 'mountain biker 5|горный байкер 5', name: '1755', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_MOUNTAIN_BIKER_0: { search: 'woman mountain biker 0|женщина гора байкер 0', name: '3081', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_MOUNTAIN_BIKER_1: { search: 'woman mountain biker 1|женщина гора байкер 1', name: '3082', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_MOUNTAIN_BIKER_2: { search: 'woman mountain biker 2|женщина гора байкер 2', name: '3083', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_MOUNTAIN_BIKER_3: { search: 'woman mountain biker 3|женщина гора байкер 3', name: '3084', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_MOUNTAIN_BIKER_4: { search: 'woman mountain biker 4|женщина гора байкер 4', name: '3085', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_MOUNTAIN_BIKER_5: { search: 'woman mountain biker 5|женщина гора велосипедист 5', name: '3086', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_HANDBALL_0: { search: 'man playing handball 0|человек играет в гандбол 0', name: '1497', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_HANDBALL_1: { search: 'man playing handball 1|человек играет в гандбол 1', name: '1498', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_HANDBALL_2: { search: 'man playing handball 2|человек играет в гандбол 2', name: '1499', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_HANDBALL_3: { search: 'man playing handball 3|человек играет в гандбол 3', name: '1500', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_HANDBALL_4: { search: 'man playing handball 4|человек играет в гандбол 4', name: '1501', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_HANDBALL_5: { search: 'man playing handball 5|человек играет в гандбол 5', name: '1502', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_HANDBALL_0: { search: 'woman playing handball 0|женщина играет в гандбол 0', name: '3105', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_HANDBALL_1: { search: 'woman playing handball 1|женщина играет в гандбол 1', name: '3106', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_HANDBALL_2: { search: 'woman playing handball 2|женщина играет в гандбол 2', name: '3107', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_HANDBALL_3: { search: 'woman playing handball 3|женщина играет в гандбол 3', name: '3108', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_HANDBALL_4: { search: 'woman playing handball 4|женщина играет в гандбол 4', name: '3109', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_HANDBALL_5: { search: 'woman playing handball 5|женщина играет в гандбол 5', name: '3110', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_HANDBALL_0: { search: 'person playing handball 0|человек играет в гандбол 0', name: '2050', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_HANDBALL_1: { search: 'person playing handball 1|человек играет в гандбол 1', name: '2051', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_HANDBALL_2: { search: 'person playing handball 2|человек играет в гандбол 2', name: '2052', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_HANDBALL_3: { search: 'person playing handball 3|человек играет в гандбол 3', name: '2053', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_HANDBALL_4: { search: 'person playing handball 4|человек играет в гандбол 4', name: '2054', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_HANDBALL_5: { search: 'person playing handball 5|человек играет в гандбол 5', name: '2055', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_WATER_POLO_0: { search: 'man playing water polo 0|человек играет воду поло 0', name: '1503', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_WATER_POLO_1: { search: 'man playing water polo 1|человек играет воду поло 1', name: '1504', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_WATER_POLO_2: { search: 'man playing water polo 2|человек играет воду поло 2', name: '1505', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_WATER_POLO_3: { search: 'man playing water polo 3|человек играет воду поло 3', name: '1506', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_WATER_POLO_4: { search: 'man playing water polo 4|человек играет воду поло 4', name: '1507', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_PLAYING_WATER_POLO_5: { search: 'man playing water polo 5|человек играет воду поло 5', name: '1508', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_WATER_POLO_0: { search: 'person playing water polo 0|человек играет воду поло 0', name: '2056', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_WATER_POLO_1: { search: 'person playing water polo 1|человек играет воду поло 1', name: '2057', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_WATER_POLO_2: { search: 'person playing water polo 2|человек играет воду поло 2', name: '2058', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_WATER_POLO_3: { search: 'person playing water polo 3|человек играет воду поло 3', name: '2059', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_WATER_POLO_4: { search: 'person playing water polo 4|человек играет воду поло 4', name: '2060', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_PLAYING_WATER_POLO_5: { search: 'person playing water polo 5|человек играет воду поло 5', name: '2061', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_WATER_POLO_0: { search: 'woman playing water polo 0|женщина играет воду поло 0', name: '3111', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_WATER_POLO_1: { search: 'woman playing water polo 1|женщина играет воду поло 1', name: '3112', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_WATER_POLO_2: { search: 'woman playing water polo 2|женщина играет воду поло 2', name: '3113', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_WATER_POLO_3: { search: 'woman playing water polo 3|женщина играет воду поло 3', name: '3114', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_WATER_POLO_4: { search: 'woman playing water polo 4|женщина играет воду поло 4', name: '3115', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_PLAYING_WATER_POLO_5: { search: 'woman playing water polo 5|женщина играет воду поло 5', name: '3116', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROCK_CLIMBER_0: { search: 'man rock climber 0|человек рок альпинист 0', name: '1521', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROCK_CLIMBER_1: { search: 'man rock climber 1|человек рок альпинист 1', name: '1522', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROCK_CLIMBER_2: { search: 'man rock climber 2|человек рок альпинист 2', name: '1523', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROCK_CLIMBER_3: { search: 'man rock climber 3|человек рок альпинист 3', name: '1524', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROCK_CLIMBER_4: { search: 'man rock climber 4|человек рок альпинист 4', name: '1525', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROCK_CLIMBER_5: { search: 'man rock climber 5|человек рок альпинист 5', name: '1526', category: EMOJI_CATEGORIES.ACTIVITY },
  ROCK_CLIMBER_0: { search: 'rock climber 0|рок альпинист 0', name: '2328', category: EMOJI_CATEGORIES.ACTIVITY },
  ROCK_CLIMBER_1: { search: 'rock climber 1|рок альпинист 1', name: '2329', category: EMOJI_CATEGORIES.ACTIVITY },
  ROCK_CLIMBER_2: { search: 'rock climber 2|рок альпинист 2', name: '2330', category: EMOJI_CATEGORIES.ACTIVITY },
  ROCK_CLIMBER_3: { search: 'rock climber 3|рок альпинист 3', name: '2331', category: EMOJI_CATEGORIES.ACTIVITY },
  ROCK_CLIMBER_4: { search: 'rock climber 4|рок альпинист 4', name: '2332', category: EMOJI_CATEGORIES.ACTIVITY },
  ROCK_CLIMBER_5: { search: 'rock climber 5|рок альпинист 5', name: '2333', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROCK_CLIMBER_0: { search: 'woman rock climber 0|женщина рок альпинист 0', name: '3129', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROCK_CLIMBER_1: { search: 'woman rock climber 1|женщина рок альпинист 1', name: '3130', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROCK_CLIMBER_2: { search: 'woman rock climber 2|женщина рок альпинист 2', name: '3131', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROCK_CLIMBER_3: { search: 'woman rock climber 3|женщина рок альпинист 3', name: '3132', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROCK_CLIMBER_4: { search: 'woman rock climber 4|женщина рок альпинист 4', name: '3133', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROCK_CLIMBER_5: { search: 'woman rock climber 5|женщина рок альпинист 5', name: '3134', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROWING_BOAT_0: { search: 'man rowing boat 0|человек гребная лодка 0', name: '1527', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROWING_BOAT_1: { search: 'man rowing boat 1|человек гребная лодка 1', name: '1528', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROWING_BOAT_2: { search: 'man rowing boat 2|человек гребная лодка 2', name: '1529', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROWING_BOAT_3: { search: 'man rowing boat 3|человек гребная лодка 3', name: '1530', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROWING_BOAT_4: { search: 'man rowing boat 4|человек гребная лодка 4', name: '1531', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_ROWING_BOAT_5: { search: 'man rowing boat 5|человек гребная лодка 5', name: '1532', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_ROWING_BOAT_0: { search: 'person rowing boat 0|человек гребная лодка 0', name: '2074', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_ROWING_BOAT_1: { search: 'person rowing boat 1|человек гребная лодка 1', name: '2075', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_ROWING_BOAT_2: { search: 'person rowing boat 2|человек гребная лодка 2', name: '2076', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_ROWING_BOAT_3: { search: 'person rowing boat 3|человек гребная лодка 3', name: '2077', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_ROWING_BOAT_4: { search: 'person rowing boat 4|человек гребная лодка 4', name: '2078', category: EMOJI_CATEGORIES.ACTIVITY },
  PERSON_ROWING_BOAT_5: { search: 'person rowing boat 5|человек гребная лодка 5', name: '2079', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROWING_BOAT_0: { search: 'woman rowing boat 0|женская гребная лодка 0', name: '3135', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROWING_BOAT_1: { search: 'woman rowing boat 1|женщина гребная лодка 1', name: '3136', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROWING_BOAT_2: { search: 'woman rowing boat 2|женская гребная лодка 2', name: '3137', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROWING_BOAT_3: { search: 'woman rowing boat 3|женская гребная лодка 3', name: '3138', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROWING_BOAT_4: { search: 'woman rowing boat 4|женская гребная лодка 4', name: '3139', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_ROWING_BOAT_5: { search: 'woman rowing boat 5|женская гребная лодка 5', name: '3140', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SURFER_0: { search: 'man surfer 0|человек сюрфер 0', name: '1581', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SURFER_1: { search: 'man surfer 1|человек сюрфер 1', name: '1582', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SURFER_2: { search: 'man surfer 2|человек сюрфер 2', name: '1583', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SURFER_3: { search: 'man surfer 3|человек сюрфер 3', name: '1584', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SURFER_4: { search: 'man surfer 4|человек сюрфер 4', name: '1585', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SURFER_5: { search: 'man surfer 5|человек сюрфер 5', name: '1586', category: EMOJI_CATEGORIES.ACTIVITY },
  SURFER_0: { search: 'surfer 0|сюрфер 0', name: '2584', category: EMOJI_CATEGORIES.ACTIVITY },
  SURFER_1: { search: 'surfer 1|сюрфер 1', name: '2585', category: EMOJI_CATEGORIES.ACTIVITY },
  SURFER_2: { search: 'surfer 2|сюрфер 2', name: '2586', category: EMOJI_CATEGORIES.ACTIVITY },
  SURFER_3: { search: 'surfer 3|сюрфер 3', name: '2587', category: EMOJI_CATEGORIES.ACTIVITY },
  SURFER_4: { search: 'surfer 4|сюрфер 4', name: '2588', category: EMOJI_CATEGORIES.ACTIVITY },
  SURFER_5: { search: 'surfer 5|сюрфер 5', name: '2589', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SURFER_0: { search: 'woman surfer 0|женщина сюрфер 0', name: '3189', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SURFER_1: { search: 'woman surfer 1|женщина сюрфер 1', name: '3190', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SURFER_2: { search: 'woman surfer 2|женщина сюрфер 2', name: '3191', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SURFER_3: { search: 'woman surfer 3|женщина сюрфер 3', name: '3192', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SURFER_4: { search: 'woman surfer 4|женщина сюрфер 4', name: '3193', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SURFER_5: { search: 'woman surfer 5|женщина сюрфер 5', name: '3194', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SWIMMER_0: { search: 'man swimmer 0|человек пловец 0', name: '1587', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SWIMMER_1: { search: 'man swimmer 1|человек пловец 1', name: '1588', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SWIMMER_2: { search: 'man swimmer 2|человек пловец 2', name: '1589', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SWIMMER_3: { search: 'man swimmer 3|человек пловец 3', name: '1590', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SWIMMER_4: { search: 'man swimmer 4|человек пловец 4', name: '1591', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_SWIMMER_5: { search: 'man swimmer 5|человек пловец 5', name: '1592', category: EMOJI_CATEGORIES.ACTIVITY },
  SWIMMER_0: { search: 'swimmer 0|пловец 0', name: '2595', category: EMOJI_CATEGORIES.ACTIVITY },
  SWIMMER_1: { search: 'swimmer 1|пловец 1', name: '2596', category: EMOJI_CATEGORIES.ACTIVITY },
  SWIMMER_2: { search: 'swimmer 2|пловец 2', name: '2597', category: EMOJI_CATEGORIES.ACTIVITY },
  SWIMMER_3: { search: 'swimmer 3|пловец 3', name: '2598', category: EMOJI_CATEGORIES.ACTIVITY },
  SWIMMER_4: { search: 'swimmer 4|пловец 4', name: '2599', category: EMOJI_CATEGORIES.ACTIVITY },
  SWIMMER_5: { search: 'swimmer 5|пловец 5', name: '2600', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SWIMMER_0: { search: 'woman swimmer 0|женщина пловец 0', name: '3195', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SWIMMER_1: { search: 'woman swimmer 1|женщина пловец 1', name: '3196', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SWIMMER_2: { search: 'woman swimmer 2|женщина пловец 2', name: '3197', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SWIMMER_3: { search: 'woman swimmer 3|женщина пловец 3', name: '3198', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SWIMMER_4: { search: 'woman swimmer 4|женщина пловец 4', name: '3199', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_SWIMMER_5: { search: 'woman swimmer 5|женщина пловец 5', name: '3200', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_WEIGHTLIFTER_0: { search: 'man weightlifter 0|человек weightlifter 0', name: '1611', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_WEIGHTLIFTER_1: { search: 'man weightlifter 1|человек weightlifter 1', name: '1612', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_WEIGHTLIFTER_2: { search: 'man weightlifter 2|человек weightlifter 2', name: '1613', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_WEIGHTLIFTER_3: { search: 'man weightlifter 3|человек weightlifter 3', name: '1614', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_WEIGHTLIFTER_4: { search: 'man weightlifter 4|человек weightlifter 4', name: '1615', category: EMOJI_CATEGORIES.ACTIVITY },
  MAN_WEIGHTLIFTER_5: { search: 'man weightlifter 5|человек weightlifter 5', name: '1616', category: EMOJI_CATEGORIES.ACTIVITY },
  WEIGHTLIFTER_0: { search: 'weightlifter 0|погрузчик 0', name: '2836', category: EMOJI_CATEGORIES.ACTIVITY },
  WEIGHTLIFTER_1: { search: 'weightlifter 1|погрузчик 1', name: '2837', category: EMOJI_CATEGORIES.ACTIVITY },
  WEIGHTLIFTER_2: { search: 'weightlifter 2|погрузчик 2', name: '2838', category: EMOJI_CATEGORIES.ACTIVITY },
  WEIGHTLIFTER_3: { search: 'weightlifter 3|погрузчик 3', name: '2839', category: EMOJI_CATEGORIES.ACTIVITY },
  WEIGHTLIFTER_4: { search: 'weightlifter 4|погрузчик 4', name: '2840', category: EMOJI_CATEGORIES.ACTIVITY },
  WEIGHTLIFTER_5: { search: 'weightlifter 5|погрузчик 5', name: '2841', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_WEIGHTLIFTER_0: { search: 'woman weightlifter 0|женщина веслифтер 0', name: '3219', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_WEIGHTLIFTER_1: { search: 'woman weightlifter 1|женщина веслифтер 1', name: '3220', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_WEIGHTLIFTER_2: { search: 'woman weightlifter 2|женщина веслифтер 2', name: '3221', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_WEIGHTLIFTER_3: { search: 'woman weightlifter 3|женщина веслифтер 3', name: '3222', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_WEIGHTLIFTER_4: { search: 'woman weightlifter 4|женщина веслифтер 4', name: '3223', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMAN_WEIGHTLIFTER_5: { search: 'woman weightlifter 5|женщина веслифтер 5', name: '3224', category: EMOJI_CATEGORIES.ACTIVITY },
  MEN_WRESTLING: { search: 'men wrestling|мужчины борьба', name: '1694', category: EMOJI_CATEGORIES.ACTIVITY },
  PEOPLE_WRESTLING: { search: 'people wrestling|люди борьба', name: '1951', category: EMOJI_CATEGORIES.ACTIVITY },
  WOMEN_WRESTLING: { search: 'women wrestling|женщины борьба', name: '3285', category: EMOJI_CATEGORIES.ACTIVITY },
  JOCKEY_ON_RACING_HORSE_0: { search: 'jockey on racing horse 0|jockey на гоночной лошади 0', name: '1151', category: EMOJI_CATEGORIES.ACTIVITY },
  JOCKEY_ON_RACING_HORSE_1: { search: 'jockey on racing horse 1|jockey на гоночной лошади 1', name: '1152', category: EMOJI_CATEGORIES.ACTIVITY },
  JOCKEY_ON_RACING_HORSE_2: { search: 'jockey on racing horse 2|jockey на гоночной лошади 2', name: '1153', category: EMOJI_CATEGORIES.ACTIVITY },
  JOCKEY_ON_RACING_HORSE_3: { search: 'jockey on racing horse 3|jockey на гоночной лошади 3', name: '1154', category: EMOJI_CATEGORIES.ACTIVITY },
  JOCKEY_ON_RACING_HORSE_4: { search: 'jockey on racing horse 4|jockey на гоночной лошади 4', name: '1155', category: EMOJI_CATEGORIES.ACTIVITY },
  JOCKEY_ON_RACING_HORSE_5: { search: 'jockey on racing horse 5|jockey на гоночной лошади 5', name: '1156', category: EMOJI_CATEGORIES.ACTIVITY },
  FENCER: { search: 'fencer|забор', name: '609', category: EMOJI_CATEGORIES.ACTIVITY },
  PARACHUTE: { search: 'parachute|парашют', name: '1915', category: EMOJI_CATEGORIES.ACTIVITY },
  SKIER: { search: 'skier|лыжник', name: '2457', category: EMOJI_CATEGORIES.ACTIVITY },
  SNOWBOARDER: { search: 'snowboarder|сноубордер', name: '2492', category: EMOJI_CATEGORIES.ACTIVITY },
  MICROPHONE: { search: 'microphone|микрофон', name: '1717', category: EMOJI_CATEGORIES.ACTIVITY },
  MARTIAL_ARTS_UNIFORM: { search: 'martial arts uniform|martial arts униформа', name: '1676', category: EMOJI_CATEGORIES.ACTIVITY },
  MUSICAL_KEYBOARD: { search: 'musical keyboard|музыкальный клавиатура', name: '1772', category: EMOJI_CATEGORIES.ACTIVITY },
  MUSICAL_SCORE: { search: 'musical score|музыкальный счет', name: '1775', category: EMOJI_CATEGORIES.ACTIVITY },
  PERFORMING_ARTS: { search: 'performing arts|исполнение искусств', name: '1952', category: EMOJI_CATEGORIES.ACTIVITY },
  PUZZLE_PIECE: { search: 'puzzle piece|головоломка часть', name: '2251', category: EMOJI_CATEGORIES.ACTIVITY },
  ROLLER_SKATE: { search: 'roller skate|роликовый коньк', name: '2339', category: EMOJI_CATEGORIES.ACTIVITY },
  RUNNING_SHIRT: { search: 'running shirt|беговая рубашка', name: '2352', category: EMOJI_CATEGORIES.ACTIVITY },
  SAXOPHONE: { search: 'saxophone|саксофон', name: '2372', category: EMOJI_CATEGORIES.ACTIVITY },
  SKATEBOARD: { search: 'skateboard|скейтборд', name: '2456', category: EMOJI_CATEGORIES.ACTIVITY },
  SKIS: { search: 'skis|лыжи', name: '2460', category: EMOJI_CATEGORIES.ACTIVITY },
  SLED: { search: 'sled|сед', name: '2464', category: EMOJI_CATEGORIES.ACTIVITY },
  SLOT_MACHINE: { search: 'slot machine|игровой автомат', name: '2470', category: EMOJI_CATEGORIES.ACTIVITY },
  TABLE_TENNIS_PADDLE_AND_BALL: { search: 'table tennis paddle and ball|настольный теннисный залы и мяч', name: '2603', category: EMOJI_CATEGORIES.ACTIVITY },
  ADMISSION_TICKET: { search: 'admission ticket|входной билет', name: '3', category: EMOJI_CATEGORIES.ACTIVITY },
  TICKET: { search: 'ticket|билет', name: '2655', category: EMOJI_CATEGORIES.ACTIVITY },
  TRUMPET: { search: 'trumpet|труба', name: '2688', category: EMOJI_CATEGORIES.ACTIVITY },
  VIDEO_GAME_CONTROLLER: { search: 'video game controller|видео игра контроллер', name: '2805', category: EMOJI_CATEGORIES.ACTIVITY },
  VIOLIN: { search: 'violin|скрипка', name: '2807', category: EMOJI_CATEGORIES.ACTIVITY },
  CAR: { search: 'car|автомобиль', name: '255', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  AMBULANCE: { search: 'ambulance|скорая помощь', name: '18', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ARTICULATED_LORRY: { search: 'articulated lorry|грузовик ', name: '32', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  AUTO_RICKSHAW: { search: 'auto rickshaw|авторикша', name: '49', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BUS: { search: 'bus|автобус', name: '234', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  DELIVERY_TRUCK: { search: 'delivery truck|доставка грузовик', name: '440', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FIRE_ENGINE: { search: 'fire engine|пожарный двигатель', name: '622', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MINIBUS: { search: 'minibus|микроавтобус', name: '1728', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ONCOMING_BUS: { search: 'oncoming bus|входящий автобус', name: '1868', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ONCOMING_CAR: { search: 'oncoming car|входящий автомобиль', name: '1869', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ONCOMING_POLICE_CAR: { search: 'oncoming police car|поступая в полицию автомобиль', name: '1870', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ONCOMING_TAXI: { search: 'oncoming taxi|входящее такси', name: '1871', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  PICKUP_TRUCK: { search: 'pickup truck|пикап грузовик', name: '2150', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  POLICE_CAR: { search: 'police car|полицейский автомобиль', name: '2189', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  POLICE_CAR_LIGHT: { search: 'police car’s light|свет полиции автомобиля', name: '2190', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  RACE_CAR: { search: 'race car|гоночный автомобиль', name: '2255', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SPORT_UTILITY_VEHICLE: { search: 'sport utility vehicle|спортивная утилита транспортного средства', name: '2533', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TAXI: { search: 'taxi|такси', name: '2610', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TRACTOR: { search: 'tractor|трактор', name: '2672', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  UFO: { search: 'UFO|нло', name: '2783', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  AIRPLANE_LANDING: { search: 'airplane landing|самолет посадки', name: '11', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  AIRPLANE: { search: 'airplane|самолет', name: '13', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  AIRPLANE_TAKING_OFF: { search: 'airplane taking off|самолет снимается', name: '12', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SMALL_AIRPLANE: { search: 'small airplane|небольшой самолет', name: '2472', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HELICOPTER: { search: 'helicopter|вертолет', name: '1056', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HIGH_SPEED_TRAIN: { search: 'high-speed train|скоростной поезд', name: '1064', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HIGH_SPEED_TRAIN_WITH_BULLET_NOSE: { search: 'high-speed train with bullet nose|скоростной поезд с пулевой нос', name: '1063', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MONORAIL: { search: 'monorail|монорельс', name: '1739', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOUNTAIN_RAILWAY: { search: 'mountain railway|горная железная дорога', name: '1757', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  LIGHT_RAIL: { search: 'light rail|светлый рельс', name: '1231', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  RAILWAY_CAR: { search: 'railway car|железнодорожный автомобиль', name: '2259', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  STATION: { search: 'station|станция', name: '2543', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  STEAM_LOCOMOTIVE: { search: 'steam locomotive|пар локомотив', name: '2546', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SUBWAY_TRAIN: { search: 'subway train|поезд метро', name: '2560', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SUSPENSION_RAILWAY: { search: 'suspension railway|подвесная железная дорога', name: '2593', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TRAIN: { search: 'train|поезд', name: '2674', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TRAM_CAR: { search: 'tram car|трамвайная машина', name: '2675', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TRAM: { search: 'tram|трамвай', name: '2676', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TROLLEYBUS: { search: 'trolleybus|троллейбус', name: '2684', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FERRY: { search: 'ferry|паром', name: '611', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOTORBOAT: { search: 'motorboat|моторная лодка', name: '1745', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  PASSENGER_SHIP: { search: 'passenger ship|пассажирский корабль', name: '1921', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SHIP: { search: 'ship|корабль', name: '2409', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SPEEDBOAT: { search: 'speedboat|скоростная лодка', name: '2526', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SAILBOAT: { search: 'sailboat|парусник', name: '2358', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ANCHOR: { search: 'anchor|якорь', name: '22', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HOOK: { search: 'hook|крюк', name: '1070', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  AERIAL_TRAMWAY: { search: 'aerial tramway|канатная дорога', name: '10', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOUNTAIN_CABLEWAY: { search: 'mountain cableway|горная канатная дорога', name: '1756', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BICYCLE: { search: 'bicycle|велосипед', name: '159', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MANUAL_WHEELCHAIR: { search: 'manual wheelchair|ручное кресло', name: '1674', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOTORIZED_WHEELCHAIR: { search: 'motorized wheelchair|моторизованный инвалидное кресло', name: '1747', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SCOOTER: { search: 'scooter|скутер', name: '2383', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOTOR_SCOOTER: { search: 'motor scooter|мотор скутер', name: '1744', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOTORCYCLE: { search: 'motorcycle|мотоцикл', name: '1746', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BANK: { search: 'bank|банк', name: '112', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CHURCH: { search: 'church|церковь', name: '294', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CLASSICAL_BUILDING: { search: 'classical building|музей', name: '311', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CONVENIENCE_STORE: { search: 'convenience store|круглосуточный магазин', name: '357', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  DEPARTMENT_STORE: { search: 'department store|магазин', name: '441', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  EUROPEAN_CASTLE: { search: 'European castle|европейский замок', name: '530', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  EUROPEAN_POST_OFFICE: { search: 'European post office|европейский почтовый офис', name: '531', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FACTORY: { search: 'factory|фабрика', name: '564', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HINDU_TEMPLE: { search: 'hindu temple|индийский храм', name: '1066', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HOSPITAL: { search: 'hospital|больница', name: '1074', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HOTEL: { search: 'hotel|отель', name: '1080', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HOUSE_WITH_GARDEN: { search: 'house with garden|дом с садом', name: '1084', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HUT: { search: 'hut|хижина', name: '1087', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  JAPANESE_CASTLE: { search: 'Japanese castle|японский замок', name: '1129', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  JAPANESE_POST_OFFICE: { search: 'Japanese post office|японский пост офис', name: '1131', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  KAABA: { search: 'Kaaba|кааба', name: '1166', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  LOVE_HOTEL: { search: 'love hotel|отель любви', name: '1245', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOSQUE: { search: 'mosque|мечеть', name: '1742', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  OFFICE_BUILDING: { search: 'office building|офисное здание', name: '1830', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SCHOOL: { search: 'school|школа', name: '2375', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SHINTO_SHRINE: { search: 'Shinto shrine|шинто святыня', name: '2408', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  STADIUM: { search: 'stadium|стадион', name: '2538', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SYNAGOGUE: { search: 'synagogue|синагога', name: '2601', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  WEDDING: { search: 'wedding|свадьба', name: '2835', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  DERELICT_HOUSE: { search: 'derelict house|нежный дом', name: '442', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HOUSE_BUILDING: { search: 'house building|дом здание', name: '1083', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  GROUP_OF_HOUSES: { search: 'group of houses|группа домов', name: '988', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  STATUE_OF_LIBERTY: { search: 'Statue of Liberty|статуя свободы', name: '2544', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TOKYO_TOWER: { search: 'Tokyo tower|токио башня', name: '2661', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  DESERT: { search: 'desert|пустыня', name: '444', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  DESERT_ISLAND: { search: 'desert island|остров пустыни', name: '443', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BEACH_UMBRELLA: { search: 'beach umbrella|пляжный зонт', name: '127', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BEACH_WITH_UMBRELLA: { search: 'beach with umbrella|пляж с зонтом', name: '128', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BRIDGE_AT_NIGHT: { search: 'bridge at night|ночной мост', name: '217', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BRIDGE_UNDER_FOG: { search: 'bridge under fog|мост в тумане', name: '218', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CITYSCAPE_AT_DUSK: { search: 'cityscape at dusk|городской пейзаж в сумерках', name: '300', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CITYSCAPE: { search: 'cityscape|городской пейзаж', name: '301', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FIREWORK_SPARKLER: { search: 'firework sparkler|фейерверк искристлер', name: '632', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FIREWORKS: { search: 'fireworks|фейерверк', name: '633', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MILKY_WAY: { search: 'Milky Way|молочный путь', name: '1727', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOON_VIEWING_CEREMONY: { search: 'moon viewing ceremony|церемония просмотра луны', name: '1740', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  NATIONAL_PARK: { search: 'national park|национальный парк', name: '1791', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  NIGHT_WITH_STARS: { search: 'night with stars|ночь со звездами', name: '1800', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SHOOTING_STAR: { search: 'shooting star|стрельба звезда', name: '2411', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SUNRISE_OVER_MOUNTAINS: { search: 'sunrise over mountains|восход солнца над горами', name: '2569', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SUNRISE: { search: 'sunrise|восход солнца', name: '2570', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SUNSET_OVER_BUILDINGS: { search: 'sunset over buildings|закат над зданиями', name: '2571', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SILHOUETTE_OF_JAPAN: { search: 'silhouette of Japan|силуэт японии', name: '2427', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  WORLD_MAP: { search: 'world map|карта мира', name: '3289', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BUILDING_CONSTRUCTION: { search: 'building construction|строительный кран', name: '230', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  HORIZONTAL_TRAFFIC_LIGHT: { search: 'horizontal traffic light|горизонтальный светофор', name: '1071', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  VERTICAL_TRAFFIC_LIGHT: { search: 'vertical traffic light|вертикальный светофор', name: '2802', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  BUS_STOP: { search: 'bus stop|автобусная остановка', name: '233', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CAMPING: { search: 'camping|кемпинг', name: '248', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  TENT: { search: 'tent|палатка', name: '2634', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CANOE: { search: 'canoe|каноэ', name: '253', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CAROUSEL_HORSE: { search: 'carousel horse|карусельная лошадь', name: '259', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  CONSTRUCTION_SIGN: { search: 'construction sign|строительный знак', name: '349', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  EASTER_ISLAND_STATUE: { search: 'Easter Island statue|статуя острова пасхи', name: '506', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FERRIS_WHEEL: { search: 'ferris wheel|колесо ferris', name: '610', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FOUNTAIN: { search: 'fountain|фонтан', name: '921', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  FUEL_PUMP: { search: 'fuel pump|топливный насос', name: '933', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  RAILWAY_TRACK: { search: 'railway track|железная дорога', name: '2260', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOTORWAY: { search: 'motorway|автострад', name: '1748', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOUNTAIN: { search: 'mountain|гора', name: '1758', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  MOUNT_FUJI: { search: 'mount fuji|mount fuji', name: '1749', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SNOW_CAPPED_MOUNTAIN: { search: 'snow capped mountain|снежная гора', name: '2491', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  VOLCANO: { search: 'volcano|вулкан', name: '2809', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ROCKET: { search: 'rocket|ракета', name: '2335', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ROLLER_COASTER: { search: 'roller coaster|роликовый берег', name: '2338', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SATELLITE: { search: 'satellite|спутник', name: '2371', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  SEAT: { search: 'seat|место', name: '2390', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  WHITE_CANE: { search: 'white cane|белый тростник', name: '2845', category: EMOJI_CATEGORIES.TRAVEL_AND_PLACES },
  ABACUS: { search: 'abacus | счеты', name: '0', category: EMOJI_CATEGORIES.OBJECTS },
  ADHESIVE_BANDAGE: { search: 'adhesive bandage|лейкопластырь', name: '2', category: EMOJI_CATEGORIES.OBJECTS },
  ALARM_CLOCK: { search: 'alarm clock|будильник', name: '14', category: EMOJI_CATEGORIES.OBJECTS },
  ALEMBIC: { search: 'alembic|перегонный куб', name: '15', category: EMOJI_CATEGORIES.OBJECTS },
  AMPHORA: { search: 'amphora|амфора', name: '20', category: EMOJI_CATEGORIES.OBJECTS },
  AXE: { search: 'axe|топор', name: '51', category: EMOJI_CATEGORIES.OBJECTS },
  BALLOON: { search: 'balloon|воздушный шар', name: '106', category: EMOJI_CATEGORIES.OBJECTS },
  BALLOT_BOX_WITH_BALLOT: { search: 'ballot box with ballot|урна с бюллетенями', name: '107', category: EMOJI_CATEGORIES.OBJECTS },
  BALLPOINT_PEN: { search: 'ballpoint pen|шариковая ручка', name: '109', category: EMOJI_CATEGORIES.OBJECTS },
  BARBER_POLE: { search: 'barber pole|парикмахерская', name: '114', category: EMOJI_CATEGORIES.OBJECTS },
  BAR_CHART: { search: 'bar chart|гистограмма', name: '113', category: EMOJI_CATEGORIES.OBJECTS },
  BASKET: { search: 'basket|корзина', name: '116', category: EMOJI_CATEGORIES.OBJECTS },
  BATHTUB: { search: 'bathtub|ванна', name: '125', category: EMOJI_CATEGORIES.OBJECTS },
  BATTERY: { search: 'battery|батарея', name: '126', category: EMOJI_CATEGORIES.OBJECTS },
  BED: { search: 'bed|кровать', name: '151', category: EMOJI_CATEGORIES.OBJECTS },
  BELLHOP_BELL: { search: 'bellhop bell|колокольчик', name: '157', category: EMOJI_CATEGORIES.OBJECTS },
  BLUE_TEXTBOOK: { search: 'blue textbook|синий учебник', name: '185', category: EMOJI_CATEGORIES.OBJECTS },
  BOMB: { search: 'bomb|бомба', name: '190', category: EMOJI_CATEGORIES.OBJECTS },
  BOOKMARK: { search: 'bookmark|закладка', name: '193', category: EMOJI_CATEGORIES.OBJECTS },
  BOOKMARK_TABS: { search: 'bookmark tabs|вкладки закладок', name: '192', category: EMOJI_CATEGORIES.OBJECTS },
  BOOKS: { search: 'books|книги', name: '194', category: EMOJI_CATEGORIES.OBJECTS },
  BRICKS: { search: 'bricks|кирпичи', name: '216', category: EMOJI_CATEGORIES.OBJECTS },
  BROOM: { search: 'broom|метла', name: '224', category: EMOJI_CATEGORIES.OBJECTS },
  BUCKET: { search: 'bucket|ведро', name: '228', category: EMOJI_CATEGORIES.OBJECTS },
  CALENDAR: { search: 'calendar|календарь', name: '238', category: EMOJI_CATEGORIES.OBJECTS },
  CAMERA: { search: 'camera|фото', name: '247', category: EMOJI_CATEGORIES.OBJECTS },
  CAMERA_WITH_FLASH: { search: 'camera with flash|камера со вспышкой', name: '246', category: EMOJI_CATEGORIES.OBJECTS },
  CANDLE: { search: 'candle|свеча', name: '250', category: EMOJI_CATEGORIES.OBJECTS },
  CARD_FILE_BOX: { search: 'card file box|ящик для картотеки', name: '256', category: EMOJI_CATEGORIES.OBJECTS },
  CARD_INDEX_DIVIDERS: { search: 'card index dividers|разделители картотеки', name: '257', category: EMOJI_CATEGORIES.OBJECTS },
  CARD_INDEX: { search: 'card index|индекс карты', name: '258', category: EMOJI_CATEGORIES.OBJECTS },
  CARP_STREAMER: { search: 'carp streamer|карп стример', name: '260', category: EMOJI_CATEGORIES.OBJECTS },
  CD: { search: 'cd|компакт-диск', name: '266', category: EMOJI_CATEGORIES.OBJECTS },
  CHAINS: { search: 'chains|цепи', name: '268', category: EMOJI_CATEGORIES.OBJECTS },
  CHAIR: { search: 'chair|стул', name: '269', category: EMOJI_CATEGORIES.OBJECTS },
  CHART_WITH_DOWNWARDS_TREND: { search: 'chart with downwards trend|график с понижением тренда', name: '270', category: EMOJI_CATEGORIES.OBJECTS },
  CHART_WITH_UPWARDS_TREND: { search: 'chart with upwards trend|график с трендом вверх', name: '272', category: EMOJI_CATEGORIES.OBJECTS },
  CIGARETTE: { search: 'cigarette|сигарета', name: '295', category: EMOJI_CATEGORIES.OBJECTS },
  CLAMP: { search: 'clamp|зажим', name: '303', category: EMOJI_CATEGORIES.OBJECTS },
  CLIPBOARD: { search: 'clipboard|буфер обмена', name: '314', category: EMOJI_CATEGORIES.OBJECTS },
  CLOSED_FILE_FOLDER: { search: 'closed file folder|Закрытая папка', name: '316', category: EMOJI_CATEGORIES.OBJECTS },
  CLOSED_LOCK: { search: 'closed lock|закрытый замок', name: '324', category: EMOJI_CATEGORIES.OBJECTS },
  CLOSED_LOCK_WITH_KEY: { search: 'closed lock with key|закрытый замок с ключом', name: '323', category: EMOJI_CATEGORIES.OBJECTS },
  CLOSED_MAILBOX_WITH_LOWERED_FLAG: { search: 'closed mailbox with lowered flag|закрытый почтовый ящик с опущенным флажком', name: '325', category: EMOJI_CATEGORIES.OBJECTS },
  CLOSED_MAILBOX_WITH_RAISED_FLAG: { search: 'closed mailbox with raised flag|закрытый почтовый ящик с поднятым флажком', name: '326', category: EMOJI_CATEGORIES.OBJECTS },
  COFFIN: { search: 'coffin|гроб', name: '339', category: EMOJI_CATEGORIES.OBJECTS },
  COIN: { search: 'coin|монета', name: '340', category: EMOJI_CATEGORIES.OBJECTS },
  COMPASS: { search: 'compass|компас', name: '343', category: EMOJI_CATEGORIES.OBJECTS },
  COMPOSITION_NOTEBOOK: { search: 'composition notebook|блокнот', name: '344', category: EMOJI_CATEGORIES.OBJECTS },
  COMPUTER_MOUSE: { search: 'computer mouse|компьютерная мышь', name: '345', category: EMOJI_CATEGORIES.OBJECTS },
  CONFETTI_BALL: { search: 'confetti ball|конфетти', name: '346', category: EMOJI_CATEGORIES.OBJECTS },
  CONTROL_KNOBS: { search: 'control knobs|ручки управления', name: '356', category: EMOJI_CATEGORIES.OBJECTS },
  COUCH_AND_LAMP: { search: 'couch and lamp|диван и лампа', name: '369', category: EMOJI_CATEGORIES.OBJECTS },
  CRAYON: { search: 'crayon|мелок', name: '387', category: EMOJI_CATEGORIES.OBJECTS },
  CREDIT_CARD: { search: 'credit card|кредитная карта', name: '388', category: EMOJI_CATEGORIES.OBJECTS },
  CROSSED_SWORDS: { search: 'crossed swords|скрещенные мечи', name: '396', category: EMOJI_CATEGORIES.OBJECTS },
  CRYSTAL_BALL: { search: 'crystal ball|хрустальный шар', name: '400', category: EMOJI_CATEGORIES.OBJECTS },
  DAGGER: { search: 'dagger|даггер', name: '416', category: EMOJI_CATEGORIES.OBJECTS },
  DESKTOP_COMPUTER: { search: 'desktop computer|настольный компьютер', name: '445', category: EMOJI_CATEGORIES.OBJECTS },
  DIYA_LAMP: { search: 'diya lamp|diya лампа', name: '460', category: EMOJI_CATEGORIES.OBJECTS },
  DNA: { search: 'DNA|днк', name: '463', category: EMOJI_CATEGORIES.OBJECTS },
  DOLLAR_BANKNOTES: { search: 'dollar banknotes|доллар банкноты', name: '467', category: EMOJI_CATEGORIES.OBJECTS },
  DOOR: { search: 'door|дверь', name: '470', category: EMOJI_CATEGORIES.OBJECTS },
  DROP_OF_BLOOD: { search: 'drop of blood|капля крови', name: '485', category: EMOJI_CATEGORIES.OBJECTS },
  DVD: { search: 'dvd|двд', name: '490', category: EMOJI_CATEGORIES.OBJECTS },
  ELECTRIC_PLUG: { search: 'electric plug|электрический разъем', name: '514', category: EMOJI_CATEGORIES.OBJECTS },
  EMAIL: { search: 'email|email', name: '525', category: EMOJI_CATEGORIES.OBJECTS },
  ENVELOPE: { search: 'envelope|конверт', name: '528', category: EMOJI_CATEGORIES.OBJECTS },
  ENVELOPE_WITH_ARROW: { search: 'envelope with arrow|конверт со стрелкой', name: '527', category: EMOJI_CATEGORIES.OBJECTS },
  EURO_BANKNOTES: { search: 'euro banknotes|евро банкноты', name: '529', category: EMOJI_CATEGORIES.OBJECTS },
  FAX: { search: 'fax|факс', name: '606', category: EMOJI_CATEGORIES.OBJECTS },
  FILE_CABINET: { search: 'file cabinet|файл кабинет', name: '613', category: EMOJI_CATEGORIES.OBJECTS },
  FILM_FRAMES: { search: 'film frames|кинорамы', name: '614', category: EMOJI_CATEGORIES.OBJECTS },
  FILM_PROJECTOR: { search: 'film projector|кинопроектор', name: '615', category: EMOJI_CATEGORIES.OBJECTS },
  FIRECRACKER: { search: 'firecracker|пожарный', name: '625', category: EMOJI_CATEGORIES.OBJECTS },
  FIRE_EXTINGUISHER: { search: 'fire extinguisher|огнетушитель', name: '623', category: EMOJI_CATEGORIES.OBJECTS },
  FLASHLIGHT: { search: 'flashlight|фонарик', name: '893', category: EMOJI_CATEGORIES.OBJECTS },
  FLOPPY_DISK: { search: 'floppy disk|floppy диск', name: '903', category: EMOJI_CATEGORIES.OBJECTS },
  FOUNTAIN_PEN: { search: 'fountain pen|фонтанная ручка', name: '920', category: EMOJI_CATEGORIES.OBJECTS },
  FRAME_WITH_PICTURE: { search: 'frame with picture|рамка с изображением', name: '926', category: EMOJI_CATEGORIES.OBJECTS },
  FUNERAL_URN: { search: 'funeral urn|похороны urn', name: '936', category: EMOJI_CATEGORIES.OBJECTS },
  GEAR: { search: 'gear|снаряжение', name: '939', category: EMOJI_CATEGORIES.OBJECTS },
  GEMSTONE: { search: 'gemstone|драгоценный камень', name: '941', category: EMOJI_CATEGORIES.OBJECTS },
  GREEN_TEXTBOOK: { search: 'green textbook|зеленый учебник', name: '980', category: EMOJI_CATEGORIES.OBJECTS },
  HAMMER_AND_PICK: { search: 'hammer and pick|молоток и выбрать', name: '1012', category: EMOJI_CATEGORIES.OBJECTS },
  HAMMER_AND_WRENCH: { search: 'hammer and wrench|молоток и wrench', name: '1013', category: EMOJI_CATEGORIES.OBJECTS },
  HAMMER: { search: 'hammer|молот', name: '1014', category: EMOJI_CATEGORIES.OBJECTS },
  HAND_SAW: { search: 'hand saw|рука увидела', name: '1016', category: EMOJI_CATEGORIES.OBJECTS },
  HOLE: { search: 'hole|отверстие', name: '1068', category: EMOJI_CATEGORIES.OBJECTS },
  HOURGLASS: { search: 'hourglass|часовое стекло', name: '1082', category: EMOJI_CATEGORIES.OBJECTS },
  HOURGLASS_WITH_FLOWING_SAND: { search: 'hourglass with flowing sand|часовое стекло с текущим песком', name: '1081', category: EMOJI_CATEGORIES.OBJECTS },
  INBOX_TRAY: { search: 'inbox tray|inbox лоток', name: '1094', category: EMOJI_CATEGORIES.OBJECTS },
  INCOMING_ENVELOPE: { search: 'incoming envelope|входящий конверт', name: '1095', category: EMOJI_CATEGORIES.OBJECTS },
  IZAKAYA_LANTERN: { search: 'izakaya lantern|izakaya фонарь', name: '1127', category: EMOJI_CATEGORIES.OBJECTS },
  JAPANESE_DOLLS: { search: 'Japanese dolls|японские куклы', name: '1130', category: EMOJI_CATEGORIES.OBJECTS },
  JOYSTICK: { search: 'joystick|радость', name: '1158', category: EMOJI_CATEGORIES.OBJECTS },
  KEYBOARD: { search: 'keyboard|клавиатура', name: '1169', category: EMOJI_CATEGORIES.OBJECTS },
  KEY: { search: 'key|ключ', name: '1168', category: EMOJI_CATEGORIES.OBJECTS },
  KNIFE: { search: 'knife|нож', name: '1190', category: EMOJI_CATEGORIES.OBJECTS },
  LABEL_TAG: { search: 'label tag|этикетка тег', name: '1194', category: EMOJI_CATEGORIES.OBJECTS },
  LADDER: { search: 'ladder|лестница', name: '1196', category: EMOJI_CATEGORIES.OBJECTS },
  LAPTOP: { search: 'laptop|ноутбук', name: '1198', category: EMOJI_CATEGORIES.OBJECTS },
  LEDGER: { search: 'ledger|ledger', name: '1206', category: EMOJI_CATEGORIES.OBJECTS },
  LEFT_POINTING_MAGNIFYING_GLASS: { search: 'left-pointing magnifying glass|левая точка увеличительное стекло', name: '1216', category: EMOJI_CATEGORIES.OBJECTS },
  LEVEL_SLIDER: { search: 'level slider|уровень слайдер', name: '1228', category: EMOJI_CATEGORIES.OBJECTS },
  LIGHT_BULB: { search: 'light bulb|лампа', name: '1230', category: EMOJI_CATEGORIES.OBJECTS },
  LINKED_PAPERCLIPS: { search: 'linked paperclips|связанных paperclips', name: '1234', category: EMOJI_CATEGORIES.OBJECTS },
  LINK_SYMBOL: { search: 'link symbol|ссылка символ', name: '1233', category: EMOJI_CATEGORIES.OBJECTS },
  LOCK_WITH_INK_PEN: { search: 'lock with ink pen|замок с ручкой чернил', name: '1240', category: EMOJI_CATEGORIES.OBJECTS },
  LOTION_BOTTLE: { search: 'lotion bottle|бутылка лосьона', name: '1243', category: EMOJI_CATEGORIES.OBJECTS },
  LOVE_LETTER: { search: 'love letter|любовное письмо', name: '1246', category: EMOJI_CATEGORIES.OBJECTS },
  MAGIC_WAND: { search: 'magic wand|волшебная палочка', name: '1257', category: EMOJI_CATEGORIES.OBJECTS },
  MAGNET: { search: 'magnet|магнит', name: '1258', category: EMOJI_CATEGORIES.OBJECTS },
  MANTELPIECE_CLOCK: { search: 'mantelpiece clock|часы mantelpiece', name: '1673', category: EMOJI_CATEGORIES.OBJECTS },
  MEMO: { search: 'memo|мемо', name: '1688', category: EMOJI_CATEGORIES.OBJECTS },
  MICROBE: { search: 'microbe|микроб', name: '1716', category: EMOJI_CATEGORIES.OBJECTS },
  MICROSCOPE: { search: 'microscope|микроскоп', name: '1718', category: EMOJI_CATEGORIES.OBJECTS },
  MINIDISC: { search: 'minidisc|мини диск', name: '1729', category: EMOJI_CATEGORIES.OBJECTS },
  MIRROR: { search: 'mirror|зеркало', name: '1731', category: EMOJI_CATEGORIES.OBJECTS },
  MOBILE_PHONE: { search: 'mobile phone|мобильный телефон', name: '1733', category: EMOJI_CATEGORIES.OBJECTS },
  MONEY_BAG: { search: 'money bag|деньги мешок', name: '1734', category: EMOJI_CATEGORIES.OBJECTS },
  MONEY_WITH_WINGS: { search: 'money with wings|деньги с крыльями', name: '1735', category: EMOJI_CATEGORIES.OBJECTS },
  MOUSE_TRAP: { search: 'mouse trap|мышь ловушка', name: '1760', category: EMOJI_CATEGORIES.OBJECTS },
  MOVIE_CAMERA: { search: 'movie camera|кинокамера', name: '1763', category: EMOJI_CATEGORIES.OBJECTS },
  NAZAR_AMULET: { search: 'nazar amulet|назар амулет', name: '1793', category: EMOJI_CATEGORIES.OBJECTS },
  NESTING_DOLLS: { search: 'nesting dolls|вложенные куклы', name: '1794', category: EMOJI_CATEGORIES.OBJECTS },
  NEWSPAPER: { search: 'newspaper|газета', name: '1799', category: EMOJI_CATEGORIES.OBJECTS },
  NOTEBOOK_WITH_DECORATIVE_COVER: { search: 'notebook with decorative cover|ноутбук с декоративной крышкой', name: '1824', category: EMOJI_CATEGORIES.OBJECTS },
  NUT_AND_BOLT: { search: 'nut and bolt|орех и болт', name: '1826', category: EMOJI_CATEGORIES.OBJECTS },
  OIL_DRUM: { search: 'oil drum|масляный барабан', name: '1838', category: EMOJI_CATEGORIES.OBJECTS },
  OLD_KEY: { search: 'old key|старый ключ', name: '1846', category: EMOJI_CATEGORIES.OBJECTS },
  OPEN_BOOK: { search: 'open book|открытая книга', name: '1876', category: EMOJI_CATEGORIES.OBJECTS },
  OPEN_FILE_FOLDER: { search: 'open file folder|открытая папка файлов', name: '1877', category: EMOJI_CATEGORIES.OBJECTS },
  OPEN_LOCK: { search: 'open lock|открытый замок', name: '1884', category: EMOJI_CATEGORIES.OBJECTS },
  OPEN_MAILBOX_WITH_LOWERED_FLAG: { search: 'open mailbox with lowered flag|открытый почтовый ящик с пониженным флагом', name: '1885', category: EMOJI_CATEGORIES.OBJECTS },
  OPEN_MAILBOX_WITH_RAISED_FLAG: { search: 'open mailbox with raised flag|открытый почтовый ящик с поднятым флагом', name: '1886', category: EMOJI_CATEGORIES.OBJECTS },
  ORANGE_TEXTBOOK: { search: 'orange textbook|оранжевый учебник', name: '1891', category: EMOJI_CATEGORIES.OBJECTS },
  OUTBOX_TRAY: { search: 'outbox tray|outbox лоток', name: '1895', category: EMOJI_CATEGORIES.OBJECTS },
  PACKAGE: { search: 'package|пакет', name: '1899', category: EMOJI_CATEGORIES.OBJECTS },
  PAGE_FACING_UP: { search: 'page facing up|страница наверх', name: '1900', category: EMOJI_CATEGORIES.OBJECTS },
  PAGER: { search: 'pager|страницаr', name: '1902', category: EMOJI_CATEGORIES.OBJECTS },
  PAGE_WITH_CURL: { search: 'page with curl|страница с curl', name: '1901', category: EMOJI_CATEGORIES.OBJECTS },
  PAINTBRUSH: { search: 'paintbrush|paintbrush', name: '1903', category: EMOJI_CATEGORIES.OBJECTS },
  PAPERCLIP: { search: 'paperclip|paperclip', name: '1914', category: EMOJI_CATEGORIES.OBJECTS },
  PARTY_POPPER: { search: 'party popper|вечеринка popper', name: '1920', category: EMOJI_CATEGORIES.OBJECTS },
  PENCIL: { search: 'pencil|карандаш', name: '1942', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_SLEEPING: { search: 'person sleeping|человек спит', name: '2086', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_TAKING_A_BATH_0: { search: 'person taking a bath 0|человек принимающий ванну 0', name: '2093', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_TAKING_A_BATH_1: { search: 'person taking a bath 1|человек принимающий ванну 1', name: '2094', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_TAKING_A_BATH_2: { search: 'person taking a bath 2|человек принимающий ванну 2', name: '2095', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_TAKING_A_BATH_3: { search: 'person taking a bath 3|человек принимающий ванну 3', name: '2096', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_TAKING_A_BATH_4: { search: 'person taking a bath 4|человек принимающий ванну 4', name: '2097', category: EMOJI_CATEGORIES.OBJECTS },
  PERSON_TAKING_A_BATH_5: { search: 'person taking a bath 5|человек принимающий ванну 5', name: '2098', category: EMOJI_CATEGORIES.OBJECTS },
  PETRI_DISH: { search: 'petri dish|petri блюдо', name: '2147', category: EMOJI_CATEGORIES.OBJECTS },
  PHONE_WITH_ARROW: { search: 'phone with arrow|телефон со стрелкой', name: '2148', category: EMOJI_CATEGORIES.OBJECTS },
  PICK: { search: 'pick|выбрать', name: '2149', category: EMOJI_CATEGORIES.OBJECTS },
  PILL: { search: 'pill|таблетки', name: '2156', category: EMOJI_CATEGORIES.OBJECTS },
  PINATA: { search: 'piñata|пиньята', name: '2163', category: EMOJI_CATEGORIES.OBJECTS },
  PLUNGER: { search: 'plunger|плунгер', name: '2186', category: EMOJI_CATEGORIES.OBJECTS },
  POSTAL_HORN: { search: 'postal horn|почтовый рог', name: '2212', category: EMOJI_CATEGORIES.OBJECTS },
  POSTBOX: { search: 'postbox|почтовый ящик', name: '2213', category: EMOJI_CATEGORIES.OBJECTS },
  POUND_BANKNOTES: { search: 'pound banknotes|фунт банкноты', name: '2219', category: EMOJI_CATEGORIES.OBJECTS },
  PRAYER_BEADS: { search: 'prayer beads|молитвенные бусины', name: '2221', category: EMOJI_CATEGORIES.OBJECTS },
  PRESENT: { search: 'present|подарок', name: '2228', category: EMOJI_CATEGORIES.OBJECTS },
  PRINTER: { search: 'printer|принтер', name: '2242', category: EMOJI_CATEGORIES.OBJECTS },
  PUSHPIN: { search: 'pushpin|pushpin', name: '2249', category: EMOJI_CATEGORIES.OBJECTS },
  RADIO: { search: 'radio|радио', name: '2257', category: EMOJI_CATEGORIES.OBJECTS },
  RAZOR: { search: 'razor|разор', name: '2283', category: EMOJI_CATEGORIES.OBJECTS },
  RECEIPT: { search: 'receipt|квитанция', name: '2284', category: EMOJI_CATEGORIES.OBJECTS },
  RED_GIFT_ENVELOPE: { search: 'red gift envelope|красный подарок конверт', name: '2293', category: EMOJI_CATEGORIES.OBJECTS },
  RED_TEXTBOOK: { search: 'red textbook|красный учебник', name: '2298', category: EMOJI_CATEGORIES.OBJECTS },
  RIBBON_BOW: { search: 'ribbon bow|лента bow', name: '2310', category: EMOJI_CATEGORIES.OBJECTS },
  RIGHT_POINTING_MAGNIFYING_GLASS: { search: 'right-pointing magnifying glass|правообладающее стекло', name: '2323', category: EMOJI_CATEGORIES.OBJECTS },
  ROLLED_UP_NEWSPAPER: { search: 'rolled-up newspaper|откатная газета', name: '2337', category: EMOJI_CATEGORIES.OBJECTS },
  ROLL_OF_PAPER: { search: 'roll of paper|рулон бумаги', name: '2336', category: EMOJI_CATEGORIES.OBJECTS },
  ROUND_PUSHPIN: { search: 'round pushpin|раунд pushpin', name: '2344', category: EMOJI_CATEGORIES.OBJECTS },
  SAFETY_PIN: { search: 'safety pin|безопасности pin', name: '2355', category: EMOJI_CATEGORIES.OBJECTS },
  SATELLITE_ANTENNA: { search: 'satellite antenna|спутниковая антенна', name: '2370', category: EMOJI_CATEGORIES.OBJECTS },
  SCALES: { search: 'scales|весы', name: '2373', category: EMOJI_CATEGORIES.OBJECTS },
  SCISSORS: { search: 'scissors|ножницы', name: '2382', category: EMOJI_CATEGORIES.OBJECTS },
  SCREWDRIVER: { search: 'screwdriver|винтовая', name: '2386', category: EMOJI_CATEGORIES.OBJECTS },
  SCROLL: { search: 'scroll|свиток', name: '2387', category: EMOJI_CATEGORIES.OBJECTS },
  SHIELD: { search: 'shield|щит', name: '2407', category: EMOJI_CATEGORIES.OBJECTS },
  SHOPPING_BAGS: { search: 'shopping bags|сумки для покупок', name: '2412', category: EMOJI_CATEGORIES.OBJECTS },
  SHOPPING_CART: { search: 'shopping cart|корзина', name: '2413', category: EMOJI_CATEGORIES.OBJECTS },
  SHOWER: { search: 'shower|душ', name: '2416', category: EMOJI_CATEGORIES.OBJECTS },
  SIGN: { search: 'sign|знак', name: '2426', category: EMOJI_CATEGORIES.OBJECTS },
  SOAP: { search: 'soap|мыло', name: '2496', category: EMOJI_CATEGORIES.OBJECTS },
  SPIRAL_CALENDAR: { search: 'spiral calendar|спиральный календарь', name: '2529', category: EMOJI_CATEGORIES.OBJECTS },
  SPIRAL_NOTEPAD: { search: 'spiral notepad|spiral блокнот', name: '2530', category: EMOJI_CATEGORIES.OBJECTS },
  SPONGE: { search: 'sponge|губка', name: '2531', category: EMOJI_CATEGORIES.OBJECTS },
  STETHOSCOPE: { search: 'stethoscope|стетоскоп', name: '2548', category: EMOJI_CATEGORIES.OBJECTS },
  STOPWATCH: { search: 'stopwatch|секундомер', name: '2550', category: EMOJI_CATEGORIES.OBJECTS },
  STRAIGHT_RULER: { search: 'straight ruler|прямой правитель', name: '2551', category: EMOJI_CATEGORIES.OBJECTS },
  STUDIO_MICROPHONE: { search: 'studio microphone|студия микрофона', name: '2559', category: EMOJI_CATEGORIES.OBJECTS },
  SYRINGE: { search: 'syringe|шприц', name: '2602', category: EMOJI_CATEGORIES.OBJECTS },
  TEAR_OFF_CALENDAR: { search: 'tear-off calendar|календарь слез', name: '2619', category: EMOJI_CATEGORIES.OBJECTS },
  TEDDY_BEAR: { search: 'teddy bear|teddy медведь', name: '2626', category: EMOJI_CATEGORIES.OBJECTS },
  TELEPHONE: { search: 'telephone|телефон', name: '2628', category: EMOJI_CATEGORIES.OBJECTS },
  TELEPHONE_RECEIVER: { search: 'telephone receiver|телефонный приемник', name: '2627', category: EMOJI_CATEGORIES.OBJECTS },
  TELESCOPE: { search: 'telescope|телескоп', name: '2629', category: EMOJI_CATEGORIES.OBJECTS },
  TELEVISION: { search: 'television|телевизор', name: '2630', category: EMOJI_CATEGORIES.OBJECTS },
  TEST_TUBE: { search: 'test tube|тест туба', name: '2636', category: EMOJI_CATEGORIES.OBJECTS },
  THERMOMETER: { search: 'thermometer|термометр', name: '2637', category: EMOJI_CATEGORIES.OBJECTS },
  TIMER_CLOCK: { search: 'timer clock|таймер часы', name: '2658', category: EMOJI_CATEGORIES.OBJECTS },
  TOILET: { search: 'toilet|туалет', name: '2660', category: EMOJI_CATEGORIES.OBJECTS },
  TOMBSTONE: { search: 'tombstone|камни', name: '2663', category: EMOJI_CATEGORIES.OBJECTS },
  TOOLBOX: { search: 'toolbox|инструмент', name: '2665', category: EMOJI_CATEGORIES.OBJECTS },
  TOOTHBRUSH: { search: 'toothbrush|зубная щетка', name: '2667', category: EMOJI_CATEGORIES.OBJECTS },
  TRACKBALL: { search: 'trackball|трекбол', name: '2671', category: EMOJI_CATEGORIES.OBJECTS },
  TRIANGULAR_RULER: { search: 'triangular ruler|треугольный правитель', name: '2682', category: EMOJI_CATEGORIES.OBJECTS },
  VIDEO_CAMERA: { search: 'video camera|видеокамера', name: '2804', category: EMOJI_CATEGORIES.OBJECTS },
  VIDEOCASSETTE: { search: 'videocassette|видеокассет', name: '2806', category: EMOJI_CATEGORIES.OBJECTS },
  WASTEBASKET: { search: 'wastebasket|отходы', name: '2816', category: EMOJI_CATEGORIES.OBJECTS },
  WATCH: { search: 'watch|смотреть', name: '2817', category: EMOJI_CATEGORIES.OBJECTS },
  WATER_FAUCET: { search: 'water faucet|кран воды', name: '2821', category: EMOJI_CATEGORIES.OBJECTS },
  WATER_PISTOL: { search: 'water pistol|пистолет для воды', name: '2822', category: EMOJI_CATEGORIES.OBJECTS },
  WIND_CHIME: { search: 'wind chime|ветер chime', name: '2859', category: EMOJI_CATEGORIES.OBJECTS },
  WINDOW: { search: 'window|окно', name: '2860', category: EMOJI_CATEGORIES.OBJECTS },
  WRENCH: { search: 'wrench|воренч', name: '3292', category: EMOJI_CATEGORIES.OBJECTS },
  YEN_BANKNOTES: { search: 'yen banknotes|иен банкноты', name: '3305', category: EMOJI_CATEGORIES.OBJECTS },
  BLACK_HEART: { search: 'black heart|черное сердце', name: '170', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLUE_HEART: { search: 'blue heart|синее сердце', name: '183', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BROWN_HEART: { search: 'brown heart|коричневое сердце', name: '226', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  GREEN_HEART: { search: 'green heart|зеленое сердце', name: '977', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ORANGE_HEART: { search: 'orange heart|оранжевое сердце', name: '1889', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PURPLE_HEART: { search: 'purple heart|фиолетовое сердце', name: '2246', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_HEART: { search: 'red heart|красное сердце', name: '2295', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_HEART: { search: 'white heart|белое сердце', name: '2850', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  YELLOW_HEART: { search: 'yellow heart|желтое сердце', name: '3303', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_HEART_AS_AN_EXCLAMATION_MARK: { search: 'red heart as an exclamation mark|красное сердце как знак возбуждения', name: '2294', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HEART_WITH_ARROW: { search: 'heart with arrow|сердце со стрелкой', name: '1050', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HEART_WITH_RIBBON: { search: 'heart with ribbon|сердце с лентой', name: '1051', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  REVOLVING_HEARTS: { search: 'revolving hearts|вращающиеся сердца', name: '2307', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPARKLING_HEART: { search: 'sparkling heart|игристое сердце', name: '2519', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TWO_HEARTS: { search: 'two hearts|два сердца', name: '2695', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BEATING_HEART: { search: 'beating heart|бьющееся сердце', name: '149', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  GROWING_HEART: { search: 'growing heart|растущее сердце', name: '989', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HEART_ON_FIRE: { search: 'heart on fire|сердце на огне', name: '1049', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BROKEN_HEART: { search: 'broken heart|разбитое сердце', name: '222', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MENDING_HEART: { search: 'mending heart|гнуть сердце', name: '1695', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLOOD_TYPE_AB: { search: 'blood type AB|группа крови АВ', name: '177', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLOOD_TYPE_A: { search: 'blood type A|группа крови А', name: '176', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLOOD_TYPE_B: { search: 'blood type B|группа крови В', name: '178', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLOOD_TYPE_O: { search: 'blood type O|группа крови О', name: '179', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CL_SIGN: { search: 'CL sign|знак CL', name: '302', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_BARGAIN: { search: 'Japanese sign meaning “bargain”|японский знак означает баргаин', name: '1134', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_CONGRATULATIONS: { search: 'Japanese sign meaning “congratulations”|японский знак означает поздравления', name: '1135', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_DISCOUNT: { search: 'Japanese sign meaning “discount”|японский знак означает скидка', name: '1136', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_FULL_NO_VACANCY: { search: 'Japanese sign meaning “full; no vacancy”|японский знак означает полный нет вакансии', name: '1138', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_PASSING_GRADE: { search: 'Japanese sign meaning “passing (grade)”|японский знак означает проход класс', name: '1142', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_PROHIBITED: { search: 'Japanese sign meaning “prohibited”|японский знак означает запрещается', name: '1143', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_SECRET: { search: 'Japanese sign meaning “secret”|японский знак означает секрет', name: '1145', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SOS_SIGN: { search: 'SOS sign|sos знак', name: '2514', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_ENTRY: { search: 'no entry|без входа', name: '1810', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NAME_BADGE: { search: 'name badge|имя бейдж', name: '1790', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  OCTAGONAL_SIGN: { search: 'octagonal sign|octagonal знак', name: '1827', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_TRIANGLE_POINTED_DOWN: { search: 'red triangle pointed down|красный треугольник указал вниз', name: '2299', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_TRIANGLE_POINTED_UP: { search: 'red triangle pointed up|красный треугольник указал вверх', name: '2300', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_DOUBLE_EXCLAMATION_MARK: { search: 'red double exclamation mark|красная двойная рассветительная марка', name: '2290', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_EXCLAMATION_MARK_AND_QUESTION_MARK: { search: 'red exclamation mark and question mark|красный знак возбуждения и знак вопроса', name: '2291', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_EXCLAMATION_MARK: { search: 'red exclamation mark|красная знак возбуждения', name: '2292', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_QUESTION_MARK: { search: 'red question mark|красный знак вопроса', name: '2296', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CIRCLE_MARK: { search: 'circle mark|круглая отметка', name: '297', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ANGER_SYMBOL: { search: 'anger symbol|символ гнева', name: '23', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PROHIBITED: { search: 'prohibited|запрещено', name: '2243', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CROSS_MARK: { search: 'cross mark|крестик', name: '394', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_QUESTION_MARK: { search: 'white question mark|белый знак вопроса', name: '2854', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_EXCLAMATION_MARK: { search: 'white exclamation mark|белый знак возбуждения', name: '2847', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HOT_SPRINGS: { search: 'hot springs|горячие источники', name: '1079', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HUNDRED_POINTS_SYMBOL: { search: 'hundred points symbol|символ 100 пунктов', name: '1086', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_BICYCLES: { search: 'no bicycles|без велосипедов', name: '1809', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_LITTERING: { search: 'no littering|нет помета', name: '1812', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_MOBILE_PHONES: { search: 'no mobile phones|нет мобильных телефонов', name: '1813', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NON_POTABLE_WATER: { search: 'non-potable water|непотребляемая вода', name: '1817', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_ONE_UNDER_EIGHTEEN: { search: 'no one under eighteen|никто под восемнадцать', name: '1814', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_PEDESTRIANS: { search: 'no pedestrians|без пешеходов', name: '1815', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_SMOKING_SIGN: { search: 'no smoking sign|не курить знак', name: '1816', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_FLOWER: { search: 'white flower|белый цветок', name: '2849', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  IDENTIFICATION_SIGN: { search: 'identification sign|идентификационный знак', name: '1093', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HEART_DECORATION: { search: 'heart decoration|украшение сердца', name: '1048', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  OM_SYMBOL: { search: 'om symbol|ом символ', name: '1866', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ORTHODOX_CROSS: { search: 'Orthodox cross|православный крест', name: '1893', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHEEL_OF_DHARMA: { search: 'wheel of dharma|колесо dharma', name: '2843', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  YIN_YANG: { search: 'yin yang|иин ян', name: '3306', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WOMEN_SYMBOL: { search: 'women’s symbol|женский символ', name: '3286', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SIX_POINTED_STAR_WITH_MIDDLE_DOT: { search: 'six pointed star with middle dot|шесть звезд с средней точки', name: '2454', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  STAR_AND_CRESCENT: { search: 'star and crescent|звезда и crescent', name: '2540', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  STAR_OF_DAVID: { search: 'Star of David|звезда давида', name: '2541', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TAURUS: { search: 'Taurus|таурус', name: '2609', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PEACE_SYMBOL: { search: 'peace symbol|символ мира', name: '1931', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PLACE_OF_WORSHIP: { search: 'place of worship|место поклонения', name: '2182', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  OPHIUCHUS: { search: 'Ophiuchus|офиукус', name: '1887', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MENORAH: { search: 'menorah|менора', name: '1696', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LATIN_CROSS: { search: 'Latin cross|латинский крест', name: '1203', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LEO: { search: 'Leo|лео', name: '1226', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  VIRGO: { search: 'Virgo|дева', name: '2808', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LIBRA: { search: 'Libra|весы', name: '1229', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  AQUARIUS: { search: 'Aquarius|Водолей', name: '30', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ARIES: { search: 'Aries|Овен', name: '31', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PISCES: { search: 'Pisces|рыбы', name: '2179', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SAGITTARIUS: { search: 'Sagittarius|стрелец', name: '2357', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SCORPIO: { search: 'Scorpio|скорпион', name: '2384', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CANCER: { search: 'Cancer|Рак', name: '249', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CAPRICORN: { search: 'Capricorn|Козерог', name: '254', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  GEMINI: { search: 'Gemini|близнецы', name: '940', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ATOM_SYMBOL: { search: 'atom symbol|символ атома', name: '48', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_APPLICATION: { search: 'Japanese sign meaning “application”|японский знак означает применение', name: '1133', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_ACCEPTABLE: { search: 'Japanese sign meaning “acceptable”|японский знак означает приемлемый', name: '1132', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_FREE_OF_CHARGE: { search: 'Japanese sign meaning “free of charge”|японский знак означает бесплатно', name: '1137', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_MONTHLY_AMOUNT: { search: 'Japanese sign meaning “monthly amount”|японский знак означает ежемесячная сумма', name: '1139', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_NOT_FREE_OF_CHARGE: { search: 'Japanese sign meaning “not free of charge”|японский знак означает не бесплатно', name: '1140', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_OPEN_FOR_BUSINESS: { search: 'Japanese sign meaning “open for business”|японский знак означает открытый для бизнеса', name: '1141', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  VIBRATION_MODE: { search: 'vibration mode|режим вибрации', name: '2803', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SQUARED_VS: { search: 'squared vs|квадрат vs', name: '2536', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MOBILE_PHONE_OFF: { search: 'mobile phone off|мобильный телефон выключен', name: '1732', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BIOHAZARD: { search: 'biohazard|биологическая опасность', name: '163', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RADIOACTIVE: { search: 'radioactive|радиоактивный', name: '2258', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  EIGHT_POINTED_STAR: { search: 'eight pointed star|восемь острых звезд', name: '510', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ATM_SIGN: { search: 'ATM sign|Знак банкомата', name: '47', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BAGGAGE_CLAIM: { search: 'baggage claim|получение багажа', name: '103', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SLEEPING_SIGN: { search: 'sleeping sign|спальный знак', name: '2466', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CIRCLED_M: { search: 'circled M|M в кругу', name: '298', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CUSTOMS: { search: 'customs|таможни', name: '408', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CYCLONE: { search: 'cyclone|циклон', name: '415', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DIAMOND_SHAPE_WITH_A_DOT_INSIDE: { search: 'diamond shape with a dot inside|алмазная форма с точки зрения внутри', name: '452', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  GLOBE_WITH_MERIDIANS: { search: 'globe with meridians|глобус с меридианами', name: '956', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ELEVATOR: { search: 'elevator|лифт', name: '516', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_SERVICE_OR_SERVICE_CHARGE: { search: 'Japanese sign meaning “service” or “service charge”|японский знак означает обслуживание или обслуживание', name: '1146', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_VACANCY: { search: 'Japanese sign meaning “vacancy”|японский знак означает вакансии', name: '1147', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LEFT_LUGGAGE: { search: 'left luggage|левый багаж', name: '1209', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MEN_SYMBOL: { search: 'men’s symbol|мужской символ', name: '1697', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PARKING_SIGN: { search: 'parking sign|знак парковки', name: '1916', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PASSPORT_CONTROL: { search: 'passport control|паспортный контроль', name: '1922', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WATER_CLOSET: { search: 'water closet|вода шкаф', name: '2819', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHEELCHAIR_SYMBOL: { search: 'wheelchair symbol|символ инвалидного кресла', name: '2844', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CHART_WITH_UPWARDS_TREND_AND_YEN_SIGN: { search: 'chart with upwards trend and yen sign|график с трендом вверх и йен знак', name: '271', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CHECK_MARK_SYMBOL: { search: 'check mark symbol|галочка', name: '273', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  EIGHT_SPOKED_ASTERISK: { search: 'eight spoked asterisk|восемь разговаривали asterisk', name: '511', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SIGN_MEANING_RESERVED: { search: 'Japanese sign meaning “reserved”|японский знак означает забронированный', name: '1144', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_SYMBOL_FOR_BEGINNER: { search: 'Japanese symbol for beginner|японский символ для начинающих', name: '1148', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RECYCLING_SYMBOL: { search: 'recycling symbol|символ переработки', name: '2286', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPARKLE: { search: 'sparkle|искрение', name: '2517', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  X_MARK: { search: 'X mark|x марка', name: '3299', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BABY_SYMBOL: { search: 'baby symbol|детский символ', name: '66', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CHILDREN_CROSSING: { search: 'children crossing|детский переход', name: '289', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLEUR_DE_LIS: { search: 'fleur-de-lis|fleur de lis', name: '896', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TRIDENT_EMBLEM: { search: 'trident emblem|тридент эмблема', name: '2683', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WARNING: { search: 'warning|предупреждение', name: '2815', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LOW_BRIGHTNESS_SYMBOL: { search: 'low brightness symbol|низкий символ яркости', name: '1253', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HIGH_BRIGHTNESS_SYMBOL: { search: 'high brightness symbol|символ высокой яркости', name: '1061', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PART_ALTERNATION_MARK: { search: 'part alternation mark|часть метка изменения', name: '1918', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CELLULAR_SIGNAL_BARS: { search: 'cellular signal bars|полосы сигнала сотовой связи', name: '267', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CINEMA_SYMBOL: { search: 'cinema|кинотеатр', name: '296', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CLOCKWISE_VERTICAL_ARROWS: { search: 'clockwise vertical arrows|вертикальные стрелки по часовой стрелке', name: '315', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  COOL_SIGN: { search: 'cool sign|крутой знак', name: '367', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  COUNTERCLOCKWISE_ARROWS: { search: 'counterclockwise arrows|стрелки против часовой стрелки', name: '370', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOWN_ARROW: { search: 'down arrow|вниз стрелка', name: '474', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOWN_LEFT_ARROW: { search: 'down-left arrow|вниз левая стрелка', name: '476', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOWN_POINTING_DOUBLE_TRIANGLE: { search: 'down-pointing double triangle|вниз точка двойной треугольник', name: '477', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOWN_POINTING_TRIANGLE: { search: 'down-pointing triangle|первый треугольник', name: '478', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOWN_RIGHT_ARROW: { search: 'down-right arrow|вниз правая стрелка', name: '479', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  EJECT_SYMBOL: { search: 'eject symbol|eject символ', name: '513', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FAST_FORWARD_SYMBOL: { search: 'fast forward symbol|быстрый вперед символ', name: '605', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FREE_SIGN: { search: 'free sign|бесплатный знак', name: '927', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INFORMATION_SOURCE: { search: 'information source|источник информации', name: '1121', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INPUT_SYMBOL_FOR_LETTERS: { search: 'input symbol for letters|входной символ для букв', name: '1122', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INPUT_SYMBOL_FOR_LOWERCASE_LETTERS: { search: 'input symbol for lowercase letters|входной символ для более низких букв', name: '1123', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INPUT_SYMBOL_FOR_NUMBERS: { search: 'input symbol for numbers|входной символ для цифр', name: '1124', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INPUT_SYMBOL_FOR_SYMBOLS: { search: 'input symbol for symbols|входной символ для символов', name: '1125', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INPUT_SYMBOL_FOR_UPPERCASE_LETTERS: { search: 'input symbol for uppercase letters|входной символ для верхних букв', name: '1126', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JAPANESE_WORD_SIGN_MEANING_HERE: { search: 'Japanese word sign meaning “here”|японский знак слова здесь', name: '1149', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_ASTERISK: { search: 'keycap asterisk|keycap астериск', name: '1170', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_ZERO: { search: 'keycap digit zero|keycap цифра ноль', name: '1180', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_ONE: { search: 'keycap digit one|keycap цифра один', name: '1175', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_TWO: { search: 'keycap digit two|keycap цифра два', name: '1179', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_THREE: { search: 'keycap digit three|keycap цифра три', name: '1178', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_FOUR: { search: 'keycap digit four|keycap цифра четыре', name: '1173', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_FIVE: { search: 'keycap digit five|keycap цифра пять', name: '1172', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_SIX: { search: 'keycap digit six|keycap цифра шесть', name: '1177', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_SEVEN: { search: 'keycap digit seven|keycap цифра семь', name: '1176', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_EIGHT: { search: 'keycap digit eight|keycap цифра восемь', name: '1171', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_DIGIT_NINE: { search: 'keycap digit nine|keycap цифра девять', name: '1174', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  KEYCAP_TEN: { search: 'keycap ten|keycap 10', name: '1181', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LEFT_ARROW_CURVING_RIGHT: { search: 'left arrow curving right|левая стрелка curving right', name: '1207', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LEFT_ARROW: { search: 'left arrow|левая стрелка', name: '1208', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LEFT_POINTING_TRIANGLE: { search: 'left-pointing triangle|левый треугольник', name: '1217', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LEFT_RIGHT_ARROW: { search: 'left-right arrow|левая стрелка', name: '1218', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NEW_SIGN: { search: 'new sign|новый знак', name: '1798', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NO_GOOD_SIGN: { search: 'no good sign|нет хорошего знака', name: '1811', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NUMBER_SIGN: { search: 'number sign|номер знак', name: '1825', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  OK_SIGN: { search: 'OK sign|ok знак', name: '1845', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PAUSE_SYMBOL: { search: 'pause symbol|пауза символ', name: '1923', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PLAY_PAUSE_SYMBOL: { search: 'play pause symbol|играть пауза символ', name: '2184', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PUT_LITTER_IN_ITS_PLACE_SYMBOL: { search: 'put litter in its place symbol|поставить помет в его месте символ', name: '2250', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RECORD_SYMBOL: { search: 'record symbol|рекордный символ', name: '2285', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  REPEAT_SINGLE_TRACK_SYMBOL: { search: 'repeat single track symbol|повторить символ одиночного трека', name: '2304', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  REPEAT_SYMBOL: { search: 'repeat symbol|повторить символ', name: '2305', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RESTROOM: { search: 'restroom|комната отдыха', name: '2306', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  REWIND_SYMBOL: { search: 'rewind symbol|rewind символ', name: '2308', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RIGHT_ARROW_CURVING_DOWN: { search: 'right arrow curving down|правая стрелка curving down', name: '2313', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RIGHT_ARROW_CURVING_LEFT: { search: 'right arrow curving left|правая стрелка curving left', name: '2314', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RIGHT_ARROW_CURVING_UP: { search: 'right arrow curving up|правая стрелка curving up', name: '2315', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RIGHT_ARROW: { search: 'right arrow|правая стрелка', name: '2316', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RIGHT_POINTING_TRIANGLE: { search: 'right-pointing triangle|правый треугольник', name: '2324', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SHUFFLE_TRACKS_SYMBOL: { search: 'shuffle tracks symbol|shuffle tracks символ', name: '2418', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SKIP_BACKWARD_SYMBOL: { search: 'skip backward symbol|пропустить назад символ', name: '2458', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SKIP_FORWARD_SYMBOL: { search: 'skip forward symbol|пропустить символ', name: '2459', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  STOP_SYMBOL: { search: 'stop symbol|остановить символ', name: '2549', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TRANSGENDER_SYMBOL: { search: 'transgender symbol|трансгендерный символ', name: '2678', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_ARROW: { search: 'up arrow|вверх по стрелке', name: '2788', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_DOWN_ARROW: { search: 'up-down arrow|вверх вниз стрелки', name: '2790', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_LEFT_ARROW: { search: 'up-left arrow|вверх левая стрелка', name: '2791', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_POINTING_DOUBLE_TRIANGLE: { search: 'up-pointing double triangle|up pointing двойной треугольник', name: '2792', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_POINTING_TRIANGLE: { search: 'up-pointing triangle|up pointing треугольник', name: '2793', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_RIGHT_ARROW: { search: 'up-right arrow|вверх правая стрелка', name: '2794', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  UP_SIGN: { search: 'up sign|зарегистрироваться', name: '2789', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MUSICAL_NOTE: { search: 'musical note|музыкальная нота', name: '1773', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MUSICAL_NOTES: { search: 'musical notes|музыкальные ноты', name: '1774', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BACK_ARROW: { search: 'back arrow|стрелка назад', name: '67', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  END_ARROW: { search: 'end arrow|конец стрелка', name: '526', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  COPYRIGHT_SIGN: { search: 'copyright sign|знак авторского права', name: '368', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CURRENCY_EXCHANGE: { search: 'currency exchange|обмен валют', name: '405', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DIVISION_SYMBOL: { search: 'division symbol|символ разделения', name: '459', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MULTIPLICATION_SYMBOL: { search: 'multiplication symbol|символ умножения', name: '1770', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MINUS_SYMBOL: { search: 'minus symbol|символ минус', name: '1730', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PLUS_SYMBOL: { search: 'plus symbol|плюс символ', name: '2187', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOLLAR_SIGN: { search: 'dollar sign|знак доллара', name: '468', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WAVY_DASH: { search: 'wavy dash|wavy даш', name: '2831', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CURLY_LOOP: { search: 'curly loop|curly цикл', name: '404', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DOUBLE_CURLY_LOOP: { search: 'double curly loop|двойной curly цикл', name: '471', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  INFINITY: { search: 'infinity|бесконечность', name: '1102', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  I_AM_A_WITNESS: { search: 'I am a witness|я свидетель', name: '1088', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HIDDENGLYPH: { search: 'hiddenglyph|скрытыйglyph', name: '1060', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ON_ARROW: { search: 'on! arrow|на стрелка', name: '1867', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RADIO_BUTTON: { search: 'radio button|радио кнопка', name: '2256', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  REGISTERED_SIGN: { search: 'registered sign|зарегистрированный знак', name: '2301', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SOON_ARROW: { search: 'soon arrow|скоро стрелка', name: '2501', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TOP_ARROW: { search: 'top arrow|верхняя стрелка', name: '2668', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TRADE_MARK_SIGN: { search: 'trade mark sign|знак торговой марки', name: '2673', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BALLOT_BOX_WITH_CHECK: { search: 'ballot box with check|галочка', name: '108', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CHECK_MARK: { search: 'check mark|галочка', name: '274', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_CIRCLE: { search: 'black circle|черный круг', name: '168', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLUE_CIRCLE: { search: 'blue circle|синий круг', name: '182', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BROWN_CIRCLE: { search: 'brown circle|коричневый круг', name: '225', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  GREEN_CIRCLE: { search: 'green circle|зеленый круг', name: '976', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ORANGE_CIRCLE: { search: 'orange circle|оранжевый круг', name: '1888', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PURPLE_CIRCLE: { search: 'purple circle|фиолетовый круг', name: '2245', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_CIRCLE: { search: 'red circle|красный круг', name: '2289', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_CIRCLE: { search: 'white circle|белый круг', name: '2846', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  YELLOW_CIRCLE: { search: 'yellow circle|желтый круг', name: '3302', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_LARGE_SQUARE: { search: 'black large square|черный большой квадрат', name: '171', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLUE_SQUARE: { search: 'blue square|синий квадрат', name: '184', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BROWN_SQUARE: { search: 'brown square|коричневая квадрат', name: '227', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  GREEN_SQUARE: { search: 'green square|зеленая площадь', name: '979', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ORANGE_SQUARE: { search: 'orange square|оранжевая площадь', name: '1890', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PURPLE_SQUARE: { search: 'purple square|фиолетовая площадь', name: '2247', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RED_SQUARE: { search: 'red square|красная площадь', name: '2297', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_LARGE_SQUARE: { search: 'white large square|белая большая площадь', name: '2851', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  YELLOW_SQUARE: { search: 'yellow square|желтая площадь', name: '3304', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_MEDIUM_SMALL_SQUARE: { search: 'black medium small square|черный небольшой квадрат', name: '172', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_MEDIUM_SQUARE: { search: 'black medium square|черный средний квадрат', name: '173', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_SMALL_SQUARE: { search: 'black small square|черный маленький квадрат', name: '174', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_SQUARE_BUTTON: { search: 'black square button|черная квадратная кнопка', name: '175', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_MEDIUM_SMALL_SQUARE: { search: 'white medium small square|белая средняя небольшая площадь', name: '2852', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_MEDIUM_SQUARE: { search: 'white medium square|белая средняя площадь', name: '2853', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_SMALL_SQUARE: { search: 'white small square|белая небольшая площадь', name: '2855', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_SQUARE_BUTTON: { search: 'white square button|белая квадратная кнопка', name: '2856', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LARGE_BLUE_DIAMOND: { search: 'large blue diamond|большой синий бриллиант', name: '1199', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  LARGE_ORANGE_DIAMOND: { search: 'large orange diamond|большой оранжевый бриллиант', name: '1200', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SMALL_BLUE_DIAMOND: { search: 'small blue diamond|небольшой голубой бриллиант', name: '2473', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SMALL_ORANGE_DIAMOND: { search: 'small orange diamond|небольшой оранжевый бриллиант', name: '2474', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  U1F3FB: { search: 'u1F3FB|у 1 ф 3 фб', name: '2701', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  U1F3FC: { search: 'u1F3FC|у 1 ф 3 фк', name: '2702', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  U1F3FD: { search: 'u1F3FD|у 1 ф 3 фд', name: '2703', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  U1F3FE: { search: 'u1F3FE|u 1 f 3 fe', name: '2704', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  U1F3FF: { search: 'u1F3FF|у 1 ф 3 ф', name: '2705', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  U1F5E8: { search: 'u1F5E8|у 1 ф 5 э 8', name: '2754', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPEAKER: { search: 'speaker|спикер', name: '2522', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPEAKER_WITH_HIGH_VOLUME: { search: 'speaker with high volume|спикер с высоким объемом', name: '2520', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPEAKER_WITH_MEDIUM_VOLUME: { search: 'speaker with medium volume|спикер с средним объемом', name: '2521', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MUTED_SPEAKER: { search: 'muted speaker|смутный оратор', name: '1777', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CHEERING_MEGAPHONE: { search: 'megaphone|мегафон', name: '275', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PUBLIC_ADDRESS_LOUDSPEAKER: { search: 'public address loudspeaker|публичный адрес loudspeaker', name: '2244', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BELL: { search: 'bell|колокол', name: '156', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MUTED_BELL: { search: 'muted bell|грязи колокол', name: '1776', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  THOUGHT_BALLOON: { search: 'thought balloon|мысль шар', name: '2639', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPEECH_BALLOON: { search: 'speech balloon|речь шар', name: '2525', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ANGRY_SPEECH_BUBBLE: { search: 'angry speech bubble|сердитый речи пузырь', name: '26', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CLUBS_SUIT: { search: 'clubs|трефы', name: '334', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  DIAMONDS_SUIT: { search: 'diamonds suit|алмазы костюм', name: '453', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  HEARTS_SUIT: { search: 'hearts suit|костюм сердца', name: '1052', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SPADES_SUIT: { search: 'spades suit|spades костюм', name: '2515', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLOWER_PLAYING_CARDS: { search: 'flower playing cards|цветочные игральные карты', name: '904', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  JOKER: { search: 'joker|шутер', name: '1157', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  MAHJONG_TILE_RED_DRAGON: { search: 'mahjong tile red dragon|mahjong черепица красный дракон', name: '1259', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  EIGHT_THIRTY: { search: 'eight-thirty|восьмитысяча', name: '512', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  EIGHT_OF_CLOCK: { search: 'eight o’clock|восемь часов', name: '509', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ELEVEN_OF_CLOCK: { search: 'eleven o’clock|одиннадцать часов', name: '517', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ELEVEN_THIRTY: { search: 'eleven-thirty|одиннадцать тридцать', name: '518', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FIVE_OF_CLOCK: { search: 'five o’clock|5 часов', name: '639', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FIVE_THIRTY: { search: 'five-thirty|пять тридцать', name: '640', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FOUR_OF_CLOCK: { search: 'four o’clock|четыре часа', name: '923', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FOUR_THIRTY: { search: 'four-thirty|четыредцать', name: '924', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NINE_OF_CLOCK: { search: 'nine o’clock|девять часов', name: '1801', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  NINE_THIRTY: { search: 'nine-thirty|девять тридцать', name: '1802', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ONE_OF_CLOCK: { search: 'one o’clock|один час', name: '1872', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  ONE_THIRTY: { search: 'one-thirty|one тридцать', name: '1874', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SEVEN_OF_CLOCK: { search: 'seven o’clock|7 часов', name: '2400', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SEVEN_THIRTY: { search: 'seven-thirty|семитысячность', name: '2401', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SIX_OF_CLOCK: { search: 'six o’clock|шесть часов', name: '2453', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  SIX_THIRTY: { search: 'six-thirty|шесть тридцать', name: '2455', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TEN_OF_CLOCK: { search: 'ten o’clock|10 часов', name: '2631', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TEN_THIRTY: { search: 'ten-thirty|десять тридцать', name: '2635', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  THREE_OF_CLOCK: { search: 'three o’clock|три часа', name: '2641', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  THREE_THIRTY: { search: 'three-thirty|тридцать', name: '2642', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TWELVE_OF_CLOCK: { search: 'twelve o’clock|двенадцать часов', name: '2693', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TWELVE_THIRTY: { search: 'twelve-thirty|двенадцать тридцать', name: '2694', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TWO_OF_CLOCK: { search: 'two o’clock|два часа', name: '2697', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TWO_THIRTY: { search: 'two-thirty|две тысячи', name: '2700', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  PIRATE_FLAG: { search: 'pirate flag|пиратский флаг', name: '2178', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  BLACK_FLAG: { search: 'black flag|черный флаг', name: '169', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  RAINBOW_FLAG: { search: 'rainbow flag|флаг радуги', name: '2261', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TRANSGENDER_FLAG: { search: 'transgender flag|трансгендерный флаг', name: '2677', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  WHITE_FLAG: { search: 'white flag|белый флаг', name: '2848', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  TRIANGULAR_FLAG_ON_POST: { search: 'triangular flag on post|треугольный флаг на пост', name: '2681', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CHEQUERED_FLAG: { search: 'chequered flag|клетчатый флаг', name: '277', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  CROSSED_FLAGS: { search: 'crossed flags|скрещенные флаги', name: '395', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_AFGHANISTAN: { search: 'flag of Afghanistan|флаг афганистана', name: '641', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ALBANIA: { search: 'flag of Albania|флаг албании', name: '642', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ALGERIA: { search: 'flag of Algeria|флаг алжира', name: '643', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_AMERICAN_SAMOA: { search: 'flag of American Samoa|флаг американского самоа', name: '644', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ANDORRA: { search: 'flag of Andorra|флаг андорры', name: '645', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ANGOLA: { search: 'flag of Angola|флаг анголы', name: '646', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ANGUILLA: { search: 'flag of Anguilla|флаг ангильи', name: '647', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ANTARCTICA: { search: 'flag of Antarctica|флаг антарктида', name: '648', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ANTIGUA_AND_BARBUDA: { search: 'flag of Antigua & Barbuda|флаг антигуа и барбуда', name: '649', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ARGENTINA: { search: 'flag of Argentina|флаг аргентины', name: '650', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ARMENIA: { search: 'flag of Armenia|флаг армении', name: '651', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ARUBA: { search: 'flag of Aruba|флаг арубы', name: '652', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_AUSTRALIA: { search: 'flag of Australia|флаг австралии', name: '653', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_AUSTRIA: { search: 'flag of Austria|флаг австрии', name: '654', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_AZERBAIJAN: { search: 'flag of Azerbaijan|флаг азербайджана', name: '655', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BAHAMAS: { search: 'flag of Bahamas|флаг багамы', name: '656', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BAHRAIN: { search: 'flag of Bahrain|флаг бахрейна', name: '657', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BANGLADESH: { search: 'flag of Bangladesh|флаг бангладеш', name: '658', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BARBADOS: { search: 'flag of Barbados|флаг барбадоса', name: '659', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BELARUS: { search: 'flag of Belarus|флаг беларуси', name: '660', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BELGIUM: { search: 'flag of Belgium|флаг бельгии', name: '661', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BELIZE: { search: 'flag of Belize|флаг белиза', name: '662', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BENIN: { search: 'flag of Benin|флаг бенина', name: '663', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BERMUDA: { search: 'flag of Bermuda|флаг бермуды', name: '664', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BHUTAN: { search: 'flag of Bhutan|флаг бутана', name: '665', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BOLIVIA: { search: 'flag of Bolivia|флаг боливии', name: '666', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BONAIRE: { search: 'flag of Bonaire|флаг bonaire', name: '667', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BOSNIA_AND_HERZEGOVINA: { search: 'flag of Bosnia & Herzegovina|флаг боснии и герцеговины', name: '668', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BOTSWANA: { search: 'flag of Botswana|флаг ботсваны', name: '669', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BRAZIL: { search: 'flag of Brazil|флаг бразилии', name: '670', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BRUNEI: { search: 'flag of Brunei|флаг бруней', name: '671', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BULGARIA: { search: 'flag of Bulgaria|флаг болгарии', name: '672', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BURKINA_FASO: { search: 'flag of Burkina Faso|флаг буркина фасо', name: '673', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_BURUNDI: { search: 'flag of Burundi|флаг бурунди', name: '674', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CAMBODIA: { search: 'flag of Cambodia|флаг камбоджи', name: '675', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CAMEROON: { search: 'flag of Cameroon|флаг камеруна', name: '676', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CANADA: { search: 'flag of Canada|флаг канады', name: '677', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CAPE_VERDE: { search: 'flag of Cape Verde|флаг cape verde', name: '678', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CHAD: { search: 'flag of Chad|флаг чада', name: '679', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CHILE: { search: 'flag of Chile|флаг чили', name: '680', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CHINA: { search: 'flag of China|флаг китая', name: '681', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CHRISTMAS_ISLAND: { search: 'flag of Christmas Island|флаг рождественского острова', name: '682', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_COLOMBIA: { search: 'flag of Colombia|флаг колумбии', name: '684', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_COMOROS: { search: 'flag of Comoros|флаг comoros', name: '685', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_COSTA_RICA: { search: 'flag of Costa Rica|флаг коста рики', name: '686', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_COTE_D_IVOIRE: { search: 'flag of Cote d’Ivoire|флаг кот дивуар', name: '683', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CROATIA: { search: 'flag of Croatia|флаг хорватии', name: '687', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CUBA: { search: 'flag of Cuba|флаг кубы', name: '688', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CURACAO: { search: 'flag of Curacao|флаг кюрасао', name: '689', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CYPRUS: { search: 'flag of Cyprus|флаг кипра', name: '690', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_CZECHIA: { search: 'flag of Czechia|флаг чехии', name: '691', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_DENMARK: { search: 'flag of Denmark|флаг дании', name: '692', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_DJIBOUTI: { search: 'flag of Djibouti|флаг джибути', name: '693', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_DOMINICA: { search: 'flag of Dominica|флаг доминики', name: '694', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ECUADOR: { search: 'flag of Ecuador|флаг эквадора', name: '695', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_EGYPT: { search: 'flag of Egypt|флаг египта', name: '696', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_EL_SALVADOR: { search: 'flag of El Salvador|флаг сальвадора', name: '697', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ENGLAND: { search: 'flag of England|флаг англии', name: '698', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_EQUATORIAL_GUINEA: { search: 'flag of Equatorial Guinea|флаг экваториальной гвинее', name: '699', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ERITREA: { search: 'flag of Eritrea|флаг эритрея', name: '700', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ESTONIA: { search: 'flag of Estonia|флаг эстонии', name: '701', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ESWATINI: { search: 'flag of Eswatini|флаг eswatini', name: '702', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ETHIOPIA: { search: 'flag of Ethiopia|флаг эфиопии', name: '703', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_EUROPEAN_UNION: { search: 'flag of European Union|флаг европейского союза', name: '704', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_FIJI: { search: 'flag of Fiji|флаг фиджи', name: '705', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_FINLAND: { search: 'flag of Finland|флаг финляндии', name: '706', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_FRANCE: { search: 'flag of France|флаг франции', name: '707', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_FRENCH_GUIANA: { search: 'flag of French Guiana|флаг французской guiana', name: '708', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_FRENCH_POLYNESIA: { search: 'flag of French Polynesia|флаг французской полинезии', name: '709', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_FRENCH_SOUTHERN_TERRITORIES: { search: 'flag of French Southern Territories|флаг французских южных территорий', name: '710', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GABON: { search: 'flag of Gabon|флаг габона', name: '711', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GAMBIA: { search: 'flag of Gambia|флаг гамбии', name: '712', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GEORGIA: { search: 'flag of Georgia|флаг грузии', name: '713', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GERMANY: { search: 'flag of Germany|флаг германии', name: '714', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GHANA: { search: 'flag of Ghana|флаг ганы', name: '715', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GIBRALTAR: { search: 'flag of Gibraltar|флаг гибралтара', name: '716', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GREECE: { search: 'flag of Greece|флаг греции', name: '717', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GREENLAND: { search: 'flag of Greenland|флаг гренландии', name: '718', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GRENADA: { search: 'flag of Grenada|флаг гренады', name: '719', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUADELOUPE: { search: 'flag of Guadeloupe|флаг guadeloupe', name: '720', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUAM: { search: 'flag of Guam|флаг гуама', name: '721', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUATEMALA: { search: 'flag of Guatemala|флаг гватемалы', name: '722', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUERNSEY: { search: 'flag of Guernsey|флаг гернси', name: '723', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUINEA_BISSAU: { search: 'flag of Guinea-Bissau|флаг гвинее бисау', name: '725', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUINEA: { search: 'flag of Guinea|флаг гвинее', name: '724', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_GUYANA: { search: 'flag of Guyana|флаг гайаны', name: '726', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_HAITI: { search: 'flag of Haiti|флаг гаити', name: '727', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_HONDURAS: { search: 'flag of Honduras|флаг гондураса', name: '728', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_HONG_KONG: { search: 'flag of Hong Kong|флаг гонконга', name: '729', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_HUNGARY: { search: 'flag of Hungary|флаг венгрии', name: '730', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ICELAND: { search: 'flag of Iceland|флаг исландии', name: '731', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_INDIA: { search: 'flag of India|флаг индии', name: '732', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_INDONESIA: { search: 'flag of Indonesia|флаг индонезии', name: '733', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_IRAN: { search: 'flag of Iran|флаг ирана', name: '734', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_IRAQ: { search: 'flag of Iraq|флаг ирака', name: '735', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_IRELAND: { search: 'flag of Ireland|флаг ирландии', name: '736', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ISRAEL: { search: 'flag of Israel|флаг израиля', name: '737', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ITALY: { search: 'flag of Italy|флаг италии', name: '738', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_JAMAICA: { search: 'flag of Jamaica|флаг ямайки', name: '739', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_JAPAN: { search: 'flag of Japan|флаг японии', name: '740', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_JERSEY: { search: 'flag of Jersey|флаг джерси', name: '741', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_JORDAN: { search: 'flag of Jordan|флаг иордании', name: '742', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_KAZAKHSTAN: { search: 'flag of Kazakhstan|флаг казахстана', name: '743', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_KENYA: { search: 'flag of Kenya|флаг кении', name: '744', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_KIRIBATI: { search: 'flag of Kiribati|флаг кирибати', name: '745', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_KOSOVO: { search: 'flag of Kosovo|флаг косово', name: '746', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_KUWAIT: { search: 'flag of Kuwait|флаг кувейта', name: '747', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_KYRGYZSTAN: { search: 'flag of Kyrgyzstan|флаг кыргызстана', name: '748', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LAOS: { search: 'flag of Laos|флаг лаоса', name: '749', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LATVIA: { search: 'flag of Latvia|флаг латвии', name: '750', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LEBANON: { search: 'flag of Lebanon|флаг ливана', name: '751', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LESOTHO: { search: 'flag of Lesotho|флаг лесото', name: '752', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LIBERIA: { search: 'flag of Liberia|флаг либерии', name: '753', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LIBYA: { search: 'flag of Libya|флаг ливии', name: '754', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LIECHTENSTEIN: { search: 'flag of Liechtenstein|флаг лихтенштейна', name: '755', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LITHUANIA: { search: 'flag of Lithuania|флаг литвы', name: '756', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_LUXEMBOURG: { search: 'flag of Luxembourg|флаг люксембурга', name: '757', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MACAO: { search: 'flag of Macao|флаг макао', name: '758', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MADAGASCAR: { search: 'flag of Madagascar|флаг мадагаскара', name: '759', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MALAWI: { search: 'flag of Malawi|флаг малави', name: '760', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MALAYSIA: { search: 'flag of Malaysia|флаг малайзии', name: '761', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MALDIVES: { search: 'flag of Maldives|флаг мальдивы', name: '762', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MALI: { search: 'flag of Mali|флаг мали', name: '763', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MALTA: { search: 'flag of Malta|флаг мальты', name: '764', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MARTINIQUE: { search: 'flag of Martinique|флаг мартиника', name: '765', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MAURITANIA: { search: 'flag of Mauritania|флаг мавритании', name: '766', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MAURITIUS: { search: 'flag of Mauritius|флаг маврикий', name: '767', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MAYOTTE: { search: 'flag of Mayotte|флаг mayotte', name: '768', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MEXICO: { search: 'flag of Mexico|флаг мексики', name: '769', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MICRONESIA: { search: 'flag of Micronesia|флаг micronesia', name: '770', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MOLDOVA: { search: 'flag of Moldova|флаг молдовы', name: '771', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MONACO: { search: 'flag of Monaco|флаг монако', name: '772', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MONGOLIA: { search: 'flag of Mongolia|флаг монголии', name: '773', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MONTENEGRO: { search: 'flag of Montenegro|флаг черногории', name: '774', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MONTSERRAT: { search: 'flag of Montserrat|флаг монсеррат', name: '775', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MOROCCO: { search: 'flag of Morocco|флаг марокко', name: '776', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MOZAMBIQUE: { search: 'flag of Mozambique|флаг мозамбика', name: '777', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_MYANMAR_BURMA: { search: 'flag of Myanmar (Burma)|флаг мьянмы burma', name: '778', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NAMIBIA: { search: 'flag of Namibia|флаг намибии', name: '779', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NAURU: { search: 'flag of Nauru|флаг науру', name: '780', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NEPAL: { search: 'flag of Nepal|флаг непала', name: '781', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NETHERLANDS: { search: 'flag of Netherlands|флаг нидерландов', name: '782', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NEW_CALEDONIA: { search: 'flag of New Caledonia|флаг новой каледонии', name: '783', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NEW_ZEALAND: { search: 'flag of New Zealand|флаг новой зеландии', name: '784', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NICARAGUA: { search: 'flag of Nicaragua|флаг никарагуа', name: '785', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NIGERIA: { search: 'flag of Nigeria|флаг нигерии', name: '787', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NIGER: { search: 'flag of Niger|флаг нигера', name: '786', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NIUE: { search: 'flag of Niue|флаг ниуэ', name: '788', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NORFOLK_ISLAND: { search: 'flag of Norfolk Island|флаг острова норфолк', name: '789', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NORTH_KOREA: { search: 'flag of North Korea|флаг северной кореи', name: '790', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NORTH_MACEDONIA: { search: 'flag of North Macedonia|флаг северной македонии', name: '791', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_NORWAY: { search: 'flag of Norway|флаг норвегии', name: '792', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_OMAN: { search: 'flag of Oman|флаг омана', name: '793', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PAKISTAN: { search: 'flag of Pakistan|флаг пакистана', name: '794', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PALAU: { search: 'flag of Palau|флаг palau', name: '795', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PANAMA: { search: 'flag of Panama|флаг панамы', name: '796', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PAPUA_NEW_GUINEA: { search: 'flag of Papua New Guinea|флаг папуа новая гвинея', name: '797', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PARAGUAY: { search: 'flag of Paraguay|флаг парагвая', name: '798', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PERU: { search: 'flag of Peru|флаг перу', name: '799', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PHILIPPINES: { search: 'flag of Philippines|флаг филиппин', name: '800', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PITCAIRN: { search: 'flag of Pitcairn|флаг pitcairn', name: '801', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_POLAND: { search: 'flag of Poland|флаг польши', name: '802', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PORTUGAL: { search: 'flag of Portugal|флаг португалии', name: '803', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_PUERTO_RICO: { search: 'flag of Puerto Rico|флаг пуэрто рико', name: '804', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_QATAR: { search: 'flag of Qatar|флаг катара', name: '805', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_REUNION: { search: 'flag of Reunion|флаг reunion', name: '806', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ROMANIA: { search: 'flag of Romania|флаг румынии', name: '807', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_RUSSIA: { search: 'flag of Russia|флаг россии', name: '808', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_RWANDA: { search: 'flag of Rwanda|флаг руанда', name: '809', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAINT_BARTHELEMY: { search: 'flag of Saint Barthelemy|флаг святой бартелии', name: '811', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAINT_HELENA: { search: 'flag of Saint Helena|флаг святой елены', name: '812', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAINT_KITTS_AND_NEVIS: { search: 'flag of Saint Kitts & Nevis|флаг сент китс и невис', name: '813', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAINT_LUCIA: { search: 'flag of Saint Lucia|флаг сент люсия', name: '814', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAINT_PIERRE_AND_MIQUELON: { search: 'flag of Saint Pierre & Miquelon|флаг saint pierre miquelon', name: '815', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAINT_VINCENT_AND_GRENADINES: { search: 'flag of Saint Vincent & Grenadines|флаг сент винсент и гренадины', name: '816', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAMOA: { search: 'flag of Samoa|флаг самоа', name: '817', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAN_MARINO: { search: 'flag of San Marino|флаг сан марино', name: '818', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAO_TOME_AND_PRINCIPE: { search: 'flag of Sao Tome & Principe|флаг sao tome principe', name: '810', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SAUDI_ARABIA: { search: 'flag of Saudi Arabia|флаг саудовской аравии', name: '819', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SCOTLAND: { search: 'flag of Scotland|флаг шотландии', name: '820', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SENEGAL: { search: 'flag of Senegal|флаг сенегала', name: '821', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SERBIA: { search: 'flag of Serbia|флаг сербии', name: '822', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SEYCHELLES: { search: 'flag of Seychelles|флаг сейшельских островов', name: '823', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SIERRA_LEONE: { search: 'flag of Sierra Leone|флаг сьерра леоне', name: '824', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SINGAPORE: { search: 'flag of Singapore|флаг сингапура', name: '825', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SINT_MAARTEN: { search: 'flag of Sint Maarten|флаг sint maarten', name: '826', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SLOVAKIA: { search: 'flag of Slovakia|флаг словакии', name: '827', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SLOVENIA: { search: 'flag of Slovenia|флаг словении', name: '828', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SOMALIA: { search: 'flag of Somalia|флаг сомали', name: '829', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SOUTH_AFRICA: { search: 'flag of South Africa|флаг южной африки', name: '830', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: { search: 'flag of South Georgia & the South Sandwich Islands|флаг южной грузии и южной сэндвич ов', name: '831', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SOUTH_KOREA: { search: 'flag of South Korea|флаг южной кореи', name: '832', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SOUTH_SUDAN: { search: 'flag of South Sudan|флаг южного судана', name: '833', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SPAIN: { search: 'flag of Spain|флаг испании', name: '834', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SRI_LANKA: { search: 'flag of Sri Lanka|флаг шри ланки', name: '835', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SUDAN: { search: 'flag of Sudan|флаг судана', name: '836', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SURINAME: { search: 'flag of Suriname|флаг суринам', name: '837', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SWEDEN: { search: 'flag of Sweden|флаг швеции', name: '838', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SWITZERLAND: { search: 'flag of Switzerland|флаг швейцарии', name: '839', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_SYRIA: { search: 'flag of Syria|флаг сирии', name: '840', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TAIWAN: { search: 'flag of Taiwan|флаг тайваня', name: '841', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TAJIKISTAN: { search: 'flag of Tajikistan|флаг таджикистана', name: '842', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TANZANIA: { search: 'flag of Tanzania|флаг танзании', name: '843', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THAILAND: { search: 'flag of Thailand|флаг таиланда', name: '844', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_ALAND_ISLANDS: { search: 'flag of the Aland Islands|флаг острова', name: '845', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_BRITISH_INDIAN_OCEAN_TERRITORY: { search: 'flag of the British Indian Ocean Territory|флаг территории британского индийского океана', name: '846', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_BRITISH_VIRGIN_ISLANDS: { search: 'flag of the British Virgin Islands|флаг британских виргинских островов', name: '847', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_CANARY_ISLANDS: { search: 'flag of the Canary Islands|флаг канарских островов', name: '848', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_CAYMAN_ISLANDS: { search: 'flag of the Cayman Islands|флаг кайманских островов', name: '849', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_CENTRAL_AFRICAN_REPUBLIC: { search: 'flag of the Central African Republic|флаг центрально африканской республики', name: '850', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_COCOS_KEELING_ISLANDS: { search: 'flag of the Cocos (Keeling) Islands|флаг острова кокос keeling', name: '851', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_COOK_ISLANDS: { search: 'flag of the Cook Islands|флаг острова кука', name: '852', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_DEMOCRATIC_REPUBLIC_OF_THE_CONGO: { search: 'flag of the Democratic Republic of the Congo|флаг демократической республики конго', name: '853', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_DOMINICAN_REPUBLIC: { search: 'flag of the Dominican Republic|флаг доминиканской республики', name: '854', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_FALKLAND_ISLANDS: { search: 'flag of the Falkland Islands|флаг фолклендских островов', name: '855', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_FAROE_ISLANDS: { search: 'flag of the Faroe Islands|флаг фарерских островов', name: '856', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_ISLE_OF_MAN: { search: 'flag of the Isle of Man|флаг острова человека', name: '857', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_MARSHALL_ISLANDS: { search: 'flag of the Marshall Islands|флаг маршалловых островов', name: '858', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_NORTHERN_MARIANA_ISLANDS: { search: 'flag of the Northern Mariana Islands|флаг северных марианских островов', name: '859', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_PALESTINIAN_TERRITORIES: { search: 'flag of the Palestinian Territories|флаг палестинских территорий', name: '860', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_REPUBLIC_OF_THE_CONGO: { search: 'flag of the Republic of the Congo|флаг республики конго', name: '861', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_SOLOMON_ISLANDS: { search: 'flag of the Solomon Islands|флаг соломоновских островов', name: '862', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_TURKS_AND_CAICOS_ISLANDS: { search: 'flag of the Turks & Caicos Islands|флаг тюркских и кайкосовых островов', name: '863', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_UNITED_ARAB_EMIRATES: { search: 'flag of the United Arab Emirates|флаг объединенных арабских эмиратов', name: '864', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_UNITED_KINGDOM: { search: 'flag of the United Kingdom|флаг великобритании', name: '865', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_UNITED_NATIONS: { search: 'flag of the United Nations|флаг организации объединенных наций', name: '866', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_UNITED_STATES: { search: 'flag of the United States|флаг сша', name: '867', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_THE_US_VIRGIN_ISLANDS: { search: 'flag of the US Virgin Islands|флаг виргинских островов сша', name: '868', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TIMOR_LESTE: { search: 'flag of Timor-Leste|флаг тимора восточный', name: '869', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TOGO: { search: 'flag of Togo|флаг togo', name: '870', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TOKELAU: { search: 'flag of Tokelau|флаг токелау', name: '871', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TONGA: { search: 'flag of Tonga|флаг тонги', name: '872', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TRINIDAD_AND_TOBAGO: { search: 'flag of Trinidad & Tobago|флаг тринидада и тобаго', name: '873', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TUNISIA: { search: 'flag of Tunisia|флаг туниса', name: '874', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TURKEY: { search: 'flag of Turkey|флаг турции', name: '875', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TURKMENISTAN: { search: 'flag of Turkmenistan|флаг туркменистана', name: '876', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_TUVALU: { search: 'flag of Tuvalu|флаг тувалу', name: '877', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_UGANDA: { search: 'flag of Uganda|флаг уганды', name: '878', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_UKRAINE: { search: 'flag of Ukraine|флаг украины', name: '879', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_URUGUAY: { search: 'flag of Uruguay|флаг уругвая', name: '880', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_UZBEKISTAN: { search: 'flag of Uzbekistan|флаг узбекистана', name: '881', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_VANUATU: { search: 'flag of Vanuatu|флаг вануату', name: '882', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_VATICAN_CITY: { search: 'flag of Vatican City|флаг города ватикана', name: '883', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_VENEZUELA: { search: 'flag of Venezuela|флаг венесуэлы', name: '884', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_VIETNAM: { search: 'flag of Vietnam|флаг вьетнама', name: '885', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_WALES: { search: 'flag of Wales|флаг уэльса', name: '886', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_WALLIS_AND_FUTUNA: { search: 'flag of Wallis & Futuna|флаг wallis futuna', name: '887', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_WESTERN_SAHARA: { search: 'flag of Western Sahara|флаг западной сахары', name: '888', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_YEMEN: { search: 'flag of Yemen|флаг йемена', name: '889', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ZAMBIA: { search: 'flag of Zambia|флаг замбии', name: '890', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
  FLAG_OF_ZIMBABWE: { search: 'flag of Zimbabwe|флаг зимбабве', name: '891', category: EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS },
});
