/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _differenceBy from 'lodash/differenceBy';
import _random from 'lodash/random';
import SpaceBox from '../SpaceBox/SpaceBox';
import CreateButton from '../CreateButton/CreateButton';
import { getLoadingSpaces, getReorderGoal, getSpaces } from '../../../DataCore/Store/Space/slice';
import Space from '../../../DataCore/Models/Space';
import styles from './SpacesList.module.scss';
import Goal from '../../../DataCore/Models/Goal';
import { DIALOG_NAMES, DRAG_TYPES } from '../../const';
import DialogService from '../../../DataCore/Service/DialogService';
import { getShowCreateSpacePopup } from '../../../DataCore/Store/Session/slice';
import Popup from '../Popup/Popup';
import SortableSpaces from '../SortableSpaces/SortableSpaces';
import SpaceBoxPlaceholder from '../SpaceBoxPlaceholder/SpaceBoxPlaceholder';
import CustomScroll from '../CustomScroll/CustomScroll';

const maxSpaces = process.env.REACT_APP_MAX_SPACES;

function SpacesList() {
  const spaces = useSelector(getSpaces);
  const loadingSpaces = useSelector(getLoadingSpaces);
  const reorderGoal = useSelector(getReorderGoal);
  const showCreateSpacePopup = useSelector(getShowCreateSpacePopup);
  const { t } = useTranslation('translation', { keyPrefix: 'spacesList' });
  const spaceCount = useMemo(() => _random(1, 3), []);

  const spacesWithSortedGoals = useMemo(() => {
    if (reorderGoal instanceof Goal && reorderGoal.id && reorderGoal.spaceId) {
      return spaces.map((space) => {
        const goals = _differenceBy(space.goals, [reorderGoal], 'id');
        if (reorderGoal.spaceId === space.id) {
          goals.splice(reorderGoal.index, 0, reorderGoal);
        }
        return new Space({
          ...space,
          goals,
        });
      });
    }

    return spaces;
  }, [reorderGoal, spaces]);

  const dropAccept = useMemo(() => [DRAG_TYPES.SPACE, DRAG_TYPES.GOAL_FROM_SPACE], []);

  return (
    <CustomScroll
      scrollLiftOnWheel
      dropAccept={dropAccept}
      className={styles.spacesBox}
    >
      <div className={styles.spaces}>
        {loadingSpaces && !spaces?.length
          ? [...Array(spaceCount)].map((item, index) => (
            <SpaceBoxPlaceholder key={index} />
          )) : (
            <>
              <SortableSpaces
                spaces={spacesWithSortedGoals}
                renderSpace={({ innerRef, space, isDragging }) => (
                  <SpaceBox
                    key={space.id}
                    innerRef={innerRef}
                    className={styles.spaceBox}
                    space={space}
                    transparency={isDragging}
                  />
                )}
              />
              <div className={styles.createBox}>
                {maxSpaces > spaces.length ? (
                  <Popup
                    message={t('createFirstSpacePopup')}
                    position="top-start"
                    always={showCreateSpacePopup ? 'show' : 'hide'}
                  >
                    <CreateButton
                      text={t('createSpace')}
                      onClick={() => DialogService.openDialog(DIALOG_NAMES.CREATE_SPACE)}
                    />
                  </Popup>
                ) : (
                  <div />
                )}
              </div>
            </>
          )}
      </div>
    </CustomScroll>
  );
}

export default SpacesList;
