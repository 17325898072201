import HttpClient from './HttpClient';
import Space from '../../Models/Space';
import parseResponse from './parseResponse';

export default class SpaceRepository {
  static async getAll() {
    return HttpClient.get('spaces', { params: { includeGoals: true } }).then(
      (response) => parseResponse(response, (data) => data.map(Space.fromApi)),
      ({ response }) => parseResponse(response),
    );
  }

  static async create(space) {
    return HttpClient.post('spaces', new Space(space).toApi()).then(
      (response) => parseResponse(response, Space.fromApi),
      ({ response }) => parseResponse(response, undefined, Space.parseErrors),
    );
  }

  static async update(space) {
    return HttpClient.put(`spaces/${space.id}`, new Space(space).toApi()).then(
      (response) => parseResponse(response, Space.fromApi),
      ({ response }) => parseResponse(response, undefined, Space.parseErrors),
    );
  }

  static async delete(space) {
    return HttpClient.delete(`spaces/${space.id}`).then(
      (response) => parseResponse(response),
      ({ response }) => parseResponse(response),
    );
  }
}
