import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import _debounce from 'lodash/debounce';
import _invoke from 'lodash/invoke';
import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import GoalIcon from '../GoalIcon/GoalIcon';
import { composeValidators, maxLengthValidate, requiredValidate } from '../../validators';
import ActionButtons from '../ActionButtons/ActionButtons';
import BaseDialog from '../BaseDialog/BaseDialog';
import styles from './GoalFormDialog.module.scss';
import {
  getVisibleEmoji, getLastUsedEmoji, getAllEmoji, isFilteredEmoji, getFilterCategory,
} from '../../../DataCore/Store/Emoji/slice';
import EmojiService from '../../../DataCore/Service/EmojiService';
import { ReactComponent as FaceSvg } from './Face.svg';
import { ReactComponent as CrueltySvg } from './Cruelty.svg';
import { ReactComponent as RestaurantSvg } from './Restaurant.svg';
import { ReactComponent as SportsTennisSvg } from './SportsTennis.svg';
import { ReactComponent as FlightSvg } from './Flight.svg';
import { ReactComponent as LightSvg } from './Light.svg';
import { ReactComponent as FavoriteSvg } from './Favorite.svg';
import { EMOJI_CATEGORIES } from '../../const';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

function FilterButton({ category, onSelectCategory }) {
  const filterCategory = useSelector(getFilterCategory);

  const Icon = useMemo(() => {
    switch (category) {
      case EMOJI_CATEGORIES.PEOPLE:
        return FaceSvg;
      case EMOJI_CATEGORIES.NATURE:
        return CrueltySvg;
      case EMOJI_CATEGORIES.FOOD_AND_DRINK:
        return RestaurantSvg;
      case EMOJI_CATEGORIES.ACTIVITY:
        return SportsTennisSvg;
      case EMOJI_CATEGORIES.TRAVEL_AND_PLACES:
        return FlightSvg;
      case EMOJI_CATEGORIES.OBJECTS:
        return LightSvg;
      default:
        return FavoriteSvg;
    }
  }, [category]);

  return (
    <Icon
      onClick={() => onSelectCategory(category)}
      className={classNames(
        styles.filterButton,
        { [styles.selected]: filterCategory === category },
      )}
    />
  );
}

FilterButton.propTypes = {
  category: PropTypes.oneOf(Object.values(EMOJI_CATEGORIES)).isRequired,
  onSelectCategory: PropTypes.func.isRequired,
};

function GoalFormDialog({
  showDialog, initialValues, title, confirmButtonText, onSubmit, onCancel,
}) {
  const popupIconBox = useRef();
  const { t } = useTranslation('translation');
  const visibleEmoji = useSelector(getVisibleEmoji);
  const lastUsedEmoji = useSelector(getLastUsedEmoji);
  const allEmoji = useSelector(getAllEmoji);
  const filteredEmoji = useSelector(isFilteredEmoji);

  const [showPopup, setShowPopup] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [{
      name: 'offset', options: { offset: [0, 4] },
    }],
  });

  useEffect(() => {
    if (showPopup && update) {
      update();
    }
  }, [showPopup, update]);

  useEffect(() => {
    if (!showDialog) {
      EmojiService.resetSearchEmoji();
    }
  }, [showDialog]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchEmoji = useCallback(_debounce((event) => {
    const { value } = event.target;
    EmojiService.searchEmoji(value);
  }, 300), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMoreEmoji = useCallback(_debounce((event) => {
    const elem = event.target;
    const scrollBottom = elem.scrollHeight - elem.scrollTop - elem.clientHeight;
    if (scrollBottom < 120) {
      EmojiService.visibleMoreEmoji();
    }
  }, 50, { leading: true }), []);

  const handleSelectCategory = (category) => {
    popupIconBox.current.scrollTo(0, 0);
    _invoke(popupIconBox, 'current.scrollTo', 0, 0);
    EmojiService.toggleCategory(category);
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit, pristine, form: finalForm, values, submitError,
      }) => (
        <BaseDialog
          className={styles.dialog}
          showDialog={showDialog}
          onClose={onCancel}
          liteClose={pristine}
        >
          <form
            onSubmit={(event) => handleSubmit(event)?.then((errors) => {
              if (!errors) {
                finalForm.reset();
                const emojiName = values.icon;
                const usedEmoji = _find(allEmoji, ['name', emojiName]);
                if (usedEmoji) {
                  EmojiService.usedEmoji(usedEmoji);
                }
              }
            })}
            className={styles.goalForm}
          >
            <h2 className={styles.header}>{title ?? t('goalForm.title')}</h2>
            <div>
              <Field name="name" validate={composeValidators(requiredValidate, maxLengthValidate(50))}>
                {({ input, meta }) => (
                  <label
                    htmlFor="goalName"
                    className={classNames(
                      styles.goalLabel,
                      { [styles.error]: meta.touched && (meta.error || meta.submitError) },
                    )}
                  >
                    {t('goalForm.name')}
                    <input
                      id="goalName"
                      className={styles.goalInput}
                      placeholder={t('goalForm.namePlaceholder')}
                      maxLength={50}
                      {...input}
                      onFocus={(...props) => {
                        setShowPopup(false);
                        return input.onFocus(...props);
                      }}
                    />
                    <div className={styles.errorMessage}>{meta.error ?? meta.submitError}</div>
                  </label>
                )}
              </Field>
            </div>
            <div>
              <Field name="description" validate={maxLengthValidate(200)}>
                {({ input, meta }) => (
                  <label
                    htmlFor="goalDescription"
                    className={classNames(
                      styles.goalLabel,
                      { [styles.error]: meta.touched && (meta.error || meta.submitError) },
                    )}
                  >
                    {t('goalForm.description')}
                    <textarea
                      id="goalDescription"
                      className={styles.goalTextarea}
                      placeholder={t('goalForm.descriptionPlaceholder')}
                      rows={5}
                      maxLength={200}
                      {...input}
                      onFocus={(...props) => {
                        setShowPopup(false);
                        return input.onFocus(...props);
                      }}
                    />
                    <div className={styles.errorMessage}>{meta.error ?? meta.submitError}</div>
                  </label>
                )}
              </Field>
            </div>
            <div>
              <Field name="icon">
                {({ input }) => (
                  <>
                    <label
                      htmlFor="goalIconSearch"
                      className={styles.goalLabel}
                    >
                      {t('goalForm.icon')}
                      <input
                        ref={setReferenceElement}
                        id="goalIconSearch"
                        className={styles.goalInput}
                        autoComplete="off"
                        placeholder={t('goalForm.searchIconPlaceholder')}
                        onFocus={() => setShowPopup(true)}
                        onChange={handleSearchEmoji}
                      />
                    </label>
                    <div
                      ref={setPopperElement}
                      className={classNames(styles.popup, { [styles.hidden]: !showPopup })}
                      style={popperStyles.popper}
                      {...attributes.popper}
                    >
                      <div
                        ref={popupIconBox}
                        className={styles.scrollable}
                        onScroll={handleMoreEmoji}
                      >
                        <div className={styles.filterBox}>
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.PEOPLE}
                          />
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.NATURE}
                          />
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.FOOD_AND_DRINK}
                          />
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.ACTIVITY}
                          />
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.TRAVEL_AND_PLACES}
                          />
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.OBJECTS}
                          />
                          <FilterButton
                            onSelectCategory={handleSelectCategory}
                            category={EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS}
                          />
                        </div>
                        <div className={styles.icons}>
                          {visibleEmoji.length === 0 ? t('goalForm.notResultSearchIcon') : null}
                          {filteredEmoji ? null : lastUsedEmoji.map((item) => (
                            <GoalIcon
                              key={item.name}
                              className={styles.icon}
                              iconName={item.name}
                              selectedIcon={input.value}
                              loader={false}
                              onClick={() => {
                                input.onChange(item.name);
                                setShowPopup(false);
                              }}
                            />
                          ))}
                          {visibleEmoji.map((item) => (
                            <GoalIcon
                              key={item.name}
                              className={styles.icon}
                              iconName={item.name}
                              selectedIcon={input.value}
                              loader={false}
                              onClick={() => {
                                input.onChange(item.name);
                                setShowPopup(false);
                                EmojiService.usedEmoji(item);
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.icons}>
                      {lastUsedEmoji.map((item) => (
                        // eslint-disable-next-line react/button-has-type
                        <button
                          key={item.name}
                          type="button"
                          className={styles.focusableIcon}
                          onClick={() => {
                            input.onChange(item.name);
                          }}
                          onFocus={() => {
                            setShowPopup(false);
                          }}
                        >
                          <GoalIcon
                            className={styles.icon}
                            iconName={item.name}
                            selectedIcon={input.value}
                          />
                        </button>
                      ))}
                    </div>
                  </>
                )}
              </Field>
            </div>
            <div>
              <Field name="deadline" validate={requiredValidate}>
                {({ input, meta }) => (
                  <label
                    htmlFor="goalDeadline"
                    className={classNames(
                      styles.goalLabel,
                      { [styles.error]: meta.touched && (meta.error || meta.submitError) },
                    )}
                  >
                    {t('goalForm.deadline')}
                    <input
                      id="goalDeadline"
                      className={styles.goalInput}
                      placeholder={t('goalForm.searchIconPlaceholder')}
                      type="date"
                      {...input}
                      onFocus={(...props) => {
                        setShowPopup(false);
                        return input.onFocus(...props);
                      }}
                    />
                    <div className={styles.errorMessage}>{meta.error ?? meta.submitError}</div>
                  </label>
                )}
              </Field>
            </div>
            <ErrorMessage content={submitError ? t(submitError) : undefined} />
            <ActionButtons
              confirmButtonText={confirmButtonText ?? t('goalForm.create')}
              cancelButtonText={t('goalForm.cancel')}
              onCancelButtonClick={() => {
                onCancel();
                finalForm.reset();
              }}
            />
          </form>
        </BaseDialog>
      )}
    />
  );
}

GoalFormDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  confirmButtonText: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

GoalFormDialog.defaultProps = {
  confirmButtonText: undefined,
  title: undefined,
};

export default GoalFormDialog;
