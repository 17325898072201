import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseDialog from '../BaseDialog/BaseDialog';
import styles from './GoalBlackHoleDialog.module.scss';
import Goal from '../../../DataCore/Models/Goal';
import { GOAL_STATES } from '../../const';
import GoalService from '../../../DataCore/Service/GoalService';
import SpaceService from '../../../DataCore/Service/SpaceService';
import BlackHoleService from '../../../DataCore/Service/BlackHoleService';
import ActionButtons from '../ActionButtons/ActionButtons';
import ToastService from '../../../DataCore/Service/ToastService';

function GoalBlackHoleDialog({
  spaceId, goal, showDialog, onClose,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'goalBlackHoleDialog' });

  const handleGoalBlackHole = async () => {
    if (!spaceId) {
      throw new Error('Required field spaceId');
    }

    const newGoal = new Goal({
      ...goal,
      state: GOAL_STATES.IN_ARCHIVE,
    });
    const result = await GoalService.update(spaceId, newGoal);
    if (result.status) {
      await Promise.all([
        SpaceService.getAll(),
        BlackHoleService.getAll(),
      ]);
      onClose();
    } else {
      ToastService.createNetworkDangerToast(result, 'goalBlackHoleDialog.dragError');
    }
  };

  return (
    <BaseDialog className={styles.dialog} showDialog={showDialog} showCloseButton={false} liteClose>
      <h2 className={styles.header}>{t('title')}</h2>
      <p className={styles.text}>{t('message')}</p>
      <ActionButtons
        confirmButtonText={t('confirm')}
        submitConfirmButton={false}
        onConfirmButtonClick={handleGoalBlackHole}
        cancelButtonText={t('cancel')}
        onCancelButtonClick={onClose}
      />
    </BaseDialog>
  );
}

GoalBlackHoleDialog.propTypes = {
  spaceId: PropTypes.number,
  goal: PropTypes.object,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

GoalBlackHoleDialog.defaultProps = {
  spaceId: undefined,
  goal: undefined,
};

export default GoalBlackHoleDialog;
