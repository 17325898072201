import BlackHoleRepository from '../Repositories/Api/BlackHoleRepository';
import { endLoadingBlackHoleGoals, setBlackHoleGoals, startLoadingBlackHoleGoals } from '../Store/BlackHole/slice';
import store from '../Store/store';

export default class BlackHoleService {
  static async getAll() {
    store.dispatch(startLoadingBlackHoleGoals());

    const result = await BlackHoleRepository.getAll();
    if (!result.status) {
      store.dispatch(endLoadingBlackHoleGoals());
      return result;
    }

    store.dispatch(setBlackHoleGoals(result.data));
    store.dispatch(endLoadingBlackHoleGoals());

    return { status: true };
  }
}
