import React, { useEffect, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import SignInPage from '../SignIn/SignInPage';
import MainPage from '../Main/MainPage';
import RequireAuth from '../../Common/Components/RequireAuth';
import RequireNotAuth from '../../Common/Components/RequireNotAuth';
import HttpClient from '../../DataCore/Repositories/Api/HttpClient';
import { islLogIn, removeToken } from '../../DataCore/Store/Session/slice';
import AchievesPage from '../Achieves/AchievesPage';
import SpaceService from '../../DataCore/Service/SpaceService';
import AchievementService from '../../DataCore/Service/AchievementService';
import BlackHoleService from '../../DataCore/Service/BlackHoleService';
import LoginProviderService from '../../DataCore/Service/LoginProviderService';
import CreateGoalDialog from '../../Common/Components/CreateGoalDialog/CreateGoalDialog';
import { DIALOG_NAMES } from '../../Common/const';
import CreateSpaceDialog from '../../Common/Components/CreateSpaceDialog/CreateSpaceDialog';
import EditSpaceDialog from '../../Common/Components/EditSpaceDialog/EditSpaceDialog';
import EditGoalDialog from '../../Common/Components/EditGoalDialog/EditGoalDialog';
import GoalAchievementDialog from '../../Common/Components/GoalAchievementDialog/GoalAchievementDialog';
import GoalBlackHoleDialog from '../../Common/Components/GoalBlackHoleDialog/GoalBlackHoleDialog';
import HelpDialog from '../../Common/Components/HelpDialog/HelpDialog';
import ShareAchieveDialog from '../../Common/Components/ShareAchieveDialog/ShareAchieveDialog';
import FeedbackDialog from '../../Common/Components/FeedbackDialog/FeedbackDialog';
import { getDialogData, getDialogName } from '../../DataCore/Store/Dialog/slice';
import DialogService from '../../DataCore/Service/DialogService';
import PrivacyPolicyPage from '../PrivacyPolicy/PrivacyPolicyPage';
import Header from '../../Common/Components/Header/Header';
import ToastBox from '../../Common/Components/ToastBox/ToastBox';

function App() {
  const dispatch = useDispatch();
  const isLogIn = useSelector(islLogIn);
  const dialogName = useSelector(getDialogName);
  const { spaceId, goal, space } = useSelector(getDialogData);

  useEffect(() => {
    HttpClient.setUnauthorizedListener(() => {
      dispatch(removeToken());
    });
    return () => {
      HttpClient.setUnauthorizedListener(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogIn) {
      SpaceService.getAll();
      AchievementService.reload();
      BlackHoleService.getAll();
      LoginProviderService.getAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn]);

  const { pathname } = useLocation();
  const showHeader = useMemo(() => pathname !== '/Achievements', [pathname]);

  const handleCloseDialog = () => {
    DialogService.closeDialog();
  };

  return (
    <div>
      {showHeader ? <Header /> : null}
      <Routes>
        <Route path="/" element={<RequireAuth><MainPage /></RequireAuth>} />
        <Route path="/Achievements/:goalId" element={<RequireAuth><MainPage /></RequireAuth>} />
        <Route path="/Achievements" element={<RequireAuth><AchievesPage /></RequireAuth>} />
        <Route path="/SignIn" element={<RequireNotAuth><SignInPage /></RequireNotAuth>} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicyPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastBox />
      <CreateGoalDialog
        spaceId={spaceId}
        showDialog={dialogName === DIALOG_NAMES.CREATE_GOAL}
        onClose={handleCloseDialog}
      />
      <CreateSpaceDialog
        showDialog={dialogName === DIALOG_NAMES.CREATE_SPACE}
        onClose={handleCloseDialog}
      />
      <EditSpaceDialog
        space={space}
        showDialog={dialogName === DIALOG_NAMES.EDIT_SPACE}
        onClose={handleCloseDialog}
      />
      <EditGoalDialog
        showDialog={dialogName === DIALOG_NAMES.EDIT_GOAL}
        onClose={handleCloseDialog}
        spaceId={goal?.spaceId}
        goal={goal}
      />
      <GoalAchievementDialog
        showDialog={dialogName === DIALOG_NAMES.GOAL_ACHIEVEMENT}
        onClose={handleCloseDialog}
        spaceId={goal?.spaceId}
        goal={goal}
      />
      <GoalBlackHoleDialog
        showDialog={dialogName === DIALOG_NAMES.GOAL_BLACK_HOLE}
        onClose={handleCloseDialog}
        spaceId={goal?.spaceId}
        goal={goal}
      />
      <HelpDialog showDialog={dialogName === DIALOG_NAMES.HELP} onClose={handleCloseDialog} />
      <ShareAchieveDialog
        goal={goal}
        showDialog={dialogName === DIALOG_NAMES.ACHIEVE_SHARE}
        onClose={handleCloseDialog}
      />
      <FeedbackDialog
        showDialog={dialogName === DIALOG_NAMES.FEEDBACK}
        onClose={handleCloseDialog}
      />
    </div>
  );
}

export default App;
