import React from 'react';
import PropTypes from 'prop-types';

function HomeIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M27 35.25V29.25C27 29.0312 26.9219 28.8594 26.7656 28.7344C26.6406 28.5781 26.4688 28.5 26.25 28.5H21.75C21.5312 28.5 21.3438 28.5781 21.1875 28.7344C21.0625 28.8594 21 29.0312 21 29.25V35.25C21 35.4688 20.9219 35.6406 20.7656 35.7656C20.6406 35.9219 20.4688 36 20.25 36H14.25C14.0312 36 13.8438 35.9219 13.6875 35.7656C13.5625 35.6406 13.5 35.4688 13.5 35.25V26.5781C13.5312 26.5781 13.5781 26.5625 13.6406 26.5312C13.7031 26.5 13.7344 26.4688 13.7344 26.4375L24 17.3906L34.2656 26.4375C34.2969 26.4688 34.3281 26.5 34.3594 26.5312C34.4219 26.5625 34.4688 26.5781 34.5 26.5781V35.25C34.5 35.4688 34.4219 35.6406 34.2656 35.7656C34.1406 35.9219 33.9688 36 33.75 36H27.75C27.5312 36 27.3438 35.9219 27.1875 35.7656C27.0625 35.6406 27 35.4688 27 35.25Z" />
      <path fill={color} d="M35.25 25.3125L24.5156 15.8438C24.3594 15.7188 24.1875 15.6562 24 15.6562C23.8125 15.6562 23.6406 15.7188 23.4844 15.8438L12.75 25.3125C12.625 25.4375 12.4531 25.5 12.2344 25.5C12.0156 25.5 11.8438 25.4219 11.7188 25.2656L10.6875 24.1406C10.5625 23.9844 10.5 23.8125 10.5 23.625C10.5 23.4062 10.5781 23.2188 10.7344 23.0625L22.7344 12.4688C23.1094 12.1562 23.5312 12 24 12C24.4688 12 24.8906 12.1562 25.2656 12.4688L37.2656 23.0625C37.4219 23.2188 37.5 23.4062 37.5 23.625C37.5 23.8125 37.4375 23.9844 37.3125 24.1406L36.3281 25.2656C36.1719 25.4219 35.9844 25.5 35.7656 25.5C35.5469 25.5 35.375 25.4375 35.25 25.3125Z" />
    </svg>
  );
}

HomeIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default HomeIcon;
