import React from 'react';
import PropTypes from 'prop-types';
import styles from './ActionButtons.module.scss';

function ActionButtons({
  confirmButtonText,
  submitConfirmButton,
  onConfirmButtonClick,
  cancelButtonText,
  onCancelButtonClick,
}) {
  return (
    <div className={styles.actions}>
      <button
        type="button"
        className={`${styles.button} ${styles.buttonCancel}`}
        onClick={onCancelButtonClick}
      >
        {cancelButtonText}
      </button>
      <button
        type={submitConfirmButton ? 'submit' : 'button'}
        className={`${styles.button} ${styles.buttonConfirm}`}
        onClick={onConfirmButtonClick}
      >
        {confirmButtonText}
      </button>
    </div>
  );
}

ActionButtons.propTypes = {
  confirmButtonText: PropTypes.string.isRequired,
  submitConfirmButton: PropTypes.bool,
  onConfirmButtonClick: PropTypes.func,
  cancelButtonText: PropTypes.string.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
};

ActionButtons.defaultProps = {
  submitConfirmButton: true,
  onConfirmButtonClick: undefined,
};

export default ActionButtons;
