import _lowerCase from 'lodash/lowerCase';
import _upperCase from 'lodash/upperCase';
import _invoke from 'lodash/invoke';
import Goal from './Goal';

const ID = 'id';
const NAME = 'name';
const ICON = 'icon';
const COLOR = 'color';
const INDEX = 'index';
const GOALS = 'goals';

const ERROR_NAME = 'Name';
const ERROR_ICON = 'Icon';
const ERROR_COLOR = 'Color';
const ERROR_INDEX = 'Index';

export default class Space {
  constructor(parameters) {
    const {
      id, name, icon, color, index, goals,
    } = parameters;
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.color = color;
    this.index = index;
    this.goals = goals || [];
  }

  static fromApi(object = {}) {
    return new Space({
      id: object[ID],
      name: object[NAME],
      icon: object[ICON],
      color: _upperCase(object[COLOR]),
      index: object[INDEX],
      goals: _invoke(
        object,
        [GOALS, 'map'],
        Goal.fromApi,
      ),
    });
  }

  toApi() {
    return {
      [ID]: this.id,
      [NAME]: this.name,
      [ICON]: this.icon,
      [COLOR]: _lowerCase(this.color),
      [INDEX]: this.index,
    };
  }

  static parseErrors(object = {}) {
    return {
      name: object[ERROR_NAME],
      icon: object[ERROR_ICON],
      color: object[ERROR_COLOR],
      index: object[ERROR_INDEX],
    };
  }
}
