import React from 'react';
import PropTypes from 'prop-types';

function PersonIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M21.6094 17.7656C20.9531 17.1094 20.625 16.3125 20.625 15.375C20.625 14.4375 20.9531 13.6406 21.6094 12.9844C22.2656 12.3281 23.0625 12 24 12C24.9375 12 25.7344 12.3281 26.3906 12.9844C27.0469 13.6406 27.375 14.4375 27.375 15.375C27.375 16.3125 27.0469 17.1094 26.3906 17.7656C25.7344 18.4219 24.9375 18.75 24 18.75C23.0625 18.75 22.2656 18.4219 21.6094 17.7656Z" />
      <path fill={color} d="M15.4688 17.5781C15.1875 17.2656 15.0469 16.9062 15.0469 16.5C15.0469 16.0938 15.1875 15.75 15.4688 15.4688C15.7812 15.1562 16.1406 15 16.5469 15C16.9531 15 17.3125 15.1406 17.625 15.4219L21.6562 19.5H26.4375L30.4688 15.4219C30.7812 15.1406 31.1406 15 31.5469 15C31.9531 15 32.2969 15.1562 32.5781 15.4688C32.8906 15.75 33.0469 16.0938 33.0469 16.5C33.0469 16.9062 32.9062 17.2656 32.625 17.5781L28.1719 21.9844V34.5C28.1719 34.9062 28.0156 35.25 27.7031 35.5312C27.4219 35.8438 27.0781 36 26.6719 36H25.9219C25.5156 36 25.1562 35.8438 24.8438 35.5312C24.5625 35.25 24.4219 34.9062 24.4219 34.5V29.25H23.6719V34.5C23.6719 34.9062 23.5156 35.25 23.2031 35.5312C22.9219 35.8438 22.5781 36 22.1719 36H21.4219C21.0156 36 20.6562 35.8438 20.3438 35.5312C20.0625 35.25 19.9219 34.9062 19.9219 34.5V21.9844L15.4688 17.5781Z" />
    </svg>
  );
}

PersonIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default PersonIcon;
