/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import _random from 'lodash/random';
import { ReactComponent as StarSvg } from '../../Images/Star.svg';
import { ReactComponent as OpenFullscreenSvg } from '../../Images/Expand.svg';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import {
  getAchievementsTotal,
  getLatestAchievements,
  getLoadingAchievements,
} from '../../../DataCore/Store/Achievement/slice';
import styles from './AchievesList.module.scss';
import Button from '../Button/Button';
import CustomScroll from '../CustomScroll/CustomScroll';
import AchieveBox from '../AchieveBox/AchieveBox';
import GoalBoxPlaceholder from '../GoalBoxPlaceholder/GoalBoxPlaceholder';
import AchievementService from '../../../DataCore/Service/AchievementService';

const pageSize = +process.env.REACT_APP_PAGE_SIZE || 20;

function AchievesList() {
  const latestAchievements = useSelector(getLatestAchievements);
  const loadingAchievements = useSelector(getLoadingAchievements);
  const achievementsTotal = useSelector(getAchievementsTotal);
  const { t } = useTranslation('translation', { keyPrefix: 'achievesList' });
  const placeholderCount = useMemo(() => {
    if (!achievementsTotal) {
      return _random(1, 5);
    }

    const unloadedCount = achievementsTotal - latestAchievements.length;
    return Math.min(pageSize, unloadedCount);
  }, [achievementsTotal, latestAchievements.length]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DRAG_TYPES.GOAL_FROM_SPACE,
    drop: () => ({ name: GOAL_DROP.ACHIEVE }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), []);

  return (
    <div
      ref={drop}
      className={classNames(
        styles.achievesBox,
        {
          [styles.canDrag]: canDrop && !isOver,
          [styles.canDropOver]: canDrop && isOver,
        },
      )}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <StarSvg className={styles.titleIcon} />
          <h3 className={styles.titleText}>{t('latestAchievements')}</h3>
        </div>
        <Link to="/Achievements">
          <Button className={styles.button}>
            <OpenFullscreenSvg />
          </Button>
        </Link>
      </div>
      <CustomScroll
        className={styles.achievementBox}
        onEndListX={async () => {
          if (achievementsTotal > latestAchievements.length) {
            await AchievementService.loadMore();
          }
        }}
        scrollLiftOnWheel
      >
        <div className={styles.achievements}>
          {latestAchievements.map((goal) => (
            <AchieveBox
              key={goal.id}
              goal={goal}
            />
          ))}
          {loadingAchievements
            ? [...Array(placeholderCount)].map((item, index) => (
              <GoalBoxPlaceholder key={`placeholder_${index}`} achievement />
            )) : null}
        </div>
      </CustomScroll>
    </div>
  );
}

export default AchievesList;
