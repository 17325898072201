import React from 'react';
import PropTypes from 'prop-types';

function KeyIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M26.2031 28.3594L25.0781 29.625C24.8594 29.875 24.5781 30 24.2344 30H22.5V31.875C22.5 32.1875 22.3906 32.4531 22.1719 32.6719C21.9531 32.8906 21.6875 33 21.375 33H19.5V34.875C19.5 35.1875 19.3906 35.4531 19.1719 35.6719C18.9531 35.8906 18.6875 36 18.375 36H13.125C12.8125 36 12.5469 35.8906 12.3281 35.6719C12.1094 35.4531 12 35.1875 12 34.875V31.2188C12 30.9062 12.1094 30.6406 12.3281 30.4219L19.9219 22.8281C20.2031 23.6719 20.6719 24.4844 21.3281 25.2656C21.9844 26.0469 22.7344 26.7188 23.5781 27.2812C24.4531 27.8438 25.3281 28.2031 26.2031 28.3594Z" />
      <path fill={color} d="M21.8906 14.4375C23.5156 12.8125 25.4688 12 27.75 12C30.0312 12 31.9688 12.8125 33.5625 14.4375C35.1875 16.0312 36 17.9688 36 20.25C36 22.5312 35.1875 24.4844 33.5625 26.1094C31.9688 27.7031 30.0312 28.5 27.75 28.5C25.4688 28.5 23.5156 27.7031 21.8906 26.1094C20.2969 24.4844 19.5 22.5312 19.5 20.25C19.5 17.9688 20.2969 16.0312 21.8906 14.4375ZM28.4062 19.5938C28.8438 20.0312 29.375 20.25 30 20.25C30.625 20.25 31.1562 20.0312 31.5938 19.5938C32.0312 19.1562 32.25 18.625 32.25 18C32.25 17.375 32.0312 16.8438 31.5938 16.4062C31.1562 15.9688 30.625 15.75 30 15.75C29.375 15.75 28.8438 15.9688 28.4062 16.4062C27.9688 16.8438 27.75 17.375 27.75 18C27.75 18.625 27.9688 19.1562 28.4062 19.5938Z" />
    </svg>
  );
}

KeyIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default KeyIcon;
