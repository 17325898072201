import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const sessionSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    startLoadingSpaces: (state) => ({
      ...state,
      loadingSpaces: true,
    }),
    endLoadingSpaces: (state) => ({
      ...state,
      loadingSpaces: false,
    }),
    setSpaces: (state, action) => {
      const spaces = action.payload;
      return {
        ...state,
        spaces,
      };
    },
    setReorderGoal: (state, action) => ({
      ...state,
      reorderGoal: action.payload,
    }),
    resetSpace: () => initialState,
  },
});

export const {
  startLoadingSpaces, endLoadingSpaces, setSpaces, setReorderGoal, resetSpace,
} = sessionSlice.actions;

export const getSpaces = (state) => state.space.spaces;

export const getLoadingSpaces = (state) => state.space.loadingSpaces;

export const getReorderGoal = (state) => state.space.reorderGoal;

export const { reducer } = sessionSlice;
