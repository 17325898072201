import _invoke from 'lodash/invoke';
import _get from 'lodash/get';
import HttpClient from './HttpClient';
import parseResponse from './parseResponse';
import GoalFull from '../../Models/GoalFull';

const pageSize = +process.env.REACT_APP_PAGE_SIZE || 20;

export default class AchievementRepository {
  static async getAll(top = false) {
    let body;
    const achievements = [];
    let index = 0;

    do {
      // eslint-disable-next-line no-await-in-loop
      const result = await HttpClient.get('achievements', {
        params: { index, type: top ? 'OnlyFavorite' : 'All' },
      }).then(
        (response) => parseResponse(response, (data) => data),
        ({ response }) => parseResponse(response),
      );

      if (!result.status) {
        return result;
      }

      body = result.data;
      const page = _invoke(body, 'data.map', GoalFull.fromApi) ?? [];
      achievements.push(...page);
      index += 1;
    } while (!body.isLastPage);

    return {
      status: true,
      data: achievements,
    };
  }

  static async getAllBySpaceId(spaceId) {
    return HttpClient.get(`spaces/${spaceId}/goals`, { params: { goalState: 'Achieved' } }).then(
      (response) => parseResponse(response, (data) => _invoke(data, 'map', GoalFull.fromApi) ?? []),
      ({ response }) => parseResponse(response),
    );
  }

  static async getAllByPage(numberPage) {
    return HttpClient.get('achievements', {
      params: { index: numberPage, pageSize },
    }).then(
      (response) => parseResponse(response, (data) => {
        const achievements = _invoke(data, 'data.map', GoalFull.fromApi) ?? [];
        const achievementsTotal = _get(data, 'itemsTotal');
        return {
          achievements,
          achievementsTotal,
        };
      }),
      ({ response }) => parseResponse(response),
    );
  }

  static async getAllBySpaceIdAndPage(spaceId, numberPage) {
    return HttpClient.get(`spaces/${spaceId}/achievements`, {
      params: { index: numberPage, pageSize },
    }).then(
      (response) => parseResponse(response, (data) => {
        const achievements = _invoke(data, 'data.map', GoalFull.fromApi) ?? [];
        const achievementsTotal = _get(data, 'itemsTotal');
        return {
          achievements,
          achievementsTotal,
        };
      }),
      ({ response }) => parseResponse(response),
    );
  }

  static async getById(id) {
    return HttpClient.get(`achievements/${id}`).then(
      (response) => parseResponse(response, GoalFull.fromApi),
      ({ response }) => parseResponse(response),
    );
  }
}
