import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SPACE_COLORS } from '../../const';
import SpaceService from '../../../DataCore/Service/SpaceService';
import styles from './EditSpaceDialog.module.scss';
import SpaceFormDialog from '../SpaceFormDialog/SpaceFormDialog';
import SpaceIcon from '../SpaceIcon/SpaceIcon';
import { parseFormErrors } from '../../func';

function EditSpaceDialog({ space, showDialog, onClose }) {
  const color = SPACE_COLORS[space?.color] || 'black';
  const { t } = useTranslation('translation', { keyPrefix: 'spaceForm' });

  const handleUpdateSpace = async (values) => {
    const result = await SpaceService.update(values);
    if (result.status) {
      onClose();
      return undefined;
    }
    return parseFormErrors(result);
  };

  const title = space ? (
    <div className={styles.title} style={{ color }}>
      <SpaceIcon className={styles.titleIcon} iconName={space.icon} color={color} />
      <h3 className={styles.titleText}>{space.name}</h3>
    </div>
  ) : (
    <div />
  );

  return (
    <SpaceFormDialog
      showDialog={showDialog}
      onSubmit={handleUpdateSpace}
      title={title}
      confirmButtonText={t('update')}
      onCancel={onClose}
      initialValues={space || {}}
    />
  );
}

EditSpaceDialog.propTypes = {
  space: PropTypes.object,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditSpaceDialog.defaultProps = {
  space: undefined,
};

export default EditSpaceDialog;
