import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GoalIcon from '../GoalIcon/GoalIcon';
import { DATE_FORMAT, VIEW_DATE_FORMAT } from '../../const';
import styles from './GoalBox.module.scss';

function GoalBox({
  innerRef, transparency, goal, editable, onClick, showAchievementButton, onAchievementClick,
}) {
  const checkbox = useRef();
  const { t } = useTranslation('translation', { keyPrefix: 'goalBox' });

  const deadline = useMemo(() => {
    const date = moment(goal.deadline, DATE_FORMAT);
    return date.isValid() ? date.format(VIEW_DATE_FORMAT) : '';
  }, [goal]);

  const deadlineOverdue = useMemo(() => {
    const date = moment(goal.deadline, DATE_FORMAT);
    return date.isValid() && date.format('YYYYMMDD') < moment().format('YYYYMMDD');
  }, [goal]);

  const handleClick = (event) => {
    if (editable && onClick && event.target !== checkbox.current) {
      onClick(goal);
    }
  };

  return (
    <button
      ref={innerRef}
      type="button"
      className={classNames(
        styles.box,
        {
          [styles.editable]: editable,
          [styles.transparency]: transparency,
        },
      )}
      onClick={handleClick}
    >
      <GoalIcon className={styles.icon} iconName={goal.icon} />
      <div className={styles.name}>{goal.name}</div>
      <div className={classNames(styles.deadline, { [styles.overdue]: deadlineOverdue })}>
        {`${t('until')} ${deadline}`}
      </div>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        ref={checkbox}
        className={classNames(styles.checkbox, { [styles.hidden]: !showAchievementButton })}
        onClick={editable ? onAchievementClick?.bind(undefined, goal) : undefined}
      />
    </button>
  );
}

GoalBox.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  transparency: PropTypes.bool,
  goal: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onClick: PropTypes.func,
  showAchievementButton: PropTypes.bool,
  onAchievementClick: PropTypes.func,
};

GoalBox.defaultProps = {
  innerRef: undefined,
  transparency: false,
  editable: false,
  onClick: undefined,
  showAchievementButton: true,
  onAchievementClick: undefined,
};

export default GoalBox;
