import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import styles from './BlackHoleButton.module.scss';
import { getBlackHoleGoals } from '../../../DataCore/Store/BlackHole/slice';
import { getDialogName } from '../../../DataCore/Store/Dialog/slice';
import { DRAG_TYPES, GOAL_DROP } from '../../const';

const blackHoleTimeout = process.env.REACT_APP_BLACK_HOLE_TIMEOUT_MIN * 60_000;

function BlackHoleButton({ onClick }) {
  const [timeEnd, setTimeEnd] = useState(false);
  const blackHoleGoals = useSelector(getBlackHoleGoals);
  const dialogName = useSelector(getDialogName);
  const [timeoutId, setTimeoutId] = useState(undefined);
  const { t } = useTranslation('translation', { keyPrefix: 'blackHoleButton' });
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [{
      name: 'arrow',
      options: {
        element: arrowElement,
      },
    }, {
      name: 'offset', options: { offset: [0, 8] },
    }],
  });

  useEffect(() => {
    if (blackHoleGoals.length) {
      const id = setTimeout(() => {
        setTimeEnd(true);
      }, blackHoleTimeout);
      setTimeoutId(id);

      return () => {
        clearTimeout(id);
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blackHoleGoals]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DRAG_TYPES.GOAL_FROM_SPACE,
    drop: () => ({ name: GOAL_DROP.BLACK_HOLE }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }), []);
  drop(referenceElement);

  const showPopup = useMemo(
    () => timeEnd && !dialogName && !canDrop,
    [canDrop, dialogName, timeEnd],
  );

  useEffect(() => {
    if (showPopup && update) {
      update();
      // update popup position after animation
      setTimeout(update, 300);
    }
  }, [showPopup, update]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        ref={setReferenceElement}
        type="button"
        className={classNames(
          styles.blackHole,
          {
            [styles.animation]: showPopup,
            [styles.canDrag]: canDrop && !isOver,
            [styles.canDropOver]: canDrop && isOver,
          },
        )}
        onClick={() => {
          onClick();
          setTimeEnd(false);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        }}
      />
      <div
        ref={setPopperElement}
        className={classNames(styles.popup, { [styles.hidden]: !showPopup })}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        {t('checkGoalPopup')}
        <div ref={setArrowElement} className={styles.arrow} style={popperStyles.arrow} />
      </div>
    </>
  );
}

BlackHoleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BlackHoleButton;
