import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CreateButton.module.scss';
import { ReactComponent as PlusSvg } from './Plus.svg';

function CreateButton({ text, disabled, onClick }) {
  const boxClass = useMemo(
    () => classNames(styles.box, { [styles.disabled]: disabled }),
    [disabled],
  );

  return (
    <button type="button" className={boxClass} onClick={disabled ? undefined : onClick}>
      <div className={styles.icon}><PlusSvg /></div>
      <div className={styles.label}>{text}</div>
    </button>
  );
}

CreateButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CreateButton.defaultProps = {
  disabled: false,
  onClick: undefined,
};

export default CreateButton;
