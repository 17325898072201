import React from 'react';
import PropTypes from 'prop-types';

function TeacherIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M39 14.3438V29.1562C39 29.8125 38.7812 30.375 38.3438 30.8438C37.9062 31.2812 37.375 31.5 36.75 31.5H25.125C25.0625 31.375 25.0156 31.25 24.9844 31.125C24.5469 30.0938 23.8906 29.2188 23.0156 28.5H36V15H19.5V17.2969C18.625 16.7969 17.7031 16.5312 16.7344 16.5H16.6875C16.6875 16.5 16.6562 16.5 16.5938 16.5C16.5625 16.5 16.5312 16.5 16.5 16.5V14.3438C16.5 13.6875 16.7188 13.1406 17.1562 12.7031C17.5938 12.2344 18.125 12 18.75 12H36.75C37.375 12 37.9062 12.2344 38.3438 12.7031C38.7812 13.1406 39 13.6875 39 14.3438Z" />
      <path fill={color} d="M18.7969 28.5C20.2344 28.5 21.4688 29.0156 22.5 30.0469C23.5312 31.0781 24.0469 32.3125 24.0469 33.75C24.0469 33.75 24.0469 33.7656 24.0469 33.7969C24.0469 34.3906 23.8281 34.9062 23.3906 35.3438C22.9531 35.7812 22.4219 36 21.7969 36H11.2969C10.6719 36 10.1406 35.7812 9.70312 35.3438C9.26562 34.9062 9.04688 34.3906 9.04688 33.7969C9.04688 33.7656 9.04688 33.75 9.04688 33.75C9.04688 32.3125 9.5625 31.0781 10.5938 30.0469C11.625 29.0156 12.8594 28.5 14.2969 28.5C14.4219 28.5 14.5312 28.5156 14.625 28.5469C15.25 28.7656 15.8906 28.875 16.5469 28.875C17.2031 28.875 17.8438 28.7656 18.4688 28.5469C18.5625 28.5156 18.6719 28.5 18.7969 28.5ZM19.7344 25.6875C18.8594 26.5625 17.7969 27 16.5469 27C15.2969 27 14.2344 26.5625 13.3594 25.6875C12.4844 24.8125 12.0469 23.75 12.0469 22.5C12.0469 21.25 12.4844 20.1875 13.3594 19.3125C14.2344 18.4375 15.2969 18 16.5469 18C17.7969 18 18.8594 18.4375 19.7344 19.3125C20.6094 20.1875 21.0469 21.25 21.0469 22.5C21.0469 23.75 20.6094 24.8125 19.7344 25.6875ZM19.5469 15H36.0469V28.5H33.0469V25.5H27.0469V28.5H23.0625C22.25 27.8438 21.3281 27.4062 20.2969 27.1875C20.4844 27.0312 20.6562 26.875 20.8125 26.7188C21.2812 26.25 21.6875 25.6094 22.0312 24.7969C22.375 23.9531 22.5469 23.1875 22.5469 22.5C22.5469 21.8125 22.375 21.0625 22.0312 20.25C21.6875 19.4062 21.2812 18.7344 20.8125 18.2344C20.4375 17.8906 20.0156 17.5781 19.5469 17.2969V15Z" />
    </svg>
  );
}

TeacherIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default TeacherIcon;
