import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DialogService from '../../../DataCore/Service/DialogService';
import styles from './PrivacyPolicyLink.module.scss';

function PrivacyPolicyLink() {
  const { t } = useTranslation('translation', { keyPrefix: 'privacyPolicyLink' });

  return (
    <Link
      className={styles.link}
      to="/PrivacyPolicy"
      onClick={() => DialogService.closeDialog()}
    >
      {t('privacyPolicy')}
    </Link>
  );
}

export default PrivacyPolicyLink;
