import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import GoalService from '../../../DataCore/Service/GoalService';
import GoalFormDialog from '../GoalFormDialog/GoalFormDialog';
import { getLastUsedEmoji } from '../../../DataCore/Store/Emoji/slice';
import { parseFormErrors } from '../../func';

function CreateGoalDialog({ spaceId, showDialog, onClose }) {
  const lastUsedEmoji = useSelector(getLastUsedEmoji);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initIcon = useMemo(() => _get(lastUsedEmoji, '0.name'), [showDialog]);

  const handleCreateGoal = async (goal) => {
    if (!spaceId) {
      throw new Error('Required field spaceId');
    }

    const result = await GoalService.create(spaceId, goal);
    if (result.status) {
      onClose();
      return undefined;
    }
    return parseFormErrors(result);
  };

  return (
    <GoalFormDialog
      showDialog={showDialog}
      onSubmit={handleCreateGoal}
      onCancel={onClose}
      initialValues={{
        name: '',
        description: '',
        icon: initIcon,
      }}
    />
  );
}

CreateGoalDialog.propTypes = {
  spaceId: PropTypes.number,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CreateGoalDialog.defaultProps = {
  spaceId: undefined,
};

export default CreateGoalDialog;
