import _includes from 'lodash/includes';
import { closeDialog, openDialog } from '../Store/Dialog/slice';
import { DIALOG_NAMES } from '../../Common/const';
import store from '../Store/store';

export default class DialogService {
  static openDialog(name, { goal, space, spaceId } = {}) {
    if (!_includes(Object.values(DIALOG_NAMES), name)) {
      throw new Error('Unknown dialog name');
    }

    if (name === DIALOG_NAMES.CREATE_GOAL && !spaceId) {
      throw new Error('Required field spaceId');
    }

    const names = [
      DIALOG_NAMES.EDIT_GOAL,
      DIALOG_NAMES.GOAL_ACHIEVEMENT,
      DIALOG_NAMES.GOAL_BLACK_HOLE,
      DIALOG_NAMES.ACHIEVE_SHARE,
    ];
    if (_includes(names, name) && !goal) {
      throw new Error('Required field goal');
    }

    if (name === DIALOG_NAMES.EDIT_SPACE && !space) {
      throw new Error('Required field space');
    }

    store.dispatch(openDialog({
      name, spaceId, goal, space,
    }));
  }

  static closeDialog() {
    store.dispatch(closeDialog());
  }
}
