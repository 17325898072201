import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseDialog from '../BaseDialog/BaseDialog';
import { composeValidators, maxLengthValidate, requiredValidate } from '../../validators';
import ActionButtons from '../ActionButtons/ActionButtons';
import styles from './FeedbackDialog.module.scss';
import FeedbackService from '../../../DataCore/Service/FeedbackService';
import { islLogIn } from '../../../DataCore/Store/Session/slice';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { parseFormErrors } from '../../func';

function FeedbackDialog({
  showDialog, onClose,
}) {
  const isLogIn = useSelector(islLogIn);
  const { t } = useTranslation('translation');

  const handleSaveFeedback = async (values) => {
    const result = await FeedbackService.create(values);
    if (result.status) {
      onClose();
      return undefined;
    }
    return parseFormErrors(result);
  };

  return (
    <FinalForm
      onSubmit={handleSaveFeedback}
      render={({
        handleSubmit, pristine, submitError,
      }) => (
        <BaseDialog
          className={styles.dialog}
          showDialog={showDialog}
          onClose={onClose}
          liteClose={pristine}
        >
          <form onSubmit={handleSubmit} className={styles.feedbackForm}>
            <h2 className={styles.header}>{t('feedbackDialog.title')}</h2>
            <div>
              <Field name="description" validate={composeValidators(requiredValidate, maxLengthValidate(1000))}>
                {({ input, meta }) => (
                  <label
                    htmlFor="feedback"
                    className={classNames(
                      styles.feedbackLabel,
                      { [styles.error]: meta.touched && meta.error },
                    )}
                  >
                    {t('feedbackDialog.feedback')}
                    <textarea
                      id="feedback"
                      className={styles.feedbackTextarea}
                      placeholder={t('feedbackDialog.feedbackPlaceholder')}
                      rows={15}
                      maxLength={1000}
                      {...input}
                    />
                    <div className={styles.errorMessage}>{meta.error}</div>
                  </label>
                )}
              </Field>
            </div>
            <ErrorMessage content={submitError ? t(submitError) : undefined} />
            <ErrorMessage content={isLogIn ? undefined : t('feedbackDialog.notAuthorizationMessage')} />
            <ActionButtons
              confirmButtonText={t('feedbackDialog.confirm')}
              cancelButtonText={t('feedbackDialog.cancel')}
              onCancelButtonClick={onClose}
            />
          </form>
        </BaseDialog>
      )}
    />
  );
}

FeedbackDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeedbackDialog;
