import React from 'react';
import PropTypes from 'prop-types';

function NotebookIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M33.75 12C34.375 12 34.9062 12.2188 35.3438 12.6562C35.7812 13.0938 36 13.625 36 14.25V30H12V14.25C12 13.625 12.2188 13.0938 12.6562 12.6562C13.0938 12.2188 13.625 12 14.25 12H33.75ZM33 27V15H15V27H33Z" />
      <path fill={color} d="M38.25 31.5C38.4688 31.5 38.6406 31.5781 38.7656 31.7344C38.9219 31.8594 39 32.0312 39 32.25V33C39 33.8125 38.7031 34.5156 38.1094 35.1094C37.5156 35.7031 36.8125 36 36 36H12C11.1875 36 10.4844 35.7031 9.89062 35.1094C9.29688 34.5156 9 33.8125 9 33V32.25C9 32.0312 9.0625 31.8594 9.1875 31.7344C9.34375 31.5781 9.53125 31.5 9.75 31.5H20.9531C20.9531 31.8438 21.1094 32.1875 21.4219 32.5312C21.7344 32.8438 22.0938 33 22.5 33H25.3594C25.7969 33 26.1562 32.875 26.4375 32.625C26.7188 32.3438 26.875 31.9688 26.9062 31.5H38.25ZM33 15V27H15V15H33ZM22.5469 23.7188C22.7031 23.5938 22.7812 23.4219 22.7812 23.2031C22.7812 22.9844 22.7031 22.8125 22.5469 22.6875L20.8594 21L22.5469 19.3125C22.7031 19.1875 22.7812 19.0156 22.7812 18.7969C22.7812 18.5781 22.7031 18.4062 22.5469 18.2812L22.0312 17.7188C21.875 17.5938 21.6875 17.5312 21.4688 17.5312C21.2812 17.5312 21.1094 17.5938 20.9531 17.7188L18.2344 20.4844C18.0781 20.6094 18 20.7812 18 21C18 21.2188 18.0781 21.3906 18.2344 21.5156L20.9531 24.2812C21.1094 24.4062 21.2812 24.4688 21.4688 24.4688C21.6875 24.4688 21.875 24.4062 22.0312 24.2812L22.5469 23.7188ZM29.7656 21.5156C29.9219 21.3906 30 21.2188 30 21C30 20.7812 29.9219 20.6094 29.7656 20.4844L27.0469 17.7188C26.8906 17.5938 26.7031 17.5312 26.4844 17.5312C26.2969 17.5312 26.125 17.5938 25.9688 17.7188L25.4531 18.2812C25.2969 18.4062 25.2188 18.5781 25.2188 18.7969C25.2188 19.0156 25.2969 19.1875 25.4531 19.3125L27.1406 21L25.4531 22.6875C25.2969 22.8125 25.2188 22.9844 25.2188 23.2031C25.2188 23.4219 25.2969 23.5938 25.4531 23.7188L25.9688 24.2812C26.125 24.4062 26.2969 24.4688 26.4844 24.4688C26.7031 24.4688 26.8906 24.4062 27.0469 24.2812L29.7656 21.5156Z" />
    </svg>
  );
}

NotebookIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default NotebookIcon;
