import React from 'react';
import PropTypes from 'prop-types';

function ImageIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M37.5 12C37.9062 12 38.25 12.1562 38.5312 12.4688C38.8438 12.75 39 13.0938 39 13.5V28.5C39 28.9062 38.8438 29.2656 38.5312 29.5781C38.25 29.8594 37.9062 30 37.5 30H31.5V15H22.5V18H15V13.5C15 13.0938 15.1406 12.75 15.4219 12.4688C15.7344 12.1562 16.0938 12 16.5 12H37.5ZM19.875 16.8281V15.4219C19.875 15.1406 19.7344 15 19.4531 15H18.0469C17.7656 15 17.625 15.1406 17.625 15.4219V16.8281C17.625 17.1094 17.7656 17.25 18.0469 17.25H19.4531C19.7344 17.25 19.875 17.1094 19.875 16.8281ZM36.375 26.5781V25.1719C36.375 24.8906 36.2344 24.75 35.9531 24.75H34.5469C34.2656 24.75 34.125 24.8906 34.125 25.1719V26.5781C34.125 26.8594 34.2656 27 34.5469 27H35.9531C36.2344 27 36.375 26.8594 36.375 26.5781ZM36.375 21.7031V20.2969C36.375 20.0156 36.2344 19.875 35.9531 19.875H34.5469C34.2656 19.875 34.125 20.0156 34.125 20.2969V21.7031C34.125 21.9844 34.2656 22.125 34.5469 22.125H35.9531C36.2344 22.125 36.375 21.9844 36.375 21.7031ZM36.375 16.8281V15.4219C36.375 15.1406 36.2344 15 35.9531 15H34.5469C34.2656 15 34.125 15.1406 34.125 15.4219V16.8281C34.125 17.1094 34.2656 17.25 34.5469 17.25H35.9531C36.2344 17.25 36.375 17.1094 36.375 16.8281Z" />
      <path fill={color} d="M28.5 19.5C28.9062 19.5 29.25 19.6562 29.5312 19.9688C29.8438 20.25 30 20.5938 30 21V34.5C30 34.9062 29.8438 35.25 29.5312 35.5312C29.25 35.8438 28.9062 36 28.5 36H10.5C10.0938 36 9.73438 35.8438 9.42188 35.5312C9.14062 35.25 9 34.9062 9 34.5V21C9 20.5938 9.14062 20.25 9.42188 19.9688C9.73438 19.6562 10.0938 19.5 10.5 19.5H28.5ZM14.5312 22.9688C14.25 22.6562 13.9062 22.5 13.5 22.5C13.0938 22.5 12.7344 22.6562 12.4219 22.9688C12.1406 23.25 12 23.5938 12 24C12 24.4062 12.1406 24.7656 12.4219 25.0781C12.7344 25.3594 13.0938 25.5 13.5 25.5C13.9062 25.5 14.25 25.3594 14.5312 25.0781C14.8438 24.7656 15 24.4062 15 24C15 23.5938 14.8438 23.25 14.5312 22.9688ZM27 33V28.5L22.5 24L16.5 30L15 28.5L12 31.5V33H27Z" />
    </svg>
  );
}

ImageIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ImageIcon;
