import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import _isEqual from 'lodash/isEqual';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import AchieveBox from '../AchieveBox/AchieveBox';
import GoalFull from '../../../DataCore/Models/GoalFull';
import GoalService from '../../../DataCore/Service/GoalService';
import ToastService from '../../../DataCore/Service/ToastService';
import Goal from '../../../DataCore/Models/Goal';

function AchieveBoxDrag({
  goal,
  index,
  onReorder,
}) {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPES.GOAL_FROM_ACHIEVE,
    canDrop: () => !!(index && onReorder),
    drop: (item) => ({
      name: GOAL_DROP.ACHIEVE,
      goal: new GoalFull({
        ...item.goal,
        spaceId: goal.spaceId,
        space: goal.space,
        index,
      }),
    }),
    hover(item) {
      if (!ref.current || index === undefined || !onReorder) {
        return;
      }
      const dragIndex = item.index;
      const dragSpaceId = item.spaceId;
      const dragStartSpaceId = item.startSpaceId;
      const hoverIndex = index;
      const hoverSpaceId = goal.spaceId;
      if (dragSpaceId === hoverSpaceId && dragIndex === hoverIndex) {
        return;
      }
      onReorder({
        sourceSpaceId: dragStartSpaceId,
        goal: new GoalFull({
          ...item.goal,
          index: hoverIndex,
          spaceId: hoverSpaceId,
          space: goal.space,
        }),
      });
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
      // eslint-disable-next-line no-param-reassign
      item.spaceId = hoverSpaceId;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPES.GOAL_FROM_ACHIEVE,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => ({
      goal,
      spaceId: goal.spaceId,
      startSpaceId: goal.spaceId,
      index,
    }),
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult?.name === GOAL_DROP.ACHIEVE) {
        const goalIsEqual = () => _isEqual(new Goal(dropResult.goal), new Goal(item.goal));
        if (dropResult.goal && !goalIsEqual()) {
          const result = await GoalService.moveOfAchievementToSpace(goal.spaceId, dropResult.goal);
          if (!result.status) {
            ToastService.createNetworkDangerToast(result, 'achieveBox.dropError');
          }
        }
      } else if (dropResult?.name === GOAL_DROP.TOP_ACHIEVE) {
        const goalIsEqual = () => _isEqual(new Goal(dropResult.goal), new Goal(item.goal));
        if (dropResult.goal && !goalIsEqual()) {
          const result = await GoalService.moveToTopAchievements(goal.spaceId, dropResult.goal);
          if (!result.status) {
            ToastService.createNetworkDangerToast(result, 'achieveBox.dropError');
          }
        }
      }
      onReorder?.call();
    },
  });

  drag(drop(ref));

  return (
    <AchieveBox
      innerRef={ref}
      transparency={isDragging}
      goal={goal}
    />
  );
}

AchieveBoxDrag.propTypes = {
  goal: PropTypes.object.isRequired,
  index: PropTypes.number,
  onReorder: PropTypes.func,
};

AchieveBoxDrag.defaultProps = {
  index: undefined,
  onReorder: undefined,
};

export default AchieveBoxDrag;
