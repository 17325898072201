/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import _some from 'lodash/some';
import _findIndex from 'lodash/findIndex';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import styles from './AchievesPage.module.scss';
import {
  getAchievementsTotal,
  getAchievementsTotalIntoSpaces,
  getLatestAchievements,
  getLoadingAchievements,
  getLoadingAchievementsIntoSpaces,
  getLoadingTopAchievements,
  getSpacesWithAchievements,
  getTopAchievements,
} from '../../DataCore/Store/Achievement/slice';
import { ReactComponent as StarSvg } from '../../Common/Images/Star.svg';
import { ReactComponent as CloseFullscreenSvg } from '../../Common/Images/Collapse.svg';
import Button from '../../Common/Components/Button/Button';
import SpaceAchieveBox from '../../Common/Components/SpaceAchieveBox/SpaceAchieveBox';
import {
  DRAG_TYPES, GOAL_DROP, SPACE_COLORS, SPACE_ICONS,
} from '../../Common/const';
import AchieveBoxDrag from '../../Common/Components/AchieveBoxDrag/AchieveBoxDrag';
import AchievementService from '../../DataCore/Service/AchievementService';
import TopAchieveBox from '../../Common/Components/TopAchieveBox/TopAchieveBox';
import SortableSpaces from '../../Common/Components/SortableSpaces/SortableSpaces';
import GoalFull from '../../DataCore/Models/GoalFull';
import CustomScroll from '../../Common/Components/CustomScroll/CustomScroll';
import GoalBoxPlaceholder from '../../Common/Components/GoalBoxPlaceholder/GoalBoxPlaceholder';

const pageSize = +process.env.REACT_APP_PAGE_SIZE || 20;

function AchievesDrop({ space, render, onReorder }) {
  const [, dropGoal] = useDrop({
    accept: DRAG_TYPES.GOAL_FROM_ACHIEVE,
    drop: (item) => {
      const findIndex = _findIndex(space.goals, ['id', item.goal.id]);
      return ({
        name: GOAL_DROP.ACHIEVE,
        goal: new GoalFull({
          ...item.goal,
          spaceId: space.id,
          index: findIndex >= 0 ? findIndex : space.goals.length,
        }),
      });
    },
    hover(item, monitor) {
      const hoverOnGoal = !monitor.isOver({ shallow: true });
      if (hoverOnGoal) {
        return;
      }

      if (_some(space.goals, ['id', item.goal.id])) {
        return;
      }

      const hoverIndex = space.goals.length;
      const hoverSpaceId = space.id;
      const dragSpaceId = item.spaceId;
      const dragStartSpaceId = item.startSpaceId;
      if (dragSpaceId === hoverSpaceId) {
        return;
      }
      onReorder({
        sourceSpaceId: dragStartSpaceId,
        goal: new GoalFull({
          ...item.goal,
          index: hoverIndex,
          spaceId: hoverSpaceId,
          space,
        }),
      });
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
      // eslint-disable-next-line no-param-reassign
      item.spaceId = hoverSpaceId;
    },
  });

  return (
    render({ innerRef: dropGoal })
  );
}

function AchievesPage() {
  const achievements = useSelector(getLatestAchievements);
  const achievementsTotal = useSelector(getAchievementsTotal);
  const loadingAchievements = useSelector(getLoadingAchievements);

  const topAchievements = useSelector(getTopAchievements);
  const loadingTopAchievements = useSelector(getLoadingTopAchievements);

  const spacesWithAchievements = useSelector(getSpacesWithAchievements);
  const loadingAchievementsIntoSpaces = useSelector(getLoadingAchievementsIntoSpaces);
  const achievementsTotalIntoSpaces = useSelector(getAchievementsTotalIntoSpaces);

  const [selectAllAchieve, setSelectAllAchieve] = useState(true);
  const { t } = useTranslation('translation', { keyPrefix: 'achievesPage' });

  const showPlaceholderTopAchievements = useMemo(
    () => loadingTopAchievements && _isEmpty(topAchievements),
    [loadingTopAchievements, topAchievements],
  );

  const placeholderCount = useMemo(() => {
    if (!achievementsTotal) {
      return _random(1, 5);
    }

    const unloadedCount = achievementsTotal - achievements.length;
    return Math.min(pageSize, unloadedCount);
  }, [achievementsTotal, achievements.length]);

  const dropAccept = useMemo(() => [DRAG_TYPES.SPACE, DRAG_TYPES.GOAL_FROM_ACHIEVE], []);

  useEffect(() => {
    AchievementService.loadTopAchievements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectAllAchieve
      && spacesWithAchievements.length
      && _isEmpty(loadingAchievementsIntoSpaces)) {
      spacesWithAchievements.forEach((space) => AchievementService.reloadBySpaceId(space.id));
    }
  }, [loadingAchievementsIntoSpaces, selectAllAchieve, spacesWithAchievements]);

  const handleReorderAchieve = ({ goal: reorderAchievement } = {}) => {
    AchievementService.setReorderAchievement(reorderAchievement);
  };

  return (
    <div className={styles.box}>
      <CustomScroll
        onEndListY={async () => {
          if (selectAllAchieve && achievementsTotal > achievements.length) {
            await AchievementService.loadMore();
          }
        }}
        dropAccept={dropAccept}
        className={styles.content}
      >
        <div className={styles.header}>
          <div className={styles.closeButton} />
          <div className={styles.title}>
            <StarSvg className={styles.titleIcon} />
            <h3 className={styles.titleText}>{t('achievements')}</h3>
          </div>
          <Link to="/">
            <Button className={styles.closeButton}>
              <CloseFullscreenSvg />
            </Button>
          </Link>
        </div>
        <div>
          <div className={styles.topAchievementsBox}>
            <TopAchieveBox
              number={1}
              onReorder={handleReorderAchieve}
              placeholder={showPlaceholderTopAchievements}
              goal={topAchievements[1]}
            />
            <TopAchieveBox
              number={2}
              onReorder={handleReorderAchieve}
              placeholder={showPlaceholderTopAchievements}
              goal={topAchievements[2]}
            />
            <TopAchieveBox
              number={3}
              onReorder={handleReorderAchieve}
              placeholder={showPlaceholderTopAchievements}
              goal={topAchievements[3]}
            />
            <TopAchieveBox
              number={4}
              onReorder={handleReorderAchieve}
              placeholder={showPlaceholderTopAchievements}
              goal={topAchievements[4]}
            />
            <TopAchieveBox
              number={5}
              onReorder={handleReorderAchieve}
              placeholder={showPlaceholderTopAchievements}
              goal={topAchievements[5]}
            />
          </div>
          <div className={styles.buttonBox}>
            <div className={styles.buttonGroup}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                className={classNames(styles.radioButton, { [styles.selected]: selectAllAchieve })}
                type="button"
                onClick={() => setSelectAllAchieve(true)}
              >
                {t('allAchievements')}
              </button>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                className={classNames(styles.radioButton, { [styles.selected]: !selectAllAchieve })}
                type="button"
                onClick={() => setSelectAllAchieve(false)}
              >
                {t('bySpaces')}
              </button>
            </div>
          </div>
          {selectAllAchieve ? (
            <SpaceAchieveBox spaceName={t('allAchievements')} color={SPACE_COLORS.GREEN} spaceIcon={SPACE_ICONS.PERSON}>
              <div className={styles.achievements}>
                {achievements.map((goal) => (
                  <AchieveBoxDrag
                    key={goal.id}
                    goal={goal}
                    onReorder={handleReorderAchieve}
                  />
                ))}
                {loadingAchievements
                  ? [...Array(placeholderCount)].map((item, index) => (
                    <GoalBoxPlaceholder key={`placeholder_${index}`} achievement />
                  )) : null}
              </div>
            </SpaceAchieveBox>
          ) : (
            <div className={styles.spaceAchieves}>
              <SortableSpaces
                spaces={spacesWithAchievements}
                renderSpace={({ innerRef, space, isDragging }) => (
                  <SpaceAchieveBox
                    innerRef={innerRef}
                    className={styles.spaceBox}
                    color={SPACE_COLORS[space.color]}
                    spaceName={space.name}
                    spaceIcon={space.icon}
                    transparency={isDragging}
                  >
                    <AchievesDrop
                      space={space}
                      onReorder={handleReorderAchieve}
                      render={({ innerRef: ref }) => (
                        <CustomScroll
                          scrollLiftOnWheel
                          onEndListX={async () => {
                            const total = _get(achievementsTotalIntoSpaces, space.id, 0);
                            if (space.goals.length < total) {
                              await AchievementService.loadMoreBySpaceId(space.id);
                            }
                          }}
                        >
                          <div ref={ref} className={styles.achievementsLine}>
                            {space.goals.map((goal, i) => (
                              <AchieveBoxDrag
                                key={goal.id}
                                goal={goal}
                                index={i}
                                onReorder={handleReorderAchieve}
                              />
                            ))}
                            {loadingAchievementsIntoSpaces[space.id] && !space.goals.length
                              ? [...Array(_random(1, 5))].map((item, index) => (
                                <GoalBoxPlaceholder key={`placeholder_${index}`} achievement />
                              )) : null}
                          </div>
                        </CustomScroll>
                      )}
                    />
                  </SpaceAchieveBox>
                )}
              />
            </div>
          )}
        </div>
      </CustomScroll>
    </div>
  );
}

export default AchievesPage;
