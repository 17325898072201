import moment from 'moment';
import { DATE_FORMAT } from '../../Common/const';
import Space from './Space';

const ID = 'id';
const NAME = 'name';
const ICON = 'icon';
const DESCRIPTION = 'description';
const STATE = 'state';
const INDEX = 'index';
const DEADLINE = 'deadline';
const COMPLETED_DATE = 'compeleteDate';
const SPACE_ID = 'spaceId';
const SPACE = 'space';
const SHARE_LINK = 'shareLink';
const FAVORITE_INDEX = 'favoriteIndex';

export default class GoalFull {
  constructor(parameters) {
    const {
      id, name, icon, description, state, index, deadline, completedDate, spaceId, space, shareLink,
      favoriteIndex,
    } = parameters;
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.description = description;
    this.state = state;
    this.index = index;
    this.deadline = deadline;
    this.completedDate = completedDate;
    this.space = space;
    this.spaceId = spaceId;
    this.shareLink = shareLink;
    this.favoriteIndex = favoriteIndex;
  }

  static fromApi(object = {}) {
    const deadline = object[DEADLINE] && moment(object[DEADLINE]);
    const completedDate = object[COMPLETED_DATE] && moment(object[COMPLETED_DATE]);
    return new GoalFull({
      id: object[ID],
      name: object[NAME],
      icon: object[ICON],
      description: object[DESCRIPTION],
      state: object[STATE],
      index: object[INDEX],
      deadline: deadline?.isValid() ? deadline.format(DATE_FORMAT) : '',
      completedDate: completedDate?.isValid() ? completedDate.format(DATE_FORMAT) : '',
      spaceId: object[SPACE_ID],
      space: object[SPACE] && Space.fromApi(object[SPACE]),
      shareLink: object[SHARE_LINK],
      favoriteIndex: object[FAVORITE_INDEX],
    });
  }

  toApi() {
    return {
      [ID]: this.id,
      [NAME]: this.name,
      [ICON]: this.icon,
      [DESCRIPTION]: this.description,
      [STATE]: this.state,
      [INDEX]: this.index,
      [DEADLINE]: this.deadline,
      [COMPLETED_DATE]: this.completedDate,
      [SPACE_ID]: this.spaceId,
      [FAVORITE_INDEX]: this.favoriteIndex,
    };
  }
}
