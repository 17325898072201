import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SPACE_ICONS } from '../../const';
import SpaceService from '../../../DataCore/Service/SpaceService';
import SpaceFormDialog from '../SpaceFormDialog/SpaceFormDialog';
import { getSpaces } from '../../../DataCore/Store/Space/slice';
import { parseFormErrors } from '../../func';

function CreateSpaceDialog({ showDialog, onClose }) {
  const spaces = useSelector(getSpaces);
  const { t } = useTranslation('translation', { keyPrefix: 'spaceForm' });

  const handleCreateSpace = async (space) => {
    const result = await SpaceService.create(space);
    if (result.status) {
      onClose();
      return undefined;
    }
    return parseFormErrors(result);
  };

  return (
    <SpaceFormDialog
      showDialog={showDialog}
      onSubmit={handleCreateSpace}
      onCancel={onClose}
      initialValues={{
        name: t('spaceIconUser'),
        color: 'BLUE',
        icon: SPACE_ICONS.USER,
        index: spaces.length,
      }}
    />
  );
}

CreateSpaceDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateSpaceDialog;
