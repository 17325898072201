import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Img } from 'react-image';
import { EMOJI } from '../../const';
import styles from './GoalIcon.module.scss';
import Loader from '../Loader/Loader';
import { ReactComponent as ImageSvg } from '../../Images/Image.svg';

const { REACT_APP_EMOJI_URL } = process.env;

function GoalIcon({
  iconName, size, selectedIcon, className, loader, ...props
}) {
  const src = `${REACT_APP_EMOJI_URL}/${size}/${iconName}.png`;

  const imgClass = useMemo(
    () => classNames(styles.icon, { [styles.select]: selectedIcon === iconName }, className),
    [iconName, selectedIcon, className],
  );

  const localProps = {};
  if (loader) {
    localProps.loader = (
      <div className={classNames(imgClass, styles.box)}>
        <Loader className={styles.loader} />
      </div>
    );
  }

  return (
    <Img
      className={imgClass}
      src={src}
      {...localProps}
      unloader={(
        <div className={classNames(imgClass, styles.box)}>
          <ImageSvg className={styles.errorImage} />
        </div>
      )}
      {...props}
    />
  );
}

GoalIcon.propTypes = {
  iconName: PropTypes.oneOf(Object.values(EMOJI).map(({ name }) => name)).isRequired,
  size: PropTypes.oneOf([20, 26, 32, 40, 48, 52, 64, 96, 160]),
  selectedIcon: PropTypes.oneOf(Object.values(EMOJI).map(({ name }) => name)),
  className: PropTypes.string,
  loader: PropTypes.bool,
};

GoalIcon.defaultProps = {
  size: 40,
  selectedIcon: undefined,
  className: '',
  loader: true,
};

export default GoalIcon;
