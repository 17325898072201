import _filter from 'lodash/filter';
import { endLoadingLoginProviders, setAvailableLoginProviders, startLoadingLoginProviders } from '../Store/LoginProvider/slice';
import LoginProviderRepository from '../Repositories/Api/LoginProviderRepository';
import { LOGIN_PROVIDER_TYPES } from '../../Common/const';
import store from '../Store/store';

export default class LoginProviderService {
  static async getAll() {
    store.dispatch(startLoadingLoginProviders());

    const result = await LoginProviderRepository.getAll();
    if (!result.status) {
      store.dispatch(endLoadingLoginProviders());
      return result;
    }

    const types = Object.values(LOGIN_PROVIDER_TYPES);
    const loginProviders = _filter(result.data, (item) => types.includes(item.type));
    const availableLoginProviders = _filter(loginProviders, ['isLinked', false]);
    store.dispatch(setAvailableLoginProviders(availableLoginProviders));
    store.dispatch(endLoadingLoginProviders());

    return { status: true };
  }
}
