import React from 'react';
import PropTypes from 'prop-types';

function CalendarIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M13.5 21H34.5V33.75C34.5 34.375 34.2812 34.9062 33.8438 35.3438C33.4062 35.7812 32.875 36 32.25 36H15.75C15.125 36 14.5938 35.7812 14.1562 35.3438C13.7188 34.9062 13.5 34.375 13.5 33.75V21ZM29.6719 26.2969C29.7969 26.1719 29.8594 26.0469 29.8594 25.9219C29.8594 25.7656 29.7969 25.625 29.6719 25.5L28.3594 24.1406C28.2656 24.0469 28.1406 24 27.9844 24C27.8281 24 27.6875 24.0469 27.5625 24.1406L22.5938 29.1094L20.4375 26.9062C20.3438 26.8125 20.2188 26.7656 20.0625 26.7656C19.9062 26.7656 19.7656 26.8125 19.6406 26.9062L18.3281 28.2188V28.2656C18.2031 28.3594 18.1406 28.4844 18.1406 28.6406C18.1406 28.7969 18.2031 28.9375 18.3281 29.0625L22.1719 32.9531C22.2969 33.0469 22.4375 33.0938 22.5938 33.0938C22.75 33.0938 22.875 33.0469 22.9688 32.9531L29.6719 26.2969ZM27.75 18C27.5312 18 27.3438 17.9375 27.1875 17.8125C27.0625 17.6562 27 17.4688 27 17.25V12.75C27 12.5312 27.0625 12.3594 27.1875 12.2344C27.3438 12.0781 27.5312 12 27.75 12H29.25C29.4688 12 29.6406 12.0781 29.7656 12.2344C29.9219 12.3594 30 12.5312 30 12.75V17.25C30 17.4688 29.9219 17.6562 29.7656 17.8125C29.6406 17.9375 29.4688 18 29.25 18H27.75ZM18.75 18C18.5312 18 18.3438 17.9375 18.1875 17.8125C18.0625 17.6562 18 17.4688 18 17.25V12.75C18 12.5312 18.0625 12.3594 18.1875 12.2344C18.3438 12.0781 18.5312 12 18.75 12H20.25C20.4688 12 20.6406 12.0781 20.7656 12.2344C20.9219 12.3594 21 12.5312 21 12.75V17.25C21 17.4688 20.9219 17.6562 20.7656 17.8125C20.6406 17.9375 20.4688 18 20.25 18H18.75Z" />
      <path fill={color} d="M29.6719 25.5C29.7969 25.625 29.8594 25.7656 29.8594 25.9219C29.8594 26.0469 29.7969 26.1719 29.6719 26.2969L22.9688 32.9531C22.875 33.0469 22.75 33.0938 22.5938 33.0938C22.4375 33.0938 22.2969 33.0469 22.1719 32.9531L18.3281 29.0156C18.2031 28.9219 18.1406 28.7969 18.1406 28.6406C18.1406 28.4844 18.2031 28.3438 18.3281 28.2188L19.6406 26.9062C19.7656 26.8125 19.9062 26.7656 20.0625 26.7656C20.2188 26.7656 20.3438 26.8125 20.4375 26.9062L22.5938 29.1094L27.5625 24.1875C27.6875 24.0625 27.8281 24 27.9844 24C28.1406 24 28.2656 24.0625 28.3594 24.1875L29.6719 25.5ZM32.25 15C32.875 15 33.4062 15.2188 33.8438 15.6562C34.2812 16.0938 34.5 16.625 34.5 17.25V21H13.5V17.25C13.5 16.625 13.7188 16.0938 14.1562 15.6562C14.5938 15.2188 15.125 15 15.75 15H18V17.25C18 17.4688 18.0625 17.6562 18.1875 17.8125C18.3438 17.9375 18.5312 18 18.75 18H20.25C20.4688 18 20.6406 17.9375 20.7656 17.8125C20.9219 17.6562 21 17.4688 21 17.25V15H27V17.25C27 17.4688 27.0625 17.6562 27.1875 17.8125C27.3438 17.9375 27.5312 18 27.75 18H29.25C29.4688 18 29.6406 17.9375 29.7656 17.8125C29.9219 17.6562 30 17.4688 30 17.25V15H32.25Z" />
    </svg>
  );
}

CalendarIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default CalendarIcon;
