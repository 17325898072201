import _sortBy from 'lodash/sortBy';
import { EMOJI, EMOJI_CATEGORIES, LAST_USED_EMOJI_KEY } from '../../../Common/const';

const getCategoryIndex = (category) => {
  switch (category) {
    case EMOJI_CATEGORIES.PEOPLE:
      return 1;
    case EMOJI_CATEGORIES.NATURE:
      return 2;
    case EMOJI_CATEGORIES.FOOD_AND_DRINK:
      return 3;
    case EMOJI_CATEGORIES.ACTIVITY:
      return 4;
    case EMOJI_CATEGORIES.TRAVEL_AND_PLACES:
      return 5;
    case EMOJI_CATEGORIES.OBJECTS:
      return 6;
    case EMOJI_CATEGORIES.SYMBOLS_AND_FLAGS:
      return 7;
    default:
      return 8;
  }
};

const excludeRegExp = / [1-9]$/;
const emoji = _sortBy(Object.values(EMOJI), ({ category }) => getCategoryIndex(category))
  .filter((item) => !excludeRegExp.test(item.search));

const defaultLastUsedEmoji = [
  EMOJI.STAR,
  EMOJI.GRINNING_FACE,
  EMOJI.SUNGLASSES,
  EMOJI.SMILING_FACE_WITH_GLASSES,
  EMOJI.THUMBS_UP_0,
  EMOJI.HANDS_RAISED_IN_CELEBRATION_0,
  EMOJI.FLEXED_BICEP_0,
  EMOJI.HANDS_PRESSED_TOGETHER_0,
  EMOJI.AIRPLANE,
  EMOJI.GLOBE_SHOWING_AMERICAS,
  EMOJI.ONCOMING_CAR,
  EMOJI.LAPTOP,
  EMOJI.PRESENT,
  EMOJI.GEMSTONE,
  EMOJI.WINE_GLASS,
  EMOJI.COOKIE,
];
const savedLastUsedEmoji = JSON.parse(localStorage.getItem(LAST_USED_EMOJI_KEY) || '[]');
const lastUsedEmoji = [...savedLastUsedEmoji, ...defaultLastUsedEmoji].slice(0, 16);

const initialState = {
  allEmoji: emoji,
  visibleEmojiCount: 48,
  search: '',
  filterCategory: undefined,
  lastUsedEmoji,
};

export default initialState;
