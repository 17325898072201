import React from 'react';
import PropTypes from 'prop-types';

function TruckIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M17.3906 29.3906C17.9844 28.7969 18.6875 28.5 19.5 28.5C20.3125 28.5 21.0156 28.7969 21.6094 29.3906C22.2031 29.9844 22.5 30.6875 22.5 31.5C22.5 32.3125 22.2031 33.0156 21.6094 33.6094C21.0156 34.2031 20.3125 34.5 19.5 34.5C18.6875 34.5 17.9844 34.2031 17.3906 33.6094C16.7969 33.0156 16.5 32.3125 16.5 31.5C16.5 30.6875 16.7969 29.9844 17.3906 29.3906ZM9.89062 29.3906C10.4844 28.7969 11.1875 28.5 12 28.5C12.8125 28.5 13.5156 28.7969 14.1094 29.3906C14.7031 29.9844 15 30.6875 15 31.5C15 32.3125 14.7031 33.0156 14.1094 33.6094C13.5156 34.2031 12.8125 34.5 12 34.5C11.1875 34.5 10.4844 34.2031 9.89062 33.6094C9.29688 33.0156 9 32.3125 9 31.5C9 30.6875 9.29688 29.9844 9.89062 29.3906ZM31.6406 29.3906C32.2344 28.7969 32.9375 28.5 33.75 28.5C34.5625 28.5 35.2656 28.7969 35.8594 29.3906C36.4531 29.9844 36.75 30.6875 36.75 31.5C36.75 32.3125 36.4531 33.0156 35.8594 33.6094C35.2656 34.2031 34.5625 34.5 33.75 34.5C32.9375 34.5 32.2344 34.2031 31.6406 33.6094C31.0469 33.0156 30.75 32.3125 30.75 31.5C30.75 30.6875 31.0469 29.9844 31.6406 29.3906Z" />
      <path fill={color} d="M38.1094 23.1094C38.7031 23.7031 39 24.4062 39 25.2188V29.25C39 29.4688 38.9219 29.6562 38.7656 29.8125C38.6406 29.9375 38.4688 30 38.25 30H38.0156C37.7031 29.1875 37.125 28.4844 36.2812 27.8906C35.4688 27.2969 34.625 27 33.75 27C32.875 27 32.0156 27.2969 31.1719 27.8906C30.3594 28.4844 29.8125 29.1875 29.5312 30H23.7656C23.4531 29.1875 22.875 28.4844 22.0312 27.8906C21.2188 27.2969 20.375 27 19.5 27C18.8438 27 18.1406 27.2031 17.3906 27.6094C16.6719 27.9844 16.125 28.4531 15.75 29.0156C15.375 28.4531 14.8125 27.9844 14.0625 27.6094C13.3438 27.2031 12.6562 27 12 27C11.5312 27 11 27.1094 10.4062 27.3281C9.8125 27.5469 9.34375 27.8281 9 28.1719V15C9 14.5938 9.14062 14.25 9.42188 13.9688C9.73438 13.6562 10.0938 13.5 10.5 13.5H30C30.4062 13.5 30.75 13.6562 31.0312 13.9688C31.3438 14.25 31.5 14.5938 31.5 15V19.5H33.2812C34.0938 19.5 34.7969 19.7969 35.3906 20.3906L38.1094 23.1094ZM31.5 24H35.8125L33.7969 21.9844C33.6406 21.8281 33.4688 21.75 33.2812 21.75H31.5V24Z" />
    </svg>
  );
}

TruckIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default TruckIcon;
