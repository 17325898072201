import { createSlice, createSelector } from '@reduxjs/toolkit';
import _filter from 'lodash/filter';
import initialState from './initialState';

const emojiSlice = createSlice({
  name: 'emoji',
  initialState,
  reducers: {
    searchEmoji: (state, action) => {
      const search = action.payload;
      return ({
        ...state,
        search,
        visibleEmojiCount: 48,
      });
    },
    visibleMoreEmoji: (state) => {
      const { visibleEmojiCount } = state;
      return ({
        ...state,
        visibleEmojiCount: visibleEmojiCount + 48,
      });
    },
    usedEmoji: (state, action) => {
      const emoji = action.payload;
      const filterLastUsedEmoji = _filter(state.lastUsedEmoji, ({ name }) => name !== emoji.name);
      const lastUsedEmoji = [emoji, ...filterLastUsedEmoji].slice(0, 16);
      return {
        ...state,
        lastUsedEmoji,
      };
    },
    resetSearchEmoji: (state) => ({
      ...initialState,
      lastUsedEmoji: state.lastUsedEmoji,
    }),
    setCategory: (state, action) => {
      const filterCategory = action.payload;

      return {
        ...state,
        filterCategory,
        visibleEmojiCount: 48,
      };
    },
  },
});

export const {
  searchEmoji, visibleMoreEmoji, resetSearchEmoji, usedEmoji, setCategory,
} = emojiSlice.actions;

const getFilteredEmoji = createSelector(
  [
    (state) => state.emoji.allEmoji,
    (state) => state.emoji.filterCategory,
    (state) => state.emoji.search,
  ],
  (allEmoji, filterCategory, searchStr) => {
    let result = allEmoji;

    if (filterCategory) {
      result = _filter(result, ['category', filterCategory]);
    }

    if (searchStr) {
      result = _filter(result, ({ search }) => search.includes(searchStr));
    }

    return result;
  },
);

export const getVisibleEmoji = createSelector(
  [
    getFilteredEmoji,
    (state) => state.emoji.visibleEmojiCount,
  ],
  (filteredEmoji, visibleEmojiCount) => {
    if (filteredEmoji.length < visibleEmojiCount) {
      return filteredEmoji;
    }

    return filteredEmoji.slice(0, visibleEmojiCount);
  },
);

export const getLastUsedEmoji = (state) => state.emoji.lastUsedEmoji;

export const getAllEmoji = (state) => state.emoji.allEmoji;

export const isFilteredEmoji = (state) => state.emoji.search || state.emoji.filterCategory;

export const getFilterCategory = (state) => state.emoji.filterCategory;

export const { reducer } = emojiSlice;
