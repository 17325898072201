import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const blackHoleSlice = createSlice({
  name: 'blackHole',
  initialState,
  reducers: {
    startLoadingBlackHoleGoals: (state) => ({
      ...state,
      loadingGoals: true,
    }),
    endLoadingBlackHoleGoals: (state) => ({
      ...state,
      loadingGoals: false,
    }),
    setBlackHoleGoals: (state, action) => {
      const goals = action.payload;
      return {
        ...state,
        goals,
      };
    },
    resetBlackHole: () => initialState,
  },
});

export const {
  startLoadingBlackHoleGoals,
  endLoadingBlackHoleGoals,
  setBlackHoleGoals,
  resetBlackHole,
} = blackHoleSlice.actions;

export const getBlackHoleGoals = (state) => state.blackHole.goals;

export const getLoadingBlackHoleGoals = (state) => state.blackHole.loadingGoals;

export const { reducer } = blackHoleSlice;
