import React from 'react';
import PropTypes from 'prop-types';

function UserIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M15.75 15.7969C18.0312 13.5156 20.7812 12.375 24 12.375C27.2188 12.375 29.9531 13.5156 32.2031 15.7969C34.4844 18.0469 35.625 20.7812 35.625 24C35.625 27.2188 34.4844 29.9688 32.2031 32.25C29.9531 34.5 27.2188 35.625 24 35.625C20.7812 35.625 18.0312 34.5 15.75 32.25C13.5 29.9688 12.375 27.2188 12.375 24C12.375 20.7812 13.5 18.0469 15.75 15.7969ZM26.9062 18.0938C26.0938 17.2812 25.125 16.875 24 16.875C22.875 16.875 21.9062 17.2812 21.0938 18.0938C20.2812 18.9062 19.875 19.875 19.875 21C19.875 22.125 20.2812 23.0938 21.0938 23.9062C21.9062 24.7188 22.875 25.125 24 25.125C25.125 25.125 26.0938 24.7188 26.9062 23.9062C27.7188 23.0938 28.125 22.125 28.125 21C28.125 19.875 27.7188 18.9062 26.9062 18.0938ZM20.25 32.0625C21.5938 32.6875 22.8438 33 24 33C25.1562 33 26.4062 32.6875 27.75 32.0625C29.0938 31.4375 30.125 30.6875 30.8438 29.8125C30.4062 28.9688 29.7812 28.2969 28.9688 27.7969C28.1562 27.2656 27.25 27 26.25 27C26.125 27 26.0156 27.0156 25.9219 27.0469C25.2969 27.2656 24.6562 27.375 24 27.375C23.3438 27.375 22.7031 27.2656 22.0781 27.0469C21.9844 27.0156 21.875 27 21.75 27C20.75 27 19.8438 27.2656 19.0312 27.7969C18.2188 28.2969 17.5938 28.9688 17.1562 29.8125C17.875 30.6875 18.9062 31.4375 20.25 32.0625Z" />
      <path fill={color} d="M26.9062 23.9062C26.0938 24.7188 25.125 25.125 24 25.125C22.875 25.125 21.9062 24.7188 21.0938 23.9062C20.2812 23.0938 19.875 22.125 19.875 21C19.875 19.875 20.2812 18.9062 21.0938 18.0938C21.9062 17.2812 22.875 16.875 24 16.875C25.125 16.875 26.0938 17.2812 26.9062 18.0938C27.7188 18.9062 28.125 19.875 28.125 21C28.125 22.125 27.7188 23.0938 26.9062 23.9062ZM26.25 27C27.25 27 28.1562 27.2656 28.9688 27.7969C29.7812 28.2969 30.4062 28.9688 30.8438 29.8125C30.5312 30.1875 30.1719 30.5469 29.7656 30.8906C27.8906 32.4844 25.7188 33.1719 23.25 32.9531C20.7812 32.7344 18.75 31.6875 17.1562 29.8125C17.5938 28.9688 18.2188 28.2969 19.0312 27.7969C19.8438 27.2656 20.75 27 21.75 27C21.875 27 21.9844 27.0156 22.0781 27.0469C22.7031 27.2656 23.3438 27.375 24 27.375C24.6562 27.375 25.2969 27.2656 25.9219 27.0469C26.0156 27.0156 26.125 27 26.25 27Z" />
    </svg>
  );
}

UserIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default UserIcon;
