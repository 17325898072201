import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseDialog from '../BaseDialog/BaseDialog';
import styles from './HelpDialog.module.scss';
import { ymAction } from '../../func';
import { YM_ACTION_TYPES } from '../../const';
import PrivacyPolicyLink from '../PrivacyPolicyLink/PrivacyPolicyLink';

const ymOpenHelpOffset = process.env.REACT_APP_YM_OPEN_HELP_OFFSET;

function HelpDialog({ showDialog, onClose }) {
  useEffect(() => {
    let timeoutId;
    if (showDialog) {
      timeoutId = setTimeout(() => {
        ymAction(YM_ACTION_TYPES.OPEN_HELP);
      }, ymOpenHelpOffset);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showDialog]);

  return (
    <BaseDialog className={styles.helpDialog} showDialog={showDialog} onClose={onClose} liteClose>
      <h2>Что такое AchieveMe.live</h2>
      <p>
        AchieveMe.live - это сервис собственной гордости и достоинства. Это
        инструмент, который позволяет каждому заглянуть в себя, в свой
        собственный мир, увидеть свои истинные цели, свои победы, свои
        перспективы, а также заброшенные планы и мечты. Это не социальная
        сеть, это не место, где мы с кем-то соревнуемся, сравниваем себя с
        кем-то, смотрим на других. Здесь мы смотрим в себя и свой безграничный мир.
      </p>
      <h3>Как пользоваться сервисом?</h3>
      <ul>
        <li>
          Создайте для каждой важной сферы жизни отдельную область.
          Например, Семья, Спорт, Здоровье, Работа, Путешествия и другие.
        </li>
        <li>
          Каждый раз, когда у Вас появляется какая-то цель или мечта — сразу же добавляйте
          ее в подходящую область. Не забывайте назначать целям сроки, а также добавлять описание,
          чтобы не забыть о своих желаниях.
        </li>
        <li>
          Для каждой цели задайте подходящую иконку, выбрать которую можно с помощью поиска.
        </li>
        <li>
          Как только Вы достигли цель, просто перетащите ее вниз в область Последних достижений.
          С этого времени Ваша цель станет Вашим достижением.
        </li>
        <li>
          Будьте внимательны со своими достижениями, ведь изменить их не получится,
          они навсегда останутся такими, как Вы их сохраните. Это сделано целенаправленно,
          чтобы Вы осознано относились к тем целям, которые достигаете.
        </li>
        <li>
          Если вдруг по какой-то причине Вы поняли, что достижение той
          или иной цели Вам стало неинтересно, то перетащите ее в Черную дыру
          в правом нижнем углу экрана. Вы всегда сможете вернуться к этим целям
          и перетащить их обратно в одну из областей, если она снова будет Вас интересовать.
        </li>
        <li>
          Не забывайте периодически просматривать свои прошлые достижения,
          подпитывая свою мотивацию и открывая для себя все новые и новые грани Ваших возможностей.
        </li>
        <li>
          И самое главное, организуйте галерею своих достижений,
          выделив те, что наиболее важны и ценны для Вас!
        </li>
      </ul>
      <h3>Остались вопросы?</h3>
      <p>
        Свяжитесь с нами через форму обратной связи.
        Мы всегда рады будем Вам помочь в работе с сервисом или услышать Ваши идеи и предложения!
      </p>
      <PrivacyPolicyLink />
    </BaseDialog>
  );
}

HelpDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HelpDialog;
