import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import Space from '../../../DataCore/Models/Space';
import SpaceService from '../../../DataCore/Service/SpaceService';
import { DRAG_TYPES } from '../../const';
import ToastService from '../../../DataCore/Service/ToastService';

function SpaceDrag({
  space, index, renderSpace, onReorder, onSaveReorder,
}) {
  const ref = useRef(null);

  const [, dropSpace] = useDrop({
    accept: DRAG_TYPES.SPACE,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const dragStartIndex = item.startIndex;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      onReorder(dragStartIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, dragSpace] = useDrag({
    type: DRAG_TYPES.SPACE,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => ({ id: space.id, startIndex: index, index }),
    end() { onSaveReorder(); },
  });
  dragSpace(dropSpace(ref));

  return (
    renderSpace({ innerRef: ref, space, isDragging })
  );
}

function SortableSpaces({ spaces, renderSpace }) {
  const [reorderSpaces, setReorderSpaces] = useState({});

  const sortedSpaces = useMemo(
    () => {
      const { sourceIndex, destinationIndex } = reorderSpaces;
      if (!Number.isInteger(sourceIndex)
        || !Number.isInteger(destinationIndex)
        || sourceIndex === destinationIndex
      ) {
        return spaces;
      }

      const newSpaces = [...spaces];
      const [deletedSpace] = newSpaces.splice(sourceIndex, 1);
      newSpaces.splice(destinationIndex, 0, deletedSpace);
      return newSpaces;
    },
    [spaces, reorderSpaces],
  );

  const handleSaveReorderSpaces = async () => {
    const { sourceIndex, destinationIndex } = reorderSpaces;

    if (Number.isInteger(sourceIndex)
      && Number.isInteger(destinationIndex)
      && sourceIndex !== destinationIndex) {
      const sourceSpace = spaces[sourceIndex];
      const newSpace = new Space({
        ...sourceSpace,
        index: destinationIndex,
      });
      const result = await SpaceService.update(newSpace);
      setReorderSpaces({});
      if (!result.status) {
        ToastService.createNetworkDangerToast(result, 'spaceBox.dropError');
      }
    }
  };

  return (
    <>
      {
        sortedSpaces.map((space, index) => (
          <SpaceDrag
            key={space.id}
            space={space}
            index={index}
            renderSpace={renderSpace}
            onReorder={(sourceIndex, destinationIndex) => {
              setReorderSpaces({ sourceIndex, destinationIndex });
            }}
            onSaveReorder={handleSaveReorderSpaces}
          />
        ))
      }
    </>
  );
}

SortableSpaces.propTypes = {
  spaces: PropTypes.array.isRequired,
  renderSpace: PropTypes.func.isRequired,
};

export default SortableSpaces;
