import {
  resetSearchEmoji, searchEmoji, setCategory, usedEmoji, visibleMoreEmoji,
} from '../Store/Emoji/slice';
import store from '../Store/store';

export default class EmojiService {
  static searchEmoji(query) {
    store.dispatch(searchEmoji(query));
  }

  static visibleMoreEmoji() {
    store.dispatch(visibleMoreEmoji());
  }

  static resetSearchEmoji() {
    store.dispatch(resetSearchEmoji());
  }

  static usedEmoji(emoji) {
    if (!emoji) {
      throw new Error('emoji required');
    }

    store.dispatch(usedEmoji(emoji));
  }

  static toggleCategory(category) {
    const state = store.getState();

    if (state.emoji.filterCategory === category) {
      store.dispatch(setCategory(undefined));
    } else {
      store.dispatch(setCategory(category));
    }
  }
}
