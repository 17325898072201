import React from 'react';
import PropTypes from 'prop-types';

function AirplaneIcon({ color, ...props }) {
  return (
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect fill={color} opacity="0.1" width="40" height="40" rx="10" />
      <path fill={color} opacity="0.5" d="M35 29V30.5C35 30.7188 34.9219 30.8906 34.7656 31.0156C34.6406 31.1719 34.4688 31.25 34.25 31.25H5.75C5.53125 31.25 5.34375 31.1719 5.1875 31.0156C5.0625 30.8906 5 30.7188 5 30.5V29C5 28.7812 5.0625 28.6094 5.1875 28.4844C5.34375 28.3281 5.53125 28.25 5.75 28.25H34.25C34.4688 28.25 34.6406 28.3281 34.7656 28.4844C34.9219 28.6094 35 28.7812 35 29Z" />
      <path fill={color} d="M32.1406 9.5C33.5781 9.59375 34.4844 10.0312 34.8594 10.8125C35.2344 11.5625 35.0312 12.5469 34.25 13.7656C33.5312 14.8906 32.4844 15.7969 31.1094 16.4844L17.4688 23.4219C17.0312 23.6406 16.5625 23.75 16.0625 23.75H9.96875C9.5 23.75 9.125 23.5781 8.84375 23.2344L5.23438 19.3438C5.10938 19.1875 5.04688 19 5.04688 18.7812C5.04688 18.4375 5.20312 18.1875 5.51562 18.0312L7.39062 17.0938C7.51562 17.0312 7.64062 17 7.76562 17C7.89062 17 8.01562 17.0312 8.14062 17.0938L11.5156 18.7812L16.3906 16.3438L9.03125 11.75C8.90625 11.5938 8.84375 11.4062 8.84375 11.1875C8.84375 10.8125 9 10.5469 9.3125 10.3906L12.3594 8.84375C12.4844 8.78125 12.6094 8.75 12.7344 8.75C12.9219 8.75 13.0781 8.79688 13.2031 8.89062L23.4688 12.7344L28.1094 10.3906C29.4531 9.70312 30.7969 9.40625 32.1406 9.5Z" />
    </svg>
  );
}

AirplaneIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AirplaneIcon;
