import React from 'react';
import PropTypes from 'prop-types';

function InfoIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M15.75 15.7969C18.0312 13.5156 20.7812 12.375 24 12.375C27.2188 12.375 29.9531 13.5156 32.2031 15.7969C34.4844 18.0469 35.625 20.7812 35.625 24C35.625 27.2188 34.4844 29.9688 32.2031 32.25C29.9531 34.5 27.2188 35.625 24 35.625C20.7812 35.625 18.0312 34.5 15.75 32.25C13.5 29.9688 12.375 27.2188 12.375 24C12.375 20.7812 13.5 18.0469 15.75 15.7969ZM25.3594 18.1406C24.9844 17.7344 24.5312 17.5312 24 17.5312C23.4688 17.5312 23 17.7344 22.5938 18.1406C22.2188 18.5156 22.0312 18.9688 22.0312 19.5C22.0312 20.0312 22.2188 20.5 22.5938 20.9062C23 21.2812 23.4688 21.4688 24 21.4688C24.5312 21.4688 24.9844 21.2812 25.3594 20.9062C25.7656 20.5 25.9688 20.0312 25.9688 19.5C25.9688 18.9688 25.7656 18.5156 25.3594 18.1406ZM26.625 29.4375V28.3125C26.625 27.9375 26.4375 27.75 26.0625 27.75H25.5V23.0625C25.5 22.6875 25.3125 22.5 24.9375 22.5H21.9375C21.5625 22.5 21.375 22.6875 21.375 23.0625V24.1875C21.375 24.5625 21.5625 24.75 21.9375 24.75H22.5V27.75H21.9375C21.5625 27.75 21.375 27.9375 21.375 28.3125V29.4375C21.375 29.8125 21.5625 30 21.9375 30H26.0625C26.4375 30 26.625 29.8125 26.625 29.4375Z" />
      <path fill={color} d="M25.3594 20.9062C24.9844 21.2812 24.5312 21.4688 24 21.4688C23.4688 21.4688 23 21.2812 22.5938 20.9062C22.2188 20.5 22.0312 20.0312 22.0312 19.5C22.0312 18.9688 22.2188 18.5156 22.5938 18.1406C23 17.7344 23.4688 17.5312 24 17.5312C24.5312 17.5312 24.9844 17.7344 25.3594 18.1406C25.7656 18.5156 25.9688 18.9688 25.9688 19.5C25.9688 20.0312 25.7656 20.5 25.3594 20.9062ZM26.0625 27.75C26.4375 27.75 26.625 27.9375 26.625 28.3125V29.4375C26.625 29.8125 26.4375 30 26.0625 30H21.9375C21.5625 30 21.375 29.8125 21.375 29.4375V28.3125C21.375 27.9375 21.5625 27.75 21.9375 27.75H22.5V24.75H21.9375C21.5625 24.75 21.375 24.5625 21.375 24.1875V23.0625C21.375 22.6875 21.5625 22.5 21.9375 22.5H24.9375C25.3125 22.5 25.5 22.6875 25.5 23.0625V27.75H26.0625Z" />
    </svg>
  );
}

InfoIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default InfoIcon;
