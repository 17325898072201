import TokenRepository from '../../Repositories/LocalStorage/TokenRepository';

const initialState = {
  token: TokenRepository.get(),
  showCreateSpacePopup: false,
  showCreateGoalPopup: false,
  toastMessages: [],
};

export default initialState;
