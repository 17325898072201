import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Scrollbar from 'smooth-scrollbar';
import { useDrop } from 'react-dnd';
import _debounce from 'lodash/debounce';

const scrollOffset = +process.env.REACT_APP_SCROLL_OFFSET || 40;
const scrollBorder = +process.env.REACT_APP_SCROLL_BORDER || 0.2;

function CustomScroll({
  scrollLiftOnWheel, dropAccept, onEndListX, onEndListY, children, ...props
}) {
  const scrollElement = useRef();
  const [scrollbar, setScrollbar] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlerScroll = useCallback(_debounce(() => {
    const maxX = _get(scrollbar, 'limit.x');
    const currentX = _get(scrollbar, 'offset.x');
    if (maxX - currentX < 160) {
      onEndListX?.call();
    }

    const maxY = _get(scrollbar, 'limit.y');
    const currentY = _get(scrollbar, 'offset.y');
    if (maxY - currentY < 160) {
      onEndListY?.call();
    }
  }, 100, { leading: true }), [onEndListX, onEndListY, scrollbar]);

  const handlerWheel = useCallback((event) => {
    if (event.deltaY > 0) {
      if (_get(scrollbar, 'offset.x') !== _get(scrollbar, 'limit.x')) {
        event.preventDefault();
      }
    } else if (_get(scrollbar, 'offset.x')) {
      event.preventDefault();
    }

    scrollbar.scrollLeft += event.deltaY;
  }, [scrollbar]);

  useEffect(() => {
    const element = scrollElement.current;
    setScrollbar(Scrollbar.init(element));

    return () => {
      setScrollbar(undefined);
      Scrollbar.destroy(element);
    };
  }, [scrollElement]);

  useEffect(() => {
    if (!scrollLiftOnWheel) return undefined;

    const element = scrollElement.current;
    element.addEventListener('wheel', handlerWheel, { passive: false });
    return () => {
      element.removeEventListener('wheel', handlerWheel);
    };
  }, [handlerWheel, scrollLiftOnWheel]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const horizonScroll = useCallback(_debounce((offset) => {
    if (scrollbar && offset) {
      if (offset > 0 && _get(scrollbar, 'offset.x') !== _get(scrollbar, 'limit.x')) {
        scrollbar.scrollLeft += offset;
      } else if (offset < 0 && _get(scrollbar, 'offset.x') !== 0) {
        scrollbar.scrollLeft += offset;
      }
    }
  }, 30), [scrollbar]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verticalScroll = useCallback(_debounce((offset) => {
    if (scrollbar && offset) {
      if (offset > 0 && _get(scrollbar, 'offset.y') !== _get(scrollbar, 'limit.y')) {
        scrollbar.scrollTop += offset;
      } else if (offset < 0 && _get(scrollbar, 'offset.y') !== 0) {
        scrollbar.scrollTop += offset;
      }
    }
  }, 20), [scrollbar]);

  const [, drop] = useDrop({
    accept: dropAccept,
    hover(item, monitor) {
      const { x, y } = monitor.getSourceClientOffset();
      const { clientHeight, clientWidth } = scrollElement.current;
      const horizonOffset = clientWidth * scrollBorder;
      const verticalOffset = clientHeight * scrollBorder;

      if (x < horizonOffset) {
        horizonScroll(-scrollOffset);
      }
      if (x > clientWidth - horizonOffset) {
        horizonScroll(scrollOffset);
      }
      if (y < verticalOffset) {
        verticalScroll(-scrollOffset);
      }
      if (y > clientHeight - verticalOffset) {
        verticalScroll(scrollOffset);
      }
    },
  });
  drop(scrollElement);

  return (
    <div
      ref={scrollElement}
      onTransitionEnd={onEndListX || onEndListY ? handlerScroll : undefined}
      onMouseMove={onEndListX || onEndListY ? handlerScroll : undefined}
      {...props}
    >
      {children}
    </div>
  );
}

CustomScroll.propTypes = {
  scrollLiftOnWheel: PropTypes.bool,
  onEndListX: PropTypes.func,
  onEndListY: PropTypes.func,
  dropAccept: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};

CustomScroll.defaultProps = {
  dropAccept: [],
  scrollLiftOnWheel: false,
  onEndListX: undefined,
  onEndListY: undefined,
};

export default CustomScroll;
