import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Loader.module.scss';

function Loader({ className }) {
  return (
    <div className={classNames(styles.box, className)} />
  );
}

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
