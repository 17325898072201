import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import styles from './BlackHole.module.scss';
import BlackHoleIcon from '../../Images/BlackHole.png';
import { getBlackHoleGoals } from '../../../DataCore/Store/BlackHole/slice';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import OpenFullscreen from '../../Images/Expand.svg';
import CloseFullscreen from '../../Images/Collapse.svg';
import CustomScroll from '../CustomScroll/CustomScroll';
import GoalBoxDragBlackHole from '../GoalBox/GoalBoxDragBlackHole';
import Button from '../Button/Button';

function BlackHole({ showBlackHole, onClose }) {
  const ref = useRef();
  const blackHoleGoals = useSelector(getBlackHoleGoals);
  const [fullScreen, setFullScreen] = useState(false);

  const [{ canDrop, itemType, isOver }, drop] = useDrop(() => ({
    accept: [
      DRAG_TYPES.GOAL_FROM_SPACE,
      DRAG_TYPES.GOAL_FROM_BLACK_HOLE,
    ],
    drop: () => ({ name: GOAL_DROP.BLACK_HOLE }),
    canDrop: (item, monitor) => monitor.getItemType() === DRAG_TYPES.GOAL_FROM_SPACE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      itemType: monitor.getItemType(),
    }),
  }), []);
  drop(ref);

  const handleClickOnPage = useCallback((event) => {
    if (showBlackHole && ref && !ref.current.contains(event.target)) {
      onClose();
    }
  }, [onClose, showBlackHole]);

  useEffect(() => {
    window.addEventListener('click', handleClickOnPage);

    return () => {
      window.removeEventListener('click', handleClickOnPage);
    };
  }, [handleClickOnPage]);

  return (
    <div
      ref={ref}
      className={classNames(
        styles.box,
        {
          [styles.hidden]: !showBlackHole,
          [styles.fullScreen]: fullScreen && itemType !== DRAG_TYPES.GOAL_FROM_BLACK_HOLE,
          [styles.canDrag]: canDrop && !isOver,
          [styles.canDropOver]: canDrop && isOver,
        },
      )}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <img className={styles.titleIcon} src={BlackHoleIcon} alt="" />
          <h3 className={styles.titleText}>Чёрная Дыра</h3>
        </div>
        <Button
          icon={fullScreen ? CloseFullscreen : OpenFullscreen}
          onClick={() => setFullScreen(!fullScreen)}
          className={styles.button}
        />
      </div>
      <CustomScroll scrollLiftOnWheel={!fullScreen} className={styles.goalBox}>
        <div className={styles.goals}>
          {blackHoleGoals.map((goal) => (
            <GoalBoxDragBlackHole
              key={goal.id}
              goal={goal}
            />
          ))}
        </div>
      </CustomScroll>
    </div>
  );
}

BlackHole.propTypes = {
  showBlackHole: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BlackHole;
