import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { endLoadingSpaces, setSpaces, startLoadingSpaces } from '../Store/Space/slice';
import SpaceRepository from '../Repositories/Api/SpaceRepository';
import Space from '../Models/Space';
import { GOAL_STATES, YM_ACTION_TYPES } from '../../Common/const';
import { setShowCreateGoalPopup, setShowCreateSpacePopup } from '../Store/Session/slice';
import store from '../Store/store';
import { ymAction } from '../../Common/func';

export default class SpaceService {
  static async getAll() {
    store.dispatch(startLoadingSpaces());

    const result = await SpaceRepository.getAll();
    if (!result.status) {
      store.dispatch(endLoadingSpaces());
      return result;
    }

    const spaces = result.data;
    const spaceWithGoalsInProgress = spaces.map((space) => new Space({
      ...space,
      goals: _sortBy(_filter(space.goals, ['state', GOAL_STATES.IN_PROGRESS]), 'index'),
    }));
    store.dispatch(setSpaces(spaceWithGoalsInProgress));
    store.dispatch(endLoadingSpaces());

    const spacesLength = _get(spaces, 'length');
    const goalsLength = _get(spaces, '0.goals.length');
    store.dispatch(setShowCreateSpacePopup(!spacesLength));
    store.dispatch(setShowCreateGoalPopup(spacesLength === 1 && !goalsLength));

    return { status: true };
  }

  static async create(space) {
    store.dispatch(startLoadingSpaces());

    const resultCreate = await SpaceRepository.create(space);
    if (!resultCreate.status) {
      store.dispatch(endLoadingSpaces());
      return resultCreate;
    }

    ymAction(YM_ACTION_TYPES.CREATE_SPACE);
    return SpaceService.getAll();
  }

  static async update(space) {
    store.dispatch(startLoadingSpaces());

    const resultCreate = await SpaceRepository.update(space);
    if (!resultCreate.status) {
      store.dispatch(endLoadingSpaces());
      return resultCreate;
    }

    const oldSpace = _find(store.getState().space.spaces, ['id', space.id]);
    if (oldSpace && oldSpace.name !== space.name) {
      ymAction(YM_ACTION_TYPES.RENAME_SPACE);
    }

    ymAction(YM_ACTION_TYPES.EDIT_SPACE);
    return SpaceService.getAll();
  }
}
