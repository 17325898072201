import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import styles from './Popup.module.scss';

function Popup({
  always, position, message, children,
}) {
  const [showPopup, setShowPopup] = useState(always === 'show');
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [{
      name: 'arrow',
      options: {
        element: arrowElement,
      },
    }, {
      name: 'offset', options: { offset: [0, 8] },
    }],
  });

  const handlerShowPopup = useCallback(async () => {
    setShowPopup(true);
    await update();
  }, [update]);

  const handlerHidePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  useEffect(() => {
    if (always === 'show') {
      handlerShowPopup();
    } else if (always === 'hide') {
      handlerHidePopup();
    }
  }, [always, handlerHidePopup, handlerShowPopup]);

  return (
    <>
      <div
        ref={setReferenceElement}
        onMouseEnter={always === 'none' ? handlerShowPopup : undefined}
        onMouseLeave={always === 'none' ? handlerHidePopup : undefined}
      >
        {children}
      </div>
      <div
        ref={setPopperElement}
        className={classNames(styles.popup, { [styles.hidden]: !showPopup })}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        {message}
        <div ref={setArrowElement} className={styles.arrow} style={popperStyles.arrow} />
      </div>
    </>
  );
}

Popup.propTypes = {
  message: PropTypes.string.isRequired,
  always: PropTypes.oneOf(['none', 'show', 'hide']),
  position: PropTypes.oneOf([
    'top', 'top-start', 'top-end',
    'bottom', 'bottom-start', 'bottom-end',
    'right', 'right-start', 'right-end',
    'left', 'left-start', 'left-end',
    'auto', 'auto-start', 'auto-end',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
};

Popup.defaultProps = {
  always: 'none',
  position: 'top',
  children: undefined,
};

export default Popup;
