import React from 'react';
import PropTypes from 'prop-types';

function ToolsIcon({ color, ...props }) {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path fill={color} opacity="0.1" d="M0 16C0 10.3995 0 7.59921 1.08993 5.46009C2.04867 3.57847 3.57847 2.04867 5.46009 1.08993C7.59921 0 10.3995 0 16 0H32C37.6005 0 40.4008 0 42.5399 1.08993C44.4215 2.04867 45.9513 3.57847 46.9101 5.46009C48 7.59921 48 10.3995 48 16V32C48 37.6005 48 40.4008 46.9101 42.5399C45.9513 44.4215 44.4215 45.9513 42.5399 46.9101C40.4008 48 37.6005 48 32 48H16C10.3995 48 7.59921 48 5.46009 46.9101C3.57847 45.9513 2.04867 44.4215 1.08993 42.5399C0 40.4008 0 37.6005 0 32V16Z" />
      <path fill={color} opacity="0.5" d="M21.0938 22.6875L24.4219 26.0156C24.1719 26.4844 24.0469 27 24.0469 27.5625C24.0469 27.75 24.0625 27.9375 24.0938 28.125L17.1094 35.1094C16.5469 35.6719 15.8594 35.9531 15.0469 35.9531C14.2031 35.9531 13.4844 35.6562 12.8906 35.0625C12.3281 34.5 12.0469 33.7969 12.0469 32.9531C12.0469 32.1406 12.3281 31.4531 12.8906 30.8906L21.0938 22.6875ZM14.2031 33.7969C14.4219 34.0156 14.6875 34.125 15 34.125C15.3125 34.125 15.5781 34.0156 15.7969 33.7969C16.0156 33.5781 16.125 33.3125 16.125 33C16.125 32.6875 16.0156 32.4219 15.7969 32.2031C15.5781 31.9844 15.3125 31.875 15 31.875C14.6875 31.875 14.4219 31.9844 14.2031 32.2031C13.9844 32.4219 13.875 32.6875 13.875 33C13.875 33.3125 13.9844 33.5781 14.2031 33.7969ZM35.8125 17.1094C35.9375 17.6406 36 18.1875 36 18.75C36 19.5312 35.7969 20.3906 35.3906 21.3281C35.0156 22.2344 34.5625 22.9688 34.0312 23.5312C33.5938 23.9375 33.0156 24.3281 32.2969 24.7031C31.5781 25.0781 30.9219 25.3125 30.3281 25.4062L30 25.0781C29.8125 24.8906 29.5781 24.7188 29.2969 24.5625C29.0156 24.4062 28.7188 24.2812 28.4062 24.1875C28.0938 24.0938 27.8125 24.0469 27.5625 24.0469C27 24.0469 26.4844 24.1719 26.0156 24.4219L22.6875 21.0938L22.875 20.8594C22.4688 19.7031 22.4062 18.5 22.6875 17.25C22.9688 15.9688 23.5625 14.875 24.4688 13.9688C24.8438 13.625 25.2969 13.2969 25.8281 12.9844C26.3906 12.6719 26.9688 12.4375 27.5625 12.2812C28.1875 12.0938 28.75 12 29.25 12C29.8125 12 30.3594 12.0625 30.8906 12.1875C31.1719 12.25 31.3125 12.4375 31.3125 12.75C31.3125 12.9062 31.25 13.0312 31.125 13.125L27.6562 16.6406L28.1719 19.8281L31.3594 20.3438L34.875 16.875C34.9688 16.75 35.0938 16.6875 35.25 16.6875C35.5312 16.6875 35.7188 16.8281 35.8125 17.1094Z" />
      <path fill={color} d="M35.4844 30.5625C35.8281 30.9062 36 31.3125 36 31.7812C36 32.25 35.8281 32.6562 35.4844 33L33 35.4844C32.6562 35.8281 32.25 36 31.7812 36C31.3125 36 30.9062 35.8281 30.5625 35.4844L25.0781 30C24.5469 29.4688 24.2188 28.8281 24.0938 28.0781C23.9688 27.3281 24.0781 26.6406 24.4219 26.0156L19.4062 21H16.5L12 15L15 12L21 16.5V19.4062L26.0156 24.4219C26.4844 24.1719 27 24.0469 27.5625 24.0469C27.8125 24.0469 28.0938 24.0938 28.4062 24.1875C28.7188 24.2812 29.0156 24.4062 29.2969 24.5625C29.5781 24.7188 29.8125 24.8906 30 25.0781L35.4844 30.5625Z" />
    </svg>
  );
}

ToolsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ToolsIcon;
