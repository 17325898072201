import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import GoalBox from './GoalBox';
import Goal from '../../../DataCore/Models/Goal';
import GoalService from '../../../DataCore/Service/GoalService';
import ToastService from '../../../DataCore/Service/ToastService';

function GoalBoxDrag({
  goal,
  index,
  canDropNewGoal,
  editable,
  onClick,
  onAchievementClick,
  onDragToAchievement,
  onDragToBlackHole,
  onReorder,
}) {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: [
      DRAG_TYPES.GOAL_FROM_SPACE,
      DRAG_TYPES.GOAL_FROM_BLACK_HOLE,
    ],
    canDrop: (item, monitor) => {
      if (canDropNewGoal) {
        return true;
      }
      return item?.goal.spaceId === goal.spaceId
        && monitor.getItemType() === DRAG_TYPES.GOAL_FROM_SPACE;
    },
    drop: (item) => ({
      name: GOAL_DROP.SPACE,
      goal: new Goal({
        ...item.goal,
        spaceId: goal.spaceId,
        index,
      }),
    }),
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const dragSpaceId = item.spaceId;
      const dragStartSpaceId = item.startSpaceId;
      const hoverIndex = index;
      const hoverSpaceId = goal.spaceId;
      if (dragSpaceId === hoverSpaceId && dragIndex === hoverIndex) {
        return;
      }
      onReorder({
        sourceSpaceId: dragStartSpaceId,
        goal: new Goal({
          ...item.goal,
          index: hoverIndex,
          spaceId: hoverSpaceId,
        }),
      });
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
      // eslint-disable-next-line no-param-reassign
      item.spaceId = hoverSpaceId;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPES.GOAL_FROM_SPACE,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => ({
      goal,
      spaceId: goal.spaceId,
      startSpaceId: goal.spaceId,
      index,
    }),
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult?.name === GOAL_DROP.ACHIEVE) {
        onReorder();
        onDragToAchievement(goal);
      } else if (dropResult?.name === GOAL_DROP.BLACK_HOLE) {
        onReorder();
        onDragToBlackHole(goal);
      } else if (dropResult?.name === GOAL_DROP.SPACE) {
        if (dropResult.goal instanceof Goal) {
          const result = await GoalService.update(goal.spaceId, dropResult.goal);
          if (!result.status) {
            ToastService.createNetworkDangerToast(result, 'goalBox.dropError');
          }
        }
        onReorder();
      } else {
        onReorder();
      }
    },
  });

  drag(drop(ref));

  return (
    <GoalBox
      innerRef={ref}
      transparency={isDragging}
      goal={goal}
      editable={editable}
      onClick={onClick}
      onAchievementClick={onAchievementClick}
    />
  );
}

GoalBoxDrag.propTypes = {
  goal: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  canDropNewGoal: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
  onClick: PropTypes.func,
  onAchievementClick: PropTypes.func,
  onDragToBlackHole: PropTypes.func.isRequired,
  onDragToAchievement: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
};

GoalBoxDrag.defaultProps = {
  editable: false,
  onClick: undefined,
  onAchievementClick: undefined,
};

export default GoalBoxDrag;
