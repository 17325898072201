import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GoalService from '../../../DataCore/Service/GoalService';
import GoalFormDialog from '../GoalFormDialog/GoalFormDialog';
import styles from './EditGoalDialog.module.scss';
import GoalIcon from '../GoalIcon/GoalIcon';
import { parseFormErrors } from '../../func';

function EditGoalDialog({
  spaceId, goal, showDialog, onClose,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'goalForm' });

  const handleUpdateGoal = async (values) => {
    if (!spaceId) {
      throw new Error('Required field spaceId');
    }

    const result = await GoalService.update(spaceId, values);
    if (result.status) {
      onClose();
      return undefined;
    }
    return parseFormErrors(result);
  };

  const title = goal ? (
    <div className={styles.title}>
      <GoalIcon className={styles.titleIcon} iconName={goal.icon} />
      <h2 className={styles.titleText}>{goal.name}</h2>
    </div>
  ) : (
    <div />
  );

  return (
    <GoalFormDialog
      showDialog={showDialog}
      onSubmit={handleUpdateGoal}
      confirmButtonText={t('update')}
      title={title}
      onCancel={onClose}
      initialValues={goal || {}}
    />
  );
}

EditGoalDialog.propTypes = {
  spaceId: PropTypes.number,
  goal: PropTypes.object,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditGoalDialog.defaultProps = {
  spaceId: undefined,
  goal: undefined,
};

export default EditGoalDialog;
