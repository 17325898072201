import HttpClient from './HttpClient';
import Goal from '../../Models/Goal';
import parseResponse from './parseResponse';

export default class GoalRepository {
  static async getAllBy(spaceId) {
    return HttpClient.get(`spaces/${spaceId}/goals`).then(
      (response) => parseResponse(response, (data) => data),
      ({ response }) => parseResponse(response),
    );
  }

  static async create(spaceId, goal) {
    return HttpClient.post(`spaces/${spaceId}/goals`, new Goal(goal).toApi()).then(
      (response) => parseResponse(response, Goal.fromApi),
      ({ response }) => parseResponse(response, undefined, Goal.parseErrors),
    );
  }

  static async update(spaceId, goal) {
    return HttpClient.put(`spaces/${spaceId}/goals/${goal.id}`, new Goal(goal).toApi()).then(
      (response) => parseResponse(response, Goal.fromApi),
      ({ response }) => parseResponse(response, undefined, Goal.parseErrors),
    );
  }

  static async delete(spaceId, goal) {
    return HttpClient.delete(`spaces/${spaceId}/goals/${goal.id}`).then(
      (response) => parseResponse(response),
      ({ response }) => parseResponse(response),
    );
  }
}
