import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import _some from 'lodash/some';
import _findIndex from 'lodash/findIndex';
import {
  DIALOG_NAMES, DRAG_TYPES, GOAL_DROP, SPACE_COLORS,
} from '../../const';
import CreateButton from '../CreateButton/CreateButton';
import SpaceIcon from '../SpaceIcon/SpaceIcon';
import Edit from '../../Images/Edit.svg';
import Popup from '../Popup/Popup';
import styles from './SpaceBox.module.scss';
import GoalBoxDrag from '../GoalBox/GoalBoxDrag';
import Goal from '../../../DataCore/Models/Goal';
import GoalService from '../../../DataCore/Service/GoalService';
import DialogService from '../../../DataCore/Service/DialogService';
import { getShowCreateGoalPopup } from '../../../DataCore/Store/Session/slice';

const maxGoals = process.env.REACT_APP_MAX_GOALS;

function SpaceBox({
  space, innerRef, transparency, className,
}) {
  const showCreateGoalPopup = useSelector(getShowCreateGoalPopup);
  const color = SPACE_COLORS[space.color];
  const disabledCreateGoal = space.goals.length >= maxGoals;
  const { t } = useTranslation('translation', { keyPrefix: 'spaceBox' });

  const [{ goalIsOver, goalCanDrop }, dropGoal] = useDrop({
    accept: [
      DRAG_TYPES.GOAL_FROM_SPACE,
      DRAG_TYPES.GOAL_FROM_BLACK_HOLE,
    ],
    canDrop: (item) => !disabledCreateGoal || _some(space.goals, ['id', item?.goal.id]),
    collect: (monitor) => ({
      goalIsOver: monitor.isOver(),
      goalCanDrop: !!monitor.canDrop(),
    }),
    drop: (item) => {
      const findIndex = _findIndex(space.goals, ['id', item.goal.id]);
      return ({
        name: GOAL_DROP.SPACE,
        goal: new Goal({
          ...item.goal,
          spaceId: space.id,
          index: findIndex >= 0 ? findIndex : space.goals.length,
        }),
      });
    },
    hover(item, monitor) {
      if (disabledCreateGoal) {
        return;
      }
      const hoverOnGoal = !monitor.isOver({ shallow: true });
      if (hoverOnGoal) {
        return;
      }

      if (_some(space.goals, ['id', item.goal.id])) {
        return;
      }

      const hoverIndex = space.goals.length;
      const hoverSpaceId = space.id;
      const reorderGoal = new Goal({
        ...item.goal,
        index: hoverIndex,
        spaceId: hoverSpaceId,
      });
      GoalService.setReorderGoal(reorderGoal);
    },
  });

  const handleReorderGoal = ({ goal: reorderGoal } = {}) => {
    if (!reorderGoal || !disabledCreateGoal || _some(space.goals, ['id', reorderGoal?.id])) {
      GoalService.setReorderGoal(reorderGoal);
    }
  };

  const handleShowDialog = (name, data) => {
    DialogService.openDialog(name, data);
  };

  return (
    <div
      ref={innerRef}
      className={classNames(
        styles.box,
        {
          [styles.transparency]: transparency,
          [styles.canNotDropOver]: goalIsOver && !goalCanDrop,
          [styles.canDrag]: !goalIsOver && goalCanDrop,
          [styles.canDropOver]: goalIsOver && goalCanDrop,
        },
        className,
      )}
      style={{ border: `2px solid ${color}` }}
    >
      <div className={styles.header} style={{ color }}>
        <div className={styles.title}>
          <SpaceIcon className={styles.spaceIcon} iconName={space.icon} color={color} />
          <h3 className={styles.spaceName}>{space.name}</h3>
        </div>
        <button
          type="button"
          className={styles.editIcon}
          onClick={() => handleShowDialog(DIALOG_NAMES.EDIT_SPACE, { space })}
        >
          <img src={Edit} alt="Edit" />
        </button>
      </div>
      <div ref={dropGoal} className={styles.goals}>
        {space.goals.map((item, i) => (
          <GoalBoxDrag
            key={item.id}
            index={i}
            goal={item}
            editable
            canDropNewGoal={!disabledCreateGoal}
            onClick={(goal) => handleShowDialog(DIALOG_NAMES.EDIT_GOAL, { goal })}
            onAchievementClick={(goal) => handleShowDialog(DIALOG_NAMES.GOAL_ACHIEVEMENT, { goal })}
            onDragToAchievement={
              (goal) => handleShowDialog(DIALOG_NAMES.GOAL_ACHIEVEMENT, { goal })
            }
            onDragToBlackHole={(goal) => handleShowDialog(DIALOG_NAMES.GOAL_BLACK_HOLE, { goal })}
            onReorder={handleReorderGoal}
          />
        ))}
        <Popup message={t('createFirstGoalPopup')} position="top-start" always={showCreateGoalPopup ? 'show' : 'hide'}>
          <Popup message={t('disabledCreateGoalPopup')} always={disabledCreateGoal ? 'none' : 'hide'}>
            <CreateButton
              text={t('createGoal')}
              disabled={disabledCreateGoal}
              onClick={() => handleShowDialog(DIALOG_NAMES.CREATE_GOAL, { spaceId: space.id })}
            />
          </Popup>
        </Popup>
      </div>
    </div>
  );
}

SpaceBox.propTypes = {
  space: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  transparency: PropTypes.bool,
  className: PropTypes.string,
};

SpaceBox.defaultProps = {
  innerRef: undefined,
  transparency: false,
  className: '',
};

export default SpaceBox;
