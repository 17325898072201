import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import _invoke from 'lodash/invoke';
import { Link } from 'react-router-dom';
import { existingLanguages } from '../../../i18n';
import { getToken, islLogIn } from '../../../DataCore/Store/Session/slice';
import Button from '../Button/Button';
import styles from './Header.module.scss';
import { ReactComponent as LogoSvg } from '../../Images/Logo.svg';
import { ReactComponent as HelpSvg } from './Help.svg';
import { ReactComponent as LogOutSvg } from './LogOut.svg';
import { ReactComponent as FeedbackSvg } from './Feedback.svg';
import { ReactComponent as LinkSvg } from '../../Images/Link.svg';
import { ReactComponent as TelegramSvg } from '../../Images/Telegram.svg';
import { ReactComponent as GoogleSvg } from '../../Images/Google.svg';
import { DIALOG_NAMES, LOGIN_PROVIDER_TYPES, YM_ACTION_TYPES } from '../../const';
import DialogService from '../../../DataCore/Service/DialogService';
import { getAvailableLoginProviders } from '../../../DataCore/Store/LoginProvider/slice';
import Popup from '../Popup/Popup';
import { ymAction } from '../../func';
import AuthService from '../../../DataCore/Service/AuthService';

function Header() {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'header' });
  const loginProviders = useSelector(getAvailableLoginProviders);
  const token = useSelector(getToken);
  const isLogIn = useSelector(islLogIn);

  const [showPopup, setShowPopup] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  const handleClickOnPage = useCallback((event) => {
    if (!_invoke(referenceElement, 'contains', event.target)) {
      setShowPopup(false);
    }
  }, [referenceElement]);

  useEffect(() => {
    window.addEventListener('click', handleClickOnPage);

    return () => {
      window.removeEventListener('click', handleClickOnPage);
    };
  }, [handleClickOnPage]);

  useEffect(() => {
    if (showPopup && update) {
      update();
    }
  }, [showPopup, update]);

  const addLoginProvider = (providerName) => {
    const redirectUrl = new URL('/', window.location);
    const url = new URL(`/api/LoginProviders/${providerName}`, process.env.REACT_APP_BACKEND_URL);
    url.searchParams.set('authKey', token);
    url.searchParams.set('returnUrl', redirectUrl.toString());
    window.location = url;
  };

  const handleChangeLocale = async (event) => {
    const locale = _get(event, 'target.value');
    if (locale) {
      await i18n.changeLanguage(locale);
    }
  };

  const handleLogOut = () => {
    AuthService.logOut();
  };

  return (
    <div className={styles.header}>
      <Link to="/"><LogoSvg className={styles.logo} /></Link>
      <div className={styles.options}>
        {loginProviders.length ? (
          <div>
            <Popup message={t('addProviderPopup')} position="left">
              <Button
                innerRef={setReferenceElement}
                onClick={() => setShowPopup(!showPopup)}
              >
                <LinkSvg className={styles.icon} />
              </Button>
            </Popup>
            <div
              ref={setPopperElement}
              className={classNames(styles.popup, { [styles.hidden]: !showPopup })}
              style={popperStyles.popper}
              {...attributes.popper}
            >
              {loginProviders.map(({ type }) => {
                switch (type) {
                  case LOGIN_PROVIDER_TYPES.TELEGRAM:
                    return (
                      <Button
                        key={LOGIN_PROVIDER_TYPES.TELEGRAM}
                        className={styles.telegramButton}
                        onClick={() => {
                          addLoginProvider(LOGIN_PROVIDER_TYPES.TELEGRAM);
                          ymAction(YM_ACTION_TYPES.ADD_PROVIDER_TELEGRAM);
                        }}
                      >
                        <TelegramSvg className={styles.icon} />
                      </Button>
                    );
                  case LOGIN_PROVIDER_TYPES.GOOGLE:
                    return (
                      <Button
                        key={LOGIN_PROVIDER_TYPES.GOOGLE}
                        className={styles.googleButton}
                        onClick={() => {
                          addLoginProvider(LOGIN_PROVIDER_TYPES.GOOGLE);
                          ymAction(YM_ACTION_TYPES.ADD_PROVIDER_GOOGLE);
                        }}
                      >
                        <GoogleSvg className={styles.icon} />
                      </Button>
                    );
                  default:
                    return undefined;
                }
              })}
            </div>
          </div>
        ) : undefined }
        <select
          className={styles.languageSelector}
          onChange={handleChangeLocale}
          value={i18n.language}
        >
          {existingLanguages.map((language) => (
            <option key={language} value={language}>{t(language)}</option>
          ))}
        </select>
        <Button onClick={() => DialogService.openDialog(DIALOG_NAMES.FEEDBACK)}>
          <FeedbackSvg className={styles.icon} />
        </Button>
        <Button onClick={() => DialogService.openDialog(DIALOG_NAMES.HELP)}>
          <HelpSvg className={styles.icon} />
        </Button>
        {isLogIn ? <Button onClick={handleLogOut}><LogOutSvg /></Button> : null }
      </div>
    </div>
  );
}

export default Header;
