import React from 'react';
import BlackHoleBackground from '../../Images/BlackHoleBackground.jpeg';

function PreloadImages() {
  return (
    <div style={{ display: 'none' }}>
      <img src={BlackHoleBackground} alt="" />
    </div>
  );
}

export default PreloadImages;
