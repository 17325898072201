const initialState = {
  achievements: [],
  nextPageIndex: 0,
  achievementsTotal: 0,
  loadingAchievements: false,
  reorderAchievement: undefined,

  loadingTopAchievements: false,
  firstLoadingTopAchievements: true,

  loadingAchievementsIntoSpaces: {},
  nextPageIndexIntoSpaces: {},
  achievementsTotalIntoSpaces: {},
};

export default initialState;
