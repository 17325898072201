import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Img } from 'react-image';
import { Pagination } from '@egjs/flicking-plugins';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import classNames from 'classnames';
import styles from './SignIn.module.scss';
import { ReactComponent as LogoSvg } from '../../Common/Images/Logo.svg';
import { ReactComponent as ImageSvg } from '../../Common/Images/Image.svg';
import Slide1 from './Slide1.jpg';
import { ReactComponent as TelegramSvg } from '../../Common/Images/Telegram.svg';
import { ReactComponent as GoogleSvg } from '../../Common/Images/Google.svg';
import { LOGIN_PROVIDER_TYPES, TOAST_STATUS, YM_ACTION_TYPES } from '../../Common/const';
import { ymAction } from '../../Common/func';
import PrivacyPolicyLink from '../../Common/Components/PrivacyPolicyLink/PrivacyPolicyLink';
import AuthService from '../../DataCore/Service/AuthService';
import ErrorMessage from '../../Common/Components/ErrorMessage/ErrorMessage';
import ToastService from '../../DataCore/Service/ToastService';
import Toast from '../../DataCore/Models/Toast';
import Button from '../../Common/Components/Button/Button';
import Loader from '../../Common/Components/Loader/Loader';

function redirect(providerName) {
  const redirectUrl = new URL('/SignIn', window.location);
  const url = new URL('/api/Auths', process.env.REACT_APP_BACKEND_URL);
  url.searchParams.set('authProvider', providerName);
  url.searchParams.set('returnUrl', redirectUrl.toString());
  window.location = url;
}

function SignInPage() {
  const [searchParams] = useSearchParams();
  const plugins = useMemo(() => [new Pagination()], []);
  const token = searchParams.get('Authorization');
  const errorMessage = searchParams.get('ErrorMessage');
  const { t } = useTranslation('translation', { keyPrefix: 'signInPage' });

  useEffect(() => {
    if (errorMessage) {
      ToastService.enqueueToast(new Toast({
        message: errorMessage,
        status: TOAST_STATUS.DANGER,
      }));
    }
  }, [errorMessage]);

  if (token) {
    AuthService.logIn(token);
  }

  const handleTelegram = () => {
    ymAction(YM_ACTION_TYPES.SIGN_IN_TELEGRAM);
    redirect(LOGIN_PROVIDER_TYPES.TELEGRAM);
  };

  const handleGoogle = () => {
    ymAction(YM_ACTION_TYPES.SIGN_IN_GOOGLE);
    redirect(LOGIN_PROVIDER_TYPES.GOOGLE);
  };

  return (
    <div className={styles.content}>
      <div className={styles.signInBox}>
        <div className={styles.info}>
          <LogoSvg className={styles.logo} />
          <div className={styles.slider}>
            <Flicking
              plugins={plugins}
              circular
            >
              <div className={classNames(styles.slideBox, 'panel')}>
                <div className={styles.slideImage}>
                  <Img
                    src={Slide1}
                    loader={<Loader />}
                    unloader={<ImageSvg className={styles.errorImage} />}
                  />
                </div>
                <p>{t('slade1')}</p>
              </div>
              <div className={classNames(styles.slideBox, 'panel')}>
                <div className={styles.slideImage}>
                  <Img
                    src={Slide1}
                    loader={<Loader />}
                    unloader={<ImageSvg className={styles.errorImage} />}
                  />
                </div>
                <p>{t('slade1')}</p>
              </div>
              <div className={classNames(styles.slideBox, 'panel')}>
                <div className={styles.slideImage}>
                  <Img
                    src={Slide1}
                    loader={<Loader />}
                    unloader={<ImageSvg className={styles.errorImage} />}
                  />
                </div>
                <p>{t('slade1')}</p>
              </div>
              <ViewportSlot>
                <div className="flicking-pagination" />
              </ViewportSlot>
            </Flicking>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.social}>
          <p>
            {t('socialSignInTitle')}
            :
          </p>
          <div className={styles.buttons}>
            <Button onClick={handleTelegram} className={`${styles.button} ${styles.telegramButton}`}>
              <TelegramSvg className={styles.buttonIcon} />
            </Button>
            <Button type="button" onClick={handleGoogle} className={`${styles.button} ${styles.googleButton}`}>
              <GoogleSvg className={styles.buttonIcon} />
            </Button>
          </div>
        </div>
        <ErrorMessage content={errorMessage} className={styles.errorMessage} />
        <PrivacyPolicyLink />
      </div>
    </div>
  );
}

export default SignInPage;
