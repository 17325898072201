import {
  addAchievements,
  endLoadingAchievements,
  endLoadingAchievementsIntoSpaces,
  endLoadingTopAchievements,
  setAchievements,
  setAchievementsNextPageIndex,
  setAchievementsNextPageIndexIntoSpaces,
  setAchievementsTotal,
  setAchievementsTotalIntoSpaces,
  setReorderAchievements,
  startLoadingAchievements,
  startLoadingAchievementsIntoSpaces,
  startLoadingTopAchievements,
} from '../Store/Achievement/slice';
import AchievementRepository from '../Repositories/Api/AchievementRepository';
import { DIALOG_NAMES } from '../../Common/const';
import { openDialog } from '../Store/Dialog/slice';
import store from '../Store/store';

export default class AchievementService {
  static async getAll() {
    store.dispatch(startLoadingAchievements());

    const result = await AchievementRepository.getAll();
    if (!result.status) {
      store.dispatch(endLoadingAchievements());
      return result;
    }

    store.dispatch(setAchievements(result.data));
    store.dispatch(endLoadingAchievements());

    return { status: true };
  }

  static async loadTopAchievements() {
    store.dispatch(startLoadingTopAchievements());

    const result = await AchievementRepository.getAll(true);
    if (!result.status) {
      store.dispatch(endLoadingTopAchievements());
      return result;
    }

    store.dispatch(addAchievements(result.data));
    store.dispatch(endLoadingTopAchievements());

    return { status: true };
  }

  static async reload() {
    store.dispatch(setAchievements([]));
    store.dispatch(startLoadingAchievements());

    const result = await AchievementRepository.getAllByPage(0);
    if (!result.status) {
      store.dispatch(endLoadingAchievements());
      return result;
    }

    store.dispatch(setAchievements(result.data.achievements));
    store.dispatch(setAchievementsTotal(result.data.achievementsTotal));
    store.dispatch(setAchievementsNextPageIndex(1));
    store.dispatch(endLoadingAchievements());

    return { status: true };
  }

  static async loadMore() {
    const state = store.getState();
    const { nextPageIndex, loadingAchievements } = state.achievement;

    if (loadingAchievements) return { status: false };

    store.dispatch(startLoadingAchievements());

    const result = await AchievementRepository.getAllByPage(nextPageIndex);
    if (!result.status) {
      store.dispatch(endLoadingAchievements());
      return result;
    }

    store.dispatch(addAchievements(result.data.achievements));
    store.dispatch(setAchievementsTotal(result.data.achievementsTotal));
    store.dispatch(setAchievementsNextPageIndex(nextPageIndex + 1));
    store.dispatch(endLoadingAchievements());

    return { status: true };
  }

  static async reloadBySpaceId(spaceId) {
    if (!spaceId) return { status: false };

    const state = store.getState();
    const { loadingAchievementsIntoSpaces } = state.achievement;

    if (loadingAchievementsIntoSpaces[spaceId]) return { status: false };

    store.dispatch(startLoadingAchievementsIntoSpaces(spaceId));

    const result = await AchievementRepository.getAllBySpaceIdAndPage(spaceId, 0);
    if (!result.status) {
      store.dispatch(endLoadingAchievementsIntoSpaces(spaceId));
      return result;
    }

    store.dispatch(addAchievements(result.data.achievements));
    store.dispatch(setAchievementsTotalIntoSpaces({
      spaceId,
      achievementsTotal: result.data.achievementsTotal,
    }));
    store.dispatch(setAchievementsNextPageIndexIntoSpaces({
      spaceId,
      nextPageIndex: 1,
    }));
    store.dispatch(endLoadingAchievementsIntoSpaces(spaceId));
    return { status: true };
  }

  static async loadMoreBySpaceId(spaceId) {
    if (!spaceId) return { status: false };

    const state = store.getState();
    const { loadingAchievementsIntoSpaces, nextPageIndexIntoSpaces } = state.achievement;

    if (loadingAchievementsIntoSpaces[spaceId]) return { status: false };

    store.dispatch(startLoadingAchievementsIntoSpaces(spaceId));

    const nextPageIndex = nextPageIndexIntoSpaces[spaceId];
    const result = await AchievementRepository.getAllBySpaceIdAndPage(spaceId, nextPageIndex);
    if (!result.status) {
      store.dispatch(endLoadingAchievementsIntoSpaces(spaceId));
      return result;
    }

    store.dispatch(addAchievements(result.data.achievements));
    store.dispatch(setAchievementsTotalIntoSpaces({
      spaceId,
      achievementsTotal: result.data.achievementsTotal,
    }));
    store.dispatch(setAchievementsNextPageIndexIntoSpaces({
      spaceId,
      nextPageIndex: nextPageIndex + 1,
    }));
    store.dispatch(endLoadingAchievementsIntoSpaces(spaceId));
    return { status: true };
  }

  static async loadAndOpenGoalDialog(goalId) {
    store.dispatch(startLoadingAchievements());

    const result = await AchievementRepository.getById(goalId);
    if (!result.status) {
      store.dispatch(endLoadingAchievements());
      return result;
    }

    const name = DIALOG_NAMES.ACHIEVE_SHARE;
    const goal = result.data;
    store.dispatch(openDialog({ name, goal }));
    store.dispatch(endLoadingAchievements());

    return { status: true };
  }

  static setReorderAchievement(achievement) {
    store.dispatch(setReorderAchievements(achievement));
  }
}
